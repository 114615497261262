import firebase from 'firebase';
import { BasicStructure } from './common/BasicStructure';

export enum DocumentAction {
  UPLOAD = 'upload',
  SIGN = 'sign',
}

export const DocumentActionLabel = {
  [DocumentAction.SIGN]: 'Assinar (por Clicksign)',
  [DocumentAction.UPLOAD]: 'Upload',
};

export interface UserDocuments {
  documentos: Document[];
  uid: string;
}

export interface Document {
  active?: boolean;
  automatic?: boolean;
  visibleToBanker: BasicStructure;
  id?: string;
  nome: string;
  mnemonico: string;
  descricao: string;
  guarantees?: BasicStructure[];
  typeOfAnticipation?: BasicStructure[];
  instrucoes: string;
  ativo: BasicStructure;
  automatico: BasicStructure;
  validade: BasicStructure;
  anoExercicio: BasicStructure;
  situacao?: string;
  qtdDias?: number;
  qtdArquivosEsperados: number;
  qtdArquviosSubmetidos: number;
  anosExercicios: [string];

  arquivosInfo?: ArquivoInfo[];
  email: string;
  uid?: string;
  dataAprovacao?: firebase.firestore.Timestamp;
  motivoReprovacao?: string;
  controleVencimentoId?: string;
  ordenacao?: number;
  anoExercicioQtd: number;
  documentoPendenteId?: string;
  pendenciaParaUsuario?: boolean;
  pendenciaParaAdm?: boolean;
  tipoDocumentoId?: string;
  instituicaoFinanceiraId?: string;
  maximumDocuments: number;
  minimumDocuments: number;
  multipleDocuments: any;

  docException?: boolean;
  usuarioAproReprov?: string;

  documentAction: DocumentAction;
  clicksignDocumentKey?: string;
  clicksignSignatureRequestKey?: string;
  anoExercicioCorrente?: boolean;
  documetType?: string;
}

export interface ArquivoInfo {
  path?: string;
  dataEmissao?: firebase.firestore.Timestamp;
  anoExercicio?: string;
  usuarioUploader?: string;
  dataUpload?: firebase.firestore.Timestamp;
  fileName?: string;
}

export interface DocControleVencimento {
  id?: string;
  uid?: string;
  email?: string;
  tipoDocumento: string;
  qtdDias: number;
  dataEmissao: firebase.firestore.Timestamp;
  dataVencimento: firebase.firestore.Timestamp;
  estaVencido: boolean;
  dataCriacao: firebase.firestore.Timestamp;
}

export interface DocumentoPendente {
  email: string;
  nomeUsuario: string;
  documentos: any[];
}
