import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase, { firestore } from 'firebase';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { User } from '../../../../functions/src/models/User';
import { CustomerJourneyAboutCompanyEntity, UtmSourceEntity } from '../../../../functions/src/models/customers/customer-journey-about-company.entity';
import { CustomerJourneyAboutYouEntity } from '../../../../functions/src/models/customers/customer-journey-about-you.entity';
import { CustomerJourneyCreditStatusEntity } from '../../../../functions/src/models/customers/customer-journey-credit-status.entity';
import { CustomerJourneyFinancialHealthEntity } from '../../../../functions/src/models/customers/customer-journey-financial-health.entity';





@Injectable({
  providedIn: 'root',
})
export class CustomerService implements OnDestroy {
  userSubscription: Subscription;
  customerSubscription: Subscription;
  customer = new BehaviorSubject<Customer>(null);


  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private angularFirestore: AngularFirestore,
  ) {
    this.userSubscription = authService.user.subscribe((user) => {
      if (user && !user.isAnonymous && user.isCustomer) {
        this.customerSubscription = this.angularFirestore
          .doc(`customers/${ user.uid }`)
          .valueChanges()
          .subscribe((customer: Customer) => {
            this.customer.next({
              ...customer,
              emailVerified: user.emailVerified,
            });
          });
      } else {
        this.customer.next(null);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async createAccount(customerData: Customer, userData: User, password: string): Promise<void> {
    const existsUserDocuments = await this.angularFirestore
      .collection(`users`, f => f.where('email', '==', userData.email))
      .get()
      .toPromise();
    if (!existsUserDocuments.empty) {
      const userDocument = existsUserDocuments.docs[0].data();
      userData.isAdmin = userDocument.isAdmin ?? false;
      userData.isAgent = userDocument.isAgent ?? false;
      userData.isBanker = userDocument.isBanker ?? false;
      userData.uid = userDocument.uid;
      userData.emailVerified = userDocument.emailVerified;
    } else {
      const userAuthData = await this.authService.createAccount(userData.email, password).catch((error) => {
        const errorData = new Error(error);
        errorData.name = 'create-account-error';
        throw errorData;
      });
      userData.createdAt = firestore.Timestamp.now();
      userData.displayName = customerData.name;
      userData.uid = userAuthData.uid;
      userData.emailVerified = userAuthData.emailVerified;
    }
    customerData.uid = userData.uid;
    customerData.emailVerified = userData.emailVerified;
    await this.angularFirestore.doc(`users/${ customerData.uid }`).set(userData, { merge: true }).catch((error) => {
      console.error('Error on save user data', error);
      throw new Error('save-user-error');
    });
    await this.authService.loginWithEmailAndPassword(customerData.email, password).catch((error) => {
      console.error('Error on user login', error);
      throw new Error('user-login-error');
    });
    await this.authService.sendEmailVerification().catch((error) => {
      console.error('Error on send email verification', error);
      throw new Error('send-email-verification-error');
    });
    await this.http.post(
      `${ environment.functionsUrl }/customer/create-customer-only`,
      { user: customerData },
      { responseType: 'json', headers: this.authService.getHeader() }
    ).toPromise();
  }

  checkIfCNPJExistsAsCustomer(cnpj: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firestore()
        .collection('customers')
        .where('cnpj', '==', cnpj)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error('Error checking if user CNPJ exists', err);
          reject(err);
        });
    });
  }

  checkIfEmailAsCustomerjourneycontrol(cnpj: string): Promise<any> {

    console.log("chamou aqui");
    const cnpjRef = cnpj.replace(/[^\d]/g, '');
    return new Promise((resolve, reject) => {
      firestore()
        .collection('customerjourneycontrol')
        .doc(cnpjRef)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            resolve(snapshot.data()); // Retorna os dados do documento
          } else {
            resolve(null); // Documento não encontrado
          }
        })
        .catch((err) => {
          console.error('Error checking if email exists as customer', err);
          reject(err);
        });
    });
  }

  getCustomerDataOnCustomerjourneycontrol(cnpj: string): Promise<any> {
    const cnpjRef = cnpj.replace(/[^\d]/g, '');
    return new Promise((resolve, reject) => {
      firestore()
        .collection('customerjourneycontrol')
        .doc(cnpjRef)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            resolve(snapshot.data());
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.error('Error checking if email exists as customer', err);
          reject(err);
        });
    });
  }

  customersInRegister(): void {
    const currentDate = new Date();
    const stepData = {
      date: currentDate,
      step: 'Cadastro'
    };
    this.angularFirestore.collection('customersInRegister').add(stepData)
      .then(() => {
        console.log('Valor do passo salvo com sucesso!');
      })
      .catch((error) => {
        console.error('Erro ao salvar o valor do passo:', error);
      });
  }

  async updateNumberCustomer(increment: number): Promise<void> {
    try {
      const docRef = this.angularFirestore.doc('configuracao/supersim');
      const doc = await docRef.get().toPromise();

      if (doc.exists) {
        const currentData = doc.data();
        const currentNumberCustomer = currentData?.numberCustomer || 0;
        const newNumberCustomer = currentNumberCustomer + increment;

        await docRef.update({ numberCustomer: newNumberCustomer });
        console.log(`Updated numberCustomer to ${newNumberCustomer}`);
      } else {
        console.error('Webflow document not found');
      }
    } catch (err) {
      console.error('Error updating numberCustomer', err);
    }
  }

  checkIfEmailExistsAsCustomer(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firestore()
        .collection('customers')
        .where('email', '==', email.toLowerCase())
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error('Error checking if email exists as customer', err);
          reject(err);
        });
    });
  }

  checkIfUtmExistsForCustomer(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firestore()
        .collection('customers')
        .where('email', '==', email.toLowerCase())
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            resolve(false);
          } else {
            // Verificar se o campo 'utm' existe no primeiro documento encontrado
            const doc = snapshot.docs[0].data();
            if (doc.hasOwnProperty('utm')) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        })
        .catch((err) => {
          console.error('Error checking if utm exists for customer', err);
          reject(err);
        });
    });
  }


  checkIfUserWhatsAppExists(whatsapp: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firestore()
        .collection('customers')
        .where('whatsapp', '==', whatsapp)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error('Error checking if user WhatsApp exists', err);
          reject(err);
        });
    });
  }

  async checkCupomExists(cupomCode: string, uid: string, data: Customer = {}): Promise<void> {

    try {
      const cupomDoc = await this.getCupomByCupomCode(cupomCode);

      if (!cupomDoc.exists) {
        throw new Error(`O Cupom ${ cupomCode } é inválido.`);
      }

      const cupomData = cupomDoc.data();

      if (cupomData.ativo !== 'true') {
        throw new Error(`O cupom ${ cupomCode } não está mais ativo.`);
      }

      const dataInicio = cupomData.dataInicio.toDate();
      const dataFim = cupomData.dataFim.toDate();
      const now = new Date();

      if (now < dataInicio || now > dataFim.setHours(23, 59, 59)) {
        throw new Error(`O cupom ${ cupomCode } já está vencido.`);
      }

      const customerRef: AngularFirestoreDocument<Customer> = this.angularFirestore.doc(`customers/${ uid }`);
      await customerRef.set({
        ...data,
        cupomCode,
        dataFim: dataFim.toLocaleString(),
        lastInteraction: firestore.Timestamp.now(),
      }, { merge: true });

      console.log('Customer atualizado com sucesso');
    } catch (error) {
      console.error('Error checking if cupom exists', error);
      throw error;
    }
  }

  private getCupomByCupomCode(cupomCode: string): Promise<firestore.DocumentSnapshot<firestore.DocumentData>> {
    return firestore()
      .collection('cupom')
      .doc(cupomCode)
      .get();
  }

  getCustomerByCNPJ(cnpj: string): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('customers', (x) => x.where('cnpj', '==', cnpj))
        .get()
        .toPromise()
        .then((snapshot) => {
          if (snapshot.docs.length > 0) {
            const data = snapshot.docs[0].data();
            resolve(data as Customer);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.error('Error getting customer', err);
          reject(err);
        });
    });
  }

  getCustomerByUID(uid: string): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('customers')
        .doc(uid)
        .get()
        .toPromise()
        .then((snapshot) => {
          if (snapshot.exists) {
            resolve(snapshot.data() as Customer);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.error('Error getting customer', err);
          reject(err);
        });
    });
  }

  getBndesProposalByID(
    id: string
  ): Promise<{
    idOferta: number;
    idProposta: number;
    cnpj: string;
    customer: Customer;
  }> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('bndes-proposal')
        .doc(id)
        .get()
        .then(async (snapshot) => {
          if (!snapshot.exists) {
            resolve(null);
          } else if (!snapshot.data()) {
            resolve(null);
          } else {
            const proposal = snapshot.data() as {
              idOferta: number;
              idProposta: number;
              cnpj: string;
              customer: Customer;
            };
            resolve(proposal);
          }
        })
        .catch((err) => {
          console.error('Error getting proposal', err);
          reject(err);
        });
    });
  }

  getSignUpConfiguration(): Observable<any> {
    return this.angularFirestore.collection('configuracao').doc('signup').valueChanges();
  }
  getGeneralConfiguration(): Observable<any> {
    return this.angularFirestore.collection('configuracao').doc('geral').valueChanges();
  }

  async signTerms(contract: Contract): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/customer/sign-terms`,
          { uid: contract.uid, contract },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Signed terms');
          resolve();
        })
        .catch((err) => {
          console.error('Error signing terms', err);
          reject(err);
        });
    });
  }

  async canceledTerms(uid: string, motivation: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${ environment.functionsUrl }/customer/${ uid }?cancel_terms=true`,
          { motivation },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Canceled terms');
          resolve();
        })
        .catch((err) => {
          console.error('Error canceled terms', err);
          reject(err);
        });
    });
  }

  async updateCustomerByRecurrence(uid: string, customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${ environment.functionsUrl }/customer/${ uid }?recurrence=true`,
          { customer },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Processo de Recorrencia Finalizado');
          resolve();
        })
        .catch((err) => {
          console.error('Erro no Processo de Recorrencia ', err);
          reject(err);
        });
    });
  }

  updateCustomerOnFirestore(uid: string, data: Customer = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const customerRef: AngularFirestoreDocument<Customer> = this.angularFirestore.doc(`customers/${ uid }`);

        customerRef
          .set(
            {
              ...data,
              lastInteraction: firestore.Timestamp.now(),
            },
            { merge: true }
          )
          .then(() => {
            console.log('Customer data updated on database');
            resolve();
          })
          .catch((err) => {
            console.error('Error getting customer data', err);
            reject(err);
          });
      } else {
        reject(new Error('Missing customer uid'));
      }
    });
  }

  async validateAgent(agent: string, isEmail = false): Promise<string> {
    return new Promise((resolve, reject) => {
      if (isEmail) {
        this.angularFirestore
          .collection('agents', (ref) => ref.where('email', '==', agent.trim().toLowerCase()))
          .get()
          .toPromise()
          .then((query) => {
            if (query.empty) {
              return reject('not-found');
            } else {
              return resolve(query.docs[0].data().uid);
            }
          })
          .catch((err) => {
            return reject(err);
          });
      } else {
        this.angularFirestore
          .collection('agents')
          .doc(agent.trim())
          .get()
          .toPromise()
          .then((query) => {
            if (!query.exists) {
              return reject('not-found');
            } else {
              return resolve(query.data().uid);
            }
          })
          .catch((err) => {
            return reject(err);
          });
      }
    });
  }

  async setRegisterCustomer(customerData: Customer): Promise<void> {
    try {
      const utmSource = new UtmSourceEntity();
      if (customerData.utm) {
        if (customerData.utm.id) {
          utmSource.id = customerData.utm.id;
        }
        if (customerData.utm.medium) {
          utmSource.medium = customerData.utm.medium;
        }
        if (customerData.utm.campaign) {
          utmSource.campaign = customerData.utm.campaign;
        }
        if (customerData.utm.term) {
          utmSource.term = customerData.utm.term;
        }
        if (customerData.utm.content) {
          utmSource.content = customerData.utm.content;
        }
        if (customerData.utm.source) {
          utmSource.source = customerData.utm.source;
        }
      }
      const registerData: CustomerJourneyAboutCompanyEntity = {
        uid: '',
        id: customerData.cnpj.replace(/\D+/g, ''),
        cnpj: customerData.cnpj,
        razaoSocial: customerData.companyName,
        email: customerData.email,
        whatsapp: customerData.whatsapp,
        faturamentoAnual: customerData.revenue,
        address: {
          cep: customerData.address.zipCode,
          logradouro: customerData.address.street,
          numero: customerData.address.number,
          bairro: customerData.address.neighborhood,
          cidade: customerData.address.city,
          uf: customerData.address.state,
          complemento: customerData.address.extraInfo,
          geoPoint: new firebase.firestore.GeoPoint(0, 0),
        },
        step: null,
        gclid: customerData.gclid,
        stepText: null,
        createdAt: null,
        dateAboutCompany: null,
        utmSource: utmSource,
      };
      await this.http.post(
        `${ environment.functionsUrl }/customerjourneycontrolapi/set-about-company`,
        { data: registerData },
        {
          responseType: 'json', headers: new HttpHeaders({
            Authorization: `Bearer ${ environment.acessKey }`,
            'Content-Type': 'application/json',
          })
        }
      ).toPromise();
    } catch (err) {
      console.error('Error on register customer', err);
    }
  }

  async setFinancialHealth(customerData: Customer): Promise<void> {
    try {
      const registerData: CustomerJourneyFinancialHealthEntity = {
        uid: '',
        id: customerData.cnpj.replace(/\D+/g, ''),
        debt: customerData.debt,
        taxRegime: customerData.taxRegime,
        banks: customerData.banks,
        banksOther: customerData.banksOther,
        step: null,
        stepText: null,
        dateFinancialHealth: null,
      };
      await this.http.post(
        `${ environment.functionsUrl }/customerjourneycontrolapi/set-financial-health`,
        { data: registerData },
        {
          responseType: 'json', headers: new HttpHeaders({
            Authorization: `Bearer ${ environment.acessKey }`,
            'Content-Type': 'application/json',
          })
        }
      ).toPromise();
    } catch (err) {
      console.error('Error on register customer', err);
    }
  }

  async setCreditStatus(customerData: Customer): Promise<void> {
    try {
      const registerData: CustomerJourneyCreditStatusEntity = {
        uid: '',
        id: customerData.cnpj.replace(/\D+/g, ''),
        creditValue: customerData.creditValue,
        purpose: customerData.purpose,
        guarantees: customerData.guarantees,
        typeOfAnticipation: customerData.typeOfAnticipation,
        typeOfInvoices: customerData.typeOfInvoices,
        creditCard: customerData.creditCard,
        // guaranteesValue: customerData.guaranteesValue,
        step: null,
        stepText: null,
        dateCreditStatus: null,
      };
      await this.http.post(
        `${ environment.functionsUrl }/customerjourneycontrolapi/set-credit-status`,
        { data: registerData },
        {
          responseType: 'json', headers: new HttpHeaders({
            Authorization: `Bearer ${ environment.acessKey }`,
            'Content-Type': 'application/json',
          })
        }
      ).toPromise();
    } catch (err) {
      console.error('Error on register customer', err);
    }
  }

  async setAboutYou(customerData: Customer): Promise<void> {
    try {
      const registerData: CustomerJourneyAboutYouEntity = {
        uid: '',
        id: customerData.cnpj.replace(/\D+/g, ''),
        name: customerData.name,
        cpf: customerData.cpf,
        hasPermissionToSign: customerData.hasPermissionToSign,
        step: null,
        stepText: null,
        dateAboutYou: null,
      };
      await this.http.post(
        `${ environment.functionsUrl }/customerjourneycontrolapi/set-about-you`,
        { data: registerData },
        {
          responseType: 'json', headers: new HttpHeaders({
            Authorization: `Bearer ${ environment.acessKey }`,
            'Content-Type': 'application/json',
          })
        }
      ).toPromise();
    } catch (err) {
      console.error('Error on register customer', err);
    }
  }

  checkCustomerNoTouch(customerData: Customer): boolean {
    const guaranteesIds = customerData?.guarantees?.map((guarantee) => guarantee.id);
    if (!guaranteesIds) {
      return false;
    }

    const revenueLessThan240000 = customerData.revenue <= 240000;
    const companyAgeLessThan2 =  !customerData.companyInformation?.age || customerData.companyInformation?.age < 2;
    const hasVehicleOrProperty = !guaranteesIds.some((id) => id.includes('veiculos') || id.includes('imovel'));

    return (revenueLessThan240000 || hasVehicleOrProperty || companyAgeLessThan2);
  }


  checkNoTouch(customerData: Customer): boolean {
    const guaranteesIds = customerData?.guarantees?.map((guarantee) => guarantee.id);
    if (!guaranteesIds) {
      return false; 
    }
  
    const revenueLessThan240000 = customerData.revenue <= 240000;
    const companyAgeLessThan2 = !customerData.companyInformation?.age || customerData.companyInformation?.age < 2;
    const hasVehicleOrProperty = guaranteesIds.some((id) => id.includes('veiculos') || id.includes('imovel'));
  
    // Retorna false se qualquer uma das condições for falsa
    if (!revenueLessThan240000 || !companyAgeLessThan2 || !hasVehicleOrProperty) {
      return false;
    }
  
    return true;
  }


  checkCustomerBabyLayout(customerData: Customer): boolean{
    const guaranteesIds = customerData?.guarantees?.map((guarantee) => guarantee.id);
    if (!guaranteesIds) {
      return false;
    }

    const revenueOver240000 = customerData.revenue >= 240000;
    const companyAgeLessThan2 =  !customerData.companyInformation?.age || customerData.companyInformation?.age < 2;
    const hasVehicleOrProperty = !guaranteesIds.some((id) => id.includes('veiculos') || id.includes('imovel'));

    if( companyAgeLessThan2 && hasVehicleOrProperty){
      return true;
    }

    return false;
  }
}
