<div appAccordion class="contacts-wrapper">
  <div class="contacts-title">
    <h4>Contatos clientes</h4>
    <p>Olá {{ nameUser }}</p>
  </div>
  <div class="contacts-content" appAccordionLink *ngFor="let menuitem of MENUCONTACTS" group="{{ menuitem.state }}">
    <a *ngIf="(menuitem.accessLevel === 'master' && accessLevel === 'master') ||  !menuitem.accessLevel " appAccordionToggle href="javascript:;" class="contacts-item" [ngClass]="{ 'selected': menuitem.selected }">
      <span>{{ menuitem.name }}</span>
      <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
    </a>
    <div class="sub-item-wrapper" *ngIf="menuitem.type === 'sub'">
      <div class="sub-item-content" *ngFor="let childitem of menuitem.children" [ngClass]="{ 'selected': childitem.selected }">
        <a *ngIf="(childitem.accessLevel === 'master' && accessLevel === 'master') ||  !childitem.accessLevel " class="sub-item" (click)="onMenuitemSelected(childitem)">
          <span>{{ childitem.name }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
