/* eslint-disable @typescript-eslint/explicit-function-return-type */
import firebase from 'firebase';
import moment from 'moment';

export const isoStringToDate = (iso: string): Date => {
  const [year, month, day, hour, minute, second] = iso.split(/\D+/).map((s) => {
    const parsedInt = parseInt(s, 10);
    return isNaN(parsedInt) ? 0 : parsedInt;
  });
  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
};

export const timeStampOrObjectToMiliseconds = (
  param?: { seconds: number; nanoseconds: number } | firebase.firestore.Timestamp
) => {
  if (!param) {
    return null;
  }
  return param.seconds * 1000 + param.nanoseconds / 1000000000;
};

export const timestampForDate = (date: any): Date => {
  const newDate = {
    seconds: date?.seconds ?? date?._seconds,
    nanoseconds: date?.nanoseconds ?? date?._nanoseconds,
  };
  const miliseconds = timeStampOrObjectToMiliseconds(newDate);
  return new Date(miliseconds);
};

export const momentDuration = (firstDate: Date, secondDate?: Date): moment.Duration => {
  const startDate = moment(firstDate);
  const finishDate = moment(secondDate ? secondDate : new Date());
  return moment.duration(finishDate.diff(startDate));
};
