<mat-expansion-panel *ngIf="bndesProposal">
  <mat-expansion-panel-header>Meu score de Crédito</mat-expansion-panel-header>
  <p>Aqui trouxemos alguns dos seus dados disponíveis no sistema do BNDES (Banco Nacional de Desenvolvimento Econômico e Social) para consulta:</p>
  <div class="main-content">
    <p class="score" *ngIf="bndesProposal?.creditScore">Score do BNDES: <span> {{bndesProposal?.creditScore}} <mat-icon  (mouseover)="isScoreHintVisible = true" (mouseout)="isScoreHintVisible = false">contact_support</mat-icon></span></p>
    <mat-hint *ngIf="isScoreHintVisible" class="scoreHint">Score é uma pontuação para avaliar o histórico financeiro do consumidor. Quanto maior a pontuação, melhor a avaliação de crédito do consumidor</mat-hint>
    <div class="values">
      <p *ngIf="bndesProposal?.valorIndicadorDividaTotal">Valor max endividamento total: <span> {{bndesProposal?.valorIndicadorDividaTotal}} <mat-icon (mouseover)="totalDebtAmountHint = true" (mouseout)="totalDebtAmountHint = false">contact_support</mat-icon></span></p>
      <mat-hint *ngIf="totalDebtAmountHint" class="totalDebtAmountHint">(Crédito a vencer + vencido + baixado a prejuízo) dividido pelo faturamento anual</mat-hint>
      <p *ngIf="bndesProposal?.valorIndicadorDividaVencida">Valor max endividamento vencido: <span> {{bndesProposal?.valorIndicadorDividaVencida}} <mat-icon (mouseover)="overdueDebtAmountHint = true" (mouseout)="overdueDebtAmountHint = false">contact_support</mat-icon></span></p>
      <mat-hint *ngIf="overdueDebtAmountHint" class="overdueDebtAmountHint">Dívidas vencidas dividido pelo faturamento anual</mat-hint>
      <p *ngIf="bndesProposal?.valorIndicadorDividaCurtoPrazo">Valor max endividamento curto prazo: <span> {{bndesProposal?.valorIndicadorDividaCurtoPrazo}} <mat-icon (mouseover)="shortTermDebtAmountHint = true" (mouseout)="shortTermDebtAmountHint = false">contact_support</mat-icon></span></p>
      <mat-hint *ngIf="shortTermDebtAmountHint" class="shortTermDebtAmountHint">Crédito a vencer em até 360 dias dividido pelo faturamento anual</mat-hint>
      <p *ngIf="bndesProposal?.valorIndicadorDividaBaixadaPrejuizo">Valor max endividamento prejuízo: <span> {{bndesProposal?.valorIndicadorDividaBaixadaPrejuizo}} <mat-icon (mouseover)="debtLossAmountHint = true" (mouseout)="debtLossAmountHint = false">contact_support</mat-icon></span></p>
      <mat-hint *ngIf="debtLossAmountHint"  class="debtLossAmountHint">Dívidas baixadas a prejuízo dividido pelo faturamento anual</mat-hint>
    </div>
  </div>
</mat-expansion-panel>
