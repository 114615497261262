<div class="gray-card" *ngIf="!editProfilePictureData">
  <div class="gray-card-user" *ngIf="!banker?.photoUrl">
    <img src="../../../../assets/images/user.png" alt="user-pic" />
  </div>
  <div class="gray-card-user" *ngIf="banker?.photoUrl">
    <img [src]="[banker?.photoUrl]" alt="user-pic" />
  </div>
  <div class="gray-card-text">
    <p>{{ banker?.name }}</p>
    <span>{{ banker?.occupation }}</span>
    <span>{{ banker?.institution?.label }}</span>
  </div>
  <button class="gray-card-button" (click)="onEditProfilePictureData()">
    Editar <img src="../../../../assets/images/edit.png" />
  </button>
</div>

<form class="gray-card" *ngIf="editProfilePictureData">
  <div class="gray-card-user">
    <img *ngIf="profilePicture" [src]="profilePicture" alt="Foto do usuário">
  </div>
  <div class="gray-card-text edit">
    <label for="choosePicture" class="picture-upload">Enviar nova foto</label>
    <input id="choosePicture" type="file" (change)="onFileSelected($event)" accept="image/*" style="display: none;">
    <span>As fotos ajudam a reconhecer as pessoas no SCE</span>
  </div>
  <button class="gray-card-button save" (click)="onSaveProfilePictureData()">
    Salvar
  </button>
</form>

<div class="gray-card large" *ngIf="!editPersonalData">
  <h3>Informações pessoais</h3>
  <div class="gray-card-personal-data">
    <div>
      <span>Nome completo</span>
      <p>{{ banker?.name }}</p>
      <span>Equipe ou Departamento</span>
      <p>{{ banker?.occupation }}</p>
      <span>Whatsapp</span>
      <p>{{ banker?.whatsapp }}</p>
    </div>
    <div>
      <span>Instituição financeira</span>
      <p>{{ banker?.institution?.label }}</p>
      <span>Email</span>
      <p>{{ banker?.email }}</p>
      <span>Telefone</span>
      <p>{{ banker?.phone }}</p>
    </div>
    <button class="gray-card-button" (click)="onEditPersonalData()">Editar <img
        src="../../../../assets/images/edit.png" /></button>
  </div>
</div>

<div class="gray-card large" *ngIf="editPersonalData">
  <h3>Informações pessoais</h3>
  <form [formGroup]="personalDataFormGroup" class="gray-card-personal-data">
    <div class="edit-form">
      <label>Nome completo</label>
      <input class="input" formControlName="nameCtrl" />
      <label>Equipe ou Departamento</label>
      <input class="input" formControlName="occupationCtrl" />
      <label>Whatsapp</label>
      <input class="input" formControlName="whatsappCtrl" />
    </div>
    <div class="edit-form">
      <label>Instituição financeira</label>
      <input class="input" formControlName="institutionCtrl" disabled />
      <label>Email</label>
      <input class="input" formControlName="emailCtrl" disabled />
      <label>Telefone</label>
      <input class="input" formControlName="phoneCtrl" />
    </div>
    <button class="gray-card-button save" (click)="onSavePersonalData()">Salvar</button>
  </form>
</div>

<div class="gray-card large" *ngIf="!editAddressData">
  <h3>Endereço</h3>
  <div class="gray-card-personal-data">
    <div>
      <span>CEP</span>
      <p>{{ banker?.address?.postalCode }}</p>
      <span>Complemento</span>
      <p *ngIf="banker?.address?.extraInfo">{{ banker?.address?.extraInfo }}</p>
      <p *ngIf="!banker?.address?.extraInfo">-</p>
      <span>Cidade</span>
      <p>{{ banker?.address?.city }}</p>
    </div>
    <div>
      <span>Endereço</span>
      <p>{{ banker?.address?.street }}</p>
      <span>Bairro</span>
      <p>{{ banker?.address?.neighborhood }}</p>
      <span>Estado</span>
      <p>{{ banker?.address?.state }}</p>
    </div>
    <button class="gray-card-button" (click)="onEditAddressData()">Editar <img
        src="../../../../assets/images/edit.png" /></button>
  </div>
</div>

<div class="gray-card large" *ngIf="editAddressData">
  <h3>Endereço</h3>
  <form class="gray-card-personal-data" [formGroup]="addressDataFormGroup">
    <div class="edit-form">
      <label>CEP</label>
      <input class="input" (input)="getAddressFromPostalCode()" formControlName="postalCodeCtrl" />
      <label>Complemento</label>
      <input class="input" formControlName="extraInfoCtrl" />
      <label>Cidade</label>
      <input class="input" formControlName="cityCtrl" />
    </div>
    <div class="edit-form">
      <label>Endereço</label>
      <input class="input" formControlName="streetCtrl" />
      <label>Bairro</label>
      <input class="input" formControlName="neighborhoodCtrl" />
      <label>Estado</label>
      <input class="input" formControlName="stateCtrl" />
    </div>
    <button *ngIf="!runSearchCEP" class="gray-card-button save" (click)="onSaveAddressData()">Salvar</button>
    <mat-spinner *ngIf="runSearchCEP" diameter="50" ></mat-spinner>
  </form>
</div>
