<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Documentos pendentes - Leads</span>
      </mat-card-title>

      <div>
        <h4 class="my-3">Filtros:</h4>
        <div class="row">
          <div class="col-12">
            <!-- filter by razao social -->
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o trecho da razão social desejada">
              <mat-label>Razão Social</mat-label>
              <input matInput type="text" name="companyName" [formControl]="companyName" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <!-- filter by cnpj -->
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o trecho do CNPJ desejado">
              <mat-label>CNPJ</mat-label>
              <input
                matInput
                type="text"
                name="cnpj"
                [formControl]="cnpj"
                [textMask]="{ mask: MASKS.cnpj.textMask, guide: false }"
              />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <!-- filter by email -->
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o trecho do email desejado">
              <mat-label>E-mail</mat-label>
              <input matInput type="text" name="email" [formControl]="email" />
            </mat-form-field>
          </div>

          <div class="col-12 text-center">
            <button mat-stroked-button class="mx-1" (click)="clearAll()">Limpar</button>
            <button mat-raised-button color="primary" class="mx-1" (click)="getPendingDocuments()">Obter dados</button>
          </div>
        </div>
      </div>

      <div class="w-100 p-5" *ngIf="isLoadingCustomers">
        <mat-progress-spinner
          *ngIf="isLoadingCustomers"
          [diameter]="60"
          class="mx-auto"
          color="primary"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>

      <div class="my-5" *ngIf="!isLoadingCustomers && usersPendingDocuments?.length === 0">
        <p class="text-center">Nenhum documento pendente encontrado.</p>
      </div>

      <div class="my-5" *ngIf="!isLoadingCustomers && usersPendingDocuments === null">
        <p class="text-center">Falha ao obter a lista de documentos.</p>
        <p class="text-center">
          <button mat-raised-button color="accent" (click)="getPendingDocuments()">Tentar novamente</button>
        </p>
      </div>

      <div [class.d-none]="isLoadingCustomers || !usersPendingDocuments?.length">
        <span *ngFor="let lead of usersPendingDocuments">
          <mat-card class="mat-card mat-elevation-z8">
            <mat-card-header class="pt-4">
              <mat-card-title>{{ lead.companyName + ' - ' + lead.cnpj }}</mat-card-title>
              <mat-card-subtitle>{{ lead.email }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="p-3">
              <ul *ngFor="let doc of lead.pendingDocuments">
                <li>
                  {{ doc.name }} - <strong>{{ doc.situation }}</strong>
                </li>
              </ul>
            </mat-card-content>
          </mat-card>
        </span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
