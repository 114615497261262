<div class="container-fluid">
  <mat-card *ngIf="isLoading || hasError">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="accent"
          type="button"
          routerLink="/admin/operadores/buscar"
          matTooltip="Voltar à listagem"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Visualização de Operador de Crédito</span>
      </mat-card-title>

      <div class="text-center my-3" *ngIf="isLoading">
        <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
      </div>

      <div *ngIf="hasError">
        <p>Não foi possível obter os dados do operador de crédito.</p>
        <p class="text-center">
          <button mat-raised-button color="primary" (click)="getAgentData()">Tentar novamente</button>
        </p>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoading && !hasError">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="accent"
          type="button"
          routerLink="/admin/operadores/buscar"
          matTooltip="Voltar à listagem"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Operador de crédito: {{ banker.name }}</span>
        <a mat-raised-button color="primary" [routerLink]="'/admin/operadores/editar/' + bankerCode">
          <mat-icon>edit</mat-icon> Editar operador
        </a>
      </mat-card-title>

      <div class="row m-0">
        <div class="col-12 mt-3">
          <div class="banker-info">
            <h3>Dados do operador de crédito</h3>
            <hr class="mb-3" />
            <div class="row">
              <div class="col-12 col-lg-6 overflow-auto">
                <p><strong>Instituição:</strong> {{ banker.institution.label || banker.institution.name || '-' }}</p>
                <p><strong>Nome:</strong> {{ banker.name || '-' }}</p>
                <p><strong>E-mail:</strong> {{ banker.email || '-' }}</p>
                <p><strong>WhatsApp:</strong> {{ banker.whatsapp || '-' }}</p>
                <p><strong>Telefone:</strong> {{ banker.phone || '-' }}</p>
                <p><strong>Criado em:</strong> {{ banker.createdAt?.toDate().toLocaleString() || '-' }}</p>
              </div>
              <div class="col-12 col-lg-6">
                <p><strong>CEP:</strong> {{ banker.address?.postalCode || '-' }}</p>
                <p>
                  <strong>Endereço:</strong> {{ banker.address?.street || '-' }}, {{ banker.address?.number || '-' }}
                </p>
                <p><strong>Complemento:</strong> {{ banker.address?.extraInfo || '-' }}</p>
                <p><strong>Bairro:</strong> {{ banker.address?.neighborhood || '-' }}</p>
                <p><strong>Cidade:</strong> {{ banker.address?.city || '-' }}</p>
                <p><strong>Estado:</strong> {{ banker.address?.state || '-' }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="banker-info">
            <h3>Status</h3>
            <hr class="mb-3" />

            <div class="row">
              <div class="col-12 col-md-7 col-lg-8 text-center status-label my-2">
                {{ banker.isActive ? 'Ativo' : 'Suspenso' }}
              </div>
              <div class="col-12 col-md-5 col-lg-4 my-2 d-flex align-items-center justify-content-center">
                <button
                  mat-stroked-button
                  color="primary"
                  *ngIf="!banker.isActive"
                  class="m-1"
                  (click)="enableBanker(banker.uid, banker.email)"
                >
                  <mat-icon>done</mat-icon> Habilitar
                </button>
                <button
                  mat-stroked-button
                  color="warn"
                  *ngIf="banker.isActive && (loggedAdmin?.accessLevel === 'master')"
                  class="m-1"
                  (click)="disableBanker(banker.uid, banker.email)"
                >
                  <mat-icon>report_problem</mat-icon> Suspender
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="banker-info">
            <h3>Regionalização</h3>
            <hr class="mb-3" />

            <div>
              <div
                class="col-12 my-2 d-flex align-items-center"
                style="font-weight: 500"
                *ngIf="!banker.isRegionalized"
              >
                <mat-icon>clear</mat-icon> <span class="ml-2">Este operador não é regionalizado</span>
              </div>

              <div
                class="col-12 col-lg-6 my-2 d-flex align-items-center"
                style="font-weight: 500"
                *ngIf="banker.isRegionalized"
              >
                <mat-icon>check_circle_outline</mat-icon> <span class="ml-2">Este operador é regionalizado</span>
              </div>
              <div *ngIf="banker.isRegionalized">
                <br />
                <div *ngIf="banker?.regionalization">
                  <div style="margin-bottom: 10px; width: 100%" *ngFor="let r of banker.regionalization; let i = index">
                    <mat-accordion class="example-headers-align">
                      <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                          <mat-card-title style="margin: 0px">{{ r.name }}</mat-card-title>
                        </mat-expansion-panel-header>
                        <div>
                          <p *ngIf="r?.region"><strong>Região:</strong> {{ r.region.name || '-' }}</p>
                          <p *ngIf="r?.state"><strong>Estado:</strong> {{ r.state.name || '-' }}</p>
                          <p *ngIf="r?.city"><strong>Cidade:</strong> {{ r.city.name || '-' }}</p>
                          <p *ngIf="r?.zipCode"><strong>CEP:</strong> {{ r.zipCode || '-' }}</p>
                          <p *ngIf="r?.zipCode">
                            <strong>Quantidade de Digitos:</strong> {{ r.qtdNumberZipCode || '-' }}
                          </p>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
