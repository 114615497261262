import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { User } from '../../../../../functions/src/models/User';
import { AuthService } from '../../../core/auth/auth.service';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit, OnDestroy {

  authSubscription: Subscription;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  customerSubscription: Subscription;

  customer: Customer;
  isCustomerNoTouch: boolean;
  user: User;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private customerService: CustomerService, private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.customer.subscribe((customer) => {
      this.customer = customer;
      this.isCustomerNoTouch = this.customerService.checkCustomerNoTouch(customer);
      // console.log('this.customer', this.customer);
    })

    this.authSubscription = this.authService.user.subscribe((user) => {
      this.user = user;
    })
  }

  ngOnDestroy(): void {
    if(this.authSubscription){
      this.authSubscription.unsubscribe();
    }
    if(this.customerSubscription){
      this.customerSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      const tab = params['tab'];
      if (tab === 'serasa') {
        this.selectTab('serasa');
      }
    });
  }

  selectTab(tabLabel: string) {
    const tabIndex = this.tabGroup._tabs.toArray().findIndex(tab => tab.textLabel === 'Dados Serasa');
    if (tabIndex !== -1) {
      this.tabGroup.selectedIndex = tabIndex;
    }
  }

}
