import { BasicStructure } from './common/BasicStructure';
import { AvaliacaoChecklist, CreditoPreAprovado, SolicitacaoCadastroAuth } from './model-interface';
import { ZendeskSupportUserData } from './Zendesk';

export interface User {
  // Common Data
  changedPassword?: boolean;
  createdAt?: firebase.firestore.Timestamp | number;
  displayName?: string;
  email?: string;
  emailVerified?: boolean;
  isAdmin?: boolean;
  isAgent?: boolean;
  isAnonymous?: boolean;
  isBanker?: boolean;
  isCustomer?: boolean;
  lastInteraction?: firebase.firestore.Timestamp;
  mainRole?: UserRole;
  name?: string;
  password?: string;
  photoURL?: string;
  uid?: string;
  zendesk?: ZendeskSupportUserData;

  // Legacy Fields
  address?: {
    zipCode?: string;
    street?: string;
    number?: string;
    extraInfo?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
  };
  agent?: string;
  agentPermissions?: {
    canSendDocs?: boolean;
    canVisualizeDocs?: boolean;
  };
  companyName?: string;
  whatsapp?: string;

  additionalData?: {
    birthDate?: string;
    loanUse?: any;
    professionalStatus?: any;
    realEstateData?: {
      debt?: number;
      hasDeed?: boolean;
      number?: string;
      value?: number;
      zipCode?: string;
    };
    autoData?: {
      manufacturer?: string;
      model?: string;
      modelYear?: number;
      value?: number;
      year?: number;
    };
  };
  avaliacaoChecklist?: AvaliacaoChecklist;
  bigdatacorp?: any;
  chaveCancelamento?: string;
  concordaComTermos?: boolean;
  concordaComTermosExterno?: boolean;
  contratoFullPath?: string;
  contratoPath?: string;
  dataUltimaInteracao?: firebase.firestore.Timestamp;
  datetimeAceite?: firebase.firestore.Timestamp;
  datetimeCancelamento?: firebase.firestore.Timestamp;
  documentos?: any;
  emailUserCreator?: string;
  estaAtivo?: boolean;
  hasAdmPendency?: boolean;
  hasPermissionToSign?: boolean;
  idAccountSalesforce?: string;
  idAgentPartnerSalesforce?: string;
  idContactSalesforce?: string;
  listCreditoExcluida?: CreditoPreAprovado[];
  listCreditoPreAprovado?: CreditoPreAprovado[];
  motivoCancelamento?: string;
  nome?: string;
  notificacoes?: {
    chat: { pendenciaParaAdm: boolean };
    documento: { pendenciaParaAdm: boolean };
    hasAdmPendency: boolean;
    oportunidade: { pendenciaParaAdm: boolean };
  };
  operacaoCreditoInicial?: any;
  origem?: string;
  pendencyType?: string;
  permiteCancelamento?: boolean;
  situacao?: BasicStructure;
  solicitacaoCadastroAuth?: SolicitacaoCadastroAuth;
  statusContrato?: any;
  telefone?: string;
  userType?: BasicStructure;
  userConsent?: UserConsent;
  accessLevel?: string;
}

export interface Cookie {
  id: string;
  name: string;
  agreed: boolean;
}
export interface UserConsent {
  privacyPolicyDate?: number;
  showUserConsent?: boolean;
  consentGiven?: boolean;
  cookiePolicy?: {
    agreedCookie?: boolean;
    agreedCookiesDate?: number;
    cancelCookiesDate?: number;
    allowedCookies?: Cookie[];
  };
}

export interface UserModel {
  uid: string;
  email: string;
  emailVerified: boolean;
  erroMsg: string;
}

export interface UserTypeFlags {
  isAdmin: boolean;
  isAgent: boolean;
  isBanker: boolean;
  isCustomer?: boolean;
  isUser?: boolean;
}

export interface UsersResult {
  page: number;
  pageSize: number;
  total: number;
  results: any[];
}

export enum UserRole {
  'Admin' = 'admin',
  'Agent' = 'agent',
  'Banker' = 'banker',
  'Customer' = 'customer',
}
