import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { Customer } from '../../../../../functions/src/models/Customer';
import { ChatService } from '../../../chat/services/chat.service';
import { SidebarStateService } from '../../services/sidebar-state.service';

@Component({
  selector: 'app-user-notes-container',
  templateUrl: './user-notes-container.component.html',
  styleUrls: ['./user-notes-container.component.scss'],
})
export class UserNotesContainerComponent implements OnInit {
  customer: string;
  createdBy: {
    email: string;
    name: string;
  };
  customerUid: Customer
  isLoading = true;
  isModalVisible?: boolean = false;
  textoDuvida?: string = "";
  numeroCapital?: string = "";

  constructor(private customerService: CustomerService, private chatService: ChatService, private activatedRoute: ActivatedRoute, private sidebarStateService: SidebarStateService) {
    this.getPhoneNumber();
    const routeSnapshot = this.activatedRoute.snapshot;
    const routeData = routeSnapshot.data;
    this.sidebarStateService.updateCurrentSection(routeData);
  }

  ngOnInit(): void {
    this.customerService.customer.subscribe((c) => {
      if (c?.uid) {
        this.customerUid = c;
        this.customer = c.uid;
        this.createdBy = {
          email: c.email || null,
          name: c.name || null,
        };

        this.isLoading = false;
      }
    });
  }



  checkCriteria(): boolean {
    const customer = this.customerUid;

    const customerHasRequiredRevenue = customer?.revenue >= 240000;
    const customerGuaranteeIds = customer?.guarantees?.map((guarantee) => guarantee?.id);
    const requiredGuaranteeIds = ['imovel-operacional', 'imovel-nao-operacional', 'veiculos', 'avalista', 'recebiveis'];
    const customerHasRequiredGuarantees = requiredGuaranteeIds
      .some((required) => customerGuaranteeIds?.some((id) => id.includes(required)));
    const customerHasRequiredCompanyAge = customer?.companyInformation?.age > 2;

    return customerHasRequiredRevenue && (customerHasRequiredGuarantees || customerHasRequiredCompanyAge);
  }


  getPhoneNumber(): void {
    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.numeroCapital = data?.phone;
      console.log('this.numeroCapital', this.numeroCapital);
    });
  }

  construirLink(): string {
    const numero: string = this.numeroCapital;
    if(!this.textoDuvida){
      this.textoDuvida = "Olá, gostaria de ajuda com meu pedido de crédito na Capital Empreendedor";
    } 
    const texto: string = encodeURIComponent(this.textoDuvida);
    return `https://wa.me/${ numero }?text=${ texto }`;
  }

  showModal() {

    this.isModalVisible = true;
    console.log('this.modalVisible', this.isModalVisible)
  }



  closeModal() {
    this.isModalVisible = false;
  }
}
