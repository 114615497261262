export const OpportunityStageSituationAdmin = {
  0: 'Fechado e perdido',
  10: 'Identificado',
  15: 'Documentos aprovados',
  20: 'Cadastrado',
  30: 'Ficha',
  40: 'Pré-proposta',
  60: 'Pré-proposta aceita',
  80: 'Análise final',
  90: 'Contratação',
  100: 'Fechado e ganho',
} as const;

export const OpportunityStageSituationAgent = {
  10: 'Aguardando documentação básica',
  15: 'Aguardando a ficha da instituição',
  20: 'aguardando assinatura SCR',
  30: 'Aguardando proposta',
  40: 'Proposta enviada, aguardando resposta do cliente',
  60: 'Proposta aceita, aguardando documentação adicional',
  80: 'Em análise final de crédito',
  90: 'Contratação/Abertura de conta',
  100: 'Crédito contratado',
  0: 'Sem oportunidades abertas',
} as const;

export const OpportunityStageSituationBanker = {
  10: 'Aguardando documentação do cliente',
  15: 'Aguardando documentação do cliente',
  20: 'Aguardando autorização SCR',
  30: 'Aguardando proposta da instituição',
  40: 'Proposta enviada, aguardando resposta do cliente',
  60: 'Proposta aceita, aguardando documentação adicional',
  80: 'Em análise final de crédito',
  90: 'Contratação/Abertura de conta',
  100: 'Crédito contratado',
  0: 'Negado pela instituição/Recusado pelo cliente',
} as const;

export const OpportunityStageSituationCustomer = {
  0: 'Proposta recusada',
  10: 'Aguardando documentação do cliente',
  15: 'Processando cadastro de parceiros',
  20: 'Cadastro recebido pela instituição',
  30: 'Em análise de crédito pela instituição',
  40: 'Aguardando resposta do cliente',
  60: 'Aguardando documentação adicional do cliente',
  80: 'Em análise final de crédito',
  90: 'Contratação/Abertura de conta',
  100: 'Operação concluída',
} as const;

export const OpportunityStageSituationCustomerNoTouch = {
  0: 'Proposta recusada',
  10: 'Aguardando documentação do cliente',
  15: 'Processando cadastro de parceiros',
  20: 'Cadastro recebido pela instituição',
  30: 'Em análise de crédito pela instituição',
  40: 'Aguardando resposta do cliente',
  60: 'Aguardando andamento no portal do parceiro',
  80: 'Em análise final de crédito',
  90: 'Contratação/Abertura de conta',
  100: 'Operação concluída',
} as const;

export type OpportunityStageSituation = keyof typeof OpportunityStageSituationAdmin; // Admin version is always the reference, the other ones are only different presentations
