export enum Compar {
  'EQUAL' = '$eq',
  'NOT_EQUAL' = '$ne',
  'GREATER' = '$gt',
  'GREATER_OR_EQUAL' = '$gte',
  'LESS' = '$lt',
  'LESS_OR_EQUAL' = '$lte',
  'INCLUDE' = '$in',
  'NOT_INCLUDE' = '$nin',
}
export enum Logic {
  'AND' = '$and',
  'NOT' = '$not',
  'OR' = '$or',
}

export enum Element {
  'EXISTS' = '$exists',
  'TYPE' = '$type',
}
export enum Evaluation {
  'TEXT' = '$text',
  'WHERE' = '$where',
  'REGEX' = '$regex',
}

export enum Array {
  'ELEM_MATCH' = '$elemMatch',
  'ALL' = '$all',
}
