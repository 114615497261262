import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CustomEmailActionsService } from 'src/app/customer/services/custom-email-actions.service';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';

@Component({
  selector: 'app-custom-email-actions',
  templateUrl: './custom-email-actions.component.html',
  styleUrls: ['./custom-email-actions.component.scss'],
})
export class CustomEmailActionsComponent implements OnInit, OnDestroy {
  isEmailValidation = false;
  isResetPassword = false;
  isCancelTermsOfService = false;
  isCreateNewUser = false;
  isProcessing = false;
  actionCode = '';

  continueUrl = '';

  resetPasswordForm: FormGroup;
  createNewUserForm: FormGroup;
  cancelTermsForm: FormGroup;
  isCancelTerms = false;
  usuarioLogado: User;
  permiteCancelamento = false;
  usuarioLogadoSubscription: Subscription;

  readonly opcaoSimNao: BasicStructure[] = [
    { id: 'sim', name: 'Sim' },
    { id: 'nao', name: 'Não' },
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private _data: CustomEmailActionsService,
    private logger: LogService,
    private imageService: ImageService
  ) {
    this.usuarioLogado = {};
    this.logger.controllerName = this.constructor.name;
    this.imageService.setImagePath('assets/images/signup/illustration1.svg');
  }

  ngOnInit(): void {
    const mode = this.activatedRoute.snapshot.queryParams['mode'];
    this.actionCode = this.activatedRoute.snapshot.queryParams['oobCode'];
    this.continueUrl = this.activatedRoute.snapshot.queryParams['continueUrl'];

    if (this.continueUrl) {
      this.continueUrl = this.continueUrl.slice(this.continueUrl.indexOf('#') + 2);
    }

    this.loadResetPasswordForm();
    // this.loadCreateUserForm();

    switch (mode) {
      case 'resetPassword':
        this.isProcessing = true;

        this.isCancelTermsOfService = false;
        this.isEmailValidation = false;
        this.isResetPassword = true;
        this.isCreateNewUser = false;

        this.authService
          .verifyPasswordResetCode(this.actionCode)
          .then((email) => {
            if (email) {
              this.resetPasswordForm.get('emailCtrl').setValue(email);
              this.isProcessing = false;
            } else {
              const messageSubscription = this.showErrorMessage(
                'Erro - Recuperação de senha',
                'Ocorreu um erro ao tentar recuperar sua senha. Tente novamente mais tarde.'
              )
                .afterClosed()
                .subscribe(() => {
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            }
          })
          .catch((err) => {
            if (err.code === 'auth/invalid-action-code') {
              const messageSubscription = this.showErrorMessage(
                'Erro - Recuperação de senha',
                'O código de verificação é inválido ou já foi utilizado.'
              )
                .afterClosed()
                .subscribe(() => {
                  this.isResetPassword = false;
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            } else if (err.code === 'auth/expired-action-code') {
              const messageSubscription = this.showErrorMessage(
                'Erro - Recuperação de senha',
                'O código de verificação já expirou, solicite uma nova redefinição de senha.'
              )
                .afterClosed()
                .subscribe(() => {
                  this.isResetPassword = false;
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            } else {
              const messageSubscription = this.showErrorMessage(
                'Erro - Recuperação de senha',
                'Ocorreu um erro ao tentar recuperar sua senha. Tente novamente mais tarde.'
              )
                .afterClosed()
                .subscribe(() => {
                  this.isResetPassword = false;
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            }
          });
        break;

      case 'recoverEmail':
        // throw new Error('Method not implemented.');
        break;

      case 'verifyEmail':
        this.isCancelTermsOfService = false;
        this.isEmailValidation = true;
        this.isCreateNewUser = false;
        this.isResetPassword = false;

        this.authService
          .verifyEmail(this.actionCode, this.continueUrl)
          .then((continueTo) => {
            this.router.navigate([continueTo || '/entrar']);
          })
          .catch((err) => {
            console.error('Error validating e-mail', err);

            if (err.code === 'auth/invalid-action-code') {
              const messageSubscription = this.showErrorMessage(
                'Erro - Validação de e-mail',
                'O código de verificação é inválido ou já foi utilizado.'
              )
                .afterClosed()
                .subscribe(() => {
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            } else if (err.code === 'auth/expired-action-code') {
              const messageSubscription = this.showErrorMessage(
                'Erro - Validação de e-mail',
                'O código de verificação já expirou, solicite uma novo e-mail de verificação.'
              )
                .afterClosed()
                .subscribe(() => {
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            } else {
              const messageSubscription = this.showErrorMessage(
                'Erro - Validação de e-mail',
                'Ocorreu um erro ao tentar recuperar sua senha. Tente novamente mais tarde.'
              )
                .afterClosed()
                .subscribe(() => {
                  if (messageSubscription) {
                    messageSubscription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            }
          });
        break;
      case 'cancelTermService':
        this.isProcessing = true;
        this.isCancelTermsOfService = true;
        this.isEmailValidation = false;
        this.isResetPassword = false;
        this.isCreateNewUser = false;
        this.loadCancelTermsForm();
        this.cancelTermsForm.get('cancelaTermosCtrl').setValue(this.opcaoSimNao[1]);
        this.usuarioLogadoSubscription = this.authService.user.subscribe((loggedUser) => {
          if (loggedUser) {
            this._data.getUserByChaveCancelamento(this.actionCode).then((userData) => {
              if (userData && userData.email === loggedUser.email) {
                this.usuarioLogado = userData;
                this.permiteCancelamento = true;
              } else {
                this.permiteCancelamento = false;
              }
              this.isProcessing = false;
            });
          } else {
            this.dialog
              .open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Usuário não autenticado',
                  alertDescription:
                    `Para realizar essa ação, é necessário primeiramente se autenticar (logar). ` +
                    `<br>Após logar, acesse o link de cancelamento novamente para prosseguir com o processo.`,
                  isOnlyConfirm: true,
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.router.navigate(['/user-login']);
              });
            this.logger.error(
              'Usuário não autenticado',
              `Para realizar essa ação, é necessário primeiramente se autenticar (logar). ` +
                `<br>Após logar, acesse o link de cancelamento novamente para prosseguir com o processo.`
            );
          }
        });
        break;
      // case 'solicitacaocadastro':
      //   this.isProcessing = true;
      //   this.isCancelTermsOfService = false;
      //   this.isEmailValidation = false;
      //   this.isResetPassword = false;
      //   this.isCreateNewUser = true;
      //   const paramsKey = CryptoJS.AES.decrypt(this.actionCode, environment.keyPass).toString(CryptoJS.enc.Utf8);
      //   const parametrosRecebidos = paramsKey.split('|');
      //   if (parametrosRecebidos.length === 2) {
      //     this.usuarioLogadoSubscription = this.authService.user.subscribe(loggedUser => {
      //       if (loggedUser) {
      //         if (!loggedUser.isAnonymous) {
      //           this.authService.logout();
      //         } else {
      //           this._data.getUserByEmail(parametrosRecebidos[0]).then(u => {
      //             if (u.exists) {
      //               const userData = u.data();
      //               if (userData.solicitacaoCadastroAuth && !userData.estaAtivo &&
      //                 userData.solicitacaoCadastroAuth.tokenSolicitacao === this.actionCode &&
      //                 userData.solicitacaoCadastroAuth.situacaoSolicitacao === 'enviada') {

      //                 this.createNewUserForm.get('userEmailCtrl').setValue(userData.email);
      //                 this.isProcessing = false;
      //               } else {
      //                 this.dialog.open(AlertDialogComponent, {
      //                   maxWidth: '600px',
      //                   data: {
      //                     alertTitle: 'Erro - Validação dos parâmetros',
      //                     alertDescription: `Esse código de verificação é inválido ou já foi utilizado em outro momento.`,
      //                     isOnlyConfirm: true
      //                   }
      //                 }).afterClosed().subscribe(() => {
      //                   this.router.navigate(['main']);
      //                 });
      //                 this.logger.error('Erro - Validação dos parâmetros',
      //                   `Esse código de verificação é inválido ou já foi utilizado em outro momento.`);
      //               }
      //             } else {
      //               this.dialog.open(AlertDialogComponent, {
      //                 maxWidth: '600px',
      //                 data: {
      //                   alertTitle: 'Erro - Validação dos parâmetros',
      //                   alertDescription: `Esse código de verificação é inválido ou o usuário não existe.`,
      //                   isOnlyConfirm: true
      //                 }
      //               }).afterClosed().subscribe(() => {
      //                 this.router.navigate(['main']);
      //               });
      //               this.logger.error('Erro - Validação dos parâmetros',
      //                 `Esse código de verificação é inválido ou o usuário não existe.`);
      //             }
      //           }).catch(e => {
      //             this.dialog.open(AlertDialogComponent, {
      //               maxWidth: '600px',
      //               data: {
      //                 alertTitle: 'Erro - Validação dos parâmetros',
      //                 alertDescription: `Houve um problema na busca pelos dados do usuário de acordo com o token informado. Tente novamente mais tarde.`,
      //                 isOnlyConfirm: true
      //               }
      //             }).afterClosed().subscribe(() => {
      //               this.router.navigate(['main']);
      //             });
      //             this.logger.error('Erro - Validação dos parâmetros', e);
      //           });
      //         }
      //       }
      //     });
      //   }
      //   break;
      default:
        this.router.navigate(['/entrar']);
    }
  }

  ngOnDestroy(): void {
    if (this.usuarioLogadoSubscription) {
      this.usuarioLogadoSubscription.unsubscribe();
    }
  }

  loadResetPasswordForm(): void {
    function passwordMatchValidator(formGroup: FormGroup): void {
      let error = null;
      if (formGroup.get('newPasswordCtrl').value !== formGroup.get('confirmNewPasswordCtrl').value) {
        error = { mismatch: true };
      }

      formGroup.get('confirmNewPasswordCtrl').setErrors(error);
    }

    this.resetPasswordForm = this.formBuilder.group(
      {
        emailCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
        newPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmNewPasswordCtrl: new FormControl('', Validators.required),
      },
      { validator: passwordMatchValidator }
    );
  }

  loadCancelTermsForm(): void {
    this.cancelTermsForm = this.formBuilder.group({
      cancelCtrl: new FormControl({ value: '', disabled: false }, Validators.required),
      cancelReasonCtrl: new FormControl({ value: '', disabled: false }, []),
    });
  }

  // loadCreateUserForm() {
  //   function createUserMatchValidator(formGroup: FormGroup) {
  //     let error = null;
  //     if (formGroup.get('passwordCtrl').value !== formGroup.get('confirmPasswordCtrl').value) {
  //       error = { 'mismatch': true };
  //     }
  //     formGroup.get('confirmPasswordCtrl').setErrors(error);
  //   }

  //   this.createNewUserForm = this.formBuilder.group({
  //     userEmailCtrl: new FormControl({ value: '', disabled: true }, Validators.required),
  //     passwordCtrl: new FormControl('', Validators.required),
  //     confirmPasswordCtrl: new FormControl('', Validators.required)
  //   }, { validator: createUserMatchValidator });
  // }

  saveNewPassword(): void {
    this.isProcessing = true;

    this.authService
      .resetPassword(
        this.actionCode,
        this.resetPasswordForm.get('newPasswordCtrl').value,
        this.resetPasswordForm.get('emailCtrl').value?.trim().toLowerCase()
      )
      .then(() => {
        this.router.navigate([this.continueUrl || '/entrar']);
      })
      .catch((err) => {
        this.showErrorMessage('Erro - Recuperação de senha', 'Houve um problema ao tentar salvar sua nova senha.');
        this.isProcessing = false;
        //
        console.error('Error saving new password', err);
      });
  }

  onChangeOpcaoTermos(event) {
    if (event.value.id === 'sim') {
      this.isCancelTerms = true;
      this.cancelTermsForm.get('motivoCancelamentoCtrl').setValidators(Validators.required);
    } else {
      this.isCancelTerms = false;
      this.cancelTermsForm.get('motivoCancelamentoCtrl').clearValidators();
      this.cancelTermsForm.get('motivoCancelamentoCtrl').setValue('');
    }
    this.cancelTermsForm.get('motivoCancelamentoCtrl').updateValueAndValidity();
  }

  onSaveCancelTerms() {
    if (this.cancelTermsForm.controls.cancelaTermosCtrl.value === this.opcaoSimNao[0]) {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Cancelar termos de serviço',
            alertDescription:
              'O Termo de Serviço atual será cancelado e não poderemos mais continuar a buscar' +
              ' oportunidades financeira para você.<br> Deseja realmente continuar?',
            isOnlyConfirm: false,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.usuarioLogado.concordaComTermos = false;
            this.usuarioLogado.motivoCancelamento = this.cancelTermsForm.controls.motivoCancelamentoCtrl.value;
            this.usuarioLogado.permiteCancelamento = false;
            this.usuarioLogado.datetimeCancelamento = firebase.firestore.Timestamp.now();
            this.usuarioLogado.situacao = { id: 'cadastro-completo', name: 'Cadastro completo' };
            this.usuarioLogado.avaliacaoChecklist.step = 'termo-cancelado';
            this._data.updateUser(this.usuarioLogado).then(() => {
              this.dialog
                .open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Alteração concluída',
                    alertDescription: 'Alteração realizada com sucesso.',
                    isOnlyConfirm: true,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['user-profile']);
                });
              this.logger.info('Cancelamento do termo de serviço concluído com sucesso');
            });
          }
        });
    } else {
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Permanecer com o termo de serviço',
            alertDescription: 'O Termo de Serviço atual não poderá mais ser cancelado.<br>' + 'Deseja continuar?',
            isOnlyConfirm: false,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.usuarioLogado.concordaComTermos = true;
            this.usuarioLogado.motivoCancelamento = null;
            this.usuarioLogado.permiteCancelamento = false;
            this.usuarioLogado.datetimeCancelamento = null;
            this._data.updateUser(this.usuarioLogado).then(() => {
              this.dialog
                .open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Alteração concluída',
                    alertDescription: 'Alteração realizada com sucesso.',
                    isOnlyConfirm: true,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['user-profile']);
                });
              this.logger.info('Alteração na permissão de cancelamento do termo de serviço realizado com sucesso');
            });
          }
        });
    }
  }

  // createNewUser() {
  //   this.isProcessing = true;
  //   this.dialog.open(AlertDialogComponent, {
  //     maxWidth: '600px',
  //     data: {
  //       alertTitle: 'Salvar Dados Acesso',
  //       alertDescription: `O acesso ao sistema Capital Empreendedor será criado para o usuário` +
  //         ` <b>${this.createNewUserForm.controls.userEmailCtrl.value}</b>. Deseja continuar?`,
  //       isOnlyConfirm: false
  //     }
  //   }).afterClosed().subscribe(result => {
  //     if (result) {
  //       const account = {
  //         email: this.createNewUserForm.controls.userEmailCtrl.value,
  //         senha: this.createNewUserForm.controls.passwordCtrl.value
  //       };
  //       this.usuarioLogadoSubscription.unsubscribe();
  //       this.authService.createAccountNoVerification(account).then(() => {
  //         this.logger.info('Nova autenticação criada com sucesso.');
  //         this._data.updateUserSolicitacaoCadastro(account.email).then(() => {
  //           this.logger.info('Dados do usuário alterado com sucesso.');
  //           this._data.updateUserProperty({
  //             email: account.email,
  //             tokenSolicitacao: this.actionCode
  //           }).then(() => {
  //             this.authService.refreshCurrentUser();
  //             this.dialog.open(AlertDialogComponent, {
  //               maxWidth: '600px',
  //               data: {
  //                 alertTitle: 'Sucesso',
  //                 alertDescription: `O acesso ao Capital Empreendedor concluído com sucesso.`,
  //                 isOnlyConfirm: true
  //               }
  //             }).afterClosed().subscribe(() => {
  //               this.isProcessing = false;
  //               this.router.navigate(['user-profile']);
  //             });
  //             this.logger.info('Sucesso na criação de acesso ao novo usuário.');
  //           });
  //         }).catch(error => {
  //           this.dialog.open(AlertDialogComponent, {
  //             maxWidth: '600px',
  //             data: {
  //               alertTitle: 'Erro - Problemas no usuário',
  //               alertDescription: `Houve um problema alteração dos dados do usuário. Por favor, tente mais tarde.`,
  //               isOnlyConfirm: true
  //             }
  //           });
  //           this.logger.error('Problemas na criação da autenticação de novo usuário.', error);
  //           this.isProcessing = false;
  //         });
  //       }).catch(e => {
  //         if (e.erroMsg === 'E-mail em uso.') {
  //           this.dialog.open(AlertDialogComponent, {
  //             maxWidth: '600px',
  //             data: {
  //               alertTitle: 'Erro - E-mail já cadastrado',
  //               alertDescription: `O e-mail <b>${account.email}</b> já está cadastrado. Por favor, utilize a opção de recuperação de senha na tela de login.`,
  //               isOnlyConfirm: true
  //             }
  //           }).afterClosed().subscribe(() => {
  //             this.router.navigate(['/user-login']);
  //           });
  //           this.logger.log('Problemas na criação da autenticação', e);
  //         } else {
  //           this.dialog.open(AlertDialogComponent, {
  //             maxWidth: '600px',
  //             data: {
  //               alertTitle: 'Erro - Problemas na autenticação',
  //               alertDescription: `Houve um problema na criação da autenticação do seu usuário. Por favor, tente mais tarde.`,
  //               isOnlyConfirm: true
  //             }
  //           });
  //           this.logger.log('Erro - Problemas na autenticação', 'Houve um problema na criação da autenticação do seu usuário');
  //         }
  //         this.isProcessing = false;
  //       });
  //     }
  //   });
  // }

  onVoltarMinhaConta() {
    this.router.navigate(['/user-profile']);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

  showErrorMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
}
