import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Agent } from 'functions/src/models/Agent';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { AgentService } from './../../../agents/services/agent.service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss'],
})
export class AgentComponent implements OnInit, OnDestroy {
  agent: Agent;
  agentCode: string;
  canSendDocs = true;
  canVisualizeDocs = true;
  hasError = false;
  isLoading = true;
  uid: string;
  customerSubscription: Subscription;

  constructor(
    private customerService: CustomerService,
    private agentService: AgentService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.customerSubscription = this.customerService.customer.subscribe((customer) => {
      if (customer) {
        this.uid = customer.uid;
        this.agentCode = customer.agent;

        this.getAgentData();

        this.canVisualizeDocs = customer.agentPermissions?.canVisualizeDocs === false ? false : true;
        this.canSendDocs = customer.agentPermissions?.canSendDocs === false ? false : true;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  getAgentData(): void {
    this.isLoading = true;

    if (this.agentCode) {
      this.agentService
        .getAgentByUID(this.agentCode)
        .then((agent) => {
          this.agent = agent || null;
          this.isLoading = false;
          this.hasError = false;
        })
        .catch((err) => {
          console.error('Error getting Agent data', err);
          this.hasError = true;
          this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '700px',
              data: {
                alertTitle: 'Erro ao buscar assessor de crédito',
                alertDescription: `Ocorreu um erro ao buscar os dados do seu assessor de crédito. Por favor, tente novamente mais tarde.`,
                isOnlyConfirm: true,
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.isLoading = false;
            });
        });
    } else {
      this.isLoading = false;
    }
  }

  saveAgentPermissions(): void {
    const customer = {
      agentPermissions: {
        canVisualizeDocs: this.canSendDocs || this.canVisualizeDocs,
        canSendDocs: this.canSendDocs,
      },
    };

    this.customerService
      .updateCustomerOnFirestore(this.uid, customer)
      .then(() => {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Permissões salvas',
            alertDescription: `As permissões do seu assessor foram alteradas com sucesso. `,
            isOnlyConfirm: true,
          },
        });
      })
      .catch((err) => {
        console.error('Error updating user agent permissions', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao salvar permissões',
            alertDescription: `Ocorreu um erro ao salvar as permissões do seu assessor. Por favor, tente novamente mais tarde.`,
            isOnlyConfirm: true,
          },
        });
      });
  }
}
