<div>
    <mat-accordion>
        <mat-expansion-panel class="expansion-panel">
            <mat-expansion-panel-header>
            <mat-panel-title>Agregador de dados Capital Empreendedor</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansion-panel-content row" >
                <div class="col-12 col-lg-4 col-xl-6">
                
                    <p><strong>Razão social:</strong> {{ customer?.companyName?.toUpperCase()}}</p>
                    <p><strong>CNPJ:</strong> {{ customer?.cnpj}}</p>
                    <ng-container *ngIf="basicData?.TaxIdStatus === 'ATIVA'">
                    <p><strong>Idade da empresa:</strong > {{ basicData?.Age}} anos </p>
                    <p><strong>Regime tributário:</strong> {{ basicData?.TaxRegime}}</p>
                    <p><strong>Natureza legal:</strong> {{basicData?.LegalNature?.Activity}}</p>
                    </ng-container>
                    <ng-container *ngIf="basicDataActivities">
                        <p *ngFor="let activity of basicDataActivities">
                        <strong>Atividade principal:</strong> {{activity?.Activity}}
                        </p>
                    </ng-container>
                </div>

                <div class="col-12 col-lg-4 col-xl-6 " >
                        <ng-container *ngIf="relationships">
                    <ng-container *ngFor="let relationship of relationships" >
                    <p > 
                        <strong>Nome do sócio:</strong> 
                        {{relationship.RelatedEntityName}}
                    </p>
                    <p>
                    <strong>CPF do sócio:</strong> 
                    {{relationship.RelatedEntityTaxIdNumber}}
                        </p>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngIf="lawsuitsData">
                        <p><strong>Total de processos como autor (empresa): </strong> {{lawsuitsData.TotalLawsuitsAsAuthor}}</p>
                        <p><strong>Total de processos como réu (empresa): </strong> {{lawsuitsData.TotalLawsuitsAsDefendant}}</p>
                        <!-- <p><strong>Total de processos como autor (sócio): </strong> </p>
                        <p><strong>Total de processos como réu (sócio):</strong> </p> -->
                    </ng-container>
                
                </div>
            </div>
            
            <div style="display: flex; align-items: center; justify-content: center;">
                <button mat-raised-button type="button" class="w-100 mt-1 button " (click)="viewDataClick()" >Ver mais dados</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
