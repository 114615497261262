<div
  class="content"
  [class.d-flex]="isLoading"
  [class.flex-column]="isLoading"
  [style.min-height]="isLoading ? '100%' : 'unset'"
>
  <div class="d-flex">
    <div style="flex: 1">
      <img alt="logo-capital-empreendedor" src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6" class="logo" />
    </div>
    <button mat-button class="d-flex align-items-center flex-row" (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" class="colored-icon mr-2"></mat-icon> Sair
    </button>
  </div>

  <div *ngIf="!isLoading">
    <div>
      <h1>Política de Privacidade</h1>
      <p>
        Nossa Política de Privacidade tem como objetivo ajudar você a entender quais informações coletamos, por que as
        coletamos e como você pode atualizar, gerenciar, exportar e excluir essas informações.
      </p>
    </div>
    <div class="col-12 col-lg-9 mt-3 mb-4 d-flex">
      <mat-checkbox
        color="primary"
        class="mr-3 my-1 checkbox"
        [(ngModel)]="agreedPrivacyPolicy"
        required
      ></mat-checkbox>
      <span style="flex: 1"
        >Eu li e concordo com a
        <a class="link" [href]="urlPrivacyPolicy" target="_blank">política de privacidade</a>.</span
      >
    </div>
    <div class="mt-auto">
      <button
        mat-raised-button
        color="primary"
        class="action-button"
        type="submit"
        [class.disabled]="!agreedPrivacyPolicy"
        *ngIf="!isProcessing"
        [disabled]="!agreedPrivacyPolicy"
        (click)="agreedPrivatyPolicy()"
      >
        Continuar
      </button>
      <div class="text-center">
        <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
      </div>
    </div>
  </div>
</div>
