<mat-expansion-panel class="container">
  <mat-expansion-panel-header>
    <mat-panel-title class="title">Infos comitê de crédito</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description class="content">
    <div class="field-wrapper recurrence">
      <span *ngIf="customer?.hasRecurrence">Cliente recorrente</span>
    </div>
    <div class="field-wrapper">
      <span>Razão social</span>
      <p>{{ customer?.companyName || '-' }}</p>
    </div>
    <div class="field-wrapper">
      <span>Faturamento anual</span>
      <p>{{ customer?.revenueStr || '-' }}</p>
    </div>
    <div class="field-wrapper">
      <span>Estado</span>
      <p>{{ customer?.address?.state || '-' }}</p>
    </div>
    <div class="field-wrapper">
      <span>Cidade</span>
      <p>{{ customer?.address?.city || '-' }}</p>
    </div>
    <div class="field-wrapper">
      <span>Data de fundação</span>
      <p>{{ customer?.companyInformation?.foundedDate?.toDate() | date }}</p>
    </div>
    <div class="field-wrapper">
      <span>Tempo de empresa</span>
      <p>{{ customer?.companyInformation?.age }} anos</p>
    </div>
    <div class="field-wrapper">
      <span>Data de assinatura do termo</span>
      <p>{{ customer?.termsSignatureDate?.toDate() | date }}</p>
    </div>
    <div class="field-wrapper">
      <span>Garantia</span>
      <p>{{ displayGuaranteeNames(customer?.guarantees)}}</p>
    </div>
    <div *ngIf="customer?.typeOfAnticipation" class="field-wrapper">
      <span>Antecipação</span>
      <p>{{ displayGuaranteeNames(customer?.typeOfAnticipation, "value")}}</p>
    </div>
    <div *ngIf="customer?.creditCard" class="field-wrapper">
      <span>Maquininha de cartão</span>
      <p>{{ displayGuaranteeNames(customer?.creditCard)}}</p>
    </div>
    <div class="field-wrapper">
      <span>Valor total das garantias</span>
      <p>{{ customer?.guaranteesValueStr }}</p>
    </div>
    <div class="field-wrapper">
      <span>Valor total Pefin</span>
      <p>{{ formatMoney(serasaData?.pefin?.summary?.balance) }}</p>
    </div>
    <div class="field-wrapper">
      <span>Valor total Refin</span>
      <p>{{ formatMoney(serasaData?.refin?.summary?.balance) }}</p>
    </div>
    <div class="field-wrapper">
      <span>Quantidade Pefin</span>
      <p>{{ serasaData?.pefin?.summary?.count }}</p>
    </div>
    <div class="field-wrapper">
      <span>Quantidade Refin</span>
      <p>{{ serasaData?.refin?.summary?.count }}</p>
    </div>
    <div class="field-wrapper cnae">
      <span>CNAE Principal</span>
      <div *ngFor="let act of customer?.companyInformation?.activities">
        <p *ngIf="act.IsMain === true">{{ act.Activity }}</p>
      </div>
    </div>
    <div class="toggle-container">
      <!-- <mat-slide-toggle [checked]="customer?.approvedByCommittee">Aprovado pelo comitê</mat-slide-toggle> -->
      <mat-slide-toggle [checked]="customer?.hasOpenFinance" (change)="toggleHasOpenFinance()">Ativar Open Finance</mat-slide-toggle>
    </div>
    <div class="field-wrapper">
      <span>Aprovado pelo comitê: </span>
      <p>{{ customer?.approvedByCommittee ? "Sim": "Não" }}</p>
    </div>
    <!-- <mat-slide-toggle [checked]="customer?.approvedByCommittee">Aprovado pelo comitê</mat-slide-toggle> -->
  </mat-panel-description>
</mat-expansion-panel>