import { Component, Inject, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase, { firestore } from 'firebase';
import { UserNote } from 'functions/src/models/Notes';
import { User } from 'functions/src/models/User';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { FeedService } from 'src/app/admin/services/feed.service';
import { NotesService } from 'src/app/admin/services/notes.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { ForwardNotesComponent } from '../forward-notes/forward-notes.component';

@Component({
  selector: 'app-user-feed-dialog',
  templateUrl: './user-feed-dialog.component.html',
  styleUrls: ['./user-feed-dialog.component.scss'],
})
export class UserFeedDialogComponent implements OnInit, OnDestroy, OnChanges {
  agent: string;
  agentName?: string;
  agentEmail?: string;

  customerData: User;

  customer: string;
  notificationData: any;
  type: UserNote['type'];
  createdBy: UserNote['createdBy'];
  editMode = [];
  createdAt: firebase.firestore.Timestamp;
  lastUpdate: firebase.firestore.Timestamp;

  isCreateMode = false;
  isLoadingNotes = true;

  newNote: UserNote = {
    message: '',
  };
  searchValue = '';

  notes: UserNote[] = [];
  filteredNotes: UserNote[] = [];
  notesSubscription: Subscription;

  readonly typeLabels = {
    ADMIN: 'Capital Empreendedor',
    AGENT: 'Assessor',
    USER: 'Cliente',
  };

  constructor(
    private dialog: MatDialog,
    private notesService: NotesService,
    private feedService: FeedService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.agent = data.agent;
    this.customer = data.customer;
    this.notificationData = data.notificationData;
    this.type = data.type;
    this.createdBy = data.createdBy;
    this.customerData = data.customerData;
    this.agentEmail = data.agentEmail;
    this.agentName = data.agentName;
  }

  ngOnChanges(): void {
    if (this.customer) {
      this.feedService.getNotes(this.customer).subscribe((userNotes) => {
        this.notes = userNotes;
        this.editMode =
          this.notes?.map((p) => ({
            enabled: false,
            data: {
              message: p.message,
            },
          })) || [];
        this.isLoadingNotes = false;
      });
    }
  }

  ngOnInit(): void {
    this.notesSubscription = this.feedService.getNotes(this.customer).subscribe((notes) => {
      this.notes = notes;
      this.searchNotes();
      this.isLoadingNotes = false;
    });
  }

  ngOnDestroy(): void {
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNewNote();
  }

  clearNewNote(): void {
    this.newNote.message = '';
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchNotes();
  }

  forwardNote(noteText): void {
    this.dialog.open(ForwardNotesComponent, {
      width: '70%',
      data: {
        customer: this.customer,
        customerData: this.customerData,
        noteText: noteText,
        noteFrom: 'USER',
      },
    });
  }

  searchNotes(): void {
    if (this.searchValue) {
      this.filteredNotes = _.filter(
        this.notes,
        (n) => n.message?.toUpperCase().indexOf(this.searchValue.toUpperCase()) >= 0
      );
    } else {
      this.filteredNotes = this.notes;
    }
  }

  clearNote(): void {
    this.newNote.message = '';
  }

  createNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar anotação',
          alertDescription: 'Deseja realmente enviar essa anotação?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        this.newNote = {
          ...this.newNote,
          type: 'ADMIN',
          createdBy: this.createdBy,
        };

        this.feedService
          .createNote(this.customer, this.newNote)
          .then(() => {
            this.isCreateMode = false;
            this.createdAt = firestore.Timestamp.now();
            this.clearNote();
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Anotação Salva',
                alertDescription: 'A anotação foi enviada com sucesso.',
                isOnlyConfirm: true,
              },
            });
          })
          .catch((err) => {
            console.error('Error creating note', err);

            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao salvar',
                alertDescription: 'Não foi possível salvar a anotação. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
          })
          .finally(() => {
            if (confirmSubscription) {
              confirmSubscription.unsubscribe();
            }
          });
      });
  }
}
