<p class="status-label">
  <span>{{ progressText }}.</span>
</p>
<div class="row">

  <div class="col-12">
    <img src="{{stepsProgressBar[getSteps()]}}" alt="" width="100%">
  </div>

</div>

<!----div *ngIf="status === 'Crédito contratado'" class="col-12" style="text-align: center; padding: 0%">
  <img src="assets/illustrations/trophy.svg" style="width: 3.3rem; height: 3.3rem" />
</div----->