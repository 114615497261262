import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-recurrence-admin-dialog',
  templateUrl: './recurrence-admin-dialog.component.html',
  styleUrls: ['./recurrence-admin-dialog.component.scss']
})
export class RecurrenceAdminDialogComponent implements OnInit {
  faturamentoForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RecurrenceAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Recebendo o customer como parte dos dados
  ) {}

  ngOnInit(): void {
    this.faturamentoForm = this.fb.group({
      faturamentoAnual: [this.data.customer.revenue || ''] // Preenche o campo com o valor de revenue
    });
  }

  onSubmit(): void {
    const customerRevenue = this.faturamentoForm.get('faturamentoAnual')?.value;


    console.log(customerRevenue)
    
    this.dialogRef.close(customerRevenue); 
  }

  onCancel(): void {
    this.dialogRef.close(); 
  }
}
