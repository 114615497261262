import { Routes } from '@angular/router';
import { TemplateBndesSignupComponent } from '../layouts/templates/template-bndes/template-bndes.component';
import { CreateAccountComponent } from './containers/create-account/create-account.component';

export const BndesRoutes: Routes = [
  {
    path: 'bndes',
    component: TemplateBndesSignupComponent,
    children: [
      { path: 'cadastrar/:key', component: CreateAccountComponent, data: { title: 'Criar Conta' } },
      { path: 'cadastrar', redirectTo: '/cadastrar' },
    ],
  },
];
