import { Component, Input, OnInit } from '@angular/core';
import { OpenFinanceService } from '../../core/services/open-finance.service';

@Component({
  selector: 'app-open-finance',
  templateUrl: './open-finance.component.html',
  styleUrls: ['./open-finance.component.scss']
})
export class OpenFinanceComponent implements OnInit {

  @Input() uid: string;
  openFinanceData: any[];

  constructor(
    private openFinanceService: OpenFinanceService,
  ) {
    // Apenas para evitar erros
  }

  ngOnInit(): void {
    if (this.uid) {
      this.getOpenFinanceData(this.uid);
    }
  }

  private getOpenFinanceData(uid: string): void {
    this.openFinanceService.getOpenFinanceData(uid).subscribe(data => {
      this.openFinanceData = data;
    });
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isObjectOrArray(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
}
