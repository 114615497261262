import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { User } from '../../../../../functions/src/models/User';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-user-security',
  templateUrl: './user-security.component.html',
  styleUrls: ['./user-security.component.scss']
})
export class UserSecurityComponent implements OnInit {
  @Input() customer: Customer;


  passwordFormGroup: FormGroup;
  
  isProcessingPassword = false;
  loggedUserSubscription: Subscription;
  user: User;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private dialog: MatDialog, private router: Router) {

    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser: User) => {
      this.user = loggedUser;

      console.log(loggedUser)

    });
   }

  ngOnInit(): void {
      this.createPasswordFormGroup();

  }

  createPasswordFormGroup(): void{
    this.passwordFormGroup = this.formBuilder.group(
      {
        oldPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        newPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmNewPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
      },
      { validator: this.checkEqualPasswords }
    );
  }

  checkEqualPasswords(formGroup: AbstractControl): { passwordsNotMatched: boolean } {
    const newPassword = formGroup.get('newPasswordCtrl')?.value;
    const confirmNewPasswordCtrl = formGroup.get('confirmNewPasswordCtrl')?.value;

    return newPassword === confirmNewPasswordCtrl ? null : { passwordsNotMatched: true }
  }

  changePassword(): void{
    this.isProcessingPassword = true;
    const newPassword = this.passwordFormGroup.get('newPasswordCtrl').value;
    const oldPassword = this.passwordFormGroup.get('oldPasswordCtrl').value;
    console.log('this.user',this.user)
    this.authService
      .loginWithEmailAndPassword(this.user.email, oldPassword)
      .then(() => {
        this.authService
          .changePassword(this.user.uid, newPassword)
          .then(() => {
            this.user.changedPassword = true;
            this.isProcessingPassword = false;
            return this.authService.updateUser(this.user)
          })
          .then(() => {
            const msgSub = this.showMessage('Senha atualizada', 'Sua senha foi atualizada com sucesso.')
              .afterClosed()
              .subscribe(() => {
                msgSub.unsubscribe();
                this.router.navigate(['minha-conta']);
              });
          })
          .catch((err) => {
            console.error(err);
            this.showMessage('Erro ao atualizar senha', 'Não foi possível atualizar sua senha, tente novamente mais tarde.')
          })
      })
      .catch(() => {
        this.showMessage('Senha incorreta', 'Verifique sua senha e tente novamente');
      })
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
  
  navigateToFirstLayer(): void {
    this.router.navigate(['minha-conta']);
  }

}
