
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { CadastrosGeraisCupom, CadastrosGeraisItem, CadastrosTemplatesMessage, ItemRegimeTributario } from './cadastros-gerais';

@Injectable({
  providedIn: 'root',
})
export class CadastrosGeraisService {
  cadastrosGeraisItemCollection: AngularFirestoreCollection<CadastrosGeraisItem>;
  cadastrosGeraisItem: Observable<CadastrosGeraisItem[]>;
  cadastrosGeraisItemDoc: AngularFirestoreDocument<CadastrosGeraisItem>;

  constructor(private angularFirestore: AngularFirestore, private logger: LogService) {
    this.logger.controllerName = this.constructor.name;
  }

  getCadastrosGerais(mnemonico): Observable<CadastrosGeraisItem[]> {
    this.cadastrosGeraisItemCollection = this.angularFirestore.collection(mnemonico, (x) => x.orderBy('nome', 'asc'));
    this.cadastrosGeraisItem = this.cadastrosGeraisItemCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.cadastrosGeraisItem;
  }

  addCadastrosGerais(dadosCadastrosGerais: CadastrosGeraisItem): void {
    this.logger.info('Cadastro gerais criado com sucesso', dadosCadastrosGerais.mnemonico);
    this.cadastrosGeraisItemCollection.add(dadosCadastrosGerais);
  }

  updateCadastrosGerais(mnemonico: string, dadosCadastrosGerais: CadastrosGeraisItem): void {
    this.cadastrosGeraisItemDoc = this.angularFirestore.doc(`${ mnemonico }/${ dadosCadastrosGerais.id }`);
    this.cadastrosGeraisItemDoc
      .set(dadosCadastrosGerais, { merge: true })
      .then((s) => {
        this.logger.info('cadastro gerais alterado com sucesso.', mnemonico);
      })
      .catch((e) => {
        this.logger.error('Problemas na alteração de cadastros gerais. ' + mnemonico, e);
      });
  }

  updateCadastrosGeraisCupom(dadosCadastrosGeraisCupom: CadastrosGeraisCupom): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const docRef = this.angularFirestore.collection('cupom').doc(dadosCadastrosGeraisCupom.id);
  
      docRef.get().toPromise().then(docSnapshot => {
        if (docSnapshot.exists) {
          const dadosAnteriores = docSnapshot.data() as CadastrosGeraisCupom;
          dadosCadastrosGeraisCupom.dataInicio = dadosAnteriores.dataInicio;
  
          const dataFimAnterior = dadosAnteriores.dataFim.toDate();
          const dataFimNova = dadosCadastrosGeraisCupom.dataFim.toDate();
      
          if (dataFimNova < dataFimAnterior) {
            reject(new Error(`Data final do Cupom não pode ser menor do que a anterior informada '${dataFimAnterior.toLocaleString().substring(0, 10)}'.`));
            return;
          }
        }
  
        docRef.set(dadosCadastrosGeraisCupom, { merge: true })
          .then(async () => {
            this.logger.info('Cupom alterado com sucesso.');
            await this.updateCupomCustomer(dadosCadastrosGeraisCupom);
            resolve(true);
          })
          .catch((e) => {
            reject(new Error('Problemas na alteração de cupom. ' + e));
          });
      }).catch(error => {
        reject(new Error('Erro ao obter o documento do cupom. ' + error));
      });
    });
  }

  async updateCupomCustomer(dadosCadastrosGeraisCupom: CadastrosGeraisCupom): Promise<void> {
    try {
      const querySnapshot = await this.angularFirestore.collection('customers', ref => ref.where('cupomCode', '==', dadosCadastrosGeraisCupom.id)).get().toPromise();

      const updatePromises: Promise<void>[] = [];

      querySnapshot.docs.forEach(doc => {
        const data = doc.data();
        const timestamp: firestore.Timestamp = data.lastInteraction;

        const lastInteractionDate = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

        const updatePromise = doc.ref.update(
          { lastContact: lastInteractionDate, 
            dataFim: dadosCadastrosGeraisCupom.dataFim.toDate().toLocaleString() 
          });
        updatePromises.push(updatePromise);
      });

      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Erro ao atualizar os customers no updateCupomCustomer:', error);
      throw error;
    }
  }

  deleteCupom(id: string): Promise<void> {
    return this.angularFirestore.collection('cupom').doc(id).delete();
  }

  deleteMessage(id: string) {
    return this.angularFirestore.collection('templates-de-mensagens').doc(id).delete();
  }


  getTipoInstituicoesFinanceiraAtivas(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('tipo-instituicao-financeira', (ref) =>
      ref.where('ativo', '==', 'true')
    );

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getSetoresAtivos(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('setor', (ref) => ref.where('ativo', '==', 'true'));

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getGarantiasAtivas(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('garantias', (ref) => ref.where('ativo', '==', 'true'));

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getLinhasAtivas(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('linhas', (ref) => ref.where('ativo', '==', 'true'));

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getModalidadesAtivas(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('modalidades', (ref) => ref.where('ativo', '==', 'true'));

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getRegimesAtivos(): Observable<ItemRegimeTributario[]> {
    const collection = this.angularFirestore.collection('regimes-tributarios', (ref) => ref.where('ativo', '==', 'true'));

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as ItemRegimeTributario;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getActiveMotives(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('motivos-de-recusa', (ref) => ref.where('ativo', '==', 'true'));

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getMaquinasAtivas(): Observable<CadastrosGeraisItem[]> {
    const collection = this.angularFirestore.collection('maquinas-de-cartao', (ref) =>
      ref.where('ativo', '==', 'true')
    );

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosGeraisItem;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getEmailGroups(): Observable<any> {
    const collectionEmailGroups = this.angularFirestore.collection('email-groups');
    const response = collectionEmailGroups.snapshotChanges().pipe(
      map((documentsListChanges) => {
        const documentsList = documentsListChanges.map((documentList) => {
          return documentList.payload.doc.data();
        });
        return documentsList;
      }),
      tap((documentList) => { console.log(documentList); })
    );
    return response;
  }

  async postEmailGroups(document): Promise<void> {
    document.createdAt = new Date();
    document.lastUpdate = new Date();
    document.emailList = [];
    await this.angularFirestore.collection('email-groups').doc(`${ document.mnemonic }`).set(document, { merge: true });
  }

  async patchEmailGroups(document): Promise<void> {
    document.lastUpdate = new Date();
    await this.angularFirestore.collection('email-groups').doc(`${ document.mnemonic }`).update(document);
  }

  async deleteEmailGroups(document) {
    return this.angularFirestore.collection('email-groups').doc(`${ document.mnemonic }`).delete();
  }

  getTemplateMessageAtivas(): Observable<CadastrosTemplatesMessage[]> {

    const collection = this.angularFirestore.collection('templates-de-mensagens', (ref) =>
      ref.where('ativo', '==', 'true')
    );
    
    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CadastrosTemplatesMessage;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    }

  }
