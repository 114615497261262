<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title> Cadastro de Clientes </mat-card-title>

      <form name="customerForm" [formGroup]="customerFormGroup">
        <div class="form row">
          <div class="field col-12 col-md-6">
            <mat-form-field color="primary">
              <mat-label>Razão Social</mat-label>
              <input
                matInput
                type="text"
                name="companyName"
                placeholder="Razão Social"
                formControlName="companyNameCtrl"
                required
              />
              <mat-error *ngIf="customerFormGroup.get('companyNameCtrl')?.hasError('required')">
                A Razão Social é obrigatória
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-6">
            <mat-form-field color="primary">
              <mat-label>Site da empresa</mat-label>
              <input
                matInput
                type="text"
                name="companyName"
                placeholder="Site da empresa"
                formControlName="websiteCtrl"
              />
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-4">
            <mat-form-field color="primary">
              <mat-label>CNPJ</mat-label>
              <input
                matInput
                type="text"
                name="CNPJ"
                placeholder="CNPJ"
                formControlName="cnpjCtrl"
                [textMask]="{ mask: MASKS.cnpj.textMask }"
                required
              />
              <mat-error *ngIf="customerFormGroup.get('cnpjCtrl')?.hasError('required')">
                O CNPJ é obrigatório
              </mat-error>
              <mat-error *ngIf="customerFormGroup.get('cnpjCtrl')?.hasError('cnpj')"> CNPJ inválido </mat-error>
            </mat-form-field>
          </div>

          <div class="field d-flex align-items-center col-12 col-md-5">
            <mat-form-field color="primary">
              <mat-label>E-mail</mat-label>
              <input matInput placeholder="E-mail" name="email" required formControlName="emailCtrl" />
              <mat-error *ngIf="customerFormGroup.get('emailCtrl')?.hasError('required')"
                >O e-mail é obrigatório</mat-error
              >
              <mat-error *ngIf="customerFormGroup.get('emailCtrl')?.hasError('email')">E-mail inválido</mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-3">
            <mat-form-field color="primary">
              <mat-label>WhatsApp</mat-label>
              <input
                matInput
                placeholder="WhatsApp"
                name="whatsapp"
                required
                formControlName="whatsappCtrl"
                [textMask]="{ mask: formatPhone, guide: false }"
              />
              <mat-error *ngIf="customerFormGroup.get('whatsappCtrl')?.hasError('required')"
                >O WhatsApp é obrigatório</mat-error
              >
              <mat-error
                *ngIf="
                  customerFormGroup.get('whatsappCtrl')?.hasError('telefone') ||
                  customerFormGroup.get('whatsappCtrl')?.hasError('minlength')
                "
                >WhatsApp inválido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-6">
            <mat-form-field color="primary">
              <mat-label>Nome do Contato</mat-label>
              <input
                matInput
                type="text"
                placeholder="Nome do Contato"
                name="name"
                required
                formControlName="nameCtrl"
              />
              <mat-error *ngIf="customerFormGroup.get('nameCtrl')?.hasError('required')"
                >O nome é obrigatório</mat-error
              >
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-6">
            <mat-form-field color="primary">
              <mat-label>CPF</mat-label>
              <input
                matInput
                placeholder="CPF"
                name="cpf"
                required
                formControlName="cpfCtrl"
                [textMask]="{ mask: MASKS.cpf.textMask }"
              />
              <mat-error *ngIf="customerFormGroup.get('cpfCtrl')?.hasError('required')">O CPF é obrigatório</mat-error>
              <mat-error *ngIf="customerFormGroup.get('cpfCtrl')?.hasError('cpf')">CPF inválido</mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-4">
            <mat-form-field color="primary">
              <input
                matInput
                placeholder="CEP"
                formControlName="zipCodeCtrl"
                #zipCode="matInput"
                name="zipCode"
                [textMask]="{ mask: MASKS.cep.textMask, guide: false }"
                required
                minlength="10"
                (blur)="getAddressFromZipCode()"
                (keyup)="zipCode.value?.length === 10 ? getAddressFromZipCode() : false"
              />
              <mat-error *ngIf="customerFormGroup.get('zipCodeCtrl')?.hasError('required')">
                O CEP é obrigatório.
              </mat-error>
              <mat-error *ngIf="customerFormGroup.get('zipCodeCtrl')?.hasError('minlength')">
                Informe um CEP válido.
              </mat-error>
              <mat-error *ngIf="customerFormGroup.get('zipCodeCtrl')?.hasError('incorrect')">
                O CEP informado não foi encontrado.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field padded col-12 col-md-8">
            <mat-form-field color="primary">
              <input matInput placeholder="Endereço" formControlName="streetCtrl" required name="street" />
              <mat-error *ngIf="customerFormGroup.get('streetCtrl')?.hasError('required')">
                O endereço é obrigatório.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-4">
            <mat-form-field color="primary">
              <input matInput type="number" placeholder="Número" formControlName="numberCtrl" required name="number" />
              <mat-error *ngIf="customerFormGroup.get('numberCtrl')?.hasError('required')">
                O número é obrigatório.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-8">
            <mat-form-field color="primary">
              <input matInput placeholder="Complemento" formControlName="extraInfoCtrl" name="extraInfo" />
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-5">
            <mat-form-field color="primary">
              <input matInput placeholder="Bairro" formControlName="neighborhoodCtrl" required name="neighborhood" />
              <mat-error *ngIf="customerFormGroup.get('neighborhoodCtrl')?.hasError('required')">
                O bairro é obrigatório.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-8 col-md-5">
            <mat-form-field color="primary">
              <input matInput placeholder="Cidade" formControlName="cityCtrl" required name="city" />
              <mat-error *ngIf="customerFormGroup.get('cityCtrl')?.hasError('required')">
                A cidade é obrigatória.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-4 col-md-2">
            <mat-form-field color="primary">
              <input matInput placeholder="Estado" formControlName="stateCtrl" required name="state" />
              <mat-error *ngIf="customerFormGroup.get('stateCtrl')?.hasError('required')">
                O estado é obrigatório.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-4">
            <mat-form-field color="primary">
              <mat-label>Faturamento Anual</mat-label>
              <input
                matInput
                placeholder="Faturamento Anual"
                name="revenue"
                required
                formControlName="revenueCtrl"
                [textMask]="{ mask: numberMask }"
                (change)="revenueValidation()"
              />
              <mat-error *ngIf="customerFormGroup.get('revenueCtrl')?.hasError('required')"
                >O faturamento é obrigatório</mat-error
              >
              <mat-error *ngIf="customerFormGroup.get('revenueCtrl')?.hasError('min')"
                >O faturamento mínimo é {{ config?.minRevenueStr }}.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-4">
            <mat-form-field color="primary">
              <mat-label>Dívida Atual</mat-label>
              <input
                matInput
                placeholder="Dívida Atual"
                name="debt"
                formControlName="debtCtrl"
                [textMask]="{ mask: numberMask }"
              />
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-4">
            <mat-form-field color="primary">
              <mat-label>Quanto busca de capital?</mat-label>
              <input
                matInput
                placeholder="Quanto busca de capital?"
                name="credit-value"
                required
                formControlName="creditValueCtrl"
                [textMask]="{ mask: numberMask }"
              />
              <mat-error *ngIf="customerFormGroup.get('creditValueCtrl')?.hasError('required')"
                >O valor de capital é obrigatório</mat-error
              >
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-6">
            <mat-form-field color="primary" class="w-100">
              <mat-label>Regime Tributário</mat-label>
              <mat-select
                placeholder="Regime Tributário"
                formControlName="taxRegimeCtrl"
                name="taxRegime"
                (selectionChange)="onChangetaxRegime($event)"
                required
              >
                <mat-option *ngFor="let taxReg of config?.taxRegimes" [value]="taxReg">
                  {{ taxReg.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="customerFormGroup.get('taxRegimeCtrl')?.hasError('required')"
                >Selecione o regime tributário</mat-error
              >
            </mat-form-field>
          </div>

          <div class="field col-12 col-md-6" *ngIf="hasTaxRegimeMei">
            <mat-form-field color="primary">
              <mat-label>Data de Nascimento</mat-label>
              <input
                matInput
                [matDatepicker]="birthDate"
                placeholder="Data de Nascimento"
                name="birthDate"
                formControlName="birthDateCtrl"
                [required]="hasTaxRegimeMei"
                (focus)="birthDate.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
              <mat-datepicker #birthDate></mat-datepicker>

              <mat-error *ngIf="customerFormGroup.get('birthDateCtrl')?.hasError('required')"
                >A data de nascimento é obrigatório
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6" *ngIf="hasTaxRegimeMei">
            <app-form-field-select
              fieldName="Estado Civil"
              controlName="maritalStatusCtrl"
              [formGroup]="customerFormGroup"
              [options]="selectTypeMaritalStatus"
              [required]="hasTaxRegimeMei"
            ></app-form-field-select>
          </div>
          <div class="col-6" *ngIf="hasTaxRegimeMei">
            <mat-form-field color="primary">
              <mat-label>Celular</mat-label>
              <input
                matInput
                placeholder="Celular"
                name="Celular"
                [required]="hasTaxRegimeMei"
                formControlName="personalCellCtrl"
                [textMask]="{ mask: formatPhone, guide: false }"
              />
              <mat-error *ngIf="customerFormGroup.get('personalCell')?.hasError('required')"
                >O Celular é obrigatório</mat-error
              >
              <mat-error
                *ngIf="
                  customerFormGroup.get('personalCell')?.hasError('telefone') ||
                  customerFormGroup.get('personalCell')?.hasError('minlength')
                "
                >Celular inválido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-{{ hasTaxRegimeMei ? '12' : '6' }}">
            <mat-form-field color="primary" class="w-100">
              <mat-label>Finalidade</mat-label>
              <mat-select placeholder="Finalidade" formControlName="purposeCtrl" name="purpose" required>
                <mat-option *ngFor="let purpose of config?.purposes" [value]="purpose">
                  {{ purpose.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="customerFormGroup.get('purposeCtrl')?.hasError('required')"
                >Selecione a finalidade do crédito</mat-error
              >
            </mat-form-field>
          </div>

          <div class="field col-12" [class.col-md-6]="hasOtherInstitutions">
            <mat-form-field color="primary" class="w-100">
              <mat-label>Instituições de Crédito Atuais</mat-label>
              <mat-select
                placeholder="Instituições de Crédito Atuais"
                formControlName="institutionsCtrl"
                name="institutions"
                multiple
                (selectionChange)="onChangeInstitutions($event)"
                required
              >
                <mat-option *ngFor="let institution of config?.institutions" [value]="institution">
                  {{ institution.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="customerFormGroup.get('institutionsCtrl')?.hasError('required')"
                >Selecione pelo menos uma instituição</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6" [class.d-none]="!hasOtherInstitutions" [class.field]="hasOtherInstitutions">
            <mat-form-field color="primary" class="w-100">
              <mat-label>Outras instituições (separe por vírgula)</mat-label>
              <input
                matInput
                type="text"
                class="text"
                placeholder="Outras instituições"
                formControlName="otherInstitutionsCtrl"
                name="other-institutions"
                [required]="hasOtherInstitutions"
              />
              <mat-error *ngIf="customerFormGroup.get('otherInstitutionsCtrl')?.hasError('required')"
                >Preencha as demais instituições</mat-error
              >
            </mat-form-field>
          </div>

          <!----Fields for Guarantees--->
          <div class="col-12 d-flex">
            <div class="{{ hasTypeOfAnticipation ? 'flex-fill mr-4' : 'col-12 p-0 m-0' }}">
              <app-form-field-select
                fieldName="Garantias"
                controlName="guaranteesCtrl"
                (selectionChange)="onChangeGuarantees($event)"
                [formGroup]="customerFormGroup"
                [options]="config?.guarantees"
                [compareWith]="true"
                [multiple]="true"
                [required]="true"
              ></app-form-field-select>
            </div>

            <app-form-field-select
              class="flex-fill"
              *ngIf="hasTypeOfAnticipation"
              fieldName="Antecipação de Recebíveis"
              controlName="typeOfAnticipationCtrl"
              (selectionChange)="onChangeAntecipation($event)"
              [formGroup]="customerFormGroup"
              [options]="selectTypeOfAnticipationOperator"
              [multiple]="true"
              [required]="hasTypeOfAnticipation"
            ></app-form-field-select>

            <div class="flex-fill {{ hasTypeOfAnticipationCard ? 'ml-4' : 'ml-0' }}">
              <app-form-field-select
                *ngIf="hasTypeOfAnticipationCard"
                fieldName="Maquininha de Cartão"
                controlName="creditCardCtrl"
                [formGroup]="customerFormGroup"
                [options]="config.cardMachines"
                [required]="true"
                [multiple]="hasTypeOfAnticipationCard"
              ></app-form-field-select>
            </div>

            <mat-form-field
              *ngIf="!hasOnlyAvalGuarantee"
              color="primary"
              class="flex-fill {{ !hasOnlyAvalGuarantee ? 'ml-4' : 'none' }}"
            >
              <mat-label>Valor Total das Garantias</mat-label>
              <input
                matInput
                placeholder="Valor Total das Garantias"
                [textMask]="{ mask: numberMask }"
                formControlName="guaranteesValueCtrl"
                name="guarantees-value"
                [required]="!hasOnlyAvalGuarantee"
              />
              <mat-error *ngIf="customerFormGroup.get('guaranteesValueCtrl')?.hasError('required')"
                >O valor total das garantias é obrigatório</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-12 text-center">
            <button
              mat-raised-button
              color="primary"
              class="action-button"
              type="button"
              (click)="createAccount()"
              [disabled]="!areAllFormsValid() || isProcessing"
            >
              Cadastrar Cliente
            </button>
            <div class="mx-auto text-center">
              <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
