import { Routes } from '@angular/router';
import { AdminModule } from './admin/admin.module';
import { TemplateAdminComponent } from './admin/templates/template-admin/template-admin.component';
import { AgentsModule } from './agents/agents.module';
import { TemplateAgentsComponent } from './agents/containers/template-agents/template-agents.component';
import { BankersModule } from './bankers/bankers.module';
import { TemplateBankersComponent } from './bankers/containers/template-bankers/template-bankers.component';
import { BndesModule } from './bndes/bndes.module';
import { CustomerModule } from './customer/customer.module';

export const AppRoutingModule: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => CustomerModule,
      },
    ],
  },
  {
    path: '',
    component: TemplateAdminComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => AdminModule,
      },
    ],
  },
  {
    path: '',
    component: TemplateAgentsComponent,
    children: [
      {
        path: 'agents',
        loadChildren: () => AgentsModule,
      },
    ],
  },
  {
    path: '',
    component: TemplateBankersComponent,
    children: [
      {
        path: 'bankers',
        loadChildren: () => BankersModule,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'bndes',
        loadChildren: () => BndesModule,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/entrar',
  },
];

// @NgModule({
//   // useHash supports github.io demo page, remove in your app
//   imports: [RouterModule.forRoot(AppRoutes, { useHash: true })],
//   exports: [RouterModule]
// })

// export class AppRoutingModule { }
