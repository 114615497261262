<div class="main-container">
  <div class="toolbar">
    <mat-toolbar color="accent">
      <button class="toggler" mat-icon-button (click)="this.toggle()" *ngIf="shouldSidenavBeVisible()">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/agents" class="branding d-none d-md-inline" style="color: #fff">
        <img [src]="logoFull" />
      </a>
      <mat-chip-list aria-label="Environment" class="d-inline-block spacer" *ngIf="!isProd">
        <mat-chip color="primary" selected class="ml-4">
          <span>Assessores</span>
        </mat-chip>
        <mat-chip class="ml-4 d-none d-sm-inline-flex">
          <span>{{ envLabel }}</span>
        </mat-chip>
      </mat-chip-list>
      <div class="d-inline-block spacer" *ngIf="isProd"></div>
      <app-agent-header-options></app-agent-header-options>
    </mat-toolbar>
  </div>
  <div class="wrapper">
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
      <mat-sidenav
        #snav
        *ngIf="shouldSidenavBeVisible()"
        [opened]="toggled"
        [disableClose]="true"
        style="min-width: 80px; background-color: #fafafa"
        [mode]="mobileQuery.matches ? 'over' : 'push'"
      >
        <mat-nav-list [class.expanded-sidenav]="toggled && expanded">
          <a class="menu-item" mat-list-item routerLink="/agents/profile" [queryParams]="{}">
            <i class="fas fa-user"></i>
            <span class="menu-label" *ngIf="expanded">Minha Conta</span>
          </a>
          <mat-divider class="my-2"></mat-divider>
          <!-- <a class="menu-item" mat-list-item routerLink="/agents/dashboard" [queryParams]="{ }">
            <i class="fas fa-chart-line"></i>
            <span class="menu-label" *ngIf="expanded">Dashboard</span>
          </a> -->
          <a class="menu-item" mat-list-item routerLink="/agents/invite" [queryParams]="{}">
            <i class="fas fa-paper-plane"></i>
            <span class="menu-label" *ngIf="expanded">Indique Leads</span>
          </a>
          <a class="menu-item" mat-list-item routerLink="/agents/leads" [queryParams]="{}">
            <i class="fas fa-users"></i>
            <span class="menu-label" *ngIf="expanded">Meus Leads</span>
          </a>
          <a class="menu-item" mat-list-item routerLink="/agents/create-customer" [queryParams]="{}">
            <i class="fas fa-user-plus"></i>
            <span class="menu-label" *ngIf="expanded">Cadastrar Lead</span>
          </a>
          <a class="menu-item" mat-list-item routerLink="/agents/pending-documents" [queryParams]="{}">
            <i class="fas fa-file-alt"></i>
            <span class="menu-label" *ngIf="expanded">Documentos Pendentes</span> </a
          >|
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content
        class="sidenav-content"
        [class.expanded-sidenav-content]="visible && toggled && expanded"
        [class.toggled-only-sidenav-content]="visible && toggled && !expanded"
        (click)="toggled = mobileQuery.matches && toggled ? false : toggled"
      >
        <div class="content">
          <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" style="height: 100%">
            <mat-spinner [diameter]="90"></mat-spinner>
          </div>
          <router-outlet #o="outlet" *ngIf="!isLoading"></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <div class="footer">
      <div class="row">
        <div class="col-12 col-lg-6 align-items-center d-flex">
          <div class="links mx-auto">
            <a href="https://www.capitalempreendedor.com.br/blog/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-blogger"></mat-icon>
              <span>Blog</span>
            </a>
            <a href="https://www.linkedin.com/company/capital-empreendedor/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-linkedin"></mat-icon>
              <span>Linkedin</span>
            </a>
            <a href="https://www.facebook.com/capitalempreededor/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-facebook"></mat-icon>
              <span>Facebook</span>
            </a>
            <a href="https://www.instagram.com/capitalempreendedor/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-instagram"></mat-icon>
              <span>Instagram</span>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-sm-6 col-md-5 offset-md-1 signature py-1">
              <a class="link" [href]="urlPrivacyPolicy" target="_blank">Política de Privacidade</a>
            </div>
            <div class="col-sm-6 col-md-5 signature py-1">
              <a href="https://www.capitalempreendedor.com.br/faq" target="_blank" style="position: initial"> Dúvidas Frequentes</a>
            </div>
          </div>
          <!-- <div class="row mb-2">
            <div class="col-sm-6 col-md-5 offset-md-1 signature py-1">
              <a routerLink="/" style="position: initial"> Área de Clientes</a>
            </div>
            <div class="col-sm-6 col-md-5 signature py-1">
              <a routerLink="/bankers" style="position: initial"> Área de Operadores</a>
            </div>
          </div> -->
          <div class="row">
            <div class="col signature">
              <strong>v.{{ version }}</strong> - &#169; <span class="year">{{ year }}</span> - Capital Empreendedor -
              CNPJ: 21.261.858/0001-80
              <!-- Rua Dr Antonio Galizia, 181, 6. Andar, Edificio Trevizzo, Campinas, SP -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
