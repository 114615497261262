import firebase from 'firebase';
import { UserConsent } from './User';
import { CustomerPartner } from './Customer';

export interface Agent {
  address?: {
    city?: string;
    complement?: string;
    neighborhood?: string;
    number?: string;
    postalCode?: string;
    state?: string;
    street?: string;
  };
  cnpj?: string;
  createdAt?: firebase.firestore.Timestamp;
  displayName?: string;
  email?: string;
  emailVerified?: boolean;
  isAnonymous?: boolean;
  isApprovedByAdmin?: boolean;
  isActive?: boolean;
  createsNoTermsCustomers?: boolean;
  lastInteraction?: firebase.firestore.Timestamp;
  linkedin?: string;
  manuallyCreated?: boolean;
  name?: string;
  notifications?: {
    statusChanged: boolean;
    leadCreate: boolean;
    leadUpdate: boolean;
    leadAcceptedOffer: boolean;
  };
  phone?: string;
  photoUrl?: string;
  salesforce?: {
    accountId?: string;
    contactId?: string;
  };
  uid?: string;
  userConsent?: UserConsent;
  website?: string;
  source?: string;
  partnerCE?: CustomerPartner;
}

export interface AgentLog {
  date: firebase.firestore.Timestamp;
  event: AgentEvent;
  user?: string;
  description?: string;
}

export interface AgentPartner {
  partnerId: string;
  user: string;
  createdAt: firebase.firestore.Timestamp;
}

export interface AgentsResult {
  page: number;
  pageSize: number;
  total: number;
  results: any[];
}

export interface AgentLeadsResult {
  page: number;
  pageSize: number;
  total: number;
  results: any[];
}

export enum AgentStatus {
  'waiting-approval' = 'waiting-approval',
  'approved' = 'approved',
  'rejected' = 'rejected',
  'incomplete' = 'incomplete',
  'suspended' = 'suspended',
}

export enum AgentEvent {
  'created' = 'created',
  'updated' = 'updated',
  'rejected' = 'rejected',
  'confirmed' = 'confirmed',
  'enabled' = 'enabled',
  'disabled' = 'disabled',
  'approved' = 'approved',
}

export enum OrderDirection {
  'asc' = 'asc',
  'desc' = 'desc',
}
