
<div class="col-12">
<form [formGroup]="createEditFormGroup" (ngSubmit)="onSaveForm()">
    <mat-slide-toggle [disabled]="!['master','admin'].includes(loggedUser.accessLevel)" color="primary" name="marketingCommission"
        formControlName="marketingCommissionCtrl"
        [(ngModel)]="isChecked"
        (change) = "toggleMarketing($event)">
        Repassar comissão para parceiro de marketing
    </mat-slide-toggle>
    <br/>
    
    <div class="content-box" *ngIf="isChecked" >
    <mat-label class="fonte">utm_source:</mat-label>
    <input class="text"
    matInput
    type="text"
    name="utmSourceStr"
    placeholder="Digite a utm..."
    formControlName="utmSourceStrCtrl"
    
  />
    <br/>
    <br/>

    <div class="d-flex" >
     <mat-label class="fonte">Comissão:</mat-label>
        <mat-select class="select"
          placeholder="Forma de pagamento"
          name="commissionPay"
          formControlName="commissionPayTypeCtrl">
          <mat-option value="porcentagem"> Porcentagem </mat-option>
          <mat-option value="valorFixo"> Valor fixo </mat-option>
        </mat-select>
        <span>
          <input class="text"
          matInput
          type="text"
          name="commissionPayValueStr"
          placeholder="Insira um valor ..."
          formControlName="commissionPayValueCtrl"
          
        />
        </span>   
    </div>
    </div>
    <br/>
    <br/>
    <mat-slide-toggle color="primary" name="haveRemuneration"
      formControlName="haveRemunerationCtrl"
      [disabled]="!['master','admin'].includes(loggedUser.accessLevel)"
      [checked] = "isCheckedRemuneracao"
      (change) = "toggleRemuneracao($event)">
      Cobrar remuneração da instituição financeira
    </mat-slide-toggle>
    <br/>
    
    <div class="content-box" *ngIf="isCheckedRemuneracao" >
        <mat-label class="fonte">Descrição:</mat-label>
        <textarea
        matInput
        placeholder="Digite uma descrição..."
        formControlName="descriptionRemunerationCtrl"
        name="descriptionRemuneration"
        
      ></textarea>
    </div>
    <br/>
    <br/>
    <button [disabled]="!['master','admin'].includes(loggedUser.accessLevel)" mat-raised-button class="btn" color="primary" type="submit" (click)="onSaveForm()"
    >
    Salvar
  </button>
</form>
</div>

