<div class="justify-content-center">
  <mat-card>
    <mat-card-content class="card-mobile div-shadow">
      <div *ngIf="!isLoading && contract" id="divTitleTerm">
        <p id="titleTerm">Termos de serviço</p>
        <p id="subtitleTerm">Abaixo você encontra os termos de serviço acordados pela empresa {{contract.companyName}} no momento do cadastro</p>
      </div>
      <div style="text-align: center">
        <div class="text-center my-3" *ngIf="isLoading">
          <mat-spinner [diameter]="80" color="primary" class="mx-auto"></mat-spinner>
        </div>
        <div *ngIf="!isLoading && !contract">
          <p>Termo não disponível para visualização.</p>
        </div>
      
        <div #contractView id="term"></div>
        <div class="div-button">
          <button
            *ngIf="!isLoading && contract"
            mat-raised-button
            color="primary"
            style="color: white"
            (click)="onPrintTerm()"
          >
            <mat-icon>print</mat-icon> Imprimir
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
