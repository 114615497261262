<div
  class="content"
  [class.d-flex]="isLoading"
  [class.flex-column]="isLoading"
  [style.min-height]="isLoading ? '100%' : 'unset'"
>
  <div class="d-flex">
    <div style="flex: 1">
      <img alt="logo-capital-empreendedor" src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6" class="logo" />
    </div>
    <button mat-button class="d-flex align-items-center flex-row" (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" class="colored-icon mr-2"></mat-icon> Sair
    </button>
  </div>

  <div *ngIf="!isLoading">
    <div>
      <h1>Trocar Senha</h1>
      <p>Use a senha que você recebeu por email para criar uma nova.</p>
    </div>
    <form name="passwordFormGroup" [formGroup]="passwordFormGroup">
      <div class="form">
        <div class="mb-1 d-flex align-items-center">
          <mat-form-field color="primary">
            <!-- <mat-label>Senha</mat-label> -->
            <input
              matInput
              placeholder="Senha atual"
              type="password"
              name="oldPassword"
              formControlName="oldPassword"
              required
              [disabled]="isProcessing"
            />
            <mat-error *ngIf="passwordFormGroup.get('oldPassword')?.hasError('required')">
              Digite sua senha atual
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mb-1 d-flex align-items-center">
          <mat-form-field color="primary">
            <!-- <mat-label>Senha</mat-label> -->
            <input
              matInput
              placeholder="Nova senha"
              type="password"
              name="newPassword"
              required
              [disabled]="isProcessing"
              formControlName="newPassword"
            />
            <mat-error *ngIf="passwordFormGroup.get('newPassword')?.hasError('required')"
              >Digite sua nova senha</mat-error
            >
            <mat-error *ngIf="passwordFormGroup.get('newPassword')?.hasError('minlength')">
              Escolha uma senha com mais de 6 caracteres
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mb-1 d-flex align-items-center">
          <mat-form-field color="primary">
            <!-- <mat-label>Senha</mat-label> -->
            <input
              matInput
              placeholder="Confirme a Senha"
              type="password"
              name="confirmPassword"
              formControlName="confirmPassword"
              required
              [disabled]="isProcessing"
            />
            <mat-error *ngIf="passwordFormGroup.get('confirmPassword')?.hasError('required')"
              >Confirme sua senha</mat-error
            >
            <mat-error *ngIf="passwordFormGroup.controls['confirmPassword'].hasError('mismatch')"
              >As senhas não coincidem</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <button
            mat-raised-button
            color="primary"
            class="action-button"
            type="submit"
            [class.disabled]="passwordFormGroup.invalid"
            *ngIf="!isProcessing"
            [disabled]="passwordFormGroup.invalid"
            (click)="changePassword()"
          >
            Continuar
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
