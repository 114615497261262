<div class="container">
  <h2>Minha conta</h2>
  <mat-tab-group color="primary">
    <mat-tab label="Meus dados">
      <div class="inner-content">
        <app-banker-profile-data [banker]="banker"></app-banker-profile-data>
      </div>
    </mat-tab>
    <mat-tab label="Segurança">
      <app-banker-profile-security [banker]="banker"></app-banker-profile-security>
    </mat-tab>
    <mat-tab label="Notificações">
      <div class="inner-content">
        <app-banker-profile-notifications [banker]="banker"></app-banker-profile-notifications>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
