import { Component, OnInit } from '@angular/core';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class AgentDashboardComponent implements OnInit {
  constructor(private agentService: AgentService) {}

  ngOnInit() {
    this.agentService.redirectAccordingStatus(true, '/agents/dashboard', '/agents/leads');
  }
}
