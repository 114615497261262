import firebase from 'firebase';

export class AddressEntity {
  cep: string;
  logradouro: string;
  numero: string;
  bairro: string;
  cidade: string;
  uf: string;
  complemento: string | null;
  geoPoint: firebase.firestore.GeoPoint;

  constructor() {
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.bairro = '';
    this.cidade = '';
    this.uf = '';
    this.complemento = '';
    this.geoPoint = new firebase.firestore.GeoPoint(0, 0);
  }
}
