import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { BankersAdminService } from '../../services/bankers-admin.service';
import { StatsService } from '../../services/stats.service';

@Component({
  selector: 'app-bankers-reports',
  templateUrl: './bankers-reports.component.html',
  styleUrls: ['./bankers-reports.component.scss'],
})
export class BankersReportsComponent implements OnInit {
  bankers = undefined;
  isLoadingBankers = false;

  isLoadingBankersOps = false;
  loadingBankersOpsProgress = 0;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;

  selectedFields;
  fieldsList = [];
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [10, 25, 50];
  currentPage = 0;

  selectedFilter = 'name';
  currentFilterValue = '';
  filterValue = '';

  // Sorting
  sortField = 'lastInteraction';
  sortDirection: MatSortable['start'] = 'desc';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly typeFile = [
    { name: 'CSV', type: 'text/csv' },
    { name: 'XLSX', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
    { name: 'XLS', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
  ];
  selectedType: FormControl;

  InstitutionsFilter: Observable<any[]>;
  selectedInstitutions: any = new Array<any>();
  institutions: { name: string; id: string }[] = [];
  lastFilter = '';

  userControl: FormControl;
  selectedReport: FormControl;
  readonly reportList = [
    { label: 'Todos os operadores', name: 'all-bankers' },
    { label: 'Operadores e suas operações', name: 'bankers-and-ops' },
  ];

  constructor(
    private statsService: StatsService,
    private dialog: MatDialog,
    private bankersAdminService: BankersAdminService
  ) {}

  ngOnInit(): void {
    this.selectedReport = new FormControl();
    this.selectedFields = new FormControl();
    this.selectedType = new FormControl({ value: this.typeFile[0], disabled: false });
    this.dataSource = new MatTableDataSource([]);
    this.userControl = new FormControl();
    this.bankersAdminService.getInstitutions().then((institutions) => {
      this.institutions = institutions.map((i) => ({ name: i.nome, id: i.nomeNoSistema, selected: false }));
    });

    this.InstitutionsFilter = this.userControl.valueChanges.pipe(
      startWith<string | any[]>(''),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this.filter(filter))
    );
  }

  getAllBankers(): void {
    this.isLoadingBankers = true;
    this.statsService
      .getAllBankers()
      .then((result) => {
        this.bankers = result.map((bnk) => ({
          ...bnk,
          institution: bnk.institution?.name,
        }));

        this.fieldsList = [
          { name: 'name', label: 'Nome' },
          { name: 'email', label: 'E-mail' },
          { name: 'phone', label: 'Telefone' },
          { name: 'institution', label: 'Instituição' },
          { name: 'minRevenueStr', label: 'Faturamento mínimo' },
          { name: 'maxRevenueStr', label: 'Faturamento máximo' },
          { name: 'status', label: 'Status' },
        ];
        this.bankers = _.sortBy(this.bankers, [this.sortField]);

        if (this.sortDirection === 'desc') {
          _.reverse(this.bankers);
        }

        this.displayedColumns = this.fieldsList.map((f) => f.name);

        this.dataSource = new MatTableDataSource(this.bankers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'minRevenue':
              return item.revenueRange?.minRevenue;
            case 'maxRevenue':
              return item.revenueRange?.maxRevenue;
            default:
              return item[property];
          }
        };
        this.selectedFields.setValue(this.displayedColumns);

        this.isLoadingBankers = false;
      })
      .catch((err) => {
        console.error('Error getting all agents', err);
        this.bankers = null;

        this.dataSource = new MatTableDataSource([]);

        this.isLoadingBankers = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao obter operadores',
            alertDescription: `Houve um problema ao obter os dados dos operadores.`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  async getBankersAndOps(): Promise<void> {
    this.loadingBankersOpsProgress = 0;
    this.bankers = [];
    this.isLoadingBankers = true;
    this.isLoadingBankersOps = true;

    try {
      const institutions = this.selectedInstitutions.map((inst) => {
        return inst.id;
      });
      const allBankers = await this.statsService.getSearchBankers(institutions);
      const singleBankerProgress = allBankers?.length ? 100 / allBankers.length : 0;
      const opps = _.flatMap(allBankers, async (banker) => {
        const result = (
          await this.statsService.getBankerActiveOps({
            bank: banker.institution.name,
            banker: banker.uid,
            filterField: this.selectedFilter,
            filterValue: this.currentFilterValue,
            /*orderField: this.sortField,
            orderDirection: this.sortDirection,
            pageSize: this.pageSize,
            page: this.currentPage,*/
            isRegionalized: banker.isRegionalized || false,
            regionalization: banker.regionalization || null,
            minRevenue: banker.revenueRange?.minRevenue || null,
            maxRevenue: banker.revenueRange?.maxRevenue || null,
          })
        ).map((op) => ({
          name: banker.name,
          email: banker.email,
          opName: op.name,
          opEmail: op.email,
          rzSocial: op.companyName,
          cnpj: op.cnpj,
          institution: banker.institution.name,
          status: banker.status,
          opRevenue: Number(op.revenue),
          opCapitalValue: Number(op.creditValue),
        }));
        this.loadingBankersOpsProgress += singleBankerProgress;
        return result;
      });

      const allTableData = await Promise.all(opps);
      this.bankers = _.flatten(allTableData);

      this.fieldsList = [
        { name: 'name', label: 'Nome do Operador' },
        { name: 'email', label: 'E-mail do Operador' },
        { name: 'opName', label: 'Nome da Empresa' },
        { name: 'opEmail', label: 'E-mail da Empresa' },
        { name: 'rzSocial', label: 'Razão Social' },
        { name: 'cnpj', label: 'CNPJ' },
        { name: 'opRevenue', label: 'Faturamento' },
        { name: 'opCapitalValue', label: 'Valor Capital' },
        { name: 'institution', label: 'Instituição' },
        // { name: 'status', label: 'Status' },
      ];

      this.bankers = _.sortBy(this.bankers, [this.sortField]);

      if (this.sortDirection === 'desc') {
        _.reverse(this.bankers);
      }

      this.displayedColumns = this.fieldsList.map((f) => f.name);
      this.dataSource = new MatTableDataSource(this.bankers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.selectedFields.setValue(this.displayedColumns);
      this.isLoadingBankers = false;
      this.isLoadingBankersOps = false;
    } catch (err) {
      console.error('Error getting agents and leads', err);
      this.bankers = null;

      this.dataSource = new MatTableDataSource([]);

      this.isLoadingBankers = false;
      this.isLoadingBankersOps = false;

      this.dialog.open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Erro ao obter operadores e operações',
          alertDescription: `Houve um problema ao obter os dados dos operadores e operações.`,
          isOnlyConfirm: true,
        },
      });
    }
  }

  getData(): void {
    switch (this.selectedReport.value) {
      case 'all-bankers':
        this.getAllBankers();
        break;
      case 'bankers-and-ops':
        this.getBankersAndOps();
        break;
    }
  }

  exportList(): void {
    if (this.selectedFields?.value?.length) {
      const data = this.bankers.map((a) => _.pick(a, this.selectedFields.value));
      const type = this.selectedType?.value.type;
      const extension = this.selectedType?.value.name.toLowerCase();
      const blob = new Blob(
        [
          this.selectedType?.value.name === 'XLSX'
            ? this.statsService.generateXLSX(data)
            : this.selectedType?.value.name === 'XLS'
            ? this.statsService.generateXLS(data)
            : this.statsService.generateCSV(data),
        ],
        { type }
      );

      const date = new Date().toLocaleDateString();
      saveAs(blob, `${this.selectedReport.value}-${date}.${extension}`.toLowerCase().replace(/\s/g, '-'));
    }
  }

  filter(filter: string): any[] {
    if (typeof filter === 'string') {
      const institutionsArr = filter.split(',');
      filter = institutionsArr[institutionsArr.length - 1];
      if (filter.indexOf(' ') === 0) {
        filter = filter.slice(1, filter.length);
      }
    }
    this.lastFilter = filter;
    if (filter) {
      return this.institutions.filter((i) => {
        return i.name.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.institutions.slice();
    }
  }

  displayFn(value: any[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((i, index) => {
        if (index === 0) {
          displayValue = i.name;
        } else {
          displayValue += ', ' + i.name;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, institution: any) {
    event.stopPropagation();
    this.toggleSelection(institution);
  }

  toggleSelection(institution: any) {
    institution.selected = !institution.selected;
    if (institution.selected) {
      this.selectedInstitutions.push(institution);
    } else {
      const i = this.selectedInstitutions.findIndex((value) => value.name === institution.name);
      this.selectedInstitutions.splice(i, 1);
    }
    this.userControl.setValue(this.selectedInstitutions);
  }

  compareSelectedType(o1: any, o2: any): boolean {
    return o1.name === o2.name;
  }
}
