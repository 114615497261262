
<div class="table-container">
  <h1 class="custom-title">{{alertTitle}}</h1>
  <div class="row">
    <div class="table-container-custom">
      <div class="custom-row">  
        <div class="custom-column">
          <div class="row item-spacing">
            <div class="item">
              <span class="gray-bold-text">Nome completo</span>
              <br/>
              <span class="black-text">{{dataSource?.data.companyName}}</span>
            </div>
          </div>
          <div class="row item-spacing">
            <div class="item">
              <span class="gray-bold-text">Garantia</span>
              <br/>
              <span class="black-text">{{guaranteesStr}}</span>
            </div>
          </div>
          <div class="row item-spacing">
            <div class="itemLast">
              <span class="gray-bold-text">Valor Solicitado</span>
              <br/>
              <span class="black-text">{{dataSource?.data.creditValueStr}}</span>
            </div>
          </div>
        </div>
        <div class="custom-column">
          <div class="row item-spacing">
            <div class="item">
              <span class="gray-bold-text">Faturamento anual</span>
              <br/>
              <span class="black-text">{{dataSource?.data.revenueStr}}</span>
            </div>
          </div>
          <div class="row item-spacing">
            <div class="item">
              <span class="gray-bold-text">Data de Fundação</span>
              <br/>
              <span class="black-text">{{ formatarData(dataSource?.data.companyInformation?.foundedDate) }}</span>
            </div>
            <div class="item">
              <span class="gray-bold-text">Tempo de Empresa</span>
              <br/>
              <span class="black-text" *ngIf="dataSource?.data.companyInformation?.foundedDate !== undefined && dataSource?.data.companyInformation?.foundedDate !== null">
                {{ calcular(dataSource?.data.companyInformation?.foundedDate) }} 
                anos
              </span>
            </div>
          </div>
          <div class="row item-spacing">
            <div class="itemLast">
              <span class="gray-bold-text">Telefone</span>
              <br/>
              <span class="black-text">{{dataSource?.data.whatsapp}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="custom-button" (click)="onGoBack()">Fechar</button>
</div>