import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Banker } from 'functions/src/models/Banker';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { BankerFilter } from '../../../../../functions/src/models/common/BankerFilter';
import { OppFilter } from '../../../../../functions/src/models/common/OppFilter';
import { BankerService } from '../../services/banker.service';
import { OpportunityService } from '../../services/opportunity.service';
@Component({
  selector: 'app-concluded-list',
  templateUrl: './concluded-list.component.html',
  styleUrls: ['./concluded-list.component.scss'],
})
export class BankerConcludedListComponent implements OnInit, OnDestroy {
  opportunities = [];

  banker: Banker;
  minRevenue: number;
  maxRevenue: number;
  bankerSubscription: Subscription;

  // Control
  isLoadingResults = true;

  displayedColumns: string[] = ['companyName', 'cnpj', 'createdAt', 'taxa', 'limiteOperacaoStr', 'prazo'];
  dataSource: MatTableDataSource<any>;

  // Filters
  labels = {
    companyName: 'Razão Social',
    cnpj: 'CNPJ',
  };
  selectedFilter = 'companyName';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [10, 25, 40, 100];
  currentPage = 0;

  // Sorting
  sortField = 'createdAt';
  sortDirection = 'desc';

  private paginator: MatPaginator;
  @ViewChild('matSort', { static: true }) matSort: MatSort;
  @ViewChild('paginator', { static: false }) set matPaginator(mp1: MatPaginator) {
    this.paginator = mp1;
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private bankerService: BankerService,
    private dialog: MatDialog,
    private opportunityService: OpportunityService
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.isLoadingResults = true;

    this.bankerService.redirectAccordingStatus('/bankers/search-concluded', '/bankers/search-concluded');

    this.bankerSubscription = this.bankerService.banker.subscribe((banker) => {
      this.banker = banker;
      this.minRevenue = this.banker.revenueRange?.minRevenue || null;
      this.maxRevenue = this.banker.revenueRange?.maxRevenue || null;
      if (banker?.uid) {
        this.isLoadingResults = true;

        const bankerFilter: Partial<BankerFilter> = {};
        bankerFilter.bank = this.banker.institution.name;
        bankerFilter.banker = this.banker.uid;
        bankerFilter.filterField = this.selectedFilter;
        bankerFilter.filterValue = this.currentFilterValue;
        bankerFilter.isRegionalized = this.banker.isRegionalized || false;
        bankerFilter.minRevenue = this.banker.revenueRange?.minRevenue || null;
        bankerFilter.maxRevenue = this.banker.revenueRange?.maxRevenue || null;
        bankerFilter.typeSearch = 'concluded';

        this.applyFilter(bankerFilter);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
  }

  applyFilter(value: Partial<OppFilter>): void {
    const bankerFilter: Partial<BankerFilter> = value;
    bankerFilter.bank = this.banker.institution.name;
    bankerFilter.banker = this.banker.uid;
    bankerFilter.isRegionalized = this.banker.isRegionalized || false;
    bankerFilter.typeSearch = 'concluded';

    this.isLoadingResults = true;

    this.opportunityService
      .searchOpportunities(bankerFilter)
      .then((response) => {
        this.opportunities = response;
        console.log(this.opportunities.map((op) => op.uid));

        this.length = response.length;

        this.dataSource = new MatTableDataSource(this.opportunities);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting opportunities of banker.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar oportunidades',
              alertDescription: `Ocorreu um erro ao buscar suas oportunidades. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string): void {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  paginatorEvent(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.currentPage = 0;
    } else {
      this.currentPage = event.pageIndex;
    }

    const bankerFilter: Partial<BankerFilter> = {};
    bankerFilter.currentPage = this.currentPage;
    bankerFilter.bank = this.banker.institution.name;
    bankerFilter.banker = this.banker.uid;
    bankerFilter.filterField = this.selectedFilter;
    bankerFilter.filterValue = this.currentFilterValue;
    bankerFilter.isRegionalized = this.banker.isRegionalized || false;
    bankerFilter.minRevenue = this.banker.revenueRange?.minRevenue || null;
    bankerFilter.maxRevenue = this.banker.revenueRange?.maxRevenue || null;
    bankerFilter.typeSearch = 'concluded';

    this.applyFilter(bankerFilter);
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;

    const bankerFilter: Partial<BankerFilter> = {};
    bankerFilter.currentPage = this.currentPage;
    bankerFilter.bank = this.banker.institution.name;
    bankerFilter.banker = this.banker.uid;
    bankerFilter.filterField = this.selectedFilter;
    bankerFilter.filterValue = this.currentFilterValue;
    bankerFilter.isRegionalized = this.banker.isRegionalized || false;
    bankerFilter.minRevenue = this.banker.revenueRange?.minRevenue || null;
    bankerFilter.maxRevenue = this.banker.revenueRange?.maxRevenue || null;
    bankerFilter.typeSearch = 'concluded';

    this.applyFilter(bankerFilter);
  }

}
