import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { InstituicaoFinanceira } from '../../admin/instituicao-financeira/instituicao-financeira';

@Injectable({
  providedIn: 'root',
})
export class FinancialInstitutionsService {
  constructor(private angularFirestore: AngularFirestore) {}

  getFinancialInstitutions(): Observable<InstituicaoFinanceira[]> {
    return this.angularFirestore.collection<InstituicaoFinanceira>(`instituicoes-financeiras`).valueChanges();
  }

  getFinancialInstitution(id: string): Observable<InstituicaoFinanceira> {
    return this.angularFirestore.doc<InstituicaoFinanceira>(`instituicoes-financeiras/${id}`).valueChanges();
  }
}
