import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/app';
import { Agent } from 'functions/src/models/Agent';
import { Customer } from 'functions/src/models/Customer';
import { AgentNote } from 'functions/src/models/Notes';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import {
  ArquivoInfo as ArquivoInfoMongoDB,
  Document as DocumentMongoDB,
} from 'functions/src/models/documents/UserDocument';
import { Contrato, DocControleVencimento } from 'functions/src/models/model-interface';
import _ from 'lodash';
import _moment from 'moment';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { LogService } from 'src/app/components/logger/log.service';
import { UploadDialogComponent } from 'src/app/components/upload-dialog/upload-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { NotesService } from '../../../admin/services/notes.service';
import { LeadNotesComponent } from '../../components/lead-notes/lead-notes.component';
import { AgentService } from '../../services/agent.service';
import { LeadService } from '../../services/lead.service';
import { OpportunityManagementService } from './../../../admin/services/opportunity-management.service';
import { ArquivoInfo } from './lead.interface';

@Component({
  selector: 'app-agent-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AgentLeadComponent implements OnInit, OnDestroy {
  // Customer Data
  customerSubscription: Subscription;
  customer: Customer;
  status: string;
  guarantees: string[];

  documentos = [];
  documentsSubscription: Subscription;

  // Contract Data
  contractSubscription: Subscription;
  contract: Contrato;

  // Agent Data
  agent: Agent;
  agentSubscription: Subscription;

  // Control Attributes
  uid: string;
  isLoadingCustomer = true;
  hasCustomerError = false;
  isLoadingContract = true;
  isUpload: boolean;

  // BDC
  bigdatacorpData: any;
  bigdatacorpSubscription: Subscription;
  readonly Object = Object;

  // Docs Atributes
  userDocumentsSubscription: Subscription;
  documents: DocumentMongoDB[];
  getDetalhesBancoSubscription: Subscription;
  documentSelected: DocumentMongoDB;
  fileInfoFormGroup: FormGroup;

  opportunities: Opportunity[];
  expandedElement: Opportunity;
  expandedElementId: string = null;
  creditColumns = [
    'logoPath',
    'institutionName',
    'deadlineForMonth',
    'limitOperation',
    'rate',
    /* 'guarantees', */ 'status' /*,'decisionCustomer'*/,
  ];
  creditDataSource: MatTableDataSource<Opportunity>;

  // Notes
  isCreateMode = false;
  isLoadingNotes = true;
  newNote: AgentNote = {
    message: '',
  };
  notes: AgentNote[] = [];
  notesSubscription: Subscription;
  readonly MAX_NOTES_PREVIEW_QUANTITY = 5;

  // User-notes
  createdBy = {};

  @ViewChild(FileUploadComponent, { static: false }) fileUpload: FileUploadComponent;
  canUpload: boolean;
  uploadYearDocument: boolean;
  // todo : colocar status como união literal
  selectedDocument: { documents: ArquivoInfo[]; status?: string; year?: string | number }[];
  yearDocuments: any[];

  constructor(
    public authService: AuthService,
    public agentService: AgentService,
    private dialog: MatDialog,
    public leadService: LeadService,
    private userDocumentService: UserDocumentService,
    private logger: LogService,
    private route: ActivatedRoute,
    private _data: UserDocumentService,
    private notesService: NotesService,
    private oppService: OpportunityManagementService
  ) {
    this.documents = [];
  }

  ngOnInit(): void {
    this.agentService.redirectAccordingStatus(true, '/agents/lead', '/agents/lead');
    this.uid = this.route.snapshot.paramMap.get('id');
    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.agent = agent;
      this.createdBy = {
        email: agent.email,
        name: agent.name || '-',
      };

      if (agent) {
        this.customerSubscription = this.leadService.getLeadData(this.uid).subscribe((lead) => {
          if (lead) {
            this.customer = lead;

            this.notesService
              .getAgentComments({
                customerUid: this.customer.uid,
                senderEmail: this.agent.email,
                isSenderAdmin: '',
                agentUid: this.agent.uid,
              })
              .subscribe((notes) => {
                this.notes = notes.slice(0, this.MAX_NOTES_PREVIEW_QUANTITY);
                this.isLoadingNotes = false;
              });

            this.guarantees = lead.guarantees?.map((g) => g.name);

            if (this.customer.uid) {
              this.userDocumentService.getDocuments({ uid: this.customer.uid }).then((docs) => {
                this.documents =
                  (docs as DocumentMongoDB[])?.sort((o1, o2) => {
                    // acusa erro que nao encontra 'documentos'
                    if (o1.ordenation > o2.ordenation) {
                      return 1;
                    } else if (o1.ordenation < o2.ordenation) {
                      return -1;
                    } else {
                      return 0;
                    }
                  }) || [];
                this.getAnosExercicios(this.documents);
              });
            }
            this.hasCustomerError = false;
          } else {
            this.hasCustomerError = true;
          }
          this.isLoadingCustomer = false;
        });
        this.getOpportunities();
      } else {
        this.hasCustomerError = true;
        this.isLoadingCustomer = false;
      }
    });

    this.bigdatacorpSubscription = this.leadService.getBigDataCorpSavedData(this.uid).subscribe((docs) => {
      this.bigdatacorpData = {};

      docs.forEach((d) => {
        _.forEach(d, (v, k) => (this.bigdatacorpData[k] = v));
      });
    });
  }

  getOpportunities(): void {
    this.oppService.getOpportunities(this.uid).then((opps) => {
      this.opportunities = opps;
      if (this.expandedElement && this.expandedElement.lineActive) {
        // Caso já esteja expandido, obtem nova referencia do objeto atualizado pelo stream.
        this.expandedElement = this.opportunities.find((a) => a.institutionId === this.expandedElementId);
      }
      if (this.opportunities?.length > 0) {
        this.creditDataSource = new MatTableDataSource(this.opportunities);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
    if (this.documentsSubscription) {
      this.documentsSubscription.unsubscribe();
    }
    if (this.bigdatacorpSubscription) {
      this.bigdatacorpSubscription.unsubscribe();
    }
  }

  onSendNewFile(item: DocumentMongoDB, year = '') {
    const confirmUpload = this.dialog
      .open(UploadDialogComponent, {
        maxWidth: '600px',
        data: {
          item: item,
          exerciseYear: year,
          customer: this.customer,
          hasEmissionDate: false,
        },
      })
      .afterClosed()
      .subscribe((doc) => {
        let document = this.documents.find((d) => d.typeId === doc.typeId);
        document = doc;
      });
    this.uploadYearDocument = year === '' ? false : true;
    this.fileInfoFormGroup = new FormGroup({
      dataEmissaoCtrl: new FormControl('', []),
      anoExercicioCtrl: new FormControl(year, []),
      caminhoArquivoCtrl: new FormControl('', []),
    });
  }

  onVoltarParaLista(): void {
    this.isUpload = false;
  }

  getAnosExercicios(userDocuments: DocumentMongoDB[]): void {
    const anoCorrente = new Date().getFullYear();
    if (userDocuments.length > 0) {
      userDocuments.forEach((tipoDocumento) => {
        if (tipoDocumento.financialYear.id === 'sim') {
          tipoDocumento.financialYears = null;
          let countYear = 0;
          const anoExercicioCorrente = tipoDocumento.anoExercicioCorrente || false;
          while (countYear < tipoDocumento.qtyFinancialYear) {
            let financialYear;

            if (anoExercicioCorrente) {
              financialYear = (anoCorrente - countYear).toString();
              countYear++;
            } else {
              countYear++;
              financialYear = (anoCorrente - countYear).toString();
            }

            if (tipoDocumento.financialYears) {
              tipoDocumento.financialYears.push(financialYear);
            } else {
              tipoDocumento.financialYears = [financialYear];
            }
          }
        }
      });
    }
  }

  onSalvarArquivo(): void {
    const arquivoInfo: ArquivoInfoMongoDB = {
      dateRelease: _moment(this.fileInfoFormGroup.controls.dataEmissaoCtrl.value).toDate(),
      financialYear: this.fileInfoFormGroup.controls.anoExercicioCtrl.value,
    };
    // Validar se a data de emissão não é uma data futura.
    const dateNow = new Date();
    if (arquivoInfo.dateRelease <= dateNow) {
      const imgUrl = this.fileUpload.getImageUrl();
      if (imgUrl) {
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '600px',
            data: { alertTitle: 'Confimação', alertDescription: 'Deseja realmente salvar esse documento?' },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              arquivoInfo.path = imgUrl;
              arquivoInfo.userUploader = this.customer.email;
              arquivoInfo.dateUpload = new Date();
              this.documentSelected.email = this.customer.email;
              if (!this.documentSelected.fileInfo) {
                this.documentSelected.fileInfo = [];
                this.documentSelected.fileInfo.push(arquivoInfo);
                if (this.documentSelected.qtySubmittedFiles < this.documentSelected.qtyExpectedFiles) {
                  this.documentSelected.qtySubmittedFiles = this.documentSelected.qtySubmittedFiles + 1;
                } else {
                  this.documentSelected.qtySubmittedFiles = this.documentSelected.qtyExpectedFiles;
                }
              } else {
                if (this.documentSelected.financialYear.id === 'sim') {
                  const arquivoInfoSalvo = this.documentSelected.fileInfo.find(
                    (a) => a.financialYear === arquivoInfo.financialYear
                  );
                  if (arquivoInfoSalvo) {
                    arquivoInfoSalvo.financialYear = arquivoInfo.financialYear;
                    arquivoInfoSalvo.dateRelease = arquivoInfo.dateRelease;
                    arquivoInfoSalvo.path = arquivoInfo.path;
                    arquivoInfoSalvo.userUploader = arquivoInfo.userUploader;
                    arquivoInfoSalvo.dateUpload = arquivoInfo.dateUpload;
                  } else {
                    this.documentSelected.fileInfo.push(arquivoInfo);
                    if (this.documentSelected.qtySubmittedFiles < this.documentSelected.qtyExpectedFiles) {
                      this.documentSelected.qtySubmittedFiles = this.documentSelected.qtySubmittedFiles + 1;
                    } else {
                      this.documentSelected.qtySubmittedFiles = this.documentSelected.qtyExpectedFiles;
                    }
                  }
                } else {
                  this.documentSelected.fileInfo = [arquivoInfo];
                  if (this.documentSelected.qtySubmittedFiles < this.documentSelected.qtyExpectedFiles) {
                    this.documentSelected.qtySubmittedFiles = this.documentSelected.qtySubmittedFiles + 1;
                  } else {
                    this.documentSelected.qtySubmittedFiles = this.documentSelected.qtyExpectedFiles;
                  }
                }
              }
              if (this.documentSelected.qtySubmittedFiles !== this.documentSelected.qtyExpectedFiles) {
                this.documentSelected.situation = 'Enviado Parcialmente';
              } else {
                this.documentSelected.situation = 'Envio Completo';
              }
              this.documents.forEach(function (item, index) {
                if (!item.fileInfo) {
                  item.fileInfo = [];
                }
                if (!item.approvalOrDisapprovalDate) {
                  item.approvalOrDisapprovalDate = null;
                }
                if (!item.disapprovalReason) {
                  item.disapprovalReason = null;
                }
                if (!item.expirationControlId) {
                  item.expirationControlId = null;
                }
              });
              this.customer.situacao = this.getSituacaoUsuario(this.documents);
              let mnemonicoDocumento = null;
              if (this.documentSelected.mnemonic) {
                mnemonicoDocumento = this.documentSelected.mnemonic;
              }
              if (!this.documentSelected.pendingIssueForAdmin) {
                this.documentSelected.pendingIssueForAdmin = true;
              }
              if (this.documentSelected.pendingIssueForUser) {
                this.documentSelected.pendingIssueForUser = false;
              }
              // sempre que realizar um upload de um documento que tiver data de emissão, incluí-lo na tabela de
              // controle de vencimento. Será retirado dessa tabela após a detecção do vencimento, aprovação ou reprovação do documento
              if (this.documentSelected.expirationDate.id === 'sim') {
                const dataVencimento = _moment(this.fileInfoFormGroup.controls.dataEmissaoCtrl.value).add(
                  this.documentSelected.qtyDays,
                  'days'
                );
                const dataControleVencimento: DocControleVencimento = {
                  email: this.documentSelected.email,
                  tipoDocumento: this.documentSelected.mnemonic,
                  qtdDias: this.documentSelected.qtyDays,
                  dataEmissao: firebase.firestore.Timestamp.fromDate(arquivoInfo.dateRelease),
                  dataVencimento: firebase.firestore.Timestamp.fromDate(dataVencimento.toDate()),
                  estaVencido: false,
                  dataCriacao: firebase.firestore.Timestamp.fromMillis(Date.now()),
                };
                this.leadService
                  .addControleVencimento(dataControleVencimento)
                  .then((res) => {
                    this.documentSelected.expirationControlId = res;
                    this.documentSelected.uid = this.customer.uid;
                    this.userDocumentService.patchDocument(this.documentSelected);
                    // this.logger.info('Arquivo salvo com sucesso.');
                  })
                  .catch((e) => {
                    this.logger.error('Problemas ao incluir o novo controle de vencimento de documentos', e);
                  });
              } else {
                this.documentSelected.uid = this.customer.uid;
                this.userDocumentService.patchDocument(this.documentSelected);
                // this.logger.info('Arquivo sem validade salvo com sucesso.');
              }
              this.isUpload = false;
            }
          });
      } else {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Arquivo obrigatório',
            alertDescription: 'É obrigatório informar um arquivo.',
            isOnlyConfirm: true,
          },
        });
      }
    } else {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Data de Emissão',
          alertDescription:
            'Informe uma data de emissão menor ou igual à data atual.O documento não pode ser de data futura.',
          isOnlyConfirm: true,
        },
      });
    }
  }

  getSituacaoUsuario(documents) {
    let situacaoUsuario: BasicStructure = {};
    // sem pendencias de documentos
    const f1 = documents.filter((a) => a.situation !== 'Envio Completo' && a.situation !== 'Aprovado');
    // recupera todas as oportunidades abertas
    const f2 = this.opportunities.filter((a) => a.lineActive && !a.lineClosed);
    // recupera todas as oportunidades que estão não fechadas
    const f3 = this.opportunities.filter((a) => !a.lineClosed);
    if (f1 && f1.length === 0) {
      // Não há documentos pendentes de envio.
      if (f2 && f2.length > 0) {
        // Existem oportunidades de crédito abertas e não há documentos pendentes.
        situacaoUsuario = { id: 'oportunidade-aberta', name: 'Oportunidade aberta' };
      } else if (f3 && f3.length === 0) {
        // Não existem linhas abertas, documentos pendentes e todas as linhas estão fechadas
        situacaoUsuario = { id: 'operacao-fechada', name: 'Operação de crédito concluída' };
      } else {
        // Não exitem oportunidades abertas e nem documentos pendentes.
        situacaoUsuario = { id: 'sem-pendencia-oportunidade', name: 'Sem pendências e oportunidades' };
      }
    } else {
      // Existem documentos pendentes independentes das situações das oportunidades
      situacaoUsuario = { id: 'documentos-pendentes', name: 'Documentos pendentes' };
    }

    return situacaoUsuario;
  }

  onClickExpanded(): void {
    if (this.expandedElement && this.isOpportunityActive(this.expandedElement)) {
      this.expandedElementId = this.expandedElement.institutionId;
    } else {
      this.expandedElementId = null;
    }
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNewNote();
  }

  clearNewNote(): void {
    this.newNote.message = '';
  }

  createNote(): void {
    // email criado pelo agente e enviado para o admin
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar registro ',
          alertDescription: 'Deseja realmente salvar esse registro de acompanhamento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const note: AgentNote = {
            ...this.newNote,
            type: 'AGENT',
            agent: this.agent.uid,
            createdBy: {
              name: this.agent.name,
              email: this.agent.email,
            },
          };

          this.notesService
            .createAgentNote(
              this.uid,
              note,
              {
                companyName: this.customer.companyName || this.customer.email,
              },
              'agent-admin'
            )
            .then(() => {
              this.isCreateMode = false;
              // this.notes = [{ ...note, createdAt: firestore.Timestamp.now() }, ...this.notes];
              this.clearNewNote();
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Registro salvo',
                  alertDescription: 'O registro de acompanhamento foi salvo com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao salvar',
                  alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  viewMoreNotes(): void {
    this.dialog.open(LeadNotesComponent, {
      data: {
        customerUid: this.customer.uid,
        agent: this.agent,
        user: this.uid,
        notificationData: {
          companyName: this.customer.companyName || this.customer.email,
        },
      },
    });
  }

  getYearDocuments(year: number): void {
    this.yearDocuments = this.selectedDocument.filter((file) => file.year === year);
    this.yearDocuments = this?.yearDocuments[0];
  }

  getDocumentStatus(item: DocumentMongoDB, year = ''): string {
    const files = year !== '' ? item.fileInfo.filter((f) => f.financialYear === year) : item.fileInfo;
    return files.length > 0 ? ((item?.minimumDocuments || 1) > files.length ? 'Pendente' : 'Enviado') : 'Pendente';
  }

  onRemoveFileOfDocument(fileIndex = null, item: DocumentMongoDB, file: DocumentMongoDB['fileInfo'][0]): void {
    const doc = this.documents.find((d) => d.typeId === item.typeId);

    doc.fileInfo = doc.fileInfo.filter((f, i) => f.path !== file.path && fileIndex !== i);
    doc.qtySubmittedFiles = item.fileInfo.length ?? 0;
    if (doc.fileInfo.length === 0) {
      doc.situation = 'Pendente';
      doc.clicksignDocumentKey = null;
      doc.clicksignSignatureRequestKey = null;
    }
    doc.uid = this.customer.uid;
    this._data.patchDocument(doc);
  }

  isOpportunityActive(opportunity: Opportunity): boolean {
    return opportunity.lineActive || opportunity.stageOpportunity.defaultprobability >= 30;
  }
  deadLineStr(opportunity: Opportunity): string {
    return opportunity?.deadlineForMonth > 0
      ? opportunity.deadlineForMonth + (opportunity.deadlineForMonth > 1 ? ' meses' : ' mes')
      : '-';
  }
}
