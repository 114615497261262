import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APICreditStatusInstitution } from '../../../../functions/src/models/APIIntegration';

@Component({
  selector: 'app-credit-status-dialog',
  templateUrl: './credit-status-dialog.component.html',
  styleUrls: ['./credit-status-dialog.component.scss'],
})
export class CreditStatusDialogComponent implements OnInit {
  public alertTitle = 'Alert';
  public infoStatusInstitution: APICreditStatusInstitution;
  public institutionName: string;
  public alertDescription: string;
  public isOnlyConfirm: boolean;
  public isInput = false;
  public hasTextDescription: boolean;
  public dataTextDescription: {
    placeholder: 'Descrição';
    errorMessage: 'Informe uma descrição';
    dataTextCtrl: '';
  };
  public hasSelectList: boolean;
  public dataSelectList: {
    placeholder: 'Opções';
    errorMessage: 'Selecione uma opção';
    dataList: [];
  };
  optionSelected: any;

  constructor(private dialogRef: MatDialogRef<CreditStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.alertTitle = data.alertTitle;
    this.alertDescription = `<p>` + data.alertDescription + `</p>`;
    this.isOnlyConfirm = data.isOnlyConfirm;
    this.isInput = data.isInput;
    this.hasTextDescription = data.hasTextDescription;
    this.institutionName = data.institutionName;
    this.infoStatusInstitution = this.mapInfoStatusInfo(data.institutionInfo, this.institutionName);
    this.mapStatusMessages(this.institutionName);

    if (this.hasTextDescription) {
      this.dataTextDescription = data.dataTextDescription;
    }
    this.hasSelectList = data.hasSelectList;
    if (this.hasSelectList) {
      this.dataSelectList = data.dataSelectList;
    }
  }

  ngOnInit(): void {}

  onOk() {
    if (this.hasTextDescription && !this.dataTextDescription.dataTextCtrl.trim()) {
      return;
    } else if (this.hasTextDescription && this.dataTextDescription.dataTextCtrl) {
      this.dialogRef.close(this.dataTextDescription.dataTextCtrl);
    } else if (this.hasSelectList) {
      if (this.optionSelected) {
        this.dialogRef.close(this.optionSelected);
      } else {
        return;
      }
    } else {
      this.dialogRef.close(true);
    }
  }

  mapStatusMessages(instName: string) {
    switch (instName) {
      case 'q_flash':
        this.infoStatusInstitution.status = this.mapQFlashStatus(this.infoStatusInstitution.status);
        break;
    }
  }

  mapQFlashStatus(status: string) {
    if (status === 'Waiting validation') {
      status = 'Aguardando Validação';
    }
    return status;
  }

  mapInfoStatusInfo(instData: any, instName: string) {
    switch (instName) {
      case 'flip':
        return {
          id: instData?.id,
          status: instData?.status,
          suggestedLimit: instData?.simulacao?.limiteSugerido,
          minimumRate: instData?.simulacao?.taxasAPartir,
        };
      case 'q_flash':
        return {
          id: instData?.id,
          status: instData?.status,
          cnpj: instData?.cnpj,
          approvedValue: instData?.limite_aprovado_cents,
          consumedLimit: instData?.consumed_limit,
        };
      case 'money_money_invest':
        return {
          id: instData?.id,
          status: instData?.status,
          cnpj: instData?.cnpj,
          details: instData?.details,
          approvedValue: instData?.limite_aprovado_cents,
          availableCredit: instData?.availableCredit,
          loanTerm: instData?.loanTerm,
          offerUrl: instData?.offerUrl,
          rating: instData?.rating,
        };
      case 'nexoos':
        return {
          id: instData?.loan_request?.id,
          status: instData?.loan_request?.loan_state,
          loanValue: instData?.loan_request?.loan_value,
          loanTerm: instData?.loan_request?.loan_term,
          loanRisk: instData?.loan_request?.loan_risk,
        };
      case 'peak':
        return {
          id: instData?.result?.id,
          status: instData?.result?.status,
          loanTerm: instData?.result?.parcelas,
          loanValue: instData?.result?.valorSolicitado,
          loanTax: instData?.result?.taxaAno,
          approvedValue: instData?.result?.valorAprovado,
        };
        case 'u_lend':
        return {
          id: instData?.request?.uuid,
          status: instData?.request?.status.label,
          cnpj: instData?.request?.company.cnpj,
          loanValue: instData?.request?.requested_amount,
          approvedValue: instData?.request?.approved_amount,
          offerUrl: instData?.request?.complete_data_url,
        };
        case 'tutu_digital':
        return {
          id: instData?.data?.codigo,
          status: instData?.data?.status,
          cnpj: instData?.data?.cnpj
        };
        case '123_qred_solucoes_financeiras_ltda':
        return {
          status: instData?.status,
          details: instData?.reason
        };
    }
  }
}
