import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { InstituicaoFinanceira } from '../../../../../functions/src/models/model-interface';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { CadastrosGeraisItem } from '../../../admin/cadastros-gerais/cadastros-gerais';
import { CadastrosGeraisService } from '../../../admin/cadastros-gerais/cadastros-gerais.service';
import { DescriptionOfferDialogComponent } from '../description-offer-dialog/description-offer-dialog.component';

@Component({
  selector: 'app-customer-credit-dialog',
  templateUrl: './customer-credit-dialog.component.html',
  styleUrls: ['./customer-credit-dialog.component.scss'],
})
export class CustomerCreditDialogComponent implements OnInit {
  proposalData: Opportunity;
  institutionData: InstituicaoFinanceira;
  nameIf: string;

  cadastrosGeraisSubscription: Subscription;
  validityMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { proposal: Opportunity; institution: InstituicaoFinanceira, nameIf: string },
    public cadastrosGeraisService: CadastrosGeraisService, private dialog: MatDialog
  ) {
    this.proposalData = data.proposal;
    this.institutionData = data.institution;
    this.nameIf = data.nameIf;
  }

  rejectReasons: CadastrosGeraisItem[];
  isRejectReasonFormOpen = false;
  selectedReason: number | string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
    const nullItem = 0;
    const nextItem = 1;
    const prevItem = -1;

    this.cadastrosGeraisSubscription = this.cadastrosGeraisService
      .getCadastrosGerais('motivos-de-recusa-cliente')
      .subscribe((result) => {
        this.rejectReasons = result;
        this.rejectReasons.sort(function (itemOne, itemTwo) {
          return itemTwo.position > itemOne.position
            ? prevItem
            : itemTwo.position < itemOne.position
            ? nextItem
            : nullItem;
        });
      });
      this.calcValidityMessage()
  }

  goBackToDetails(): void {
    this.isRejectReasonFormOpen = false;
    this.selectedReason = undefined;
  }

  convertLinks(text: string): string {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  openDescriptionDialog(proposalData: any) {
    return this.dialog.open(DescriptionOfferDialogComponent, {
      data: {
        proposal: proposalData,
        institution: this.institutionData,
        nameIf: this.nameIf,
        customerData: this.proposalData.customerData,
      },
      // Desativa o foco automático para evitar o scroll para o botão
      autoFocus: false,
    });
  }
  
  calcValidityMessage(): void {
    if (this.proposalData) {
      const creationDate = new Date(this.proposalData.lastStageChangeDate);
      const proposedValidity = this.proposalData.proposedValidity;
  
      const expirationDate = new Date(creationDate);
      expirationDate.setDate(expirationDate.getDate() + proposedValidity);
  
      const today = new Date();
  
      if (today < expirationDate) {
        const timeDiff = expirationDate.getTime() - today.getTime();
        const daysUntilExpiration = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.validityMessage = `Faltam ${daysUntilExpiration} dias para expirar, até ${expirationDate.toLocaleDateString()}`;
      } else {
        this.validityMessage = `Venceu em ${expirationDate.toLocaleDateString()}`;
      }
    } else {
      console.log('Instituição não encontrada para a oportunidade:', this.proposalData);
      this.validityMessage = 'Instituição não encontrada';
    }
  }

  isNoTouch(institutionData: any, proposalData: any): boolean {
    if (!institutionData?.noTouch) {
      return true;
    }
    if (institutionData.noTouch.id === 'nao') {
      return true;
    }
    if (institutionData.noTouch.id === 'sim') {
      const defaultProbability = proposalData?.stageOpportunity.defaultprobability;
      if (defaultProbability !== 40 && defaultProbability !== 60) {
        return true;
      }
    }
    return false;
  }

  isExpired(opportunity: any): boolean {
    const today = new Date();
    const creationDate = new Date(this.proposalData.lastStageChangeDate);
    const proposedValidity = opportunity.proposedValidity ? opportunity.proposedValidity : today;
    const expirationDate = new Date(creationDate);
    expirationDate.setDate(expirationDate.getDate() + proposedValidity);

    return today >= expirationDate;
    
  }



}
