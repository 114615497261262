import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Banker } from '../../../../../functions/src/models/Banker';
import { BankerNote } from '../../../../../functions/src/models/Notes';
import { Customer } from '../../../../../functions/src/models/customers/Customer';
import { NotesService } from '../../../admin/services/notes.service';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { BankerService } from '../../services/banker.service';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'app-chat-message-banker',
  templateUrl: './chat-message-banker.component.html',
  styleUrls: ['./chat-message-banker.component.scss'],
})
export class ChatMessageBankerComponent implements OnInit, OnDestroy {
  @Input() customer: Customer;
  @Input() banker: Banker;

  // Notes
  isCreateMode = false;
  isLoadingNotes = true;
  message = '';
  notes: BankerNote[] = [];
  notesSubscription: Subscription;

  constructor(private notesService: NotesService, private opportunityService: OpportunityService, private bankerService: BankerService, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.getNotes();
  }

  ngOnDestroy(): void {
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
  }

  private getNotes(): void {
    this.notesSubscription = this.notesService
      .getBankerComments({
        customerUid: this.customer.uid,
        isSenderAdmin: '',
        institution: this.banker.institution.name,
        senderEmail: this.banker.email,
      })
      .subscribe((notes) => {
        this.notes = notes.filter(n => !n?.deleted).slice(0);
        this.isLoadingNotes = false;
      });
  }

  async createNote(): Promise<void> {
    console.log(this.message);
    if (!this.message) {
      this.messageError('Menssagem vazia');
      return;
    }
    const note: BankerNote = {
      message: this.message,
      type: 'BANKER',
      banker: this.banker.uid,
      createdBy: {
        name: this.banker.name,
        email: this.banker.email,
      },
    };

    let labelInstitution = this.banker.institution.label;
    if (!labelInstitution) {
      await this.bankerService
      .getCreditInstitutionName(this.banker.institution.name)
      .then((name) => {
        labelInstitution = name;
      })
      .catch((err) => {
        console.error('Error getting institution name', err);
      });
    }

    await this.opportunityService
      .createNote(this.customer.uid, this.banker.institution.name, note, {
        companyName: this.customer.companyName,
        institution: labelInstitution
      })
      .then(() => {
        this.isCreateMode = false;
        this.clearNewNote();
      })
      .catch((err) => {
        console.error('Error creating note', err);

        this.messageError();
      });
  }

  private messageError(alertDescription?: string): void {
    this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: 'Erro ao enviar',
        alertDescription: alertDescription || 'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
        isOnlyConfirm: true,
      },
    });
  }

  deleteNote(note: BankerNote): void {
    note.deleted = true;

    this.opportunityService
      .updateNote(this.customer.uid, this.banker.institution.name, note)
      .then(() => {
        this.isCreateMode = false;
      })
      .catch((err) => {
        console.error('Error creating note', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao enviar',
            alertDescription: 'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
            isOnlyConfirm: true,
          },
        });
      });
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNewNote();
  }

  clearNewNote(): void {
    this.message = '';
  }
}
