import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MASKS } from 'ng-brazil';
import { Customer } from '../../../../functions/src/models/Customer';
import { Opportunity } from '../../../../functions/src/models/opportunity/Opportunity';
// import { LeadService } from '../../agents/services/lead.service';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
// import { CustomerOpportunityComponent } from '../containers/customer-opportunity/customer-opportunity.component';
import { OpportunityManagementService } from '../services/opportunity-management.service';
import { UserManagementService } from '../services/user-management.service';


@Component({
  selector: 'app-opp-list',
  templateUrl: './opp-list.component.html',
  styleUrls: ['./opp-list.component.scss']


})
export class OppListComponent implements OnInit {



  readonly MASKS = MASKS;

  customer: Customer;

  opportunities: Opportunity[] = [];




  displayedColumns: string[] = [
    'cnpj',
    'companyName',
    'email',
    'institution',
    'stageOpportunity',
    'duração fase atual',
    'whatsApp',
    'limitOperation',
    'uid',
    'actions'
  ];

  dataSource: MatTableDataSource<Opportunity>;


  isLoadingResults = false;

   // Filters
   labels = {
    cnpj: 'CNPJ',
    companyName: 'Razão Social',
  };
  selectedFilter = 'companyName';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [25, 50, 100];
  currentPage = 0;

   // Sorting
   sortField = 'lastInteraction';
   sortDirection = 'desc';

   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private ums: UserManagementService, private dialog: MatDialog, private opportunityManagementService: OpportunityManagementService)  { }

   ngOnInit(): void {
  //
  }

  applyFilter(resetPage = false): void {
    if (resetPage) {
      this.currentPage = 0;
    }
    this.currentFilterValue = this.filterValue;

    this.isLoadingResults = true;

    this.ums
    .searchCustomers({
      filterField: this.selectedFilter,
      filterValue: this.currentFilterValue,
      orderField: this.sortField,
      orderDirection: this.sortDirection,
      pageSize: this.pageSize,
      page: this.currentPage,

    })
    .then(async (response) => {
      this.customer = response [0];
      console.log('oq tras', this.customer);
      this.length = response.length;


   this.opportunities = await  this.opportunityManagementService.getOpportunities(this.customer.uid);
   console.log('traz', this.opportunities);

      this.dataSource = new MatTableDataSource(this.opportunities);

      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
    })
    .catch((err) => {
      console.error('Error getting customer.', err);
      this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao buscar cliente',
            alertDescription: `Ocorreu um erro ao buscar os cliente. Por favor, tente novamente mais tarde.`,
            isOnlyConfirm: true,
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.isLoadingResults = false;
        });
    });
}


setPageSizeOptions(setPageSizeOptionsInput: string): void {
  if (setPageSizeOptionsInput) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
  }
}

paginatorEvent(event: PageEvent): void {
  if (event.pageSize !== this.pageSize) {
    this.pageSize = event.pageSize;
    this.currentPage = 0;
  } else {
    this.currentPage = event.pageIndex;
  }
  this.applyFilter();
}

    sortData(event: Sort): void {
      this.sortDirection = event.direction || 'asc';
      this.sortField = event.active;
      this.currentPage = 0;
      this.applyFilter(true);
    }

    clearFilter(): void {
      this.currentFilterValue = '';
    this.filterValue = '';
    this.currentPage = 0;

      this.isLoadingResults = false;

    }

    clearFilterValue(): void {
      this.filterValue = '';
     // this.applyFilter(true);


    }
}
