import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Serasa, SerasaNegativeData } from '../../../../functions/src/models/Serasa';
import { User } from '../../../../functions/src/models/User';
import { UserManagementService } from '../../admin/services/user-management.service';
import { AuthService } from '../../core/auth/auth.service';
import { SerasaService } from '../../core/services/serasa.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-serasa-card',
  templateUrl: './serasa-card.component.html',
  styleUrls: ['./serasa-card.component.scss']
})
export class SerasaCardComponent implements OnInit, OnDestroy {
  serasaNegativeData: SerasaNegativeData;
  serasaTotalData: Serasa;
  hasSerasaData = false;
  loggedUser: User;
  isProcessingSerasa = false;

  @Input() isAdmin: boolean;
  @Input() expanded: boolean;
  @Input() small: boolean;
  @Input() uid: string;

  loggedUserSubscription: Subscription;

  constructor(private serasaService: SerasaService, private dialog: MatDialog, private ums: UserManagementService, private authService: AuthService ) {}

  ngOnInit(): void {
    if (this.uid) {
      this.serasaService.getSerasaData(this.uid).subscribe((data: Serasa) => {
        this.hasSerasaData = true;
        this.serasaNegativeData = data?.negativeData;
        this.serasaTotalData = data;
      });
    }

    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = loggedUser;
    });
  }

  ngOnDestroy(): void {
    if(this.loggedUserSubscription){
      this.loggedUserSubscription.unsubscribe();
    }
  }

  getSerasaNegativeData(): void{
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmação',
          alertDescription: `Deseja mesmo fazer uma consulta no serasa?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isProcessingSerasa = true;
          this.ums
            .getNegativeDataFromSerasa(this.uid, this.loggedUser.uid)
            .then(() => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Consulta no Serasa solicitada',
                  alertDescription: `Os dados foram solicitados com sucesso!`,
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error Serasa', err);
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao integrar',
                  alertDescription: `Ocorreu um erro ao executar a consulta. Por favor, tente novamente mais tarde.`,
                  isOnlyConfirm: true,
                },
              });
            }).finally(() => {
              this.isProcessingSerasa = false;
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  formatMoney(value): string {
    if (isNaN(parseFloat(value))) return "-";

    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

}
