<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Consulta de Operadores de Crédito</span>
        <a
          mat-flat-button
          color="primary"
          routerLink="/admin/operadores/cadastrar"
          class="d-block"
          style="float: right"
        >
          + Cadastrar Operador
        </a>
      </mat-card-title>

      <div class="row px-3 mt-4">
        <div class="col-12 col-sm-5 col-md-4">
          <mat-form-field style="width: 100%" color="primary">
            <mat-label>Filtro</mat-label>
            <mat-select [(ngModel)]="selectedFilter" [disabled]="isLoadingResults">
              <mat-option value="institution"> Instituição </mat-option>
              <mat-option value="name"> Nome </mat-option>
              <mat-option value="email"> E-mail </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-7 col-md-8 d-flex">
          <mat-form-field style="flex: 1" color="primary">
            <mat-label>{{ labels[selectedFilter] }}</mat-label>
            <input
              matInput
              [(ngModel)]="filterValue"
              (keydown.enter)="applyFilter(true)"
              [placeholder]="labels[selectedFilter]"
              [disabled]="isLoadingResults"
            />
          </mat-form-field>
          <button mat-icon-button color="accent" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </button>
          <button mat-icon-button color="accent" (click)="applyFilter(true)">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <div class="w-100 p-5" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
      </div>

      <div class="table-container" *ngIf="!isLoadingResults && dataLength === 0">
        <p class="text-center">Nenhum operador encontrado.</p>
      </div>

      <div class="table-container mat-elevation-z3" *ngIf="!isLoadingResults && dataLength > 0">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortData($event)"
          [matSortActive]="sortField"
          [matSortDirection]="sortDirection"
          matSortDisableClear
        >
          <!-- Institution Column -->
          <ng-container matColumnDef="institution">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Instituição</th>
            <td mat-cell *matCellDef="let row">
              {{ row.institution.label || '-' }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Nome</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <!-- E-mail Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
            <td mat-cell *matCellDef="let row">{{ row.email }}</td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Criado em</th>
            <td mat-cell *matCellDef="let row">{{ row.createdAt | date: 'dd/MM/yyyy' }}</td>
          </ng-container>

          <!-- Last Interaction Column -->
          <ng-container matColumnDef="lastInteraction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Últ. Inter.</th>
            <td mat-cell *matCellDef="let row">{{ row.lastInteraction | date: 'dd/MM/yyyy' }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef disableClear>Ações</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <div class="w-100 text-center">
                <a mat-flat-button color="primary" class="m-1 bt-w" [routerLink]="'/admin/operadores/info/' + row.uid">
                  <mat-icon>search</mat-icon>
                </a>
                <a mat-flat-button *ngIf="loggedAdmin?.accessLevel === 'master' || loggedAdmin?.accessLevel === 'admin'" color="accent" class="m-1 bt-w" [routerLink]="'/admin/operadores/editar/' + row.uid">
                  <mat-icon>edit</mat-icon>
                </a>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [length]="dataLength"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="paginatorEvent($event)"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
