import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';

@Component({
  selector: 'app-customer-change-password',
  templateUrl: './customer-change-password.component.html',
  styleUrls: ['./customer-change-password.component.scss'],
})
export class CustomerChangePasswordComponent implements OnInit, OnDestroy {
  // Control
  isProcessing = false;
  resetEmailWasSent = false;
  authSubscription: Subscription;
  isLoading = true;

  passwordFormGroup: FormGroup;

  user: User;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration1.svg');
  }

  ngOnInit(): void {
    this.passwordFormGroup = this.formBuilder.group(
      {
        oldPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      },
      { validators: this.passwordMatchValidator }
    );
    this.authSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        this.isLoading = false;
        this.user = user;
      }
    });
  }

  passwordMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('newPassword').value === formGroup.get('confirmPassword').value ? null : { mismatch: true };
    formGroup.get('confirmPassword').setErrors(error);
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }

  changePassword(): void {
    this.isProcessing = true;

    const newPassword = this.passwordFormGroup.value.newPassword;
    const oldPassword = this.passwordFormGroup.value.oldPassword;

    this.authService
      .loginWithEmailAndPassword(this.user.email, oldPassword)
      .then(() => {
        this.authService
          .changePassword(this.user.uid, newPassword)
          .then(() => {
            this.user.changedPassword = true;
            return this.authService.updateUser(this.user);
          })
          .then(() => {
            this.isProcessing = false;
            const msgSub = this.showMessage('Senha Atualizada', 'Sua senha foi atualizada com sucesso.')
              .afterClosed()
              .subscribe(() => {
                this.router.navigate(['/entrar']);
                msgSub.unsubscribe();
              });
          })
          .catch((err) => {
            console.error(err);
            this.isProcessing = false;
            this.showMessage(
              'Erro ao Atualizar Senha',
              'Não foi possível atualizar sua senha, tente novamente mais tarde.'
            );
          });
      })
      .catch(() => {
        this.showMessage('Senha Incorreta', 'Verifique sua senha e tente novamente');
        this.isProcessing = false;
      });
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }
}
