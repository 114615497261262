import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../../functions/src/models/User';
import { AutomaticMessages, FaseOportunidade } from '../../../../functions/src/models/model-interface';
import { InstituicaoFinanceira } from '../../admin/instituicao-financeira/instituicao-financeira';
import { InstituicaoFinanceiraService } from '../../admin/instituicao-financeira/instituicao-financeira.service';
import { OpportunityManagementService } from '../../admin/services/opportunity-management.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-template-messages-institution',
  templateUrl: './template-messages-institution.component.html',
  styleUrls: ['./template-messages-institution.component.scss'],
})
export class TemplateMessagesInstitutionComponent implements OnInit {
  @Input() isEdit = false;
  @Input() institution: InstituicaoFinanceira;
  @Input() loggedUser: User;
  createMessageFormGroup: FormGroup;
  automaticMessage: Array<AutomaticMessages> = [];
  autoMsgList;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  intervalInDaysList: Array<number> = [];
  readonly opportunityStepsOptions: FaseOportunidade[] = this.opportunityService.getAllOppStages();
  constructor(
    public _data: InstituicaoFinanceiraService,
    private formBuilder: FormBuilder,
    private opportunityService: OpportunityManagementService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.createMessageFormGroup = this.formBuilder.group({
      // automaticMessageCtl: new FormControl(),
      automaticMessageContentCtrl: new FormControl('', Validators.required),
      oppPhaseCtrl: new FormControl(),
      messageNameCtrl: new FormControl(),
    });

    this.validatePermission();

    // if (this.isEdit) {
    this.getMessagesInst(this.institution.nomeNoSistema);
    // }
  }

  private validatePermission(): void {
    if (!['master', 'admin'].includes(this.loggedUser?.accessLevel)) {
      this.createMessageFormGroup.disable();
    }
  }

  compareObjectsValue(o1: any, o2: any): boolean {
    return o1 === o2;
  }

  addIntervalDay(event: MatChipInputEvent, index?: number): void {
    const input = event.input;
    const value = event.value;
    // Add our day
    if ((event.value || '').trim()) {
      if (index !== undefined) {
        this.automaticMessage[index].intervalInDays.push(parseInt(value, 10));
      } else {
        this.intervalInDaysList.push(parseInt(value, 10));
      }
    }
    // Clear the input value
    if (input) {
      input.value = null;
    }
  }

  removeIntervalDay(day, i?): void {
    const intervalInList = this.intervalInDaysList.indexOf(day);
    const index = intervalInList === -1 ? this.automaticMessage[i].intervalInDays.indexOf(day) : intervalInList;
    if (index >= 0) {
      if (this.intervalInDaysList.length > 0) {
        this.intervalInDaysList.splice(index, 1);
      } else {
        this.automaticMessage[i].intervalInDays.splice(index, 1);
      }
    }
  }

  getMessagesInst(instFinancId) {
    this._data.getAutomaticMessagesInstituition(instFinancId).subscribe((res) => {
      this.autoMsgList = res;

      if (res.length > 0) {
        this.automaticMessage = res;
      }
    });
  }

  onAddNewMessage(): void {
    const active = this.institution.ativo.id === 'sim';
    this.automaticMessage.push({
      active,
      remindPercentage: this.createMessageFormGroup.get('oppPhaseCtrl').value || null,
      intervalInDays: this.intervalInDaysList,
      templateMessage: this.createMessageFormGroup.get('automaticMessageContentCtrl').value || null,
      instSystemName: this.institution.nomeNoSistema,
      messageName: this.createMessageFormGroup.get('messageNameCtrl').value || null,
    });

    // resetar o valor dos campos e setar os erros para null
    this.createMessageFormGroup.get('oppPhaseCtrl')?.reset();
    this.createMessageFormGroup.get('oppPhaseCtrl')?.setErrors(null);
    this.createMessageFormGroup.get('oppPhaseCtrl')?.markAsUntouched();

    this.createMessageFormGroup.get('automaticMessageContentCtrl')?.reset('');
    this.createMessageFormGroup.get('automaticMessageContentCtrl')?.setErrors(null);
    this.createMessageFormGroup.get('automaticMessageContentCtrl')?.markAsUntouched();

    this.createMessageFormGroup.get('messageNameCtrl')?.reset('');
    this.createMessageFormGroup.get('messageNameCtrl')?.setErrors(null);
    this.createMessageFormGroup.get('messageNameCtrl')?.markAsUntouched();

    this.intervalInDaysList = [];

    this.saveAutomaticMsgToInst(this.automaticMessage[this.automaticMessage.length - 1]);
  }

  isMessageInvalid(): boolean {
    if (
      !this.createMessageFormGroup.get('oppPhaseCtrl').value ||
      this.createMessageFormGroup.get('oppPhaseCtrl').invalid ||
      !this.createMessageFormGroup.get('automaticMessageContentCtrl').value ||
      this.createMessageFormGroup.get('automaticMessageContentCtrl').invalid ||
      !this.createMessageFormGroup.get('messageNameCtrl').value ||
      this.createMessageFormGroup.get('messageNameCtrl').invalid ||
      this.intervalInDaysList.length === 0
    ) {
      return true;
    }
  }

  isEditMessageInvalid(message): boolean {
    if (message.intervalInDays.length === 0 || message.templateMessage === '') {
      return true;
    }
  }

  saveAutomaticMsgToInst(message): void {
    this._data
      .addInstituicaoFinanceiraAutoMsg(this.institution, message)
      .then(() => {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Sucesso ao Salvar',
            alertDescription: `A nova mensagem automática foi salva com sucesso`,
            isOnlyConfirm: true,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao Salvar',
            alertDescription: `Ocorreu um erro ao salvar a nova mensagem automática`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  editAutomaticMsgToInst(message): void {
    this._data
      .updateInstituicaoFinanceiraAutoMsg(this.institution, message)
      .then(() => {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Sucesso ao Editar',
            alertDescription: `A mensagem automática foi editada com sucesso`,
            isOnlyConfirm: true,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao Editar',
            alertDescription: `Ocorreu um erro ao editar a mensagem automática`,
            isOnlyConfirm: true,
          },
        });
      });
  }

  updateOrAddAutomaticMsgToInst(message): void {
    if (message.id) {
      this.editAutomaticMsgToInst(message);
    } else {
      this.saveAutomaticMsgToInst(message);
    }
  }

  removeAutomaticMessage(message): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover Mensagem',
          alertDescription: `Deseja realmente remover essa mensagem?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.automaticMessage.splice(this.automaticMessage.indexOf(message), 1);
          this._data.deleteInstituicaoFinanceiraAutoMsg(this.institution, message);
        }
      });
  }
  calculeDateAsDay(intervalo: number): string {
    const start = new Date(Date.now() - 1000 * 60 * 60 * 24 * (intervalo + 1));
    const end = intervalo !== 0 ? new Date(Date.now() - 1000 * 60 * 60 * 24 * intervalo) : new Date();
    // console.log(start.toISOString());
    // console.log(end.toISOString());

    const startDate = this.formatDate(start);
    const endDate = this.formatDate(end);
    return `de ${ startDate } ate ${ endDate }`;
  }
  formatDate(date: Date): string {
    return `${ this.formateNumbers(date.getDate()) }/${ this.formateNumbers(date.getUTCMonth() + 1) }/${ this.formateNumbers(
      date.getFullYear()
    ) } - 18:00`;
  }
  formateNumbers(n: number): string {
    return n > 9 ? '' + n : '0' + n;
  }
}
