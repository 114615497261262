<div class="container" style="flex: 1;">
    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title >
                    <span  *ngIf="!isEdit" style="flex: 1;">Novo Onboarding para parceiros</span>
                    <a
                    *ngIf="isEdit"
                    
                    color="accent"
                    type="button"
                    [routerLink]="'/admin/parceiro/visualizar/'"
                    matTooltip="Voltar à visualização"
                    class="mr-3"
                  >
                    <mat-icon style="margin-top: 9px;">chevron_left</mat-icon>
                  </a>
                    <span class="titulo" *ngIf="isEdit" style="flex: 1;">{{partnership?.partner}} onboarding</span>
                    <mat-icon *ngIf="!isEdit">add</mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="createFormGroup">
                <div class="d-flex">
                    <div class="edit-form flex-grow-1" style="padding-right: 10px; color: #909090;">
                        <input matInput placeholder="Nome*" class="input" formControlName="nameCtrl" required [readonly]="partnership" />
                    </div>

                    <div class="edit-form">
                        <mat-label style="margin-top: -20px; color: #909090">Tipo de parceria*</mat-label>
                        <mat-form-field appearance="outline">
                            <mat-select placeholder="Selecione"  formControlName="isTermCtrl" required>
                                <mat-option></mat-option>
                                <mat-option value="true">Com mandato</mat-option>
                                <mat-option  value="false">Sem mandato</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            <br/>
                <div class="d-flex">
                    <div *ngIf="!isEdit" class="edit-form flex-grow-1" style="padding-right: 10px; color: #909090;">
                        <input matInput placeholder="Tenant_id:" class="input" formControlName="tenant_idCtrl" />
                    </div>

                    <div *ngIf="!isEdit " class="edit-form">
                        <mat-label style="margin-top: -20px; color: #909090;" >Possui Senha*</mat-label>
                        <mat-form-field appearance="outline">
                            
                            <mat-select placeholder="Selecione" formControlName="isPasswordCtrl" required>
                                <mat-option></mat-option>
                                <mat-option  value="true">Sim</mat-option>
                                <mat-option  value="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!isEdit" class="edit-form ml-3">
                        <mat-label style="margin-top: -20px; color: #909090;">Confirmação de email*</mat-label>
                        <mat-form-field appearance="outline">
                            <mat-select placeholder="Selecione" formControlName="isConfirmationEmailCtrl" required>
                                <mat-option></mat-option>
                                <mat-option  value="true">Sim</mat-option>
                                <mat-option  value="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="edit-form flex-grow-1">
                        <input matInput placeholder="Título" class="input" formControlName="tituloCtrl" />
                    </div>
                </div>

                <div class="d-flex">
                    <div class="edit-form flex-grow-1">
                        <input matInput placeholder="Subtítulo" class="input" formControlName="subtituloCtrl" />
                    </div>
                </div>
            
                
                <div class="row">
                    <div class="col-md-6">
                   
                        <label class="label-title">Imagem Logotipo</label>
                        <app-partner-logo-file-upload    [maximumDocuments]="1" [hasEmissionDate]="false"></app-partner-logo-file-upload>
                        
                        <div *ngIf="this.createFormGroup.controls.logoCtrl.value">
                            <img [src]="this.createFormGroup.controls.logoCtrl.value"  class="appDropZone">
                          </div>
                        <div *ngIf="this.createFormGroup.controls.logoCtrl.value" class="d-flex ">
                            <!-- <span class="span">Insira a imagem, seguindo o Manual. </span> -->
                            <span style="margin-right: 56px;" class="span">
                                <mat-icon (click)="deleteLogo()">delete_outline</mat-icon>
                            </span>
                        </div>
                        <div class="d-flex" style="margin-top: 5px;">
                            <div  class="edit-form-custom">
                                <label>Cor de fundo</label>
                           <input matInput class="input" type="text"  name="corDeFundo" formControlName="corDeFundoCtrl" 
                               />
                                <div class="cor-preview" [style.background]="createFormGroup.value.corDeFundoCtrl"></div>
                                
                            </div>
                        
                            <div class="edit-form-custom">
                                <label>Cor da fonte</label>
                                <input matInput class="input" type="text"   formControlName="corDaFonteCtrl" />
                                <div class="cor-preview" [style.background]="createFormGroup.value.corDaFonteCtrl"></div>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        
                        <label class="label-title">Imagem Banner</label>
                        <app-partner-banner-file-upload  [maximumDocuments]="1" [hasEmissionDate]="false"></app-partner-banner-file-upload>
                        
                        <div *ngIf="partnership?.banner">
                            <img [src]="this.createFormGroup.controls.bannerCtrl.value" alt="Banner" class="appDropZone-custom">
                          </div>
                        <div *ngIf="partnership?.banner" class="d-flex">
                            <span style="margin-right: 56px;" class="span-custom">
                                
                                <mat-icon (click)="deleteBanner()">delete_outline</mat-icon>
                            </span>
                        </div>
                        
                    </div>
                </div>
          
            
                <div >
                    <div class="text-end">

                        <a
                        *ngIf="isEdit"
                        
                        [routerLink]="'/admin/parceiro/visualizar/'"
                        matTooltip="Voltar à visualização" class="mr-3"
                      >
                      Cancelar
                      </a>
                        <a *ngIf="!isEdit" href="#" class="mr-3" (click)="onCancel()">Cancelar</a>
                        <button type="button" class=" button w-100 mt-1" color="accent" [disabled]="!isFormValid()" (click)="onSavePartnershipData()">
                            Salvar
                            <mat-spinner class="mx-auto my-2" color="accent" *ngIf="isLoading" diameter="20"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
</div>
