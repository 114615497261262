import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OpportunityStage } from '../../../../functions/src/models/opportunity/OpportunityStage';
import { OpportunityManagementService } from '../../admin/services/opportunity-management.service';

@Component({
  selector: 'app-list-opportunities-by-cnpj',
  templateUrl: './list-opportunities-by-cnpj.component.html',
  styleUrls: ['./list-opportunities-by-cnpj.component.css']
})
export class ListOpportunitiesByCnpjComponent {
  @Input() formGroup?: FormGroup;
  opportunityStepsOptions: OpportunityStage[];
  cnpjList: string;
  constructor(
    private oppService: OpportunityManagementService
  ) {
    this.opportunityStepsOptions = this.oppService.getAllOppStages();
  }

}
