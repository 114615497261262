import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { Banker, BankerEvent, BankerLog } from 'functions/src/models/Banker';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BankerService implements OnDestroy {
  customerSubscription: Subscription;
  bankerSubscription: Subscription;

  banker = new BehaviorSubject<Banker>(null);

  constructor(private authService: AuthService, private angularFirestore: AngularFirestore, private router: Router) {
    this.customerSubscription = authService.user.subscribe((customer) => {
      if (customer && !customer.isAnonymous && customer.isBanker) {
        this.bankerSubscription = this.angularFirestore
          .doc(`bankers/${customer.uid}`)
          .valueChanges()
          .subscribe((banker: Banker) => {
            this.banker.next({
              ...banker,
              emailVerified: customer.emailVerified,
              changedPassword: customer.changedPassword,
            });
          });

        this.updateBankerOnFirestore(customer.uid, {
          emailVerified: customer.emailVerified,
          displayName: customer.displayName,
        });
      } else {
        this.banker.next(null);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  async getBankerByEmail(email: string): Promise<Banker | null> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection<Banker>(`bankers`, (ref) => ref.where('email', '==', email.trim().toLowerCase()))
        .get()
        .toPromise()
        .then((snapshot) => {
          if (!snapshot.empty) {
            resolve(snapshot.docs[0].data());
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  async getBankerByUID(uid: string): Promise<Banker | null> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc<Banker>(`bankers/${uid}`)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            return resolve(doc.data());
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  getBankerByEmailFromFirestore(email: string): Promise<Banker | null> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection(`bankers`, (ref) => ref.where('email', '==', email.trim().toLowerCase()))
        .get()
        .toPromise()
        .then((snapshot) => {
          if (!snapshot.empty) {
            resolve(snapshot.docs[0].data());
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  getCreditInstitutionName(uniqueName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection(`instituicoes-financeiras`, (ref) => ref.where('nomeNoSistema', '==', uniqueName))
        .get()
        .toPromise()
        .then((snapshot) => {
          if (!snapshot.empty) {
            resolve(snapshot.docs[0].data().nome);
          }
          return resolve(null);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  async redirectAccordingStatus(current = '/entrar', next = '/bankers/opportunities'): Promise<void> {
    const statusSubs = this.banker.pipe(skip(1)).subscribe((banker) => {
      let redirection = next;

      if (banker) {
        if (banker.name && (!banker.isActive /*|| !banker.changedPassword*/)) {
          redirection = '/bankers/profile';
        }

        if (redirection !== current) {
          console.log(`Redirecting from ${current} to ${redirection}`);
          this.router.navigate([redirection]);
        }

        if (statusSubs) {
          statusSubs.unsubscribe();
        }
      }
    });
  }

  registerBankerLog(banker: string, event: BankerEvent, customer?: string): void {
    const log: BankerLog = {
      date: firestore.Timestamp.now(),
      event: event,
    };

    if (customer) {
      log.user = customer;
    }

    const bankerRef: AngularFirestoreDocument<BankerLog> = this.angularFirestore
      .doc(`bankers/${banker}`)
      .collection('logs')
      .doc(log.date.toDate().toISOString());
    bankerRef
      .set(log, { merge: true })
      .then(() => {
        console.log('Added BankerLog');
      })
      .catch((e) => {
        console.error('Error on adding BankerLog', e);
      });
  }

  updateBankerOnFirestore(uid: string, data: Banker = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const bankerRef: AngularFirestoreDocument<Banker> = this.angularFirestore.doc(`bankers/${uid}`);

        bankerRef
          .set(
            {
              ...data,
              lastInteraction: firestore.Timestamp.now(),
            },
            { merge: true }
          )
          .then(() => {
            console.log('Banker data updated on database');
            resolve();
          })
          .catch((err) => {
            console.error('Error getting banker data', err);
            reject(err);
          });
      } else {
        reject(new Error('Missing banker uid'));
      }
    });
  }
}
