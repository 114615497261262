import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  ClicksignCreateSignerResponse,
  ClicksignDocumentByTemplateAttributes,
  ClicksignDocumentByTemplateResponse,
  ClicksignDocumentsBatchAttributes,
  ClicksignSignerAttributes,
  ClicksignSignerToDocumentAttributes,
  ClicksignSignerToDocumentResponse,
} from '../../../../functions/src/models/Clicksign';
import { Customer } from '../../../../functions/src/models/Customer';
import { environment } from '../../../environments/environment';
import { LogService } from '../../components/logger/log.service';
import { AuthService } from '../../core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClicksignService {
  constructor(
    private angularFirestore: AngularFirestore,
    private logger: LogService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.logger.controllerName = this.constructor.name;
  }

  requestSignatureKey(data: { uid: string; documentsId: string[] }): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const response = (await this.http
          .post(`${environment.functionsUrl}/clicksign/signature-key`, data, { headers: this.authService.getHeader() })
          .toPromise()) as any;
        resolve(response?.requestSignatureKey);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }
}
