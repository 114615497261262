import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Banker } from '../../../../../functions/src/models/Banker';
import { LinkedinService } from './../../../core/services/linkedin.service';

@Component({
  selector: 'app-login-linkedin',
  templateUrl: './login-linkedin.component.html',
  styleUrls: ['./login-linkedin.component.css']
})
export class LoginLinkedinComponent implements OnInit {
  banker: Banker;
  creatingBanker = true; // Variável para controlar a exibição do spinner
  password: string; // Variável para armazenar a senha
  institutionName: string;
  routeParam: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private linkedinService: LinkedinService
  ) { }

  ngOnInit(): void {

    // Assim que o componente é inicializado, verifica se há um code na URL
    const authorizationCode = this.route.snapshot.queryParams['code'];
     // Capturar o parâmetro da rota após '/login-linkedin/'
      this.routeParam = this.route.snapshot.paramMap.get('partner');

      console.log("Authorization Code:", authorizationCode);
      console.log("Route Parameter:", this.routeParam);
      



    if (authorizationCode) {
      this.createAccessTokenLinkedin(authorizationCode)
        .then((accessToken) => {

          console.log(accessToken)
          // Após gerar o Access Token, você pode chamar os outros métodos aqui, passando o accessToken
          this.getDataAndCreateBanker(accessToken);
        })
        .catch((err) => {
          console.error('Erro ao gerar Access Token', err);
        });
    }

  }

  createAccessTokenLinkedin(authorizationCode: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {

      this.linkedinService.createAccessTokenLinkedin(authorizationCode)
        .then((accessToken) => {
          resolve(accessToken); 
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getDataAndCreateBanker(accessToken: string): Promise<void> {
    try {
        const emailAddress = await this.linkedinService.getDataLinkedin({ accessToken });
        const profile = await this.linkedinService.getProfileLinkedin({ accessToken });

        if (emailAddress && profile) {
            this.banker = {
                email: emailAddress,
                name: profile.data.localizedFirstName + " " + profile.data.localizedLastName,
                institution: {name: this.routeParam}
            };


            await this.createBanker();
        } else {
            console.error('Could not get both email address and profile from LinkedIn.');
        }
    } catch (error) {
        console.error('Error in getDataAndCreateBanker:', error);
    }
}
  createBanker(): void {
    this.password = this.banker.name.replace(/\s/g, "") + this.banker.email;


    console.log(this.banker)
    this.linkedinService.createBanker(this.banker, this.password,  'Criado com linkedin')
      .then((result) => {
        if (result === 'success') {
          console.log('Banker criado com sucesso');

        } else if (result === 'email-in-use') {
          console.error('Email já em uso');
        } else if (result === 'error-sending-email') {
          console.error('Erro ao enviar o email');
        }
      })
      .catch((err) => {
        console.error('Erro ao criar o Banker', err);
      })
      .finally(() => {
        this.creatingBanker = false; 
      });
  }
}
