import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

import { environment } from '../../../environments/environment';

import { LogPublisher, LogConsole, LogLocalStorage, LogUserFirestore } from './log-publishers';

// ****************************************************
// Logging Publishers Service Class
// ****************************************************
@Injectable()
export class LogPublishersService {
  constructor(private http: HttpClient, private angularFirestore: AngularFirestore) {
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  // *************************
  // Public methods
  // *************************
  // Build publishers array
  buildPublishers(): void {
    if (!environment.production) {
      // Create instance of LogConsole Class
      this.publishers.push(new LogConsole());
    }

    // this.publishers.push(new LogWebApi(this.http));
    this.publishers.push(new LogUserFirestore(this.angularFirestore));
  }
}
