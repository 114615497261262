  <div class="audio-player">
    <button (click)="toggleAudio()">
      <i class="fa" [ngClass]="currentTime !== duration ? 'fa-pause' : 'fa-play'"></i>
    </button>
    <div class="audio-controls">
      <div class="audio-progress" (click)="seekTimeAudio($event)">
        <div class="progress-bar" [style.width]="(currentTime / duration) * 100 + '%'"></div>
      </div>
     
      <div class="time-display">
        {{ formatTime(currentTime !== duration ? currentTime : 0) }} / {{ formatTime(duration === 0 ? durationInitial : duration) }}
      </div>
      <span>{{ formatDate(message.sendDate).replace(',', ' -') }} 
        &nbsp;
        <mat-icon *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'SENT'" style="color: #88B3AB; font-size: 16px;">done</mat-icon>
        <mat-icon *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'RECEIVED'" style="color: #88B3AB; font-size: 16px;">done_all</mat-icon>
        <mat-icon *ngIf="message.messageType === 'WHATSAPP' && message.sender.type === 'admin' && message.messageStatus?.status === 'READ'" style="color: #3186F5; font-size: 16px;">done_all</mat-icon>
      </span>
    </div>
    <audio #audioElement controls (timeupdate)="updateTime($event)">
      <source [src]="message.audio" type="audio/mpeg">
      Seu navegador não suporta o elemento de áudio.
    </audio>
  </div>
  