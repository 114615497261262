import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firestore } from 'firebase';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { CustomersAdminService } from '../../services/customers-admin.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-customer-info-card',
  templateUrl: './customer-info-card.component.html',
  styleUrls: ['./customer-info-card.component.scss']
})

export class CustomerInfoCardComponent implements OnInit, OnDestroy {
  customer!: Customer;
  customerUID: string;
  serasaData: any;

  @Input() serasaDataInput: any;

  @Output() serasaDataChange: EventEmitter<any> = new EventEmitter<any>();

  customerDataSubscription: Subscription;
  serasaDataSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private ums: UserManagementService, private customersAdminService: CustomersAdminService) {
    this.customerUID = this.activatedRoute.snapshot.params.uid;
   }

  ngOnInit(): void {
    this.getCustomer();   
  }

  ngOnDestroy(): void {
    if(this.customerDataSubscription){
      this.customerDataSubscription.unsubscribe()
    }
    if(this.serasaDataSubscription){
      this.serasaDataSubscription.unsubscribe()
    }
  }

  getCustomer(): void{
    this.customerDataSubscription = this.ums.getCustomer(this.customerUID).subscribe((customer) => {
      this.customer = customer;
      if (this.customer?.companyInformation?.foundedDate) {
        this.customer.companyInformation.foundedDate = customer.companyInformation.foundedDate.seconds
          ? new firestore.Timestamp(
            customer.companyInformation.foundedDate.seconds,
            customer.companyInformation.foundedDate.nanoseconds || 999000000
          )
          : Object.keys(customer?.companyInformation?.foundedDate).length > 0
            ? customer.companyInformation.foundedDate
            : null;
      }
      this.getSerasaData();
    })
  }

  getSerasaData(): void {
    if (!this.customer || !this.customer.cnpj) {
      console.log('CNPJ do cliente não está disponível.');
      return;
    }

    this.ums.getCustomerDataFromSerasa(this.customer.cnpj).subscribe((docs) => {
      if (docs.length > 0) {
        this.serasaData = docs[0].negativeData;
        this.updateSerasaData(this.serasaData);
      } else {
        console.log('Nenhum documento encontrado.');
      }
    });
  }

  formatMoney(value): string {
    if (isNaN(parseFloat(value))) return "";

    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  updateSerasaData(newData: any) {
    this.serasaData = newData;
    this.serasaDataChange.emit(this.serasaData);
  }

  toggleHasOpenFinance(): void {
    this.customer.hasOpenFinance = !this.customer.hasOpenFinance;
    this.ums.updateCustomer(this.customer);
    if (this.customer.hasOpenFinance) {
      this.customersAdminService.notifyOpenFinanceData(this.customer);
    }
  }
  
  displayGuaranteeNames(guarantees, value='name'){
    if (guarantees && Array.isArray(guarantees)) {
      return guarantees.map((g) => g[value]).join(", ");
    } else {
      return '';
    }
  }
}
