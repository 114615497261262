import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from 'functions/src/models/Customer';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomersAdminService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  createCustomerAccount(customerData: Customer): Promise<any> {
    return this.http
      .post(
        `${environment.functionsUrl}/customer/create`,
        { user: customerData },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();
  }

  notifyCustomerViaEmail(customerData: Customer): Promise<any> {
    return this.http
      .post(
        `${environment.functionsUrl}/email/notify-customer-created`,
        {
          customerEmail: customerData.email,
          customerPassword: customerData.password,
          customerName: customerData.name,
          createdBy: 'ADMIN',
        },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();
  }

  async notifyNewDocumentToSign(data: { documentName: string; customer: Customer }): Promise<void> {
    const { documentName, customer } = data;
    const { name, email } = customer;
    try {
      await this.http
        .post(
          `${environment.functionsUrl}/email/notify-new-document-to-sign`,
          {
            documentName,
            customer: { name, email },
          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise();
    } catch (err) {
      console.error('Error while notifying about new doc to sign: ', err);
      throw err;
    }
  }

  async notifyOpenFinanceData(customer: Customer): Promise<any> {
    try{
      await this.http
        .post(
          `${environment.functionsUrl}/email/pluggy-notification`,
          {
            customer: customer
          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise();
    } catch(err) {
      console.error('Error while notifying about open finance data request', err);
      throw err;
    }
  }

}
