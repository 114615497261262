import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from '../components/shared.module';
import { AgentsRoutes } from './agents-routing.module';
import { LeadNotesComponent } from './components/lead-notes/lead-notes.component';
import { AgentCustomerRegisterComponent } from './containers/agent-customer-register/agent-customer-register.component';
import { AgentDashboardComponent } from './containers/dashboard/dashboard.component';
import { AgentHomeComponent } from './containers/home/home.component';
import { AgentInviteComponent } from './containers/invite/invite.component';
import { AgentLeadComponent } from './containers/lead/lead.component';
import { AgentLeadsComponent } from './containers/leads/leads.component';
import { PendingDocumentsComponent } from './containers/pending-documents/pending-documents.component';
import { AgentProfileComponent } from './containers/profile/profile.component';
import { AgentSignUpComponent } from './containers/signup/signup.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartistModule,
    ChartsModule,
    RouterModule.forChild(AgentsRoutes),
  ],
  declarations: [
    AgentDashboardComponent,
    AgentLeadsComponent,
    AgentLeadComponent,
    AgentSignUpComponent,
    AgentProfileComponent,
    AgentHomeComponent,
    AgentInviteComponent,
    LeadNotesComponent,
    PendingDocumentsComponent,
    AgentCustomerRegisterComponent,
  ],
  // providers: [{
  //   provide: LocationStrategy,
  //   useClass: HashLocationStrategy
  // }],
})
export class AgentsModule {}
