import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserManagementService } from '../../../admin/services/user-management.service';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  customer: any;
  deleteAccountForm: FormGroup;
  isExtraCardOpen: boolean;
  buttonDisabled: boolean;
  cancellationRequested: boolean = localStorage.getItem('cancellationRequested') === 'true';

  customerSubscription: Subscription;

  cancelationReasons = [
    {id: 'sem-notivo', label: 'Sem motivo'},
    {id: 'taxa', label: 'Não concordo com a taxa'},
    {id: 'simulacao-instantanea', label: 'Achava que a plataforma fazia uma simulação instantânea'},
  ]

  constructor(private dialog: MatDialog, private authService: AuthService, private http: HttpClient, private ums: UserManagementService) { 
    this.isExtraCardOpen = false;
  }

  ngOnInit(): void {
    this.customerSubscription = this.authService.user.subscribe((customer) => {
      this.customer = customer;
    })
    this.deleteAccountForm = new FormGroup({
      reason: new FormControl(''),
      password: new FormControl('')
    })
  }

  onSubmit(formValue: any): void{
    const { reason: motivation, password: password } = formValue;
    const { uid, mainRole } = this.customer;
    const deletedBy = 'user';

    this.dialog.open(AlertDialogComponent, {
      maxWidth: '560px',
      data: {
        alertTitle: 'Cancelar conta',
        alertDescription: 'Tem certeza que deseja enviar o pedido de cancelamento da sua conta?',
      }
    })
    .afterClosed()
    .subscribe((result) => {
      if(result){
        this.buttonDisabled = true;
        this.ums
          .deleteUser(uid, mainRole, motivation, deletedBy)
          .then(() => {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '560px',
              data: {
                alertTitle: 'Sua solicitação está sendo processada',
                alertDescription: 'Seu pedido de cancelamento já foi enviado à nossa equipe e em até 24 horas será concluído.',
                isOnlyConfirm: true
              }
            })
            this.cancellationRequested = true;
            localStorage.setItem('cancellationRequested', this.cancellationRequested.toString())
            this.isExtraCardOpen = true;
          })
          .catch((err) => {
            console.error('Erro ao solicitar cancelamento', err);
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '560px',
              data: {
                alertTitle: 'Erro ao solicitar cancelamento',
                alertDescription: 'Houve um erro ao solicitar o cancelamento da sua conta, tente novamente mais tarde ou entre em contato com a nossa equipe.',
                isOnlyConfirm: true
              }
            })
          })
      }
    })
  }

}
