import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { Banker } from 'functions/src/models/Banker';
import { ActivatedRoute } from '@angular/router';
import { BankersAdminService } from '../../services/bankers-admin.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-banker-view',
  templateUrl: './banker-view.component.html',
  styleUrls: ['./banker-view.component.scss'],
})
export class BankerViewComponent implements OnInit, OnDestroy {
  bankerSubscription: Subscription;
  banker: Banker;
  bankerCode: string;
  isLoading = true;
  hasError = false;

  // Admin control
  loggedAdmin: User;
  loggedAdminSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private admin: BankersAdminService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.bankerCode = this.route.snapshot.paramMap.get('id');

    this.bankerSubscription = this.admin.getBankerData(this.bankerCode).subscribe((banker) => {
      if (banker) {
        this.banker = banker;
        this.isLoading = false;
        this.hasError = false;
      } else {
        this.hasError = true;
      }
      this.isLoading = false;
    });

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
    });
  }

  ngOnDestroy(): void {
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
    if (this.bankerSubscription) {
      this.bankerSubscription.unsubscribe();
    }
  }

  enableBanker(uid: string, email: string): void {
    this.isLoading = true;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Habilitar operador de crédito',
          alertDescription: `Deseja realmente <strong>habilitar</strong> o cadastro do operador de crédito ${email}?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Faz a ação somente se respondeu SIM.
          this.admin
            .enableBanker(uid, this.loggedAdmin.email)
            .then(() => {
              console.log(`Banker ${uid} enabled`);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
  }

  disableBanker(uid: string, email: string): void {
    this.isLoading = true;

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '700px',
        data: {
          alertTitle: 'Suspender operador de crédito',
          alertDescription: `Deseja realmente <strong>suspender</strong> o cadastro do operador de crédito ${email}?`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // Faz a ação somente se respondeu SIM.
          this.admin
            .disableBanker(uid, this.loggedAdmin.email)
            .then(() => {
              console.log(`Banker ${uid} disabled`);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
  }
}
