
export class ChatContactEntity {
  id: string;
  uid: string;
  contactName: string;
  whatsapp: string;
  description: string;
  companyName: string;
  bankerName?: string;
  type: string;
  cnpj: string;
  bankName?: string;
  isContactMain: boolean;
  createdAt?: Date;
  constructor() {
    this.id = "";
    this.uid = "";
    this.contactName = "";
    this.whatsapp = "";
    this.description = "";
    this.companyName = "";
    this.type = "";
    this.cnpj = "";
    this.isContactMain = true;
    this.createdAt = new Date();
  }
}
