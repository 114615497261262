import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { firestore } from 'firebase';
import { Backup, FirebaseCollections } from '../../../../../functions/src/models/Firebase';
import { BackupsService } from '../../services/backups.service';

interface StatusIcon {
  name: string;
  color: string;
}

export interface BackupItem extends Backup {
  collectionsItems?: { name: FirebaseCollections; icon: StatusIcon }[];
  statusIcon?: StatusIcon;
  statusLabel?: string;
  triggerTypeLabel?: string;
}

@Component({
  selector: 'app-backup',
  templateUrl: './backups.component.html',
  styleUrls: ['./backups.component.scss'],
})
export class BackupsComponent implements OnInit {
  createBackupFormGroup: FormGroup;
  collectionsOptions: FirebaseCollections[];
  selectedCollections: FirebaseCollections[];
  backupsList: BackupItem[];

  isProcessing = false;
  isLoadingBackups = false;

  constructor(private formBuilder: FormBuilder, private backupService: BackupsService) {}

  ngOnInit(): void {
    this.createBackupFormGroup = this.formBuilder.group({
      collectionsCtrl: new FormControl('', Validators.required),
    });

    this.collectionsOptions = Object.keys(FirebaseCollections).map(
      (key) => FirebaseCollections[key]
    ) as FirebaseCollections[];
    this.createBackupFormGroup.controls.collectionsCtrl.setValue(this.collectionsOptions);

    this.loadBackups();
  }

  onSave(): void {
    this.isProcessing = true;

    this.selectedCollections = this.createBackupFormGroup.controls.collectionsCtrl.value;

    try {
      const triggeredAt = firestore.Timestamp.now().toDate().toISOString();
      if (Array.isArray(this.selectedCollections)) {
        this.selectedCollections.forEach(async (collection) => {
          this.backupService.backupCollection(collection, triggeredAt, this.selectedCollections);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadBackups();
      this.isProcessing = false;
    }
  }

  async loadBackups(): Promise<void> {
    this.isLoadingBackups = true;
    try {
      this.backupsList = await this.backupService.getBackups();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingBackups = false;
    }
  }
}
