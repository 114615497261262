import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { timestampForDate } from 'functions/src/utils/dates';
import { uniqBy } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GroupOppsEntity } from '../../../../functions/src/models/opportunity/GroupOppsEntity';
import { OpportunityHistoryEntity } from '../../../../functions/src/models/opportunity/OpportunityHistory';

@Injectable({
  providedIn: 'root'
})
export class OpportunityHistoryService {

  constructor(private angularFirestore: AngularFirestore, ) { }

  getOpportunityHistoryByUid(uid: string): Promise<OpportunityHistoryEntity[]> {
    const ninetyDaysAgo = firebase.firestore.Timestamp.fromDate(new Date());
    const ninetyDaysAgoDate = new Date(ninetyDaysAgo.toDate());
    ninetyDaysAgoDate.setDate(ninetyDaysAgoDate.getDate() - 90);

    return new Promise<OpportunityHistoryEntity[]>((resolve, reject) => {

      this.angularFirestore
      .collection('opportunityhistory', ref =>
          ref.where('uid', '==', uid)
            .where('stageOpportunity.defaultprobability', '==', 100)
            .where('closingDate', '<=', ninetyDaysAgo)
        )
        .valueChanges()
        .subscribe(
          (history: OpportunityHistoryEntity[]) => {
            resolve(history);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getOpportunityHistoryByCustomer(uid: string): Observable<GroupOppsEntity[]> {
    const ninetyDaysAgo = firebase.firestore.Timestamp.fromDate(new Date());
    const ninetyDaysAgoDate = new Date(ninetyDaysAgo.toDate());
    ninetyDaysAgoDate.setDate(ninetyDaysAgoDate.getDate() - 90);

    return this.angularFirestore
      .collection('opportunityhistory', ref =>
        ref.where('uid', '==', uid)
        .where('stageOpportunity.defaultprobability', '==', 100)
        .where('closingDate', '<=', ninetyDaysAgo)
      )
      .valueChanges()
      .pipe(
        map(data => this.groupByMonthYear(data as OpportunityHistoryEntity[])),
        catchError((error) => {
          console.error(`Ocorreu erro getOpportunityHistoryByCustomer na consulta ao Firestore para o uid: ${uid}`, error);
          return throwError(error);
        })
      );
  }

  private groupByMonthYear(data: OpportunityHistoryEntity[]): GroupOppsEntity[] {
    const groupedData: GroupOppsEntity[] = [];

    data.forEach(item => {
      const closingDate = timestampForDate(item.closingDate || item.lastStageChangeDate);
      const month = closingDate.toLocaleString('default', { month: 'long' });
      const year = closingDate.getFullYear();
      const id = `${ month }-${ year }`;
      const label = `${ month } - ${ year }`;

      const newGroup: GroupOppsEntity = {
        id, label, opportunities: [], selected: false
      };
      const group = groupedData.find(g => g.id === id) ?? newGroup;

      if (group.opportunities.length >= 0) {
        group.opportunities.push(item);
      }
      groupedData.push(group);
    });

    return uniqBy(groupedData, 'id');
  }
}
