import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { environment } from 'src/environments/environment';

interface Recipient {
  email: string;
  valid: boolean;
  color: string;
}

@Component({
  selector: 'app-email-card',
  templateUrl: './email-card.component.html',
  styleUrls: ['./email-card.component.scss'],
})
export class EmailCardComponent implements OnInit {
  @Input() message = '';
  @Input() header = '';
  @Input() footer = '';
  @Input() subject = '';

  /* tslint:disable-next-line */
  @Output() onCancel = new EventEmitter<void>();

  loggedUserSubscription: Subscription;
  configSubscription: Subscription;

  config: any;

  enableSendEmail = false;
  recipients: Recipient[] = [];
  copyTo: Recipient[] = [];
  removable = true;
  readonly recipientsLimit = 10;
  isProcessing = false;
  hasErrorRecipients = false;
  hasErrorCopyTo = false;
  disabled = true;
  loggedUser: User;
  selectable = false;
  selectableCopyTo = false;
  addOnBlur = true;
  addOnBlurCopyTo = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private authService: AuthService,
    private configService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.configSubscription = this.configService.getConfiguration('signup').subscribe((config) => {
      this.config = config;
    });

    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = loggedUser;
    });
  }

  addRecipient(name: 'copyTo' | 'recipients', event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add lead
    if ((value || '').trim()) {
      const valid = !!value.toLowerCase().match(UtilHandler.emailRegex);
      const iscopyTo = this.copyTo.some((item) => item.email === value.trim().toLowerCase());
      const isRecipients = this.recipients.some((item) => item.email === value.trim().toLowerCase());

      if (!iscopyTo && !isRecipients) {
        this[name].push({
          email: value.trim().toLowerCase(),
          valid,
          color: valid ? '' : 'warn',
        });
      }
      if (name === 'copyTo') {
        this.hasErrorCopyTo = this.hasInvalidRecipients(name);
        this.hasErrorCopyTo =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorCopyTo;
      } else {
        this.hasErrorRecipients = this.hasInvalidRecipients(name);
        this.hasErrorRecipients =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorRecipients;
      }
    }
    if (input) {
      input.value = null;
    }
  }

  cancelMessage() {
    this.header = '';
    this.message = '';
    this.footer = '';
    this.subject = '';
    this.recipients = [];
    this.copyTo = [];
    this.onCancel.emit();
  }

  hasInvalidRecipients(name = ''): boolean {
    if (name !== '') {
      return this[name].some((item) => !item.valid);
    } else {
      return this.recipients.some((item) => !item.valid) || this.copyTo.some((item) => !item.valid);
    }
  }

  isUnableToSend(): boolean {
    return !this.subject || !this.message || this.recipients.length === 0 || this.hasInvalidRecipients();
  }

  removeRecipient(name: string, recipient: Recipient): void {
    const index = this[name].indexOf(recipient);

    if (index >= 0) {
      this[name].splice(index, 1);
      if (name === 'copyTo') {
        this.hasErrorCopyTo = this.hasInvalidRecipients(name);
        this.hasErrorCopyTo =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorCopyTo;
      } else {
        this.hasErrorRecipients = this.hasInvalidRecipients(name);
        this.hasErrorRecipients =
          this[name].length === 0 || this[name].length > this.recipientsLimit || this.hasErrorRecipients;
      }
    }
  }

  sendMessage(): void {
    this.isProcessing = true;
    if (!this.isUnableToSend()) {
      const confirmSubscription = this.dialog
        .open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Confirmação',
            alertDescription: `Deseja mesmo enviar esta mensagem?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const formattedMessage = `
              <div>
                <div>${this.header}</div>
                <p>${this.message.replace(/[\n]/g, '<br />')}<br /></p>
                <div>${this.footer}</div>
              </div>
            `;

            this.http
              .post(
                `${environment.functionsUrl}/email/send-report`,
                {
                  subject: this.subject,
                  message: formattedMessage,
                  recipients: [...this.recipients.map((l) => l.email), this.loggedUser.email],
                  copyTo: this.copyTo.map((l) => l.email) ?? null,
                  replyTo: this.loggedUser.email,
                },
                {
                  responseType: 'text',
                  headers: this.authService.getHeader(),
                }
              )
              .toPromise()
              .then((res) => {
                console.log('Mensagem enviada');
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Mensagem enviada',
                    alertDescription: `A mensagem foi enviada com sucesso!`,
                    isOnlyConfirm: true,
                  },
                });

                this.recipients = [];
                this.copyTo = [];
                this.isProcessing = false;
              })
              .catch((err) => {
                console.error('Erro ao enviar relatório por e-mail');
                this.dialog.open(AlertDialogComponent, {
                  maxWidth: '600px',
                  data: {
                    alertTitle: 'Erro ao enviar mensagem',
                    alertDescription: `Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente mais tarde.`,
                    isOnlyConfirm: true,
                  },
                });

                this.isProcessing = false;
              });
          }

          if (confirmSubscription) {
            confirmSubscription.unsubscribe();
          }
        });
    }
  }

  getHTMLMessage(): string {
    return '<p>' + this.message.replace(/[\n]/g, '<br />') + '<br />' + '</p>';
  }

  getRecipientsAsText(param: string): string {
    return this[param]?.map((r) => r.email).join(', ');
  }
}
