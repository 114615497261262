/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../../../../functions/src/models/User';
import { AlertDialogComponent } from '../../../../components/alert-dialog/alert-dialog.component';
import { CadastrosGeraisService } from '../../cadastros-gerais.service';

@Component({
  selector: 'app-email-group',
  templateUrl: './email-group.component.html',
  styleUrls: ['./email-group.component.css']
})
export class EmailGroupComponent implements OnInit, OnDestroy {
  @ViewChild('editNameInput') editNameInput: ElementRef;
  @Input() loggedAdmin: User;

  constructor(private _service: CadastrosGeraisService, private _dialog: MatDialog) {
    this.isFormCreate = false;
    this.isFormEdit = false;
    this.isAddEmail = false;
  }

  databaseElement;
  databaseElementEmailList;
  databaseServiceCollection$;
  databaseServiceColumns = ['group', 'emailList', 'mnemonic', 'active', 'edit'];
  formEmailListEditColumns = ['name', 'email', 'delete'];
  isFormCreate: boolean;
  isFormEdit: boolean;
  isAddEmail: boolean;
  formCreateDocument = new FormGroup({
    group: new FormControl('', Validators.required),
    mnemonic: new FormControl('', Validators.required),
    active: new FormControl('', Validators.required)
  });
  formEditDocument = new FormGroup({
    group: new FormControl(''),
    active: new FormControl(''),
  });
  formEditEmailList = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required)
  });

  ngOnInit(): void {
    this.getEmailGroupsList();
  }

  ngOnDestroy(): void {
    this.getEmailGroupsList().unsubscribe();
  }

  automaticFill(value): void {
    this.formCreateDocument.get('mnemonic').setValue(value);
  }

  existDocument(): boolean {
    const document = this.formCreateDocument.value;
    const existDocument = this.databaseServiceCollection$.find((element) => {
      return element.mnemonic === document.mnemonic;
    });
    if (existDocument) {
      return true;
    }
    return false;
  }

  documentCreate(): void {
    const existDocument = this.existDocument();
    if (existDocument) {
      this._dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        disableClose: true,
        data: {
          alertTitle: 'Grupo Existe !',
          alertDescription: 'O Grupo de Emails já está cadastrado !',
          isOnlyConfirm: true,
        },
      });
      return;
    }
    this.postEmailGroupsList();
    this.showDataList();
  }

  documentEdit(): void {
    this.databaseElement.group = this.formEditDocument.get('group').value;
    this.databaseElement.active = this.formEditDocument.get('active').value;
    console.log('form edit : ', this.formEditDocument.value);
    console.log('database element : ', this.databaseElement);
    this.patchEmailGroupsList();
    this.showDataList();
  }

  documentDelete(element): void {
    this._service.deleteEmailGroups(element)
    .then(() => {
      console.log('DELETE - Request Success');
    })
    .catch((error) => { console.log('ERROR : ', error); });
    this.showDataList();
  }

  // function called with button "Adicionar Emails"

  documentEditAddEmailListUser(): void {
    this.databaseElement.emailList.push(this.formEditEmailList.value);
    this.patchEmailGroupsList();
    this.databaseElementEmailList = [...this.databaseElement.emailList];
    this.formEditEmailList.get('name').setValue('');
    this.formEditEmailList.get('email').setValue('');
    this.getEmailGroupsList();
  }

  // function called with button icon "delete"

  documentEditDeleteEmailListUser(user): void {
    const databaseElementDeleted = user;
    this.databaseElementEmailList = this.databaseElementEmailList.filter(
      (item) => item.email !== databaseElementDeleted.email
    );
    this.databaseElement.emailList = this.databaseElementEmailList;
    this.patchEmailGroupsList();
    this.getEmailGroupsList();
  }

  showFormCreate(): void {
    this.isFormCreate = true;
    this.isFormEdit = false;
  }

  showFormEdit(element): void {
    this.databaseElement = element;
    this.databaseElementEmailList = element.emailList;
    this.formEditDocument.get('group').setValue(element.group);
    this.formEditDocument.get('active').setValue(element.active);
    this.isFormCreate = false;
    this.isFormEdit = true;
  }

  showDataList(): void {
    this.isFormCreate = false;
    this.isFormEdit = false;

  }

  getEmailGroupsList(): Subscription {
    return this._service.getEmailGroups()
      .subscribe({
        next: (data) => {
          this.databaseServiceCollection$ = data;
        },
        error: (error) => { console.log('ERROR : ', error); }
      });
  }

  postEmailGroupsList(): void {
    this._service.postEmailGroups(this.formCreateDocument.value)
      .then(() => {
        console.log('POST - Request Success');
      })
      .catch((error) => { console.log('ERROR : ', error); });
  }

  patchEmailGroupsList(): void {
    this._service.patchEmailGroups(this.databaseElement)
      .then(() => {
        console.log('PATCH - Request Success');
      })
      .catch((error) => { console.log('ERROR : ', error); });
  }

}
