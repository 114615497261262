<div class="container-fluid">
  <mat-card *ngIf="isLoading.agent">
    <mat-card-content>
      <div class="w-100 p-5">
        <mat-spinner [diameter]="40" class="mx-auto"></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoading.agent">
    <mat-card-content>
      <mat-card-title> Link de Cadastro </mat-card-title>
      <p class="my-2">Compartilhe o seu link de cadastro com seus leads:</p>
      <div class="row">
        <div class="col-12 col-sm d-flex align-items-center">
          <mat-form-field>
            <textarea
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1.1"
              readonly
              class="p-1"
              [value]="agentLink"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-auto col d-flex align-items-center justify-content-center">
          <button mat-raised-button color="accent" [cdkCopyToClipboard]="agentLink">
            <span class="d-flex align-items-center mx-1"> <i class="far fa-copy mr-2"></i> Copiar link </span>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <mat-card-title> Indique seus leads </mat-card-title>
      <p class="my-2">
        Envie um convite aos seus leads para conhecerem a Capital Empreendedor. Você pode adicioná-los abaixo e
        visualizar a mensagem que eles receberão.
      </p>

      <div class="w-100 p-4" *ngIf="isLoading.config">
        <mat-spinner [diameter]="40" class="mx-auto"></mat-spinner>
      </div>
      <div class="row" *ngIf="!isLoading.config">
        <div class="col-12 col-sm d-flex align-items-center">
          <mat-form-field class="w-100" color="accent">
            <mat-chip-list #emailList aria-label="E-mail input">
              <mat-basic-chip
                class="chip"
                *ngFor="let lead of leads"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeLead(lead)"
                [class.error]="!lead.valid"
              >
                {{ lead.email }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-basic-chip>
              <input
                placeholder="E-mails dos leads"
                [matChipInputFor]="emailList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addLead($event)"
                [readonly]="leads.length >= limit"
              />
            </mat-chip-list>
            <mat-hint align="start" *ngIf="!hasError"> Adicione até {{ limit }} e-mails </mat-hint>
            <mat-hint align="start" *ngIf="hasError" class="error-hint"> A lista contém e-mails inválidos. </mat-hint>
            <mat-hint align="end"> E-mails adicionados: {{ leads.length }} </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-auto col d-flex align-items-center justify-content-center mt-3 mt-sm-0">
          <button mat-raised-button color="primary" [disabled]="disabled || isProcessing" (click)="sendInvites()">
            <span class="d-flex align-items-center mx-1" *ngIf="!isProcessing">
              <i class="far fa-paper-plane mr-2"></i> Enviar
            </span>
            <mat-spinner [diameter]="20" class="mx-3 my-2" *ngIf="isProcessing"></mat-spinner>
          </button>
        </div>
      </div>
      <div class="mt-4 p-2">
        <mat-expansion-panel>
          <mat-expansion-panel-header style="min-height: 5em">
            <mat-panel-title>
              <span style="font-size: 1.1em; display: flex; align-items: center">Pré-visualização da mensagem *</span>
            </mat-panel-title>
            <mat-panel-description class="d-flex align-items-center"> </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="w-100 p-4" *ngIf="isLoading.config">
            <mat-spinner [diameter]="40" class="mx-auto"></mat-spinner>
          </div>
          <div class="p-1 pl-3"><span style="font-weight: bold">Assunto:</span> {{ subject }}</div>
          <hr class="mb-2" />
          <div #messagePreview class="mx-auto"></div>
          <div class="mt-5">
            * A formatação da mensagem pode ser diferente de acordo com o navegador e/ou serviço de e-mail que o
            destinatário estiver utilizando.
          </div>
        </mat-expansion-panel>
      </div>
    </mat-card-content>
  </mat-card>
</div>
