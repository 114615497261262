<div class="main-container">
  <div class="toolbar">
    <mat-toolbar color="primary">
      <button class="toggler" mat-icon-button (click)="this.toggle()" *ngIf="shouldSidenavBeVisible()">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" class="branding d-inline d-md-none">
        <img [src]="logo" />
      </a>
      <a routerLink="/" class="branding d-none d-md-inline" style="color: #fff">
        <img [src]="logoFull" />
      </a>
      <mat-chip-list aria-label="Environment" class="d-inline-block spacer" *ngIf="!isProd">
        <mat-chip color="white" selected class="ml-4">
          <span>Operadores</span>
        </mat-chip>
        <mat-chip class="ml-4 d-none d-sm-inline-flex">
          <span>{{ envLabel }}</span>
        </mat-chip>
      </mat-chip-list>
      <div class="d-inline-block spacer" *ngIf="isProd"></div>

      <button mat-flat-button class="border" *ngIf="!banker || banker.isAnonymous" (click)="onLoginClick()">
        Entrar
      </button>

      <button *ngIf="banker && !banker.isAnonymous" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu" class="m-r-5">
        <mat-icon class="mat-icon-size">account_circle</mat-icon>
      </button>
      <mat-menu #toolbarUserMenu="matMenu">
        <button mat-menu-item disabled style="height: auto">
          <span class="d-flex alignt-items-center accent-text-color" style="line-height: 2.5em">
            <span class="d-inline-block" style="width: 1em; height: 1em; margin-right: 1em">
              <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon>
            </span>
            <span class="d-inline-block">{{
              !!banker && !!banker.name ? banker.name.split(' ')[0] : 'Anonymous'
              }}</span>
          </span>
          <span class="d-block" style="font-size: 0.85em; line-height: 1.5em; margin-bottom: 1em">{{
            !!banker ? banker.email : ''
            }}</span>
        </button>
        <mat-divider></mat-divider>

        <a mat-menu-item
          href="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/manuais%2Fmanual-operadores.pdf?alt=media"
          target="_blank">
          <mat-icon fontSet="fas" fontIcon="fa-question-circle"></mat-icon>
          <span>Manual do Operador</span>
        </a>

        <button mat-menu-item (click)="onLogoutClick()">
          <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
          <span>Sair</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  </div>
  <div class="wrapper">
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
      <mat-sidenav #snav *ngIf="shouldSidenavBeVisible()" [opened]="toggled" [disableClose]="true"
        style="min-width: 80px; background-color: #fafafa; position: fixed; margin-top: 64px"
        [mode]="mobileQuery.matches ? 'over' : 'push'" appAccordion>
        <mat-nav-list appAccordionLink *ngFor="let option of menuOptions"
          [class.expanded-sidenav]="toggled && expanded">
          <a appAccordionToggle class="menu-item" mat-list-item [routerLink]="[option.state]" [queryParams]="{}">
            <img [src]="[option.img]" />
            <span class="menu-label" *ngIf="expanded">{{ option.name }}</span>
          </a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content class="sidenav-content" [class.expanded-sidenav-content]="visible && toggled && expanded"
        [class.toggled-only-sidenav-content]="visible && toggled && !expanded"
        (click)="toggled = mobileQuery.matches && toggled ? false : toggled">
        <div class="content">
          <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center" style="height: 100%">
            <mat-spinner [diameter]="90"></mat-spinner>
          </div>
          <router-outlet #o="outlet" *ngIf="!isLoading"></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <div class="footer">
      <div class="row">
        <div class="col-12 col-lg-6 align-items-center d-flex">
          <div class="links mx-auto">
            <a href="https://www.capitalempreendedor.com.br/blog/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-blogger"></mat-icon>
              <span>Blog</span>
            </a>
            <a href="https://www.linkedin.com/company/capital-empreendedor/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-linkedin"></mat-icon>
              <span>Linkedin</span>
            </a>
            <a href="https://www.facebook.com/capitalempreededor/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-facebook"></mat-icon>
              <span>Facebook</span>
            </a>
            <a href="https://www.instagram.com/capitalempreendedor/" target="_blank">
              <mat-icon fontSet="fab" fontIcon="fa-instagram"></mat-icon>
              <span>Instagram</span>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-sm-6 col-md-5 offset-md-1 signature py-1">
              <a class="link" [href]="urlPrivacyPolicy" target="_blank">Política de Privacidade</a>
            </div>
            <div class="col-sm-6 col-md-5 signature py-1">
              <a href="https://www.capitalempreendedor.com.br/faq" target="_blank" style="position: initial"> Dúvidas
                Frequentes</a>
            </div>
          </div>
          <div class="row">
            <div class="col signature">
              <strong>v.{{ version }}</strong> - &#169; <span class="year">{{ year }}</span> - Capital Empreendedor -
              CNPJ: 21.261.858/0001-80<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>