<mat-card class="big-input" [ngClass]="{ 'has-focus': hasFocus }">
  <input
    (focus)="hasFocus = true"
    (blur)="hasFocus = false"
    [value]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
  />
  <ng-content></ng-content>
</mat-card>
