import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  extraContactsCollection: AngularFirestoreCollection;
  contactSelected: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor( private angularFirestore: AngularFirestore) {
    this.extraContactsCollection = this.angularFirestore.collection('customers');
  }

  getCustomers() {
    return this.angularFirestore.collection('customers').valueChanges();
  }

  getContactSelected(contact: any) {
    this.contactSelected.next(contact);
  }
}
