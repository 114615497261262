<div class="notes-area my-4">
    <div>
      <h3 style="font-weight: bold; flex: 1">Contato com o cliente</h3>

      <hr style="background-color: #9e9e9e" class="mb-3" />
   
    <form [formGroup]="contactFormGroup">
      <div class="d-flex">
      <mat-label class="fonte">Data da ultima tentativa de contato:</mat-label>
      <mat-form-field class="data" >
        <input 
          matInput [matDatepicker]="lastContactDate" id="lastContactDate" placeholder="Data"
          name="lastContactDate"   formControlName="lastContactDateCtrl" 
          />
        <mat-datepicker-toggle matSuffix [for]="lastContactDate"></mat-datepicker-toggle>
        <mat-datepicker #lastContactDate></mat-datepicker>
      </mat-form-field>
      </div>
     <br/>

    
     <div class="flex-fill pt-2">
    <mat-label class="fonte">Teve retorno?</mat-label>
    <mat-form-field  class="col-lg-8 ml-4">
      <mat-select 
      name="returnContact"
      placeholder="Retorno"
      formControlName="returnContactCtrl"
      [compareWith]="compareObjects">
    <mat-option [value]="item" *ngFor="let item of returnList">{{item.name}}</mat-option>
   </mat-select>
  </mat-form-field>
  </div>
   

   <div class="flex-fill">
   <mat-label class="fonte">Meio de contato:</mat-label>
   <mat-form-field class="col-lg-8 ">
      <mat-select 
        name="customerContact"
        placeholder="Meio de contato"
        formControlName="customerContactCtrl"
        multiple>
      <mat-option value="whatsapp"> Whatsapp </mat-option>
      <mat-option value="sce"> SCE </mat-option>
      <mat-option value="email">  Email </mat-option>
      <mat-option value="ligação">  Ligação </mat-option>
    </mat-select>
  </mat-form-field>
  </div>

 

   <div class="flex-fill">
   <mat-label class="fonte">Responsável:</mat-label>
   <mat-form-field class="col-lg-9 pb-2">
   <input class="w-100"
      matInput 
      type="text"
      placeholder="Nome do responsável"
      formControlName ="contactingNameControl"
      [matAutocomplete]="auto"
      />
    <mat-autocomplete class="col-md-6" #auto="matAutocomplete" >
      <mat-option *ngFor="let contacting of filtercontactings | async" [value]="contacting">
        {{contacting}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
 
    </div>



 <div class="flex-fill">
 <mat-label class="fonte">Descrição:</mat-label>
 <mat-form-field  class="col-lg-9">
 <textarea
    matInput
    matTextareaAutosize
     matAutosizeMinRows="1"
      matAutosizeMaxRows="3"
    matAutosizeMinRows="1"
    name="descriptionContact"
    placeholder="Descrição"
    formControlName="descriptionContactCtrl"
 ></textarea>
  </mat-form-field>
  </div>

<br/>

   <div >
    <button mat-raised-button class="text-right" (click)="saveContact()" >
      Salvar
    </button>
    </div>
  </form>
    <br/>
    <br/>
        <span class="span">* Estas anotações são internas e não são repassadas às instituições</span>
    
</div>
