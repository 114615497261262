function Clicksign(i) {
  'use strict';
  function n(n) {
    var t;
    (e[(t = n).name || t] || []).forEach(function (t) {
      t(n.data);
    });
  }
  var o,
    r,
    t = window.location.protocol + '//' + window.location.host,
    e = {},
    u = function (t) {
      n(t.data);
    };
  return {
    endpoint: 'https://app.clicksign.com',
    origin: t,
    mount: function (t) {
      var n = '/sign/' + i,
        e = '?embedded=true&origin=' + this.origin,
        e = this.endpoint + n + e;
      return (
        (r = document.getElementById(t)),
        (o = document.createElement('iframe')).setAttribute('src', e),
        o.setAttribute('style', 'width: 100%; height: 100%;'),
        o.setAttribute('allow', 'camera'),
        window.addEventListener('message', u),
        r.appendChild(o)
      );
    },
    unmount: function () {
      return o && (r.removeChild(o), (o = r = null), window.removeEventListener('message', n)), !0;
    },
    on: function (t, n) {
      return e[t] || (e[t] = []), e[t].push(n);
    },
    trigger: n,
  };
}

module.exports = Clicksign;
