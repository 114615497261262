import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firestore } from 'firebase';
import { Customer } from 'functions/src/models/Customer';
import { UserNote, UserNotification } from 'functions/src/models/Notes';
import { User } from 'functions/src/models/User';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { FeedService } from 'src/app/admin/services/feed.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UserFeedDialogComponent } from '../user-feed-dialog/user-feed-dialog.component';

@Component({
  selector: 'app-user-notes-history',
  templateUrl: './user-notes-history.component.html',
  styleUrls: ['./user-notes-history.component.scss'],
})
export class UserNotesHistoryComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  customer: string;

  @Input()
  createdBy: UserNote['createdBy'];

  @Input()
  showHr = false;

  admin: User;
  notificationData: UserNotification;
  customerData: Customer;
  agent: string;
  editMode = [];

  filteredNotes: UserNote[] = [];

  isCreateMode = false;
  isLoadingNotes = true;

  newNote: UserNote = {
    message: '',
  };
  searchValue = '';
  createdAt: firebase.firestore.Timestamp;
  lastUpdate: firebase.firestore.Timestamp;
  now: firebase.firestore.Timestamp;

  notes: UserNote[] = [];
  notesSubscription: Subscription;

  user: User;
  userSubscription: Subscription;

  constructor(private feedService: FeedService, private dialog: MatDialog, private snackbar: MatSnackBar) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.customer) {
      this.notesSubscription = this.feedService.getFeedPreview(this.customer, 3).subscribe((userNotes) => {
        this.notes = userNotes;
        this.editMode =
          this.notes?.map((p) => ({
            enabled: false,
            data: {
              message: p.message,
            },
          })) || [];
        this.isLoadingNotes = false;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
  }

  cancelEditNote(i: number): void {
    this.editMode[i] = {
      enabled: false,
      data: {
        message: this.notes[i]?.message || '',
      },
    };
  }

  clearNote(): void {
    this.newNote.message = '';
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNote();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchNotes();
  }

  createNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar anotação',
          alertDescription: 'Deseja realmente enviar essa anotação?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        this.newNote = {
          ...this.newNote,
          type: 'ADMIN',
          createdBy: this.createdBy,
        };

        this.feedService
          .createNote(this.customer, this.newNote)
          .then(() => {
            this.isCreateMode = false;
            this.createdAt = firestore.Timestamp.now();
            this.clearNote();
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Anotação Salva',
                alertDescription: 'A anotação foi enviada com sucesso.',
                isOnlyConfirm: true,
              },
            });
          })
          .catch((err) => {
            console.error('Error creating note', err);

            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao salvar',
                alertDescription: 'Não foi possível salvar a anotação. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
          })
          .finally(() => {
            if (confirmSubscription) {
              confirmSubscription.unsubscribe();
            }
          });
      });
  }

  saveNote(noteId, i): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar anotação',
          alertDescription: 'Deseja realmente salvar as alterações nesta anotação?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.feedService
            .editNote(noteId, this.editMode[i].data, this.customer)
            .then(() => {
              this.isCreateMode = false;
              this.clearNote();
              this.snackbar.open('Anotação editada!', null, {
                duration: 2000,
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao salvar',
                  alertDescription: 'Não foi possível editar a anotação. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  searchNotes(): void {
    if (this.searchValue) {
      this.filteredNotes = _.filter(
        this.notes,
        (n) => n.message?.toUpperCase().indexOf(this.searchValue.toUpperCase()) >= 0
      );
    } else {
      this.filteredNotes = this.notes;
    }
  }

  viewMoreNotes(): void {
    this.dialog.open(UserFeedDialogComponent, {
      height: '80%',
      width: '70%',
      data: {
        customer: this.customer,
        notificationData: '',
        createdBy: this.createdBy,
        customerData: this.customerData,
      },
    });
  }
}
