<div class="mt-2 mb-2 " *ngIf="formGroup">
  <mat-form-field class="col-12" color="primary">
    <mat-label>Mudar para a fase:</mat-label>
    <mat-select [formControl]="formGroup.get('stageChangeCtrl')" required>
      <div *ngFor="let stageOpportunity of opportunityStepsOptions">
        <mat-option *ngIf="stageOpportunity.defaultprobability !== 100" [value]="stageOpportunity.defaultprobability">
          {{ stageOpportunity.defaultprobabilitystr + ' - ' + stageOpportunity.name }}
        </mat-option>
      </div>
    </mat-select>
    <mat-error> Informe a fase desejada. </mat-error>
  </mat-form-field>
  <mat-form-field class="col-12">
    <mat-label>Adicionar Lista de CNPJ </mat-label>
    <textarea matInput [formControl]="formGroup.get('listCnpjCtrl')" placeholder="Adicionar Lista de CNPJ"
      name="list-cnpj" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="8"></textarea>
  </mat-form-field>

</div>