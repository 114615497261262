import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Observable } from 'rxjs';
import { Note, UserNotification } from 'functions/src/models/Notes';
import { AuthService } from 'src/app/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeedService {
  constructor(private angularFirestore: AngularFirestore, private authService: AuthService) {}

  createNote(uid: string, note: Note): Promise<void> {
    return new Promise((resolve, reject) => {
      const now = firestore.Timestamp.now();

      this.angularFirestore
        .collection('customers')
        .doc(uid)
        .collection('admin-notes')
        .doc(now.toDate().toISOString())
        .set({
          ...note,
          createdAt: now,
          lastUpdate: now,
          id: now.toDate().toISOString(),
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  editNote(noteId: string, data: Note, customerId: string) {
    return new Promise((resolve, reject) => {
      const now = firestore.Timestamp.now();

      this.angularFirestore
        .collection('customers')
        .doc(customerId)
        .collection('admin-notes')
        .doc(noteId)
        .set(
          {
            ...data,
            lastUpdate: now,
          },
          { merge: true }
        )
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNotes(uid: string): Observable<Note[]> {
    return this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('admin-notes', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  getFeedPreview(uid: string, quantity = 3 /* , agent?: string */): Observable<Note[]> {
    const collection = this.angularFirestore
      .collection('customers')
      .doc(uid)
      .collection('admin-notes', (ref) => ref.orderBy('createdAt', 'desc').limit(quantity))
      .valueChanges();

    return collection;
  }

  searchNote() {}
}
