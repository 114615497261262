import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '../../../../../functions/src/models/User';
import { Customer } from '../../../../../functions/src/models/customers/Customer';

@Component({
  selector: 'app-chat-customer-attributed',
  templateUrl: './chat-customer-attributed.component.html',
  styleUrls: ['./chat-customer-attributed.component.scss']
})
export class ChatCustomerAttributedComponent implements OnInit, OnChanges {
  @Input() users: User[] = [];
  @Input() customer: Customer;
  @Input() selectedUser: User;
  @Output() selectedUserUidChange = new EventEmitter<User>();
  ngOnInit(): void {
    console.log('initi selectedUser com base no customer.attributedTo')

    this.updateSelectedUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Verifica se a propriedade 'customer' foi alterada
    if (changes['customer']) {
      console.log('changes[customer]')

      this.updateSelectedUser();
    }
  }

  onUserSelectionChange(selected: User): void {
    this.selectedUser = selected;
    this.selectedUserUidChange.emit(selected);
  }

  compareSelectedUser(user1: User, user2: User): boolean {
    return user1 && user2 ? user1.uid === user2.uid : user1 === user2;
  }

  private updateSelectedUser(): void {
    // Atualiza 'selectedUser' com base no 'customer.attributedTo'
    console.log('Atualiza selectedUser com base no customer.attributedTo')
    this.selectedUser = this.users.find(u => u.uid === this.customer?.attributedTo);
  }
}
