<div class="info-container">
    <div class="inner-container">
        <div class="main-content">
            <div class="info-content">
                <div>
                    <h1 style="flex: 1" class="mb-4">Habilite todo seu potencial de crédito!</h1>
                    <p>
                        O Open Finance é <b>a nova revolução financeira</b> que oferece liberdade e controle aos usuários. Com esta tecnologia, as instituições financeiras podem acessar facilmente os dados financeiros dos usuários <span class="highlight-gray">de forma segura</span>, possibilitando <span class="highlight-orange">mais opções de crédito mais rápido.</span> 
                    </p>
                    <p>
                        Ele <b>promove uma maior inclusão financeira</b> ao permitir que pessoas com histórico de crédito limitado obtenham mais opções de empréstimos com base em outros dados financeiros! Ao habilitar o Open Finance, sua empresa consegue <span class="highlight-orange">mais chances de crédito e taxas de juros mais competitivas!</span>
                    </p>
                </div>

                <div class="example-card-container">
                    <mat-card class="example-card">
                        <mat-card-content>
                            <mat-card-title>Potencialize sua análise de crédito!</mat-card-title>
                            <p>Conecte sua conta com <b> mais movimentações </b> para liberar mais oportunidades para sua empresa!</p>
                            
                                <button class="open-finance-btn" type="button" (click)="openStepModal()">Conecte agora</button>
                
                            
                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="doubts-wrapper">
                    <p>Tem alguma dúvida?</p>
                    <button class="open-finance-btn-green"><a routerLink="/mensagens">Fale conosco</a></button>
                </div>

                

            </div>
        </div>
    </div>
</div>