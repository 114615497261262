import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/components';

import { settingsReducer } from './settings.reducer';

@NgModule({
  imports: [SharedModule],
  declarations: [],
})
export class SettingsModule {}
