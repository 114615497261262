import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateBndesSignupService {
  private _image = '';
  private _imagePath = new BehaviorSubject<string>(this._image);
  readonly imagePath = this._imagePath.asObservable();

  constructor() {}

  setImagePath(path: string): void {
    this._image = path;
    this._imagePath.next(this._image);
  }

  getImagePath(): string {
    return this._image;
  }
}
