import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { Customer } from '../../../../../functions/src/models/Customer';
import { OpportunityService } from '../../services/opportunity.service';

interface basicDataActivities {
  IsMain: boolean;
  Activity: string;
  
}

interface lawsuitsData {
  TotalLawsuitsAsAuthor: number;
  TotalLawsuitsAsDefendant: number;
}

interface relationships {
  RelatedEntityName : string;
  RelatedEntityTaxIdNumber: number
}


@Component({
  selector: 'app-data-aggregator',
  templateUrl: './data-aggregator.component.html',
  styleUrls: ['./data-aggregator.component.scss']
})
export class DataAggregatorComponent implements OnInit {
  @Input() customer: Customer;
  @Output() viewDataClicked = new EventEmitter<void>();



  bigdatacorpData: any;
  basicData: any;
  basicDataActivities: any;
  lawsuitsData: lawsuitsData;
  lawsuits: lawsuitsData;
  
  relationships: relationships;
  

  constructor(private opportunityService: OpportunityService) { }

  ngOnInit(): void {

    this.opportunityService.getBigDataCorpSavedData(this.customer.uid).subscribe((docs) => {

      

      this.bigdatacorpData = {};
    
      this.basicData = this.findDataByIndex(docs, 0);
      

      this.basicDataActivities = this.findMainActivities(docs);
      
     
      this.lawsuitsData = this.findDataByLawsuitsData(docs);
      
      
      this.relationships = this.findDataByRelationships(docs);
      

      docs.forEach((d) => {
        _.forEach(d, (v, k) => (this.bigdatacorpData[k] = v));
      });
    })
  }
 
  findDataByLawsuitsData(docs: any[]): lawsuitsData {
    const foundData = docs.find(doc => doc['Lawsuits'] !== undefined);
    return foundData ? foundData['Lawsuits'] : null;
  }

  findDataByIndex<T>(docs: T[], index: number): T | null {
    return docs[index] !== undefined ? docs[index] : null;
  }

  findMainActivities(docs: any[]): basicDataActivities[] {
    const data = this.findDataByType(docs) as basicDataActivities[];
    return data ? data.filter(activity => activity.IsMain) : [];
  }

  findDataByType(docs: any[]): any {
    const foundData = docs.find(doc => doc['Activities'] !== undefined);
    return foundData ? foundData['Activities'] : null;
  }

  
  findDataByRelationships(docs: any[]):  relationships{
    const foundData = docs.find(doc => doc['Relationships'] !== undefined);
    return foundData ? foundData['Relationships'] : null;
  }
  ngOnDestroy(): void {
   
 
  }

  viewDataClick(): void {
    this.viewDataClicked.emit();
  }

}
