<div
  class="content"
  [class.d-flex]="isLoading"
  [class.flex-column]="isLoading"
  [style.min-height]="isLoading ? '100%' : 'unset'"
>
  <div class="d-flex align-items-center justify-content-center" style="flex: 1" *ngIf="isLoading">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>

  <div class="main-content" *ngIf="!isResetPassword && !isLoading">
    <div>
      <h1>Acessar</h1>
      <p>Acesse sua conta para acompanhar o andamento das suas oportunidades.</p>
    </div>
    <form #loginForm="ngForm">
      <div class="form">
        <div class="mb-1 d-flex align-items-center">
          <mat-icon fontSet="fas" fontIcon="fa-user" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>E-mail</mat-label>
            <input
              matInput
              placeholder="E-mail"
              [(ngModel)]="loginData.email"
              name="email"
              #email="ngModel"
              required
              email
              [disabled]="isProcessing"
            />
            <mat-error *ngIf="email.errors?.required">Digite seu e-mail</mat-error>
            <mat-error *ngIf="email.errors?.email">E-mail inválido</mat-error>
          </mat-form-field>
        </div>

        <div class="mb-1 d-flex align-items-center">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <mat-label>Senha</mat-label>
            <input
              matInput
              placeholder="Senha"
              [(ngModel)]="loginData.password"
              type="password"
              name="password"
              #password="ngModel"
              required
              [disabled]="isProcessing"
            />
            <mat-error>Digite sua senha</mat-error>
          </mat-form-field>
        </div>
        <div class="text-right mb-3">
          <button mat-button type="button" (click)="showResetPassword()" style="font-size: 0.8em">
            Esqueci minha senha
          </button>
        </div>
        <div>
          <button
            mat-raised-button
            color="primary"
            class="action-button"
            type="submit"
            [class.disabled]="loginForm.invalid"
            *ngIf="!isProcessing"
            [disabled]="loginForm.invalid"
            (click)="login()"
          >
            Continuar
          </button>

          <button
          mat-raised-button
          style="background-color: #4285f4;"
          class="action-button"
          type="button"
          *ngIf="!isProcessing"
          (click)="loginWithGoogle()"
        >
        <img src="assets/images/google.png" class="google-logo" />
          Login com Google
        </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
        <p class="mt-4 cta">
          Está acessando pela primeira vez?
          <a (click)="redirectToSignUp()" class="colored-link">Compare Grátis!</a>
        </p>
      </div>
    </form>
  </div>

  <div class="main-content" *ngIf="isResetPassword && !isLoading">
    <div>
      <h1>Esqueci minha senha</h1>
      <p>Insira abaixo seu email e siga as instruções enviadas para criar uma nova senha.</p>
    </div>
    <form #resetPasswordForm="ngForm">
      <div class="form">
        <div class="mb-4 mb-sm-5 d-flex align-items-center">
          <mat-icon fontSet="fas" fontIcon="fa-key" class="colored-icon"></mat-icon>

          <mat-form-field color="primary">
            <input
              matInput
              placeholder="Digite seu e-mail"
              [(ngModel)]="emailResetPassword"
              required
              [disabled]="isProcessing"
              name="email"
              #email="ngModel"
              email
            />
            <mat-error *ngIf="email.errors?.required">Digite seu e-mail</mat-error>
            <mat-error *ngIf="email.errors?.email">E-mail inválido</mat-error>
            <mat-hint class="colored-hint" *ngIf="resetEmailWasSent">E-mail enviado</mat-hint>
          </mat-form-field>
        </div>

        <div class="button-container mb-4">
          <button
            mat-stroked-button
            class="action-button text-black autosized"
            type="button"
            (click)="showLogin()"
            *ngIf="!isProcessing"
          >
            Voltar
          </button>
          <button
            mat-raised-button
            color="primary"
            class="action-button"
            [class.disabled]="resetPasswordForm.invalid"
            type="submit"
            [disabled]="resetPasswordForm.invalid"
            (click)="sendEmailResetPassword()"
            *ngIf="!isProcessing"
          >
            Enviar e-mail
          </button>
          <div class="text-center">
            <mat-spinner *ngIf="isProcessing" [diameter]="40" color="primary" class="spinner-area"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
