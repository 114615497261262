<div style="margin: 2px">
  <mat-accordion class="example-headers-align" *ngIf="linesCreditActive?.length > 0">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>Novas linhas de crédito</mat-panel-title>
        <mat-panel-description>
          <mat-label>Associe novas linhas de crédito ao usuário</mat-label>
          <mat-icon>add_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <div class="d-flex flex-column">
          <mat-selection-list #linhasCredAtivosSelecionados>
            <mat-list-option *ngFor="let lineCredit of linesCreditActive" [value]="lineCredit">
              {{ lineCredit.institutionName }}
            </mat-list-option>
          </mat-selection-list>
        </div>

        <br />

        <div class="d-flex justify-content-end">
          <button mat-raised-button class="btn" color="accent" type="button" (click)="onAddNewLinhaCredito()"
            [disabled]="linhasCredAtivosSelecionados.selectedOptions.selected.length === 0" *ngIf="!isAddingCreditLine">
            <mat-icon>add</mat-icon> &nbsp;Adicionar
          </button>

          <button mat-button type="button" readonly *ngIf="isAddingCreditLine">
            <mat-spinner [diameter]="30" color="accent"></mat-spinner>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <br />

  <mat-accordion class="example-headers-align" *ngIf="opportunities?.length > 0">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Fechar linhas de crédito </mat-panel-title>
        <mat-panel-description>
          <mat-label>Fechar linhas de crédito do usuário</mat-label>
          <mat-icon>add_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="closeOppsFormGroup" class="d-flex flex-column" (submit)="onCloseOpportunities()">
        <mat-form-field class="flex-fill" color="primary">
          <mat-label>Fase menor ou igual a:</mat-label>
          <mat-select formControlName="stageCtrl" required>
            <div *ngFor="let stageOpportunity of opportunityStepsOptions">
              <mat-option *ngIf="stageOpportunity.defaultprobability !== 100"
                [value]="stageOpportunity.defaultprobability">
                {{ stageOpportunity.defaultprobabilitystr + ' - ' + stageOpportunity.name }}
              </mat-option>
            </div>
          </mat-select>
          <mat-error> Informe a fase desejada. </mat-error>
        </mat-form-field>

        <button class="d-flex justify-content-end" mat-raised-button
          [disabled]="runCloseOpps || closeOppsFormGroup.invalid" class="btn" color="primary" type="submit">
          <span *ngIf="!runCloseOpps"> Fechar </span>
          <mat-spinner [diameter]="20" class="mx-3 my-2" *ngIf="runCloseOpps"></mat-spinner>
        </button>
      </form>
      <div class="d-flex justify-content-end"></div>
    </mat-expansion-panel>
  </mat-accordion>
  <div *ngIf="!hasDifferentProbabilityButton">
    <br>
    <button class="buttonCredit" *ngIf="!runReprocesseRecurrence">
      <a (click)="openDialog()">Iniciar recorrência para o cliente</a>
    </button>
    <mat-spinner diameter='60' *ngIf="runReprocesseRecurrence"></mat-spinner>
  </div>

  <br />
  <mat-spinner *ngIf="runOpportunities" [diameter]="80" class="mx-auto"></mat-spinner>
  <br />
  <table mat-table multiTemplateDataRows *ngIf="dataSource?.data?.length > 0" [dataSource]="dataSource"
    class="mat-elevation-z8" matSort style="border-collapse: separate" #matSortCredit="matSort"
    matSortActive="pendingToAdm" matSortDirection="desc">
    <ng-container matColumnDef="imagem">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let operacao">
        <div *ngIf="getLogoPath(operacao)?.indexOf('http') >= 0">
          <img src="{{ getLogoPath(operacao) }}" width="30px" />
        </div>
        <div *ngIf="!getLogoPath(operacao)">
          <mat-icon>account_balance</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="institutionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Operação</th>
      <td mat-cell *matCellDef="let operacao">{{ operacao.institutionName }}</td>
    </ng-container>

    <ng-container matColumnDef="deadlineForMonth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Prazo de até</th>
      <td mat-cell *matCellDef="let operacao">
        {{ (operacao.deadlineForMonth || '') + ' mes' + (operacao.deadlineForMonth > 1 ? 'es' : '') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="guarantees">
      <th mat-header-cell mat-sort-header *matHeaderCellDef
        style="display: flex; justify-content: center; padding: 16px 0px">
        Garantia
      </th>
      <td mat-cell *matCellDef="let operacao" class="text-center">{{ operacao.guarantees }}</td>
    </ng-container>

    <ng-container matColumnDef="pendingToAdm">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let operacao">
        <mat-icon class="pendencia-element" style="vertical-align: middle" *ngIf="operacao.pendingToAdm">*</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Fases da Oportunidade</th>
      <td mat-cell *matCellDef="let operacao" (click)="$event.stopPropagation()">
        <div style="display: flex; flex-direction: row; align-items: center">
          <mat-form-field appearance="outline" style="max-width: 350px">
            <mat-select [(ngModel)]="operacao.stageOpportunity" name="stageOpportunity"
              [disabled]="isOppDisabled(operacao) || loadingOpportunity === operacao.institutionKey"
              [compareWith]="compareOppStage" (selectionChange)="onChangeStage(operacao)"
              (focus)="onFocusStage(operacao.stageOpportunity)">
              <mat-option *ngFor="let stageOpportunity of opportunityStepsOptions" [value]="stageOpportunity">
                {{ stageOpportunity.defaultprobabilitystr + ' - ' + stageOpportunity.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-spinner style="margin-left: 8px" [diameter]="20" *ngIf="loadingOpportunity === operacao.institutionKey"
            color="accent"></mat-spinner>
        </div>
        <button mat-flat-button color="accent" type="button" class="btn text-center"
          (click)="reopenOpportunity(operacao)" style="width: 100%; margin-bottom: 1em; max-width: 350px"
          *ngIf="isOppDisabled(operacao)">
          Reabrir
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="opportunityPhases" class="opp-phases-column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let operacao" (click)="getOpportunityPhases(operacao)">
        <mat-icon>info</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let operacao" [attr.colspan]="columnsCreditoPre.length">
        <div [@detailExpand]="expandedElementId === operacao.institutionId ? 'expanded' : 'collapsed'"
          [style.height]="expandedElementId === operacao.institutionId ? 'unset' : '0 !important'">
          <div *ngIf="operacao.institutionKey === 'biz_capital'" class="text-center">
            <button mat-raised-button color="accent" class="btn d-block mx-auto" (click)="integrateBizCapital()"
              [disabled]="
                !customer.agreedTerms || operacao.lineClosed || operacao.stageOpportunity.defaultprobability > 10
              ">
              Executar Integração
            </button>
          </div>

          <!-- Integration Flip -->

          <div *ngIf="operacao.institutionKey === 'flip'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('flip')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('flip')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    isProcessingIntegration ||
                    operacao.stageOpportunity.defaultprobability <= 10
                  ">
                  Verificar Solicitação
                </button>
              </div>
            </div>
          </div>

          <!-- Fim Integration Flip -->

          <!-- Integration Finanzero -->

          <div *ngIf="operacao.institutionKey === 'finanzero'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('finanzero')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
          </div>

          <!-- Fim Integration Finanzero -->

          <!-- Integração Iouu -->
          <div class="text-center" *ngIf="isProcessingIntegration">
            <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
          </div>

          <div *ngIf="operacao.institutionKey === 'iouu'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateIouu()" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>

              <div *ngIf="customer.agreedTerms && !operacao.lineClosed && operacao.registrationLink" class="col">
                <button mat-raised-button color="primary" class="btn d-block mx-auto">
                  <a href="{{ operacao.registrationLink }}" target="_blank">Acompanhar solicitação</a>
                </button>
              </div>
            </div>
          </div>

          <!-- Fim Integração Iouu -->

          <!-- Integração Bom Capital -->
          <div *ngIf="operacao.institutionKey === 'bom_capital'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('bom_capital')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração Bom Capital -->

          <!-- Integração BTG -->
          <div *ngIf="operacao.institutionKey === 'banco_btg_pactual_s_a'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('banco_btg_pactual_s_a')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração BTG -->

          <!-- Integração Tutu Digital -->
          <div *ngIf="operacao.institutionKey === 'tutu_digital'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('tutu_digital')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('tutu_digital')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    isProcessingIntegration ||
                    operacao.stageOpportunity.defaultprobability <= 10
                  ">
                  Verificar Solicitação
                </button>
              </div>
            </div>
          </div>

          <!-- Fim Integração Tutu Digital -->

          <!-- Integração Qred -->
          <div *ngIf="operacao.institutionKey === '123_qred_solucoes_financeiras_ltda'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('123_qred_solucoes_financeiras_ltda')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('123_qred_solucoes_financeiras_ltda')"
                  [disabled]="!customer.agreedTerms || operacao.lineClosed || isProcessingIntegration">
                  Verificar Status Empresa
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração Qred -->

            <!-- Integração Gyra+ -->
            <div *ngIf="operacao.institutionKey === 'gyra'" class="text-center">
              <div class="row">
                <div class="col">
                  <button mat-raised-button color="accent" class="btn d-block mx-auto"
                    (click)="saveDataAndIntegrateInstitution('gyra')" [disabled]="
                      !customer.agreedTerms ||
                      operacao.lineClosed ||
                      operacao.stageOpportunity.defaultprobability !== 10 ||
                      isProcessingIntegration
                    ">
                    Executar Integração
                  </button>
                </div>
              </div>
              <!-- <div class="row mt-2">
                <div class="col">
                  <button mat-raised-button color="accent" class="btn d-block mx-auto"
                    (click)="verifyCreditInstitutionStatus('gyra')"
                    [disabled]="!customer.agreedTerms || operacao.lineClosed || isProcessingIntegration">
                    Verificar Status Empresa
                  </button>
                </div>
              </div> -->
            </div>
            <!-- Fim Integração Gyra+ -->

          <!-- Integração Adiante -->
          <div *ngIf="operacao.institutionKey === 'adiante_sa'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateAdianteSA()" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração Adiante -->

          <!-- Integração Cora -->
          <div *ngIf="operacao.institutionKey === 'cora'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('cora')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração Cora -->

          <div *ngIf="operacao.institutionKey === 'tomatico'" class="text-center">
            <button mat-raised-button color="accent" class="btn d-block mx-auto" (click)="integrateTomatico()"
              [disabled]="
                !customer.agreedTerms || operacao.lineClosed || operacao.stageOpportunity.defaultprobability > 10
              ">
              Executar Integração
            </button>
          </div>

          <!-- Integração Nexoos -->
          <div *ngIf="operacao.institutionKey === 'nexoos'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('nexoos')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 20 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <!-- <div class="row mt-2">
              <div class="col">
                <button
                  mat-raised-button
                  color="accent"
                  class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('nexoos')"
                  [disabled]="!customer.agreedTerms || operacao.lineClosed || isProcessingIntegration"
                >
                  Verificar Status Empresa
                </button>
              </div>
            </div> -->
          </div>
          <!-- Fim Integração Nexoos -->

          <div class="integration-extra-data" *ngIf="operacao.institutionKey === 'creditas'">
            <h4 style="font-weight: 500">Integração: Dados adicionais</h4>

            <p>Para realizar a integração deste cliente com a Creditas é necessário preencher algumas informações:</p>

            <hr />

            <div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <app-form-datepicker name="birthDate" [formControl]="birthDateControl"></app-form-datepicker>
                </div>
              </div>

              <h5 *ngIf="hasSpecificGuarantee('veiculos')">Dados do veículo</h5>

              <div class="row" *ngIf="hasSpecificGuarantee('veiculos')">
                <div class="col-12 col-lg-4">
                  <mat-form-field color="accent" class="w-100">
                    <mat-select placeholder="Ano do Veículo" [(value)]="vehicle.year" name="vehicleYear" required
                      (selectionChange)="getCarManufacturers(vehicle.year)">
                      <mat-option *ngFor="let year of years" [value]="year">
                        {{ year }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-4">
                  <mat-form-field color="accent" class="w-100">
                    <mat-select placeholder="Marca do Veículo" [(value)]="vehicle.manufacturer"
                      name="vehicleManufacturer" required (selectionChange)="getCarModels()"
                      [disabled]="!this.manufacturers?.length">
                      <mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.name">
                        {{ manufacturer.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-4">
                  <mat-form-field color="accent" class="w-100">
                    <mat-select placeholder="Modelo do Veículo" [(value)]="vehicle.model" name="vehicleModel" required
                      [disabled]="!this.models?.length">
                      <mat-option *ngFor="let model of models" [value]="model">
                        {{ model.model }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <h5 *ngIf="
                  !hasSpecificGuarantee('veiculos') &&
                  (hasSpecificGuarantee('imovel-operacional') || hasSpecificGuarantee('imovel-nao-operacional'))
                " class="mt-3">
                Dados do imóvel
              </h5>

              <div class="row" *ngIf="
                  !hasSpecificGuarantee('veiculos') &&
                  (hasSpecificGuarantee('imovel-operacional') || hasSpecificGuarantee('imovel-nao-operacional'))
                ">
                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-select placeholder="Possui matrícula do imóvel?" [(value)]="realEstate.hasDeed" name="hasDeed"
                      required>
                      <mat-option *ngFor="let opt of hasDeedOptions" [value]="opt.value">
                        {{ opt.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-select placeholder="Tipo do imóvel?" [(value)]="realEstate.type" name="realEstateType"
                      required>
                      <mat-option *ngFor="let opt of realEstateTypeOptions" [value]="opt.value">
                        {{ opt.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Valor do Imóvel</mat-label>
                    <input matInput type="number" [(ngModel)]="realEstate.value" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Dívida Atual do Imóvel</mat-label>
                    <input matInput type="number" [(ngModel)]="realEstate.debt" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>CEP do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.zipCode"
                      [textMask]="{ mask: MASKS.cep.textMask, guide: false }" minlength="10" maxlength="10" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-6">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Endereço do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.address" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Número do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.number" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-4">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Bairro do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.neighborhood" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Cidade do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.city" required />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-2">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>Estado do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.state" required maxlength="2" />
                  </mat-form-field>
                </div>

                <div class="col-12 col-lg-3">
                  <mat-form-field color="primary" class="w-100">
                    <mat-label>País do Imóvel</mat-label>
                    <input matInput type="text" [(ngModel)]="realEstate.country" required />
                  </mat-form-field>
                </div>
              </div>

              <div class="text-center" *ngIf="
                  !hasSpecificGuarantee('veiculos') &&
                  !hasSpecificGuarantee('imovel-operacional') &&
                  !hasSpecificGuarantee('imovel-nao-operacional')
                ">
                Integração não disponível para este usuário. Necessário possuir uma das seguintes garantias: Veículo,
                Imóvel.
              </div>

              <mat-spinner [diameter]="40" class="mx-auto my-2" *ngIf="isProcessingIntegration"
                color="accent"></mat-spinner>

              <button mat-raised-button color="accent" class="btn d-block mx-auto" *ngIf="
                  (hasSpecificGuarantee('veiculos') ||
                    hasSpecificGuarantee('imovel-operacional') ||
                    hasSpecificGuarantee('imovel-nao-operacional')) &&
                  !isProcessingIntegration
                " (click)="saveDataAndIntegrateCreditas()"
                [disabled]="!customer.agreedTerms || !canIntegrateCreditas()">
                Executar Integração
              </button>
            </div>
          </div>

          <!-- Integração Credihome -->
          <div  class="integration-extra-data-date" *ngIf="operacao.institutionKey === 'credihome'">
            <h4 style="font-weight: 500">Integração: Dados adicionais</h4>

            <p>Para realizar a integração deste cliente com a Credihome é necessário preencher algumas informações:</p>

            <hr />

            <div class="row">
              <div class="col-12 col-lg-6">
                <app-form-datepicker name="birthDate" [formControl]="birthDateControl"></app-form-datepicker>
              </div>
            </div>
            <button mat-raised-button
            color="accent" class="btn d-block mx-auto"
          [disabled]="operacao.stageOpportunity.defaultprobability !== 10 ||
          isProcessingIntegration"
          (click)="saveDataAndIntegrateCredihome()">
    Executar Integração
  </button>
          </div>

          <!-- Integração MoneyMoney -->
          <div *ngIf="operacao.institutionKey === 'money_money_invest'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateMoneyMoney()" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('money_money_invest')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    isProcessingIntegration ||
                    operacao.stageOpportunity.defaultprobability <= 10
                  ">
                  Verificar Status Empresa
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração MoneyMoney -->

          <!-- Integração QFlash -->
          <div *ngIf="operacao.institutionKey === 'q_flash'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateQFlash()" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('q_flash')"
                  [disabled]="!customer.agreedTerms || operacao.lineClosed || isProcessingIntegration">
                  Verificar Status Empresa
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração QFlash -->

          <!-- Integração BS2 -->
          <div *ngIf="operacao.institutionKey === 'well_bsd_fomento_mercantil_limitada'" class="text-center">
            <div class="row">
              <div class="col">
                <button #bs2Bttn mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('well_bsd_fomento_mercantil_limitada')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração BS2 -->

          <!-- Integração Peak -->
          <div *ngIf="operacao.institutionKey === 'peak'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegratePeak()" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('peak')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    isProcessingIntegration ||
                    operacao.stageOpportunity.defaultprobability <= 10
                  ">
                  Verificar Solicitação
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração Peak -->

          <!-- Integração Ulend -->
          <div *ngIf="operacao.institutionKey === 'u_lend'" class="text-center">
            <div class="row">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="saveDataAndIntegrateInstitution('u_lend')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    operacao.stageOpportunity.defaultprobability !== 10 ||
                    isProcessingIntegration
                  ">
                  Executar Integração
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button mat-raised-button color="accent" class="btn d-block mx-auto"
                  (click)="verifyCreditInstitutionStatus('u_lend')" [disabled]="
                    !customer.agreedTerms ||
                    operacao.lineClosed ||
                    isProcessingIntegration ||
                    operacao.stageOpportunity.defaultprobability <= 10
                  ">
                  Verificar Solicitação
                </button>
              </div>
            </div>
          </div>
          <!-- Fim Integração Ulend -->

          <div class="mx-auto p-3" style="max-width: 600px">
            <!-- Bcredi -->

            <div class="integration-extra-data" *ngIf="operacao.institutionKey === 'novi_barigui'">
              <h4 style="font-weight: 500">Integração: Dados adicionais</h4>

              <p>Para realizar a integração deste cliente com a BCredi é necessário preencher algumas informações:</p>

              <hr />

              <div>
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <app-form-datepicker name="birthDate" [formControl]="birthDateControl"></app-form-datepicker>

                  </div>
                </div>

                <h5 *ngIf="hasSpecificGuarantee('imovel-operacional') || hasSpecificGuarantee('imovel-nao-operacional')"
                  class="mt-3">
                  Dados do imóvel
                </h5>

                <div class="row"
                  *ngIf="hasSpecificGuarantee('imovel-operacional') || hasSpecificGuarantee('imovel-nao-operacional')">
                  <div class="col-12 col-lg-6">
                    <mat-form-field color="accent" class="w-100">
                      <mat-select placeholder="Qual o tipo de imóvel para garantia?" [(value)]="bcrediRealty.type"
                        name="type" required>
                        <mat-option *ngFor="let opt of bcrediRealtyTypeOptions" [value]="opt.value">
                          {{ opt.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-6">
                    <mat-form-field color="accent" class="w-100">
                      <mat-label>CEP do Imóvel</mat-label>
                      <input matInput type="text" [(ngModel)]="bcrediRealty.warrantyZipCode"
                        [textMask]="{ mask: MASKS.cep.textMask, guide: false }" minlength="10" maxlength="10"
                        required />
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-4">
                    <mat-form-field color="accent" class="w-100">
                      <mat-label>Endereço do Imóvel</mat-label>
                      <input matInput type="text" [(ngModel)]="bcrediRealty.warrantyAddress" required />
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-4">
                    <mat-form-field color="accent" class="w-100">
                      <mat-label>Estado</mat-label>
                      <input matInput type="text" [(ngModel)]="bcrediRealty.warrantyState" required />
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-lg-4">
                    <mat-form-field color="accent" class="w-100">
                      <mat-label> Valor do Imóvel</mat-label>
                      <input matInput type="number" [(ngModel)]="bcrediRealty.value" required />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="text-center"
                *ngIf="!hasSpecificGuarantee('imovel-operacional') && !hasSpecificGuarantee('imovel-nao-operacional')">
                Integração não disponível para este usuário. Necessário possuir um Imóvel como garantia.
              </div>

              <mat-spinner [diameter]="40" class="mx-auto my-2" *ngIf="isProcessingIntegration"
                color="accent"></mat-spinner>

              <button mat-raised-button color="accent" class="btn d-block mx-auto"
                (click)="saveDataAndIntegrateBCredi()" *ngIf="
                  (hasSpecificGuarantee('imovel-operacional') || hasSpecificGuarantee('imovel-nao-operacional')) &&
                  !isProcessingIntegration
                " [disabled]="!canIntegrateBCredi()">
                Executar Integração
              </button>
            </div>

            <!--Fim Bcredi -->

            <div *ngIf="!operacao?.lineClosed && !!operacao?.lastStageChangeDate" class="text-center">
              <h5>
                Última alteração:
                {{ operacao?.lastStageChangeDate ? (operacao?.lastStageChangeDate | date: 'dd/LL/yyyy HH:mm') : '-' }}
              </h5>
            </div>
            <div *ngIf="operacao?.lineClosed && operacao?.closingDate" class="text-center">
              <h5>
                Data de fechamento:
                {{ (operacao?.closingDate) ? (viewDateClosind(operacao.closingDate) | date: 'dd/LL/yyyy HH:mm') : '-' }}
              </h5>
            </div>

            <div class="example-element-detail">
              <mat-form-field style="width: 100%">
                <mat-label>Taxa (%)</mat-label>
                <input matInput type="number" placeholder="Taxa (%)" [value]="operacao.rate" name="operacaotaxa"
                  [(ngModel)]="operacao.rate" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Prazo em meses</mat-label>
                <input matInput type="number" placeholder="Prazo em meses" [value]="operacao.deadlineForMonth"
                  name="operacaoprazo" [(ngModel)]="operacao.deadlineForMonth" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Limite Operação</mat-label>
                <input matInput placeholder="Limite Operação" type="number" [value]="operacao.limitOperation"
                  name="operacaoOperacao" [(ngModel)]="operacao.limitOperation" />
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Validade da proposta em dias</mat-label>
                <input matInput placeholder="Validade da proposta" type="number" name="proposedValidity"
                [(ngModel)]="operacao.proposedValidity" (ngModelChange)="updateValidity()" />
                
              </mat-form-field>
             
              <div *ngIf="operacao?.proposedValidity">
                <p>Validade da proposta: {{ calcValidityMessage(operacao) }}</p>
              </div>
          <!-- Exibindo o valor no template (opcional) -->
              <mat-form-field style="width: 100%">
                <textarea matInput placeholder="Descrição" [(ngModel)]="operacao.description" name="descricao"
                  matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <textarea matInput placeholder="URL Proposta" [value]="operacao.proposalLink" name="urlproposta"
                  [(ngModel)]="operacao.proposalLink"  matTextareaAutosize matAutosizeMinRows="1"
                  matAutosizeMaxRows="3">
                </textarea>
              </mat-form-field>

              <mat-form-field>
                <mat-select [(ngModel)]="operacao.usedWarranty" name="usedWarranty" placeholder="Garantia usada">
                  <mat-option *ngFor="let guarantee of guaranteesList" [value]="guarantee.name">
                    {{ guarantee.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-select [(ngModel)]="operacao.usedLine" name="usedLine" placeholder="Linha de crédito usada">
                  <mat-option *ngFor="let line of linesList" [value]="line.name">
                    {{ line.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              


              <div class="text-right">
                <button mat-raised-button color="primary" type="button" class="btn" (click)="onSaveDetalhes(operacao)"
                  [disabled]="!agreedTerms">
                  Salvar
                </button>
              </div>

              <hr style="background-color: #9e9e9e" class="mb-3" *ngIf="operacao.contract" />

              <div *ngIf="operacao.contract">
                <h4 class="my-2">Simulação enviada:</h4>
                <!-- <p>
                  <strong>Data de submissão:</strong> {{ operacao.contract?.submission?.toDate().toLocaleString() || '-' }}
                </p> -->
                <a mat-raised-button class="d-block mx-auto mt-3 mb-4" color="accent" [href]="operacao.contract.path"
                  target="_blank">
                  <mat-icon class="mr-2">cloud_download</mat-icon> Baixar simulação
                </a>
              </div>
            </div>

            <div class="notes-area my-4" *ngIf="expandedElementId === operacao.institutionId">
              <div class="d-flex flex-column flex-sm-row">
                <h3 style="font-weight: bold; flex: 1">Registros de acompanhamento</h3>

                <button mat-raised-button color="primary" class="btn" (click)="isBankerNoteCreateMode = true">
                  <mat-icon>post_add</mat-icon> Novo Registro
                </button>
              </div>

              <hr style="background-color: #9e9e9e" class="mb-3" />

              <div *ngIf="isBankerNoteCreateMode">
                <mat-card class="p-3">
                  <mat-card-content>
                    <h3 style="font-weight: 500">Novo Registro</h3>

                    <mat-form-field class="w-100" color="accent">
                      <textarea matInput placeholder="Descrição" mat-autosize matAutosizeMinRows="5"
                        [(ngModel)]="newBankerNote.message" required></textarea>
                    </mat-form-field>

                    <div class="text-right">
                      <button mat-raised-button type="button" (click)="cancelBankerNote()" class="mx-2 btn">
                        <mat-icon>clear</mat-icon> Cancelar
                      </button>

                      <button mat-raised-button color="accent" type="button" [disabled]="!newBankerNote.message || !operacao.institutionName"
                        (click)="createBankerNote(operacao.institutionName)" class="mx-2 btn">
                        <mat-icon>save</mat-icon> Salvar
                      </button>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <div class="text-center" *ngIf="isLoadingBankerNotes">
                <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
              </div>

              <div *ngIf="!isLoadingBankerNotes">
                <p class="p-4 m-3 text-center" *ngIf="this.bankerNotes?.length === 0">Ainda não há nenhum registro.</p>

                <p class="mb-2 mt-4" style="font-weight: 500" *ngIf="this.bankerNotes?.length > 0">
                  Últimos registros:
                </p>

                <mat-card *ngFor="let note of bankerNotes; let i = index" class="my-2 note"
                  [class.admin-note]="note.type === 'ADMIN'">
                  <mat-card-content class="p-3">
                    <div style="white-space: pre-line">
                      {{ note.message }}
                    </div>
                    <div *ngIf="note.attachments?.length">
                      <a style="color: blue" href="{{ attachment.contentUrl }}"
                        *ngFor="let attachment of note.attachments">{{ attachment.fileName }}</a>
                    </div>

                    <mat-card-footer class="text-right p-3">
                      <span class="date-label">{{ note.createdBy?.name }} -
                        {{ note?.createdAt ? (note.createdAt?.toDate() | date: 'dd/MM/yyyy HH:mm:ss') : '-' }}
                        -
                        {{ note.type === 'ADMIN' ? '(Admin)' : note.banker }}
                      </span>
                      <button (click)="forwardNote(note.message, 'BANKER')" mat-icon-button
                        matTooltip="Encaminhar mensagem">
                        <mat-icon>forward</mat-icon>
                      </button>
                    </mat-card-footer>
                  </mat-card-content>
                </mat-card>

                <div class="text-center mt-3" *ngIf="this.bankerNotes?.length > 0">
                  <button mat-raised-button class="btn" color="accent" type="button" (click)="viewMoreBankerNotes()">
                    Ver mais <mat-icon>open_in_new</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <app-customer-contact [opportunity]="operacao">

            </app-customer-contact>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsCreditoPre"></tr>
    <tr mat-row *matRowDef="let operacao; columns: columnsCreditoPre" class="example-element-row taskboard-task"
      [class.example-expanded-row]="
        expandedElementId && operacao.instituicaoFinanceiraId && expandedElementId === operacao.instituicaoFinanceiraId
      " (click)="expandedElement = expandedElement === operacao ? null : operacao; onClickExpanded()"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <app-opportunity-history-button-list [groupHistory]="groupOpps" (selectGroup)="onChangeGroupSelected($event)" ></app-opportunity-history-button-list>
</div>
