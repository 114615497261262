import { timestampForDate } from '../../../../functions/src/utils/dates';

interface UserAtribuited {
  uid: string;
  name: string;
  email: string;

}
;

enum AttributedAction { 'attributed'= 'attributed', 'desattributed' = 'desattributed' };

export class ChatAttributedEntity implements ChatAttributedInterface {
  createdAt: Date | string | firebase.firestore.Timestamp;
  action: AttributedAction;
  atribuitedTo?: UserAtribuited;
  desatribuitedTo?: UserAtribuited;
  atribuitedBy: UserAtribuited;

  constructor() {
    this.createdAt = new Date();
    this.action = null;
    this.atribuitedTo = null;
    this.desatribuitedTo = null;
    this.atribuitedBy = null;
  }

  static firestoreConverter = {
    toFirestore: (entityData: ChatAttributedEntity): Partial<ChatAttributedInterface> => {
      const returnData: Partial<ChatAttributedInterface> = {};
      returnData.action = entityData.action;
      returnData.atribuitedBy = entityData.atribuitedBy;
      returnData.desatribuitedTo = entityData.desatribuitedTo;
      returnData.atribuitedTo = entityData.atribuitedTo;
      returnData.createdAt = entityData.createdAt;
      return returnData;
    },
    fromFirestore: (snapshot): ChatAttributedEntity => {
      const snapshotData = snapshot.data();
      if (snapshotData?.createdAt?.nanoseconds) snapshotData.createdAt = timestampForDate(snapshotData.createdAt);
      return Object.assign(new ChatAttributedEntity(), snapshotData);
    },
  };

  static generateChatAttributed(atribuitedBy: UserAtribuited, options: { desatribuitedTo?: UserAtribuited; atribuitedTo?: UserAtribuited }): ChatAttributedEntity {
    const atribuitedItem = new ChatAttributedEntity();
    atribuitedItem.atribuitedBy = atribuitedBy;
    if (options.atribuitedTo) {
      atribuitedItem.action = AttributedAction.attributed
      atribuitedItem.atribuitedTo = options.atribuitedTo;
    };

    if (options.desatribuitedTo) {
      atribuitedItem.action = AttributedAction.desattributed;
      atribuitedItem.desatribuitedTo = options.desatribuitedTo;
    };

    return atribuitedItem;
  }
}

export interface ChatAttributedInterface {
  createdAt: Date | string | firebase.firestore.Timestamp;
  action: AttributedAction;
  atribuitedTo?: UserAtribuited; // admin atribuido
  desatribuitedTo?: UserAtribuited; // admin que desatribuido
  atribuitedBy: UserAtribuited; // admin que atribuiu
}
