import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../functions/src/models/Customer';
import { CustomerScreeningModalComponent } from '../components/customer-screening-modal/customer-screening-modal.component';
import { ScreeningInstitution } from '../instituicao-financeira/instituicao-financeira';
import { InstituicaoFinanceiraService } from '../instituicao-financeira/instituicao-financeira.service';
import { StatsService } from '../services/stats.service';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-customer-screening',
  templateUrl: './customer-screening.component.html',
  styleUrls: ['./customer-screening.component.scss']
})
export class CustomerScreeningComponent implements OnInit, OnDestroy {

  screeningInstitutions: ScreeningInstitution[];
  institutionSelected: ScreeningInstitution[];
  customersData: any[] = [];
  selectedCustomers: Customer[] = [];

  displayedColumns: string[] = [
    'checkbox',
    'displayName',
    'email',
    'actions',
  ];
  dataSource: MatTableDataSource<any>;

  financialInstitutionSubscription: Subscription;
  userSubscription: Subscription;
  isLoadingCustomers = false;

  constructor(private financialInstitutionService: InstituicaoFinanceiraService, private statsService: StatsService, private userService: UserManagementService, private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.getInstitutions();
  }

  ngOnDestroy(): void {
    if(this.financialInstitutionSubscription) {
      this.financialInstitutionSubscription.unsubscribe();
    }
    if(this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  getInstitutions() {
    this.financialInstitutionSubscription = this.financialInstitutionService.getScreeningInstitutions().subscribe((result) => {
      this.screeningInstitutions = result;
    });
  }

  getScreeningInstitution(institution: string) {
    this.financialInstitutionSubscription = this.financialInstitutionService.getScreeningInstitutionSelected(institution).subscribe((result) => {
      this.institutionSelected = result;
    });
  }

  // SUBSTITUIR O MÉTODO CRIADO NO USER SERVICE (GETCUSTOMERSTESTE) QUANDO HOUVER O MÉTODO REAL
  getCustomerData(response: any) {
    // Use a resposta da API (response) para fazer algo aqui
    this.customersData = response; // Suponhamos que a resposta seja um array de dados de cliente
    this.dataSource = new MatTableDataSource(this.customersData);

    this.isLoadingCustomers = false;
  }

  openModal(customer: Customer): void {
    this.dialog.open(CustomerScreeningModalComponent, {
      data: customer
    });
  }

  async getCustomerScreening(institution): Promise<void> {
    const filter = institution;
    this.isLoadingCustomers = true
    try {
      const response = await this.statsService.getOpportunitiesScreening(filter);
      // Chame a função getCustomerData e passe a resposta como argumento
      this.getCustomerData(response);
      
    } catch (error) {
      console.error('Erro na função de teste:', error);
      // Trate o erro, se necessário
    }


  }
  
  

  exportList(): void {
    if (this.selectedCustomers.length) {
      const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // Tipo para XLSX
      const extension = 'xlsx'; // Extensão do arquivo
  
      // Mapeamento de nomes de campos
      const fieldMapping = {
        companyName: 'Razão Social (obrigatório)',
        cnpj: 'CNPJ (obrigatório)',
        revenue: 'Faturamento Anual (obrigatório)',
        displayName: 'Nome 1º Contato - Consultor ou Parceiro (obrigatório)',
        email: 'Email 1º Contato - Consultor ou Parceiro (obrigatório)',
        whatsapp: 'Telefone 1º Contato - Consultor ou Parceiro (obrigatório)',
        displayName2: 'Nome do Cliente (opcional)',
        email2: 'E-mail do Cliente (opcional)',
        whatsapp2: 'Telefone do Cliente (opcional)',
        creditRequested: 'Crédito Solicitado pelo Cliente (opcional)',
        openField: 'Campo Aberto (Observação - opcional)',
        data: 'Data (obrigatório)',
        parceiro: 'Parceiro (Não Alterar)',
        orderId: 'Order ID (opcional)',
      };
      
      
  
      const selectedCustomerData = this.selectedCustomers.map((customer) => {
        const filteredData = {};
        const fieldsToInclude = Object.keys(fieldMapping);
        for (const field of fieldsToInclude) {
          if (field === 'data') {
            // Adicione a data atual
            filteredData[fieldMapping[field]] = new Date().toLocaleDateString();
          } else if (field === 'parceiro') {
            // Defina o valor do campo "Parceiro"
            filteredData[fieldMapping[field]] = 'Capital Empreendedor';
          } else if (field === 'orderId') {
            // Defina o campo "Order ID" como vazio
            filteredData[fieldMapping[field]] = '';
          } else if (field.endsWith("2")) {
            // Preencha os novos campos com valores padrão se estiverem vazios ou nulos
            const originalField = field.slice(0, -1); // Remova o '2' do campo
            filteredData[fieldMapping[field]] = customer[originalField] || 'Valor padrão';
          } else if (field === 'creditRequested') {
            // Adicione o valor de 'debtStr' como 'Crédito Solicitado pelo Cliente (opcional)'
            filteredData[fieldMapping[field]] = customer.debtStr || 'Valor padrão';
          } else if (field === 'openField') {
            // Defina o valor do campo "Campo Aberto (Observação - opcional)" como vazio
            filteredData[fieldMapping[field]] = '';
          } else {
            const fieldSegments = field.split('.');
            let fieldValue = customer;
            for (const segment of fieldSegments) {
              if (fieldValue && fieldValue.hasOwnProperty(segment)) {
                fieldValue = fieldValue[segment];
              } else {
                fieldValue = null;
                break;
              }
            }
            filteredData[fieldMapping[field]] = fieldValue;
          }
        }
      
        return filteredData;
      });
      
      
  
      const blob = new Blob([this.statsService.generateXLSX(selectedCustomerData)], { type });
  
      const date = new Date().toLocaleDateString();
      saveAs(blob, `Planilha de envio de leads_${date}.${extension}`.toLowerCase().replace(/\s/g, '-'));
    }
  }
  
  
  toggleCustomerSelection(customer: Customer): void {
    const index = this.selectedCustomers.indexOf(customer);

    if (index >= 0) {
      this.selectedCustomers.splice(index, 1); // Remove o cliente selecionado
    } else {
      this.selectedCustomers.push(customer); // Adiciona o cliente à lista de selecionados
    }
  }
}
