import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'app-agent-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class AgentSignUpComponent implements OnInit, OnDestroy {
  readonly MASKS = MASKS;
  readonly urlPrivacyPolicy =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Privacidade%20-%20Capital%20Empreendedor.pdf';

  createAgentFormGroup: FormGroup;
  isProcessing = true;
  CESource: string;

  formatPhone = UtilHandler.formatPhone;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private agentService: AgentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private logger: LogService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.tagOrigin();
    this.agentService.redirectAccordingStatus(false, '/agents/signup', '/agents/signup');

    const passwordMatchValidator = (formGroup: FormGroup): void => {
      const error =
        formGroup.get('passwordCtrl').value === formGroup.get('confirmCtrl').value ? null : { mismatch: true };
      formGroup.get('confirmCtrl').setErrors(error);
    };

    this.createAgentFormGroup = this.formBuilder.group(
      {
        agreedPrivacyPolicyCtrl: new FormControl(false, [Validators.required]),
        nameCtrl: new FormControl('', Validators.required),
        emailCtrl: new FormControl('', [Validators.required, Validators.email]),
        phoneCtrl: new FormControl('', [Validators.required, NgBrazilValidators.telefone]),
        CNPJCtrl: new FormControl('', [NgBrazilValidators.cnpj]),
        linkedinCtrl: new FormControl('', Validators.required),
        passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmCtrl: new FormControl('', []),
      },
      { validator: passwordMatchValidator }
    );

    this.isProcessing = false;
  }

  changeCheckBoxValue(): void {
    this.createAgentFormGroup.controls.agreedPrivacyPolicyCtrl.setValue(
      !this.createAgentFormGroup.controls.agreedPrivacyPolicyCtrl.value
    );
  }

  ngOnDestroy(): void {}

  createAgent(): void {
    // analyticsReportConversion('assessores', 'cadastro');

    this.isProcessing = true;

    const account = {
      email: this.createAgentFormGroup.value.emailCtrl.trim().toLowerCase(),
      name: this.createAgentFormGroup.value.nameCtrl.trim().toUpperCase(),
      password: this.createAgentFormGroup.value.passwordCtrl,
      phone: this.createAgentFormGroup.value.phoneCtrl.trim(),
      cnpj: this.createAgentFormGroup.value.CNPJCtrl.trim(),
      linkedin: this.createAgentFormGroup.value.linkedinCtrl.trim(),
      uid: firebase.auth().currentUser.uid,
      source: this.CESource || 'organico',
      userConsent: {
        privacyPolicyDate: this.createAgentFormGroup.value.agreedPrivacyPolicyCtrl
          ? firebase.firestore.Timestamp.now().toMillis()
          : null,
      },
    };

    this.agentService
      .createAgent(account)
      .then((res) => {
        if (res === 'success') {
          // Realizar o login e o envio do e-mail de verificação de conta.
          this.authService
            .loginWithEmailAndPassword(account.email, account.password)
            .then(async () => {
              try {
                await this.authService.sendEmailVerification();
              } finally {
                console.log('User logged', account.email);
                this.isProcessing = false;
                this.router.navigate(['/agents/profile']);
              }
            })
            .catch((err) => {
              console.error('Error logging in user', err);

              const errorSubcription = this.showMessage(
                'Erro no login',
                `Seu usuário foi criado com sucesso, mas houve um problema ao tentar realizar seu login automaticamente.`
              )
                .afterClosed()
                .subscribe(() => {
                  this.isProcessing = false;
                  if (errorSubcription) {
                    errorSubcription.unsubscribe();
                  }
                  this.router.navigate(['/entrar']);
                });
            });
        } else if (res === 'email-in-use') {
          this.showMessage(
            'E-mail em uso',
            `O e-mail ${account.email} já está em uso. Favor entrar em contato através do e-mail <strong>ops@capitalempreendedor.com.br</strong> informando o ocorrido.`
          );

          this.logger.error('Agent tried to signup with an existing e-mail.', account.email);
          this.isProcessing = false;
        } else if (res === 'existent-cnpj') {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'CNPJ em uso',
              alertDescription: `O CNPJ ${account.cnpj} já está em uso. Favor entrar em contato através do e-mail <strong>ops@capitalempreendedor.com.br</strong> informando o ocorrido.`,
              isOnlyConfirm: true,
            },
          });

          this.logger.error('Agent tried to signup with an existing CNPJ.', account.email);
          this.isProcessing = false;
        } else {
          this.logger.error('Cannot create Agent - Unknown error', account.email);
          this.isProcessing = false;
        }
      })
      .catch((err) => {
        // Erro na inclusão dos dados do usuário ou na criação da sua autenticação.
        this.showMessage(
          'Erro no cadastro',
          `Houve um problema ao tentar criar seu usuário. Por favor, tente novamente mais tarde.`
        );

        this.logger.error('Cannot create Agent - Error', err);
        this.isProcessing = false;
      });
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

  tagOrigin(): void {
    this.CESource =
      this.activatedRoute.snapshot.queryParams.utm_source ||
      this.activatedRoute.snapshot.queryParams.ce_source ||
      'organico';
  }

  getCESourceValue(): { ce_source: string; utm_source: string } {
    // so passa a tag ce_source se ela nao for a padrao
    return this.CESource && this.CESource !== 'organico'
      ? { ce_source: this.CESource, utm_source: this.CESource }
      : null;
  }
}
