<button mat-flat-button class="border" *ngIf="!agent || agent.isAnonymous" (click)="onLoginClick()">Entrar</button>

<a
  *ngIf="agent && !agent.isAnonymous"
  mat-icon-button
  style="margin-right: 0.5em"
  class="help-button"
  href="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/manuais%2Fmanual-assessores.pdf?alt=media"
  target="_blank"
>
  <mat-icon>help</mat-icon>
</a>
<button *ngIf="agent && !agent.isAnonymous" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu" class="m-r-5">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #toolbarUserMenu="matMenu">
  <button mat-menu-item disabled style="height: auto">
    <span class="d-flex alignt-items-center accent-text-color" style="line-height: 2.5em">
      <span class="d-inline-block" style="width: 1em; height: 1em; margin-right: 1em">
        <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon>
      </span>
      <span class="d-inline-block">{{ !!agent && !!agent.name ? agent.name.split(' ')[0] : 'Anonymous' }}</span>
    </span>
    <span class="d-block" style="font-size: 0.85em; line-height: 1.5em; margin-bottom: 1em">{{
      !!agent ? agent.email : ''
    }}</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/agents/profile">
    <mat-icon fontSet="fas" fontIcon="fa-user-cog"></mat-icon>
    <span>Minha Conta</span>
  </button>
  <a
    mat-menu-item
    href="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/manuais%2Fmanual-assessores.pdf?alt=media"
    target="_blank"
  >
    <mat-icon fontSet="fas" fontIcon="fa-question-circle"></mat-icon>
    <span>Manual do Assessor</span>
  </a>
  <a mat-menu-item href="https://feedback.userreport.com/24af9245-31c3-40fe-9744-032b351a68e2/" target="_blank">
    <mat-icon fontSet="fas" fontIcon="fa-comment-alt"></mat-icon>
    <span>Feedbacks e Sugestões</span>
  </a>
  <button mat-menu-item (click)="onLogoutClick()">
    <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
    <span>Sair</span>
  </button>
</mat-menu>
