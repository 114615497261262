import { environment } from 'src/environments/environment';

declare function gtag_report_conversion_event(category: string, action: string, label: string, value: any): any;

export const analyticsReportConversion = (category: string, action: string, label = '', value = ''): void => {
  if (environment.envName === 'PROD') {
    gtag_report_conversion_event(category, action, label, value);
  } else {
    console.warn('Conversion not reported due the environment in use');
  }
};
