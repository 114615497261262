export class UtilHandler {
  public static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static getFormattedPrice(
    price: number,
    options?: {
      removeCents: boolean;
    }
  ): string {
    if (!price || isNaN(price)) {
      return options?.removeCents ? 'R$ 0' : 'R$ 0,00';
    } else {
      const normalPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
      return options?.removeCents ? normalPrice.slice(0, -3) : normalPrice;
    }
  }

  public static formatPhone(phone: string): (string | RegExp)[] {
    if (phone.replace(/([^\d])+/gim, '').length < 11) {
      return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }
  public static formatCnae(cnae: string): (string | RegExp)[] {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }

  public static getSituacaoUsuario(dataUserDocuments, detailUserData) {
    let situacaoUsuario = <any>{};
    // sem pendencias de documentos
    const f1 = dataUserDocuments.documentos.filter((a) => a.situacao !== 'Envio Completo' && a.situacao !== 'Aprovado');
    // recupera todas as oportunidades abertas
    const f2 = detailUserData.listCreditoPreAprovado.filter((a) => a.linhaAtiva && !a.linhaFechada);
    // recupera todas as oportunidades que estão não fechadas
    const f3 = detailUserData.listCreditoPreAprovado.filter((a) => !a.linhaFechada);
    if (f1 && f1.length === 0) {
      // Não há documentos pendentes de envio.
      if (f2 && f2.length > 0) {
        // Existem oportunidades de crédito abertas e não há documentos pendentes.
        situacaoUsuario = { id: 'oportunidade-aberta', name: 'Oportunidade aberta' };
      } else if (f3 && f3.length === 0) {
        // Não existem linhas abertas, documentos pendentes e todas as linhas estão fechadas
        situacaoUsuario = { id: 'operacao-fechada', name: 'Operação de crédito concluída' };
      } else {
        // Não exitem oportunidades abertas e nem documentos pendentes.
        situacaoUsuario = { id: 'sem-pendencia-oportunidade', name: 'Sem pendências e oportunidades' };
      }
    } else {
      // Existem documentos pendentes independentes das situações das oportunidades
      situacaoUsuario = { id: 'documentos-pendentes', name: 'Documentos pendentes' };
    }

    return situacaoUsuario;
  }

  public static getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();

    if (agent.indexOf('edge') > -1) {
      return 'edge';
    } else if (agent.indexOf('opr') > -1 && !!(window as any).opr) {
      return 'opera';
    } else if (agent.indexOf('chrome') > -1 && !!(window as any).chrome) {
      return 'chrome';
    } else if (agent.indexOf('trident') > -1) {
      return 'ie';
    } else if (agent.indexOf('firefox') > -1) {
      return 'firefox';
    } else if (agent.indexOf('safari') > -1) {
      return 'safari';
    } else {
      return 'other';
    }
  }
}
