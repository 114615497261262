import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { timestampForDate } from '../../../../../functions/src/utils/dates';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements AfterViewInit, OnDestroy {
  @Input() message: any;
  audioPlaying = false;
  currentTime = 0;
  duration = 0;
  durationInitial = 0;
  @ViewChild('audioElement') audioElement: ElementRef;

  ngAfterViewInit(): void {
    const audio = this.audioElement.nativeElement as HTMLAudioElement;
    audio.addEventListener('loadedmetadata', () => {
      this.durationInitial = audio.duration;
    });
  }

  ngOnDestroy(): void {
    const audio = this.audioElement.nativeElement as HTMLAudioElement;
    audio.removeEventListener('loadedmetadata', () => {
      this.durationInitial = audio.duration;
    });
  }

  formatDate(date: any): string {
    if (date) {
      return new Date(timestampForDate(date)).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    } else {
      return '';
    }
  }

  toggleAudio(): void {
    const audio = this.audioElement.nativeElement as HTMLAudioElement;

    if (this.audioPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    this.audioPlaying = !this.audioPlaying;
  }

  updateTime(event: Event): void {
    const audio = event.target as HTMLAudioElement;
    this.currentTime = audio.currentTime;
    this.duration = audio.duration;
  }

  formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  seekTimeAudio(event: MouseEvent): void {
    const audio = this.audioElement.nativeElement as HTMLAudioElement;
    const progressBar = event.currentTarget as HTMLDivElement;
    const rect = progressBar.getBoundingClientRect();
    const totalWidth = rect.width;
    const clickX = event.clientX - rect.left;
    const seekTime = (clickX / totalWidth) * this.duration;

    audio.currentTime = seekTime;
  }

}
