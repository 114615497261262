import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContactsCustomer } from '../../models/contact';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss',]
})
export class ContactItemComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() contact: ContactsCustomer;
  @Input() isToday = false;
  messageSubscription: Subscription;
  runCountMessage = true;
  section: string;
  constructor(private router: Router) {

  }

  ngOnInit(): void {
    this.verifyRoute()
  }


  async onContactSelected(contact: ContactsCustomer): Promise<void> {
    const id = contact.cnpj.replace(/\D/g, '');
    this.router.navigate([`/admin/chat-sce/${ this.section }/cliente/${ id }`]);
  }

  verifyRoute(): void {
    const url = this.router.url;
    const menu = ['sem-atribuicao', 'atribuidos-a-mim', 'contatos', 'colaborador/' + this.contact.attributedTo];
    console.log(url)
    if (menu) {
      menu.forEach(m => {
        if (url.includes(`/${ m }/`)) {
          this.section = m;
        }
      })
    }

  }

}
