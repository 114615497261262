<div class="container">
  <mat-card *ngIf="loading || !banker">
    <mat-card-content>
      <section class="my-6 text-center">
        <mat-spinner [diameter]="60" color="accent" class="d-inline-block mx-3"></mat-spinner>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loading && banker && !banker.isActive">
    <mat-card-content>
      <mat-card-title> Ops... </mat-card-title>
      <section class="mt-4">
        Infelizmente seu cadastro foi suspenso pela nossa equipe. Caso tenha alguma dúvida, entre em contato através do
        e-mail <strong>ops@capitalempreendedor.com.br</strong>.
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loading && banker && banker.isActive && !banker.changedPassword">
    <mat-card-content>
      <p class="my-2">
        <strong>Atenção:</strong> Você só poderá acessar as demais funcionalidades quando alterar sua senha.
      </p>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!loading && banker && banker.isActive">
    <app-banker-settings [banker]="banker"></app-banker-settings>
  </div>
</div>
