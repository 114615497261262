<div class="main-container">
  <div class="toolbar">
    <mat-toolbar color="primary">
      <button class="open-sidenav--button" (click)="toggle()"><mat-icon>menu</mat-icon></button>
      <a routerLink="/" class="branding d-inline d-md-none">
        <img [src]="logo"/>
      </a>
      <a routerLink="/" class="branding d-none d-md-inline" style="color: #fff">
        <img [src]="logoFull"/>
      </a>
      <mat-chip-list aria-label="Environment" class="d-inline-block spacer" *ngIf="!isProd">
        <mat-chip color="accent" selected class="ml-4">
          <span>Admin</span>
        </mat-chip>
        <mat-chip class="ml-4">
          <span>{{ envLabel }}</span>
        </mat-chip>
      </mat-chip-list>
      <div class="d-inline-block spacer" *ngIf="isProd"></div>
      <app-header></app-header>
    </mat-toolbar>
  </div>

  <div class="wrapper">
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
      <mat-sidenav
        #snav
        id="snav"
        class="dark-sidebar pl-xs"
        [mode]="mobileQuery.matches ? 'side' : 'over'"
        style="width: 300px"
        fixedTopGap="0"
        [opened]="toggled"
        [disableClose]="true"
        [perfectScrollbar]="config"
      >
        <app-sidebar class="app-sidebar"></app-sidebar>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet #o="outlet"></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>

      <div class="footer" *ngIf="!divDisabled">
      <div class="row">
        <div class="col-12">
          <!-- <div class="row">
            <div class="col-12 links">
              <a href="https://www.capitalempreendedor.com.br/blog/" target="_blank">
                <mat-icon fontSet="fab" fontIcon="fa-blogger"></mat-icon>
                <span>Blog</span>
              </a>
              <a href="https://linkedin.com/company/capital-empreendedor/" target="_blank">
                <mat-icon fontSet="fab" fontIcon="fa-linkedin"></mat-icon>
                <span>Linkedin</span>
              </a>
              <a href="https://youtube.com/channel/UCUxRzepIJw7IogNnhY_TfwA" target="_blank">
                <mat-icon fontSet="fab" fontIcon="fa-youtube"></mat-icon>
                <span>Youtube</span>
              </a>
              <a href="https://facebook.com/capitalempreededor/" target="_blank">
                <mat-icon fontSet="fab" fontIcon="fa-facebook"></mat-icon>
                <span>Facebook</span>
              </a>
              <a href="https://twitter.com/CapEmpreendedor" target="_blank">
                <mat-icon fontSet="fab" fontIcon="fa-twitter"></mat-icon>
                <span>Twitter</span>
              </a>
            </div>
          </div> -->
          <div class="flex" style="display: flex; flex-direction: row; justify-content: space-between;">
            <ng-container ></ng-container>
            
            <div class="col signature">
              <strong>v.{{ version }}</strong> - &#169; <span class="year">{{ year }}</span> - Capital Empreendedor -
              CNPJ: 21.261.858/0001-80
              <!-- Rua Dr Antonio Galizia, 181, 6. Andar, Edificio Trevizzo, Campinas, SP -->
            </div>

            <ng-container > <img width="160px" src="../../../../assets/images/Parceiro BNDES.svg" alt=""> </ng-container>
          </div>
        </div>
        <!-- <div class="col-12 col-lg-5 d-flex align-items-center justify-content-center">
          <div class="col-6 col-md-3 col-lg-6 signature py-1">
            <a href="https://www.mastermindscapital.com/privacy-policy" target="_blank" style="position: initial">
              Política de privacidade</a
            >
          </div>
          <div class="col-6 col-md-3 col-lg-6 signature py-1">
            <a href="/duvidas-frequentes" target="_blank" style="position: initial"> Dúvidas Frequentes</a>
          </div>
          <div class="col-6 col-md-3 col-lg-6 signature py-1">
            <a routerLink="/agents" style="position: initial;">
              Área de Assessores</a>
          </div>
          <div class="col-6 col-md-3 col-lg-6 signature py-1">
            <a routerLink="/bankers" style="position: initial;">
              Área de Operadores</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
