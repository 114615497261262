
<mat-card>
  <mat-card-content class="card-name">
      <div>
        <h2>{{customer?.companyName}}</h2>
      <span>Razão social da empresa </span>
    </div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content class="card-name">
      <h3>Informações pessoais</h3>
      <div class="info-section">
          <div class="info-item">
              <p>Nome completo</p>
              <span >{{customer?.name || '-'}}</span>
              <p>Telefone</p>
              <span >{{customer?.telefone || '-'}}</span>
          </div>
          <div class="info-item">
              <p>Whatsapp</p>
              <span >{{customer?.whatsapp || '-'}}</span>
              <p>Email</p>
              <span >{{customer?.email || '-'}}</span>
          </div>
      </div>
  </mat-card-content>
</mat-card>

<div class="text-wrapper">
  <p class="text">Algum dado está incorreto? Entre em contato para corrigir a situação:</p>
  <a mat-flat-button  class="action-button" type="button" routerLink="/mensagens">Fale conosco</a>
</div>