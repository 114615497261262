import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tree-child',
  templateUrl: './tree-child.component.html',
  styleUrls: ['./tree-child.component.scss'],
})
export class TreeChildComponent implements OnInit {
  @Input() public data: any;
  @Input() public label: string;

  constructor() {}

  ngOnInit(): void {}
}
