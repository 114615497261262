import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { User } from 'functions/src/models/User';
import _moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserData } from 'src/app/customer/containers/custom-email-actions/custom-email-actions';
import { environment } from 'src/environments/environment';
import { DocControleVencimento } from '../../../../functions/src/models/UserDocument';

@Injectable({
  providedIn: 'root',
})
export class CustomEmailActionsService {
  userData: Observable<UserData>;

  constructor(
    private angularFirestore: AngularFirestore,
    private http: HttpClient,
    private logger: LogService,
    private authService: AuthService
  ) {
    this.logger.controllerName = this.constructor.name;
  }

  async getUserByChaveCancelamento(chaveCancelamento: string) {
    const userRef = this.angularFirestore.collection('users', (x) =>
      x.where('chaveCancelamento', '==', chaveCancelamento)
    );
    const result = await userRef.get().toPromise();
    if (result.docs.length > 0) {
      const userData = result.docs[0].data();
      // Verificar se essa operação já foi realizada alguma vez.
      if (userData.permiteCancelamento) {
        // Verificar se está dentro do tempo de cancelar o termo de serviço.
        const datetimeNow = _moment();
        const datetime24h = _moment(userData.datetimeAceite.toDate()).add(24, 'hours');
        if (datetimeNow < datetime24h) {
          return userData;
        } else {
          this.angularFirestore.doc(`users/${userData.email}`).update({ permiteCancelamento: false });
        }
      } else {
        if (userData.permiteCancelamento === undefined || userData.permiteCancelamento === null) {
          this.angularFirestore.doc(`users/${userData.email}`).update({ permiteCancelamento: false });
        }
      }
    }
  }

  async updateUser(userData: User) {
    const userRef = this.angularFirestore.firestore.collection(`users`).doc(userData.email);
    const userDocumentsRef = this.angularFirestore.firestore.collection(`user-documents`).doc(userData.email);
    const userContractRef = this.angularFirestore.firestore.collection(`contrato`).doc(userData.email);
    try {
      const resultTrans = await this.angularFirestore.firestore.runTransaction(async (transaction) => {
        await transaction.get(userRef);
        transaction.set(
          userRef,
          {
            concordaComTermos: userData.concordaComTermos,
            motivoCancelamento: userData.motivoCancelamento,
            permiteCancelamento: userData.permiteCancelamento,
            datetimeCancelamento: userData.datetimeCancelamento,
            situacao: userData.situacao,
          },
          { merge: true }
        );
        if (!userData.concordaComTermos) {
          transaction.delete(userDocumentsRef);
          transaction.set(userContractRef, { htmlContrato: null }, { merge: true });
          this.getControleVencimento(userData.email).then((controlVenc) => {
            if (controlVenc.length > 0) {
              controlVenc.forEach((item) => {
                this.deleteControleVencimento(item['id']);
              });
            }
          });
        }
        this.logger.log('Transação concluida');
      });
      return resultTrans;
    } catch (err) {
      this.logger.error('Problemas na transação', err);
    }
  }

  async getControleVencimento(email: string): Promise<any> {
    const collectionRef = this.angularFirestore.collection<DocControleVencimento>('user-doc-controle-vencimento', (x) =>
      x.where('email', '==', email)
    );
    const dadosControleVencimento = collectionRef.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as DocControleVencimento;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    try {
      return new Promise((resolve, reject) => {
        dadosControleVencimento.subscribe((dadosExistentes) => {
          if (dadosExistentes) {
            resolve(dadosExistentes);
          } else {
            resolve(null);
          }
        });
      });
    } catch (e) {
      this.logger.error('Problemas ao buscar os controle de vencimento de um usuário', email, e);
    }
  }

  deleteControleVencimento(docId: string) {
    return this.angularFirestore.collection('user-doc-controle-vencimento').doc(docId).delete();
  }

  async getUserByEmail(userEmail: string) {
    const result = await this.angularFirestore.doc(`users/${userEmail}`).get().toPromise();
    return result;
  }

  async updateUserSolicitacaoCadastro(userEmail: string) {
    try {
      await this.angularFirestore.doc(`users/${userEmail}`).update({
        estaAtivo: true,
        emailVerified: true,
        'solicitacaoCadastroAuth.situacaoSolicitacao': 'concluida',
        'solicitacaoCadastroAuth.dataCriacaoAutenticacao': firebase.firestore.Timestamp.now(),
        situacao: {
          id: 'cadastro-completo',
          name: 'Cadastro Completo',
        },
      });
      this.logger.log('Usuário alterado com sucesso');
    } catch (e) {
      this.logger.error('Problemas ao alterar um usuário', e);
      throw e;
    }
  }

  async updateUserProperty(dadosUsuario) {
    try {
      const result = await this.http
        .post(`${environment.functionsUrl}/usermanagement/user-email-verified`, dadosUsuario, {
          responseType: 'text',
          headers: this.authService.getHeader(),
        })
        .toPromise();
      return result;
    } catch (error) {
      this.logger.error('Problemas ao tentar atualizar uma propriedade do usuário.', error);
      throw error;
    }
  }
}
