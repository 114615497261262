import { firestore } from 'firebase';


export class DateUtilHandler {
  static convertToDateType(valueToConvert: Date | firestore.Timestamp | null | undefined): Date | null {
    let dateOrNull: Date | null = null;
    if (valueToConvert) {
      if (valueToConvert instanceof Date || typeof valueToConvert === 'string') {
        dateOrNull = new Date(valueToConvert);
      } else if (typeof valueToConvert === 'object' && 'seconds' in valueToConvert && 'nanoseconds' in valueToConvert) {
        dateOrNull = new firestore.Timestamp(valueToConvert.seconds, valueToConvert.nanoseconds).toDate();
      } else {
        dateOrNull = (valueToConvert as firestore.Timestamp).toDate();
      }
    }
    return dateOrNull;
  }
}
