<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title> Meus Leads </mat-card-title>

      <div class="row px-3 mt-4">
        <div class="col-12 col-sm-5 col-md-4">
          <mat-form-field style="width: 100%" color="primary">
            <mat-label>Filtro</mat-label>
            <mat-select [(ngModel)]="selectedFilter" [disabled]="isLoadingResults">
              <mat-option value="name"> Nome </mat-option>
              <mat-option value="email"> E-mail </mat-option>
              <mat-option value="companyName"> Razão Social </mat-option>
              <mat-option value="cnpj"> CNPJ </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-7 col-md-8 d-flex">
          <mat-form-field style="flex: 1" color="primary">
            <mat-label>{{ labels[selectedFilter] }}</mat-label>
            <input
              matInput
              [(ngModel)]="filterValue"
              (keydown.enter)="applyFilter()"
              [placeholder]="labels[selectedFilter]"
              [disabled]="isLoadingResults"
            />
          </mat-form-field>
          <button mat-icon-button color="accent" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </button>
          <button mat-icon-button color="accent" (click)="applyFilter()">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <div class="w-100 p-5" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
      </div>

      <div class="table-container" *ngIf="!isLoadingResults && length === 0">
        <p class="text-center">Nenhum lead encontrado.</p>
      </div>

      <div class="table-container mat-elevation-z3" *ngIf="!isLoadingResults && length > 0">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortData($event)"
          [matSortActive]="sortField"
          [matSortDirection]="sortDirection"
          matSortDisableClear
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Nome</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <!-- Last Interaction -->
          <ng-container matColumnDef="lastInteraction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Última interação</th>
            <td mat-cell *matCellDef="let row">
              {{ row.lastInteraction ? (row.lastInteraction | date: 'yyyy-MM-dd') : '-' }}
            </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Criado em</th>
            <td mat-cell *matCellDef="let row">{{ row.createdAt ? (row.createdAt | date: 'yyyy-MM-dd') : '-' }}</td>
          </ng-container>

          <!-- E-mail Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
            <td mat-cell *matCellDef="let row">{{ row.email }}</td>
          </ng-container>

          <!-- ComapnyName Column -->
          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Razão Social</th>
            <td mat-cell *matCellDef="let row">
              {{ row.companyName || '-' }}
            </td>
          </ng-container>

          <!-- CNPJ Column -->
          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>CNPJ</th>
            <td mat-cell *matCellDef="let row">
              {{ row.cnpj }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Status</th>
            <td mat-cell *matCellDef="let row">
              {{ row.status }}
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef disableClear>Ver dados</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <a mat-flat-button color="primary" [routerLink]="'/agents/lead/' + row.uid">
                <mat-icon>search</mat-icon>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [length]="length"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="paginatorEvent($event)"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
