import { ContratoStatusId } from '../contrato-status';
import { firestore } from 'firebase';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';

export interface EvaluationChecklist {
  valorCapital?: number;
  valorCapitalStr?: string;
  valorDivida?: number;
  valorDividaStr?: string;
  valorFaturamento?: number;
  valorFaturamentoStr?: string;
  icms?: BasicStructure;
  tempoAtividade?: BasicStructure;
  valorTotal?: number;
  valorTotalStr?: string;
  outrosBancos?: string;
  step?: string;

  bancos?: BasicStructure[];
  garantias?: BasicStructure[];

  email?: string;
  telefone?: string;
  cnpj?: string;
  agent?: string;
  cpf?: string;
  dataCriacao?: firestore.Timestamp;
  whatsapp?: string;

  pefin?: BasicStructure;
  refin?: BasicStructure;
  chequeSemFundo?: BasicStructure;
  fraude?: BasicStructure;
}

// export interface User {
//   agent?: string;
//   avaliacaoChecklist?: AvaliacaoChecklist;
//   bigdatacorp?: any;
//   chaveCancelamento?: string;
//   concordaComTermos?: boolean;
//   concordaComTermosExterno?: boolean;
//   contratoFullPath?: string;
//   contratoPath?: string;
//   dataUltimaInteracao?: firestore.Timestamp;
//   datetimeAceite?: firestore.Timestamp;
//   datetimeCancelamento?: firestore.Timestamp;
//   displayName?: string;
//   documentos?: TipoDocumento[];
//   email?: string;
//   emailUserCreator?: string;
//   emailVerified?: boolean;
//   estaAtivo?: boolean;
//   hasAdmPendency?: boolean;
//   hasPermissionToSign?: boolean;
//   isAdmin?: boolean;
//   isAnonymous?: boolean;
//   listCreditoExcluida?: CreditoPreAprovado[];
//   listCreditoPreAprovado?: CreditoPreAprovado[];
//   motivoCancelamento?: string;
//   nome?: string;
//   notificacoes?: {
//     chat: { pendenciaParaAdm: boolean };
//     documento: { pendenciaParaAdm: boolean };
//     hasAdmPendency: boolean;
//     oportunidade: { pendenciaParaAdm: boolean };
//   };
//   operacaoCreditoInicial?: any;
//   origem?: string;
//   pendencyType?: string;
//   permiteCancelamento?: boolean;
//   photoUrl?: string;
//   qtdRegistros?: number;
//   situacao?: BasicStructure;
//   solicitacaoCadastroAuth?: SolicitacaoCadastroAuth;
//   statusContrato?: ContratoStatusId;
//   telefone?: string;
//   uid?: string;
//   userType?: BasicStructure;
// }

export interface AvaliacaoChecklist {
  agent?: string;
  bancos?: BasicStructure[];
  outrosBancos?: string;
  garantias?: BasicStructure[];
  step?: string;
  tempoAtividade?: BasicStructure;
  valorCapital?: number;
  valorDivida?: number;
  valorFaturamento?: number;
  valorTotal?: number;
  valorCapitalStr?: string;
  valorDividaStr?: string;
  valorFaturamentoStr?: string;
  valorTotalStr?: string;
  cnpj?: string;
  cpf?: string;
  dataCriacao?: firestore.Timestamp;

  pefin?: BasicStructure;
  refin?: BasicStructure;
  chequeSemFundo?: BasicStructure;
  fraude?: BasicStructure;
  email?: string;
  whatsapp?: string;
}

export interface DadosContrato {
  razaoSocial?: string;
  cnpj?: string;
  cpf?: string;
  endereco?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  data?: string;
  dataContrato?: firestore.Timestamp;
  datetimeAceite?: firestore.Timestamp;

  bancos?: string;

  nomeContratante?: string;
  emailContratante?: string;

  testemunha1nome?: string;
  testemunha1email?: string;
  testemunha2nome?: string;
  tesemunha2email?: string;

  autorizarDadosContador?: boolean;
  emailContador?: string;
  telefoneContador?: string;
}

export interface Contrato {
  id?: string;
  dadosContrato?: DadosContrato;
  statusContrato?: ContratoStatusId;
  htmlContrato?: string;
}

export interface Configuracao {
  retURL: string;
  call_salesforce?: boolean;
  salesforce_url: string;
  oauth: SalesforceParams;
}

export interface SalesforceParams {
  last_name: string;
  title: string;
  lead_name: string;
  oid: string;
  retURL: string;
  description: string;
}

export interface CepError {
  error: boolean;
  descricao?: string;
}

export interface Endereco {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}

export interface UserCredential {
  uid: string;
  email: string;
  emailVerified: boolean;
  erroMsg: string;
}

export interface UserMessage {
  remetente: string;
  nome: string;
  message?: UserMessageContent[];
}

export interface UserMessageContent {
  tipoMensagem: string /* enviada ou recebida */;
  email: string;
  nome: string;
  conteudo: string;
  dataEnvio: firestore.Timestamp;
}

export interface SolicitacaoCadastroAuth {
  situacaoSolicitacao: string;
  emailSolicitante: string;
  dataCriacaoSolicitacao: firestore.Timestamp;
  dataCriacaoAutenticacao?: firestore.Timestamp;
  tokenSolicitacao: string;
}

export interface UserHistory {
  nome: string;
  email: string;
  dataUltimaInteracao?: firestore.Timestamp;
}

export interface UserHistoryLog {
  userHistoryLogDetail: UserHistoryLogDetail[];
}

export interface UserHistoryLogDetail {
  emailLoggedUser: string;
  logLevel: BasicStructure; // Erro, sucesso ou info.
  controllerName: string; // Nome do controller.
  message: string; // Breve descrição da ação logada.
  extraInfo: any[];
  entryLogDate: firestore.Timestamp;
}

export class Constantes {
  static cadastroImportado = { id: 'cadastro-importado', name: 'Cadastro Importado' };
  static cadastroCompleto = { id: 'cadastro-completo', name: 'Cadastro Completo' };
  static documentosPendentes = { id: 'documentos-pendentes', name: 'Documentos Pendentes' };
  static oportunidadeAberta = { id: 'oportunidade-aberta', name: 'Oportunidade aberta' };
  static oportunidadeFechada = { id: 'operacao-fechada', name: 'Operação de crédito concluída' };
  static semPendenciaOportunidade = { id: 'sem-pendencia-oportunidade', name: 'Sem pendências e oportunidades' };

  static origemSalesforce = 'salesforce';
  static origemCadastroManual = 'cadastro-manual-admin';

  static logLevelLog = { id: 'all', name: 'All' };
  static logLevelDebug = { id: 'debug', name: 'Debug' };
  static logLevelInfo = { id: 'info', name: 'Info' };
  static logLevelWarn = { id: 'warn', name: 'Warn' };
  static logLevelError = { id: 'error', name: 'Error' };
  static logLevelFatal = { id: 'fatal', name: 'Fatal' };
  static logLevelOff = { id: 'off', name: 'Off' };
}
