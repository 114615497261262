import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contrato } from 'functions/src/models/model-interface';
import { OpportunityManagementService } from '../../../admin/services/opportunity-management.service';
import { Opportunity } from './../../../../../functions/src/models/opportunity/Opportunity';

@Component({
  selector: 'app-confirm-complete-opp',
  templateUrl: './confirm-complete-opp.component.html',
  styleUrls: ['./confirm-complete-opp.component.scss'],
})
export class ConfirmCompleteOppComponent implements OnInit {
  customer?: string;
  banker?: string;
  contract: Contrato;

  currentOpp: Opportunity;

  oppInfo: {
    amount?: number;
    interest?: number;
    term?: number;
    description?: string;
  };

  newAmount: number;
  newInterest: number;
  newTerm: number;

  updateInterest = false;
  updateAmount = false;
  updateTerm = false;

  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<ConfirmCompleteOppComponent>,
    private oppMgmtService: OpportunityManagementService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.oppInfo = data.oppInfo;
    this.customer = data.customer;
    this.currentOpp = data.opp;
    this.contract = data.contract;
    this.banker = data.banker;

    if (!this.oppInfo.amount) {
      this.updateAmount = true;
    }
    if (!this.oppInfo.term) {
      this.updateTerm = true;
    }
    if (!this.oppInfo.interest) {
      this.updateInterest = true;
    }
  }

  ngOnInit(): void {
    //
   }

  cancel(): void {
    this.dialogRef.close();
  }

  handleSubmit(): void {
    this.isLoading = true;

    const opp: Opportunity = {
      ...this.currentOpp,
      stageOpportunity: this.oppMgmtService.getOppStageFromNumber(100),
    };

    if (this.updateAmount) {
      opp.limitOperation = this.newAmount;
    }
    if (this.updateInterest) {
      opp.rate = this.newInterest;
    }
    if (this.updateTerm) {
      opp.deadlineForMonth = this.newTerm;
    }

    this.oppMgmtService.updateOpportunity(this.customer, opp)
      .then(() => {
        this.dialogRef.close('success');
        this.isLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.isLoading = false;
        this.dialogRef.close('error');
      });
  }

  isValidData(): boolean {
    return (
      (!this.updateAmount || this.newAmount > 0) &&
      (!this.updateTerm || this.newTerm > 0) &&
      (!this.updateInterest || this.newInterest > 0)
    );
  }
}
