import { Component, Input } from '@angular/core';
import { ChatAttributedInterface } from '../../models/ChatAttributedEntity';

@Component({
  selector: 'app-chat-attributed-item',
  templateUrl: './chat-attributed-item.component.html',
  styleUrls: ['./chat-attributed-item.component.scss']
})
export class ChatAttributedItemComponent {
  @Input() attributedList: ChatAttributedInterface[] = [];
}
