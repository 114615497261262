import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Agent, AgentEvent, AgentsResult } from 'functions/src/models/Agent';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentService } from 'src/app/agents/services/agent.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgentsAdminService {
  agents: Agent[] = [];
  size;

  constructor(
    private angularFirestore: AngularFirestore,
    private agentService: AgentService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.size = angularFirestore
      .collection('agents')
      .snapshotChanges()
      .pipe(
        map((snapshot) => {
          return snapshot.length;
        })
      );
  }

  searchAgents({
    filterField,
    filterValue,
    orderField = 'createdAt',
    orderDirection = 'desc' as firestore.OrderByDirection,
    pageSize = 10,
    page = 0,
  }: {
    filterField?: string;
    filterValue?: string;
    orderField?: string;
    orderDirection?: firestore.OrderByDirection;
    pageSize?: number;
    page: number;
  }): Promise<AgentsResult> {
    return new Promise((resolve, reject) => {
      const params = `filterField=${filterField}&filterValue=${filterValue}&orderField=${orderField}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`;

      this.http
        .get(`${environment.functionsUrl}/agent/search?${params}`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          resolve(res as AgentsResult);
        })
        .catch((err) => {
          console.error('Error getting agents.', err);
          reject(err);
        });
    });
  }

  enableAgent(uid: string, email: string, loggedAdmin?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`agents/${uid}`)
        .update({ isActive: true })
        .then(() => {
          this.agentService.registerAgentLog(uid, AgentEvent.enabled, loggedAdmin || undefined);
          resolve();
        })
        .catch((err) => {
          console.error('Error enabling agent', err);
          reject(err);
        });
    });
  }

  disableAgent(uid: string, email: string, loggedAdmin?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`agents/${uid}`)
        .update({ isActive: false })
        .then(() => {
          this.agentService.registerAgentLog(uid, AgentEvent.disabled, loggedAdmin || undefined);
          resolve();
        })
        .catch((err) => {
          console.error('Error disabling agent', err);
          reject(err);
        });
    });
  }

  approveAgent(uid: string, email: string, loggedAdmin?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`agents/${uid}`)
        .update({ isApprovedByAdmin: true, isActive: true, status: 'approved' })
        .then(() => {
          Promise.all([
            this.agentService.registerAgentLog(uid, AgentEvent.approved, loggedAdmin || undefined),
            this.http
              .post(
                `${environment.functionsUrl}/email/agent-approved-account`,
                { email, redirect: `${environment.baseURL}/agents/profile` },
                { responseType: 'text', headers: this.authService.getHeader() }
              )
              .toPromise(),
          ])
            .then(() => {
              console.log('Agent notified about the approving');
              resolve();
            })
            .catch((err) => {
              console.error('Error notifying agent about the approving', err);
              resolve();
            });
        })
        .catch((err) => {
          console.error('Error approving agent', err);
          reject(err);
        });
    });
  }

  rejectAgent(uid: string, email: string, loggedAdmin?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`agents/${uid}`)
        .update({ isApprovedByAdmin: false, isActive: false, status: 'rejected' })
        .then(() => {
          Promise.all([
            this.agentService.registerAgentLog(uid, AgentEvent.rejected, loggedAdmin || undefined),
            this.http
              .post(
                `${environment.functionsUrl}/email/agent-rejected-account`,
                { email },
                { responseType: 'text', headers: this.authService.getHeader() }
              )
              .toPromise(),
          ])
            .then(() => {
              console.log('Agent notified about the approving');
              resolve();
            })
            .catch((err) => {
              console.error('Error notifying agent about the approving', err);
              resolve();
            });
        })
        .catch((err) => {
          console.error('Error rejecting agent', err);
          reject(err);
        });
    });
  }

  getAgentData(uid: string): Observable<Agent> {
    return this.angularFirestore.collection('agents').doc(uid?.trim()).valueChanges();
  }

  updateAgentData(uid: string, data, loggedAdmin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const agentRef: AngularFirestoreDocument<Agent> = this.angularFirestore.doc(`agents/${uid}`);

        agentRef
          .set(data, { merge: true })
          .then(() => {
            console.log('Agent data updated on database');

            this.agentService.registerAgentLog(uid, AgentEvent.updated, loggedAdmin || undefined);

            return resolve();
          })
          .catch((err) => {
            console.error('Error updating agent data', err);
            reject(err);
          });
      }
    });
  }

  async createAgent(agentData, password: string, loggedAdmin: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (agentData.cnpj) {
        try {
          await this.agentService.checkIfCNPJExists(agentData.cnpj);
        } catch (err) {
          if (err === 'existent-cnpj') {
            return resolve('existent-cnpj');
          } else {
            return reject(err);
          }
        }
      }
      const agent = {
        ...agentData,
        isActive: true,
        isApprovedByAdmin: true,
        manuallyCreated: true,
        notifications: {
          statusChanged: true,
          leadCreate: true,
          leadUpdate: true,
          leadAcceptedOffer: true,
        },
        status: 'approved',
      };

      this.http
        .post(
          `${environment.functionsUrl}/agent/create-manually`,
          { agent: { ...agent, password }, admin: loggedAdmin, redirect: `${environment.baseURL}/entrar` },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          return resolve('success');
        })
        .catch((err) => {
          console.error('Problemas ao tentar criar um novo usuário.', err);
          if (err.error) {
            if (err.error.isExistentAuth) {
              return resolve('email-in-use');
            } else if (err.error === 'error-sending-email') {
              return resolve('error-sending-email');
            } else {
              return reject(err);
            }
          } else {
            return reject(err);
          }
        });
    });
  }

  generatePassword(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array(length)
      .fill('')
      .map(() => charset.charAt(Math.random() * charset.length))
      .join('');
  }
}
