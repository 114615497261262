import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Banker } from '../../../../../functions/src/models/Banker';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-banker-profile-security',
  templateUrl: './banker-profile-security.component.html',
  styleUrls: ['./banker-profile-security.component.scss']
})
export class BankerProfileSecurityComponent implements OnInit {
  @Input() banker: Banker = null;

  passwordFormGroup: FormGroup;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.banker) {
      this.createPasswordFormGroup();
    }
  }

  createPasswordFormGroup(): void {
    this.passwordFormGroup = this.formBuilder.group(
      {
        oldPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        newPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmNewPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
      },
      { validator: this.checkEqualPasswords }
    );
  }

  checkEqualPasswords(formGroup: AbstractControl) {
    const newPassword = formGroup.get('newPasswordCtrl')?.value;
    const confirmNewPasswordCtrl = formGroup.get('confirmNewPasswordCtrl')?.value;

    return newPassword === confirmNewPasswordCtrl ? null : { passwordsNotMatched: true };
  }

  changePassword(): void {
    const newPassword = this.passwordFormGroup.get('newPasswordCtrl').value;
    const oldPassword = this.passwordFormGroup.get('oldPasswordCtrl').value;

    this.authService
      .loginWithEmailAndPassword(this.banker.email, oldPassword)
      .then(() => {
        this.authService
          .changePassword(this.banker.uid, newPassword)
          .then(() => {
            this.banker.changedPassword = true;
            return this.authService.updateUser(this.banker);
          })
          .then(() => {
            const msgSub = this.showMessage('Senha atualizada', 'Sua senha foi atualizada com sucesso.')
              .afterClosed()
              .subscribe(() => {
                msgSub.unsubscribe();
              });
          })
          .catch((err) => {
            console.error(err);
            this.showMessage('Erro ao atualizar senha', 'Não foi possível atualizar sua senha, tente novamente mais tarde.');
          });
      })
      .catch(() => {
        this.showMessage('Senha incorreta', 'Verifique sua senha e tente novamente');
      });
  }

  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }

}
