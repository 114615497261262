<div class="bmp-moneyplus-container" *ngIf="bmpMoneyPlusData">
  <div class="section">
    <h3>Dados do cliente</h3>
    <div class="section-content">
      <div class="data-container">
        <p>CNPJ Solicitante</p>
        <span>{{ bmpMoneyPlusData.resumoDoCliente.cnpjDaIfSolicitante || '-' }}</span>
      </div>
      <div class="data-container">
        <p>Cliente</p>
        <span>{{ bmpMoneyPlusData.resumoDoCliente.codigoDoCliente || '-'  }}</span>
      </div>
      <div class="data-container">
        <p>Tipo cliente</p>
        <span>{{ bmpMoneyPlusData.resumoDoCliente.tipoDoCliente === '2' ? "Pessoa Jurídica" : 'Pessoa Física' }}</span>
      </div>
      <div class="data-container">
        <p>Inicio relacionamento</p>
        <span>{{ bmpMoneyPlusData.resumoDoCliente.dataInicioRelacionamento || '-' }}</span>
      </div>
    </div>
  </div>
  <div class="section">
    <h3>Resumo de operações</h3>
    <div class="section-content left">
      <div class="data-container">
        <p>Coobrigação Assumida:</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoCliente.coobrigacaoAssumida) }}</span>
      </div>
      <div class="data-container">
        <p>Coobrigação Recebida:</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoCliente.coobrigacaoRecebida) }}</span>
      </div>
      <div class="data-container">
        <p>% Doctos Processados:</p>
        <span>-</span>
      </div>
      <div class="data-container">
        <p>% Volume Processado:</p>
        <span>-</span>
      </div>
      <div class="data-container">
        <p>Qtde Operações Discordância:</p>
        <span>{{ bmpMoneyPlusData.resumoDoClienteTraduzido.qtdeOperacoesDiscordancia || '0' }}</span>
      </div>
      <div class="data-container">
        <p>Vlr Operações Discordância:</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.vlrOperacoesDiscordancia) }}</span>
      </div>
      <div class="data-container">
        <p>Qtde Operações Sub Judice:</p>
        <span>{{ bmpMoneyPlusData.resumoDoClienteTraduzido.qtdeOperacoesSobJudice || '0' }}</span>
      </div>
      <div class="data-container">
        <p>Vlr Operações Sub Judice:</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.vlrOperacoesSobJudice) }}</span>
      </div>
      <div class="data-container">
        <p>Qtde Instituições:</p>
        <span>{{ bmpMoneyPlusData.resumoDoClienteTraduzido.qtdeInstituicoes || '0' }}</span>
      </div>
      <div class="data-container">
        <p>Qtde Operações:</p>
        <span>{{ bmpMoneyPlusData.resumoDoClienteTraduzido.qtdeOperacoes || '0' }}</span>
      </div>
      <div class="data-container">
        <p>Risco Indireto Vendor:</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.riscoIndiretoVendor) }}</span>
      </div>
    </div>
  </div>
  <div class="section">
    <h3>Carteira a vencer</h3>
    <div class="section-content left">
      <div class="column">
        <div class="data-container row">
          <p>De 14 a 30 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencerAte30diasVencidosAte14dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 31 a 60 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData?.resumoDoClienteTraduzido?.carteiraVencer61a90dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 61 a 90 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencer61a90dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 91 a 180 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencer91a180dias) }}</span>
        </div>
      </div>
      <div class="column">
        <div class="data-container row">
          <p>De 181 a 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencer181a360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Mais de 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencerAcima360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Prazo Indeterminado:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencerPrazoIndeterminado) }}</span>
        </div>
        <div class="data-container row">
          <p>Total:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencer) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <h3>Vencidos</h3>
    <div class="section-content left">
      <div class="column">
        <div class="data-container row">
          <p>De 14 a 30 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencido15a30dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 31 a 60 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencido31a60dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 61 a 90 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencido61a90dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 91 a 180 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencido91a180dias) }}</span>
        </div>
      </div>
      <div class="column">
        <div class="data-container row">
          <p>De 181 a 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencido181a360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Mais de 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencidoAcima360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Total:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiraVencido) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section">
      <h3>Prejuízos</h3>
      <div class="section-content">
        <div class="data-container row">
          <p>Até 12 meses:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.prejuizoAte12meses) }}</span>
        </div>
        <div class="data-container row">
          <p>Acima de 12 meses:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.prejuizoAcima12meses) }}</span>
        </div>
        <div class="data-container row">
          <p>Total:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.prejuizo) }}</span>
        </div>
      </div>
    </div>
    <div class="section">
      <h3>Limite de crédito</h3>
      <div class="section-content">
        <div class="data-container row">
          <p>Até 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.limitesdeCreditoAte360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Acima de 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.limitesdeCreditoAcima360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Total:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.limitesdeCredito) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <h3>Outros Valores</h3>
    <div class="section-content left">
      <div class="data-container">
        <p>Carteira de Crédito</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.carteiradeCredito) }}</span>
      </div>
      <div class="data-container">
        <p>Repasses</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.repasses) }}</span>
      </div>
      <div class="data-container">
        <p>Coobrigações</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.coobrigacoes) }}</span>
      </div>
      <div class="data-container">
        <p>Responsabilidade Total</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.responsabilidadeTotal) }}</span>
      </div>
      <div class="data-container">
        <p>Créditos a Liberar</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.creditosaLiberar) }}</span>
      </div>
      <div class="data-container">
        <p>Risco Indireto Vendor</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.riscoIndiretoVendor) }}</span>
      </div>
      <div class="data-container">
        <p>Risco Total</p>
        <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.riscoTotal) }}</span>
      </div>
    </div>
  </div>
  <div class="section">
    <h3>Valores Discriminados em Moeda Estrangeira</h3>
    <div class="section-content left">
      <div class="column">
        <div class="data-container row">
          <p>De 14 a 30 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValor15a30dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 31 a 60 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValor31a60dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 61 a 90 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValor61a90dias) }}</span>
        </div>
        <div class="data-container row">
          <p>De 91 a 180 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValor91a180dias) }}</span>
        </div>
      </div>
      <div class="column">
        <div class="data-container row">
          <p>De 181 a 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValor181a360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Mais de 360 dias:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValorAcima360dias) }}</span>
        </div>
        <div class="data-container row">
          <p>Total:&nbsp;</p>
          <span>{{ formatMoney(bmpMoneyPlusData.resumoDoClienteTraduzido.moedaEstrangeiraValor) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="table-content">
    <h4>Modalidades</h4>
    <table>
      <tr class="table-header">
        <th>Tipo</th>
        <th>Modalidade</th>
        <th>Dominio</th>
        <th>Subdominio</th>
        <th>Valor Vencimento</th>
      </tr>
      <tr class="table-content-row" *ngFor="let modality of bmpMoneyPlusData.resumoModalidade">
        <td>{{modality.tipo}}</td>
        <td>{{modality.modalidade}}</td>
        <td>{{modality.dominio}}</td>
        <td>{{modality.subdominio}}</td>
        <td>{{formatMoney(modality.valorVencimento)}}</td>
      </tr>
    </table>
  </div>
</div>
<p *ngIf="!bmpMoneyPlusData">Nenhum dado encontrado.</p>
