<div class="serasa-card-container-admin" *ngIf="hasSerasaData && isAdmin">
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title class="title">Histórico Serasa</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="content">
      <h6>Resumo da consulta - {{serasaTotalData?.date.toDate() | date: 'MM/yyyy'}}</h6>
      <ul class="row">
        <li>Ocorrências</li>
        <li>Quantidade</li>
        <li>Valor</li>
        <li>Último Registro</li>
      </ul>
      <mat-expansion-panel class="inner-expansion-card">
        <mat-expansion-panel-header>
          <mat-panel-title>Pendências internas</mat-panel-title>
          <mat-panel-description>
            <span>Sem ocorrencias</span>
            <span>-</span>
            <span>-</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inner-expansion-card-content">
          <p>NÃO CONSTAM OCORRÊNCIAS</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="inner-expansion-card">
        <mat-expansion-panel-header>
          <mat-panel-title>Pendência Pefin</mat-panel-title>
          <mat-panel-description *ngIf="serasaNegativeData?.pefin?.pefinResponse">
            <span class="pendencies-number">{{ serasaNegativeData?.pefin?.summary?.count }}</span>
            <span>{{ formatMoney(serasaNegativeData?.pefin?.summary?.balance) }}</span>
            <span>{{ serasaNegativeData?.pefin?.summary?.lastOccurrence | date: 'MM/yyyy' }}</span>
          </mat-panel-description>
          <mat-panel-description *ngIf="!serasaNegativeData?.pefin?.refinResponse">
            <span>Sem ocorrências</span>
            <span>-</span>
            <span>-</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inner-expansion-card-content" *ngIf="serasaNegativeData?.pefin?.pefinResponse">
          <ul class="inner-row">
            <li>Data</li>
            <li>Modalidade</li>
            <li>Aval</li>
            <li>Valor</li>
            <li>Contrato</li>
            <li>Origem</li>
            <li>PÇA</li>
          </ul>
          <div class="main-content" *ngFor="let data of serasaNegativeData?.pefin?.pefinResponse">
            <p>{{data?.occurrenceDate | date: 'dd/MM/yyyy'}}</p>
            <p>{{data?.legalNature}}</p>
            <p>N</p>
            <p>{{formatMoney(data?.amount)}}</p>
            <p>{{data?.contractId}}</p>
            <p>{{data?.creditorName}}</p>
            <p>{{data?.legalNatureId}}</p>
          </div>
        </div>
        <div class="inner-expansion-card-content" *ngIf="!serasaNegativeData?.pefin?.pefinResponse">
          <p>NÃO CONSTAM OCORRÊNCIAS</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="inner-expansion-card">
        <mat-expansion-panel-header>
          <mat-panel-title>Pendência Refin</mat-panel-title>
          <mat-panel-description *ngIf="serasaNegativeData?.refin?.refinResponse">
            <span class="pendencies-number">{{ serasaNegativeData?.refin?.summary?.count }}</span>
            <span>{{ formatMoney(serasaNegativeData?.refin?.summary?.balance) }}</span>
            <span>{{ serasaNegativeData?.refin?.summary?.lastOccurrence | date: 'MM/yyyy' }}</span>
          </mat-panel-description>
          <mat-panel-description *ngIf="!serasaNegativeData?.refin?.refinResponse">
            <span>Sem ocorrências</span>
            <span>-</span>
            <span>-</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inner-expansion-card-content" *ngIf="serasaNegativeData?.refin?.refinResponse">
          <ul class="inner-row">
            <li>Data</li>
            <li>Modalidade</li>
            <li>Aval</li>
            <li>Valor</li>
            <li>Contrato</li>
            <li>Origem</li>
            <li>PÇA</li>
          </ul>
          <div class="main-content" *ngFor="let data of serasaNegativeData?.refin?.refinResponse">
            <p>{{data?.occurrenceDate | date: 'dd/MM/yyyy'}}</p>
            <p>{{data?.legalNature}}</p>
            <p>N</p>
            <p>{{formatMoney(data?.amount)}}</p>
            <p>{{data?.contractId}}</p>
            <p>{{data?.creditorName}}</p>
            <p>{{data?.legalNatureId}}</p>
          </div>
        </div>
        <div class="inner-expansion-card-content" *ngIf="!serasaNegativeData?.refin?.refinResponse">
          <p>NÃO CONSTAM OCORRÊNCIAS</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="inner-expansion-card">
        <mat-expansion-panel-header>
          <mat-panel-title>Protesto Estadual</mat-panel-title>
          <mat-panel-description>
            <span>Sem ocorrencias</span>
            <span>-</span>
            <span>-</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inner-expansion-card-content">
          <p>NÃO CONSTAM OCORRÊNCIAS</p>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="inner-expansion-card">
        <mat-expansion-panel-header>
          <mat-panel-title>Cheques sem fundo BACEN</mat-panel-title>
          <mat-panel-description>
            <span>Sem ocorrencias</span>
            <span>-</span>
            <span>-</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inner-expansion-card-content">
          <p>NÃO CONSTAM OCORRÊNCIAS</p>
        </div>
      </mat-expansion-panel>
    </div>
    <hr>
    <mat-spinner class="mx-auto my-3" *ngIf="isProcessingSerasa" color="primary" diameter="40"></mat-spinner>
    <button (click)="getSerasaNegativeData()" *ngIf="!isProcessingSerasa">Obter Informações Serasa</button>
  </mat-expansion-panel>
</div>

<div class="serasa-card-container-expanded" *ngIf="hasSerasaData && expanded">
  <div class="content">
    <h6>Resumo da última consulta - {{ serasaTotalData?.date ? (serasaTotalData.date.toDate() | date: 'MM/yyyy') : 'Não há consulta' }}</h6>
    <ul class="row">
      <li>Ocorrências</li>
      <li>Quantidade</li>
      <li>Valor</li>
      <li>Último Registro</li>
    </ul>

    <mat-expansion-panel class="inner-expansion-card">
      <mat-expansion-panel-header>
        <mat-panel-title>Pendências internas</mat-panel-title>
        <mat-panel-description>
          <span>Sem ocorrencias</span>
          <span>-</span>
          <span>-</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="inner-expansion-card-content">
        <p>NÃO CONSTAM OCORRÊNCIAS</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="inner-expansion-card">
      <mat-expansion-panel-header>
        <mat-panel-title>Pendência Pefin</mat-panel-title>
        <mat-panel-description *ngIf="serasaNegativeData?.pefin?.pefinResponse">
          <span class="pendencies-number">{{ serasaNegativeData?.pefin?.summary?.count }}</span>
          <span>{{ formatMoney(serasaNegativeData?.pefin?.summary?.balance) }}</span>
          <span>{{ serasaNegativeData?.pefin?.summary?.lastOccurrence | date: 'MM/yyyy' }}</span>
        </mat-panel-description>
        <mat-panel-description *ngIf="!serasaNegativeData?.pefin?.refinResponse">
          <span>Sem ocorrências</span>
          <span>-</span>
          <span>-</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="inner-expansion-card-content" *ngIf="serasaNegativeData?.pefin?.pefinResponse">
        <ul class="inner-row">
          <li>Data</li>
          <li>Modalidade</li>
          <li>Aval</li>
          <li>Valor</li>
          <li>Contrato</li>
          <li>Origem</li>
          <li>PÇA</li>
        </ul>
        <div class="main-content" *ngFor="let data of serasaNegativeData?.pefin?.pefinResponse">
          <p>{{data?.occurrenceDate | date: 'dd/MM/yyyy'}}</p>
          <p>{{data?.legalNature}}</p>
          <p>N</p>
          <p>{{formatMoney(data?.amount)}}</p>
          <p>{{data?.contractId}}</p>
          <p>{{data?.creditorName}}</p>
          <p>{{data?.legalNatureId}}</p>
        </div>
      </div>
      <div class="inner-expansion-card-content" *ngIf="!serasaNegativeData?.pefin?.pefinResponse">
        <p>NÃO CONSTAM OCORRÊNCIAS</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="inner-expansion-card">
      <mat-expansion-panel-header>
        <mat-panel-title>Pendência Refin</mat-panel-title>
        <mat-panel-description *ngIf="serasaNegativeData?.refin?.refinResponse">
          <span class="pendencies-number">{{ serasaNegativeData?.refin?.summary?.count }}</span>
          <span>{{ formatMoney(serasaNegativeData?.refin?.summary?.balance) }}</span>
          <span>{{ serasaNegativeData?.refin?.summary?.lastOccurrence | date: 'MM/yyyy' }}</span>
        </mat-panel-description>
        <mat-panel-description *ngIf="!serasaNegativeData?.refin?.refinResponse">
          <span>Sem ocorrências</span>
          <span>-</span>
          <span>-</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="inner-expansion-card-content" *ngIf="serasaNegativeData?.refin?.refinResponse">
        <ul class="inner-row">
          <li>Data</li>
          <li>Modalidade</li>
          <li>Aval</li>
          <li>Valor</li>
          <li>Contrato</li>
          <li>Origem</li>
          <li>PÇA</li>
        </ul>
        <div class="main-content" *ngFor="let data of serasaNegativeData?.refin?.refinResponse">
          <p>{{data?.occurrenceDate | date: 'dd/MM/yyyy'}}</p>
          <p>{{data?.legalNature}}</p>
          <p>N</p>
          <p>{{formatMoney(data?.amount)}}</p>
          <p>{{data?.contractId}}</p>
          <p>{{data?.creditorName}}</p>
          <p>{{data?.legalNatureId}}</p>
        </div>
      </div>
      <div class="inner-expansion-card-content" *ngIf="!serasaNegativeData?.refin?.refinResponse">
        <p>NÃO CONSTAM OCORRÊNCIAS</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="inner-expansion-card">
      <mat-expansion-panel-header>
        <mat-panel-title>Protesto Estadual</mat-panel-title>
        <mat-panel-description>
          <span>Sem ocorrencias</span>
          <span>-</span>
          <span>-</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="inner-expansion-card-content">
        <p>NÃO CONSTAM OCORRÊNCIAS</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="inner-expansion-card">
      <mat-expansion-panel-header>
        <mat-panel-title>Cheques sem fundo BACEN</mat-panel-title>
        <mat-panel-description>
          <span>Sem ocorrencias</span>
          <span>-</span>
          <span>-</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="inner-expansion-card-content">
        <p>NÃO CONSTAM OCORRÊNCIAS</p>
      </div>
    </mat-expansion-panel>
  </div>
</div>

<div class="serasa-card-container-small" *ngIf="hasSerasaData && small">
  <div class="content">
    <h5>Resumo da última consulta - {{ serasaTotalData?.date ? (serasaTotalData.date.toDate() | date: 'MM/yyyy') : 'Não há consulta' }}</h5>
    <ul class="row">
      <li>Ocorrências</li>
      <li>Quantidade</li>
      <li>Valor</li>
      <li>Último Registro</li>
    </ul>
    <div class="inside-card">
      <p>Pendências internas</p>
      <span>Sem ocorrencias</span>
      <span>-</span>
      <span>-</span>
    </div>

    <div class="inside-card" *ngIf="serasaNegativeData?.pefin?.pefinResponse">
      <p>Pendência Pefin</p>
      <span class="pendencies-number">{{ serasaNegativeData?.pefin?.summary?.count }}</span>
      <span>{{ formatMoney(serasaNegativeData?.pefin?.summary?.balance) }}</span>
      <span>{{ serasaNegativeData?.pefin?.summary?.lastOccurrence | date: 'MM/yyyy' }}</span>
    </div>
    <div class="inside-card" *ngIf="!serasaNegativeData?.pefin?.pefinResponse">
      <p>Pendência Pefin</p>
      <span>Sem ocorrencias</span>
        <span>-</span>
        <span>-</span>
    </div>

    <div class="inside-card" *ngIf="serasaNegativeData?.refin?.refinResponse">
      <p>Pendência Refin</p>
      <span class="pendencies-number">{{ serasaNegativeData?.refin?.summary?.count }}</span>
      <span> {{ formatMoney(serasaNegativeData?.refin?.summary?.balance) }}</span>
      <span>{{ serasaNegativeData?.refin?.summary?.lastOccurrence | date: 'MM/yyyy' }}</span>
    </div>
    <div class="inside-card" *ngIf="!serasaNegativeData?.refin?.refinResponse">
      <p>Pendência Refin</p>
      <span>Sem ocorrencias</span>
      <span>-</span>
      <span>-</span>
    </div>

    <div class="inside-card">
      <p>Protesto Estadual</p>
      <span>Sem ocorrencias</span>
      <span>-</span>
      <span>-</span>
    </div>

    <div class="inside-card">
      <p>Cheques sem fundo BACEN</p>
      <span>Sem ocorrencias</span>
      <span>-</span>
      <span>-</span>
    </div>
  </div>
</div>
