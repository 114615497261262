import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { ClicksignSettings } from '../../../../functions/src/models/Clicksign';
import { environment } from '../../../environments/environment';
import * as Clicksign from './clicksign-widget.js';

@Component({
  selector: 'app-clicksign-dialog',
  templateUrl: 'clicksign-dialog.html',
})
export class ClicksignDialogComponent implements OnInit, OnDestroy {
  private requestSignatureKey: string;
  private widget: any;

  constructor(private dialog: MatDialogRef<ClicksignDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.requestSignatureKey = data.requestSignatureKey;
  }

  async ngOnInit(): Promise<void> {
    if (!!this.widget) {
      this.widget.unmount();
    }

    this.widget = new Clicksign(this.requestSignatureKey);
    const snapshot = await firebase.firestore().collection('configuracao').doc('clicksign').get();

    if (snapshot.exists) {
      const clicksignSettings = snapshot.data() as ClicksignSettings;

      this.widget.endpoint = clicksignSettings.host;
      this.widget.origin = environment.baseURL;
      this.widget.mount('clicksign-widget-container');
      this.widget.on('signed', () => {
        this.dialog.close('signed');
      });
    } else {
      console.error('Clicksign - Missing configuration document on database');
      throw new Error('Missing configuration document on database');
    }
  }

  ngOnDestroy(): void {
    if (!!this.widget) {
      this.widget.unmount();
    }
  }

  closeDialog(): void {
    if (!!this.widget) {
      this.widget.unmount();
    }
    this.dialog.close(null);
  }
}
