import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from '../../../../functions/src/models/Customer';
import { timestampForDate } from '../../../../functions/src/utils/dates';
import { ChatAttributedEntity, ChatAttributedInterface } from '../models/ChatAttributedEntity';
import { ChatMenuItem } from '../models/chat-menuItem';

@Injectable({
  providedIn: 'root'
})
export class ChatAttributedService {
  menuItem: ChatMenuItem;
  collectionName = 'chat-atribuited-list';

  constructor(private firestore: AngularFirestore) { }

  private customerCollection = (uid: string): AngularFirestoreDocument<Customer> => this.firestore.collection('customers').doc<Customer>(uid)

  private atribuitedCollection = (uid: string): AngularFirestoreCollection<ChatAttributedInterface> => {
    return this.customerCollection(uid).collection<ChatAttributedInterface>(this.collectionName, (x) => x.orderBy('createdAt', 'desc'));
  };

  getAtributedList(uid: string): Observable<ChatAttributedInterface[]> {
    return this.atribuitedCollection(uid).valueChanges().pipe(map(res => res.map(c => {
      if (c.createdAt && c.createdAt['nanoseconds']) c.createdAt = timestampForDate(c.createdAt);
      return c;
    })));
  }

  addAttributedTo(uid: string, data: ChatAttributedInterface): Promise<DocumentReference> {
    console.log(ChatAttributedEntity.firestoreConverter.toFirestore(data))
    return this.customerCollection(uid).collection(this.collectionName).add(ChatAttributedEntity.firestoreConverter.toFirestore(data));
  }
}
