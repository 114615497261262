<div
  class="content"
  [class.d-flex]="isLoading"
  [class.flex-column]="isLoading"
  [style.min-height]="isLoading ? '100%' : 'unset'"
>
  <div class="d-flex">
    <div style="flex: 1">
     
    </div>
    <button mat-button class="d-flex align-items-center flex-row" (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" class="colored-icon mr-2"></mat-icon> Sair
    </button>
  </div>

  <section class="d-flex align-items-center justify-content-center" style="flex: 1" *ngIf="isLoading">
    <mat-spinner [diameter]="80"></mat-spinner>
  </section>

  <section *ngIf="!isLoading && step === 'opportunities'">
    <div>
      <span class="step-label">ETAPA 2 de 3: Sobre você</span>
      <h1>E-mail confirmado!</h1>
      <p>Seu cadastro está quase pronto! Finalize-o para ter acesso a todas as oportunidades disponíveis.</p>
    </div>

    <div class="colored-box">
      <h1 class="header">Confira empréstimos disponíveis!</h1>
      <p class="m-0">Sujeitos a verificação documental e análise de crédito da instituição financeira.</p>

      <div style="overflow-x: auto; overflow-y: hidden">
        <div class="box-content">
          <div class="d-flex">
            <table mat-table [dataSource]="displayedCreditOperations" style="flex: 1">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="p-1">Instituição de Crédito</th>
                <td mat-cell *matCellDef="let opp; let i = index" class="p-1">
                  <span class="d-flex align-items-center">
                    <mat-icon fontSet="fas" fontIcon="fa-university" class="colored-icon"></mat-icon>
                    <span class="ml-2">Instituição {{ i + 1 }}</span>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="term">
                <th mat-header-cell *matHeaderCellDef class="p-1">Prazo</th>
                <td mat-cell *matCellDef="let opp" class="p-1">até {{ opp.term }}</td>
              </ng-container>

              <ng-container matColumnDef="guarantees">
                <th mat-header-cell *matHeaderCellDef class="p-1">Garantias</th>
                <td mat-cell *matCellDef="let opp" class="p-1">
                  {{ generateGuaranteesString(opp.guarantees) }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="creditColumns"></tr>

              <tr mat-row *matRowDef="let row; columns: creditColumns" [ngClass]="row.cellClass"></tr>
            </table>
          </div>
        </div>
      </div>

      <p class="mt-4 mb-2" style="font-weight: 600">Finalize seu cadastro para ver todas as opções disponíveis</p>
    </div>

    <button mat-raised-button color="primary" class="action-button" type="button" (click)="nextStep()">Próximo</button>
  </section>

  <section *ngIf="!isLoading && step === 'terms'">
    <div>
      <span class="step-label">ETAPA 3 de 3: Termos e condições</span>
      <h1>Termos de Uso</h1>
      <p>
        Agora estamos no último passo! Lembramos também que você só paga se e quando o valor é recebido por você. Todos
        os detalhes se encontram nos
        <span class="link" (click)="openTermsDialog()">termos e condições do serviço</span>. Confira também nossas
        <span class="link" (click)="openFeesDialog()">tarifas</span>, e veja abaixo uma simulação para entender
        funcionam as taxas do o melhor financiamento para sua empresa.
      </p>
    </div>

    <div class="colored-box">
      <h1 class="header">Simulação - Taxa de abertura de crédito</h1>

      <div style="overflow-x: auto; overflow-y: hidden">
        <div class="d-flex flex-column flex-md-row align-items-center">
          <div class="mt-2 mx-0 d-flex" style="flex: 1">
            <mat-form-field color="primary">
              <mat-label>Valor da operação</mat-label>
              <input
                matInput
                placeholder="Valor da operação"
                name="opp-value"
                [(ngModel)]="simulationValue"
                [textMask]="{ mask: numberMask }"
              />
            </mat-form-field>
          </div>
          <div class="mt-2 mx-0 d-flex" style="flex: 1">
            <mat-form-field color="primary">
              <mat-label>Prazo</mat-label>
              <mat-select placeholder="Prazo" [(ngModel)]="simulationTerm" name="opp-term">
                <mat-option *ngFor="let term of termOptions" [value]="term">
                  {{ term }} {{ term > 1 ? 'meses' : 'mês' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <p class="my-2">
              Nossa taxa de abertura de crédito será de <strong>{{ 100 * calculateMontlyFee() | number: 2 }}% a.m.</strong>
              <!-- ou <strong>{{ calculateMontlyValue() }}</strong> -->
            </p>
          </div>
          <div>
            <button mat-icon-button color="accent" (click)="openInfoDialog()">
              <mat-icon fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-9 mt-3 mb-4 d-flex">
      <mat-checkbox color="primary" class="mr-3 my-1 checkbox" [(ngModel)]="agreesTerms" required></mat-checkbox>
      <span style="flex: 1"
        >Eu li e concordo com os <span class="link" (click)="openDialogTerms()">termos e condições</span>.</span
      >
    </div>

    <div class="button-container mb-4">
      <button mat-stroked-button class="action-button text-black autosized" type="button" (click)="previousStep()">
        Voltar
      </button>
      <button
        mat-raised-button
        color="primary"
        class="action-button"
        type="button"
        (click)="acceptTerms()"
        [class.disabled]="!agreesTerms"
        [disabled]="!agreesTerms"
      >
        Finalizar Cadastro
      </button>
    </div>
  </section>

  <section *ngIf="!isLoading && step === 'processing'">
    <div>
      <span class="step-label">TUDO PRONTO</span>
      <h1>Cadastro concluído!</h1>
      <p>
        Estamos terminando de configurar seu painel. Em alguns instantes você acessar nossa plataforma e conferir todos
        os empréstimos disponíveis para a sua empresa!
      </p>
      <!-- <p>
        Enquanto isso, confira abaixo todos os serviços oferecidos pela Capital Empreendedor!
      </p> -->

      <div class="button-container mb-4">
        <a
          mat-raised-button
          color="primary"
          class="action-button autosized px-3"
          *ngIf="!processingError"
          type="button"
          [routerLink]="'/documentacao'"
          [disabled]="processingSignature"
          [class.disabled]="processingSignature"
        >
          <mat-spinner [diameter]="20" *ngIf="processingSignature" class="d-inline-block mr-2"></mat-spinner>
          <span *ngIf="processingSignature" class="d-inline-block">Configurando o painel</span>

          <mat-icon
            fontSet="fas"
            fontIcon="fa-check-circle"
            class="d-inline-flex align-items-center justify-content-center mr-2"
            *ngIf="!processingSignature"
          ></mat-icon>
          <span *ngIf="!processingSignature" class="d-inline-block">Ir para meu painel</span>
        </a>

        <button
          mat-raised-button
          color="primary"
          class="action-button autosized px-3"
          *ngIf="processingError"
          type="button"
          (click)="acceptTerms()"
        >
          <mat-icon
            fontSet="fas"
            fontIcon="fa-check-circle"
            class="d-inline-flex align-items-center justify-content-center mr-2"
          ></mat-icon>
          <span class="d-inline-block">Tentar novamente</span>
        </button>
      </div>
    </div>
  </section>
</div>
