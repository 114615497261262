<div class="container-fluid">
  <mat-card *ngIf="isLoading || hasError">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="primary"
          type="button"
          routerLink="/admin/assessores/buscar"
          matTooltip="Voltar à listagem"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Visualização de Assessor de Crédito</span>
      </mat-card-title>

      <div class="text-center my-3" *ngIf="isLoading">
        <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
      </div>

      <div *ngIf="hasError">
        <p>Não foi possível obter os dados do assessor de crédito.</p>
        <p class="text-center">
          <button mat-raised-button color="primary" (click)="getAgentData()">Tentar novamente</button>
        </p>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoading && !hasError">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="accent"
          type="button"
          routerLink="/admin/assessores/buscar"
          matTooltip="Voltar à listagem"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Assessor de crédito: {{ agent.name }}</span>
        <a mat-raised-button color="primary" [routerLink]="'/admin/assessores/editar/' + agentCode">
          <mat-icon>edit</mat-icon> Editar assessor
        </a>
      </mat-card-title>

      <div class="row m-0">
        <div class="col-12 col-lg-7 col-xl-8 mt-3">
          <div class="agent-info">
            <h3>Dados do assessor de crédito</h3>
            <hr class="mb-3" />

            <p><strong>Código do assessor:</strong> {{ agent.uid || '-' }}</p>
            <p><strong>Nome:</strong> {{ agent.name || '-' }}</p>
            <p><strong>E-mail:</strong> {{ agent.email || '-' }}</p>
            <p><strong>Telefone:</strong> {{ agent.phone || '-' }}</p>
            <p><strong>CNPJ:</strong> {{ agent.cnpj || '-' }}</p>
            <p>
              <strong>Linkedin:</strong>
              <a href="{{ agent.linkedin }}" target="_blank" *ngIf="agent.linkedin; else noLinkedin">
                {{ agent.linkedin }}
              </a>
              <ng-template #noLinkedin> - </ng-template>
            </p>
            <p><strong>Origem:</strong> {{ agent.source || '-' }}</p>
            <p>
              <strong>Política de Privacidade:</strong>
              {{ agent?.userConsent?.privacyPolicyDate | date: 'dd/MM/yyyy' || '-' }}
            </p>
            <p *ngIf="agent?.userConsent?.cookiePolicy?.agreedCookiesDate">
              <strong>Aceitou Política de Cookies em:</strong>
              {{ agent.userConsent?.cookiePolicy?.agreedCookiesDate | date: 'dd/MM/yyyy' || '-' }}
            </p>
            <p *ngIf="agent?.userConsent?.cookiePolicy?.cancelCookiesDate">
              <strong>Recusou Política de Cookies em:</strong>
              {{ agent.userConsent?.cookiePolicy?.cancelCookiesDate | date: 'dd/MM/yyyy' || '-' }}
            </p>
            <p>
              <strong>Link para cadastro de leads:</strong>
            </p>
            <div class="row">
              <div class="col-12 col-sm d-flex align-items-center">
                <mat-form-field>
                  <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1.1"
                    readonly
                    class="p-1"
                    [value]="agentLink"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-auto col d-flex align-items-center justify-content-center">
                <button mat-raised-button color="accent" [cdkCopyToClipboard]="agentLink">
                  <span class="d-flex align-items-center mx-1"> <i class="far fa-copy mr-2"></i> Copiar link </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-5 col-xl-4 mt-3">
          <div class="agent-info">
            <h3>Status</h3>
            <hr class="mb-3" />

            <div class="d-flex flex-column align-items-center">
              <div class="mx-auto text-center my-4 status-label">
                {{
                  !agent.emailVerified
                    ? 'Aguardando confirmação de e-mail'
                    : agent.isActive
                    ? !agent.isApprovedByAdmin
                      ? 'Aguardando aprovação'
                      : 'Aprovado'
                    : !agent.isApprovedByAdmin
                    ? 'Recusado'
                    : 'Suspenso'
                }}
              </div>

              <div class="mx-auto text-center">
                <button
                  mat-stroked-button
                  color="primary"
                  *ngIf="!agent.isApprovedByAdmin && agent.isActive"
                  class="m-1"
                  (click)="approveAgent(agent.uid, agent.email)"
                >
                  <mat-icon>check_circle_outline</mat-icon> Aprovar
                </button>
                <button
                  mat-stroked-button
                  color="warn"
                  *ngIf="!agent.isApprovedByAdmin && agent.isActive"
                  class="m-1"
                  (click)="rejectAgent(agent.uid, agent.email)"
                >
                  <mat-icon>block</mat-icon> Recusar
                </button>
                <button
                  mat-stroked-button
                  color="primary"
                  *ngIf="!agent.isActive"
                  class="m-1"
                  (click)="enableAgent(agent.uid, agent.email)"
                >
                  <mat-icon>done</mat-icon> Habilitar
                </button>
                <button
                  mat-stroked-button
                  color="warn"
                  *ngIf="loggedAdmin?.accessLevel === 'master' && agent.isApprovedByAdmin && agent.isActive"
                  class="m-1"
                  (click)="disableAgent(agent.uid, agent.email)"
                >
                  <mat-icon>report_problem</mat-icon> Suspender
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3">
        <div class="agent-info">
          <h3>Leads do assessor</h3>
          <hr class="mb-3" />

          <div class="w-100 p-5" *ngIf="isLoadingResults">
            <mat-spinner *ngIf="isLoadingResults" [diameter]="60" class="mx-auto"></mat-spinner>
          </div>

          <div class="table-container" *ngIf="!isLoadingResults && length === 0">
            <p class="text-center">Nenhum lead encontrado.</p>
          </div>

          <div class="table-container mat-elevation-z3" *ngIf="!isLoadingResults && length > 0">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              (matSortChange)="sortData($event)"
              [matSortActive]="sortField"
              [matSortDirection]="sortDirection"
              matSortDisableClear
            >
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Nome</th>
                <td mat-cell *matCellDef="let row">{{ row.name }}</td>
              </ng-container>

              <!-- E-mail Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
                <td mat-cell *matCellDef="let row">{{ row.email }}</td>
              </ng-container>

              <!-- Created Column -->
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Criado em</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.createdAt ? (row.createdAt | date: 'dd/MM/yyyy HH:mm') : '-' }}
                </td>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Status</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.status }}
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef disableClear>Ações</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <a mat-stroked-button color="primary" [routerLink]="'/admin/clientes/detalhes/' + row.uid">
                    <mat-icon>open_in_new</mat-icon>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <mat-paginator
              [length]="length"
              [pageIndex]="currentPage"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="paginatorEvent($event)"
            ></mat-paginator>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
