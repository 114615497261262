import { Component, Input, SimpleChanges } from '@angular/core';
import { MoneyplusEntity } from '../../../../functions/src/models/moneyplus/Moneyplus';
import { ScrBmpService } from '../../core/services/scr-bmp.service';

@Component({
  selector: 'app-card-bmp-moneyplus',
  templateUrl: './card-bmp-moneyplus.component.html',
  styleUrls: ['./card-bmp-moneyplus.component.scss']
})
export class CardBmpMoneyplusComponent {
  @Input() customer: string;
  @Input() bmpMoneyPlusData: MoneyplusEntity;
  
  constructor(private scrBmpService: ScrBmpService) {}

  ngOnInit(): void {
    if (this.customer) {
      this.getScrBmpData(this.customer);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bmpMoneyPlusData && changes.bmpMoneyPlusData.currentValue) {
      this.updateData(changes.bmpMoneyPlusData.currentValue);
    }
  }

  private getScrBmpData(customer: string): void {
    this.scrBmpService.getDataFromScrBmp(customer).subscribe(data => {
      this.bmpMoneyPlusData = data;
    });
  }

  private updateData(data: MoneyplusEntity): void {
    this.bmpMoneyPlusData = data;
  }

  formatMoney(value): string {
    if (isNaN(parseFloat(value))) return "-";

    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }
}
