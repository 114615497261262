import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { firestore } from 'firebase';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ContractService } from 'src/app/admin/services/contract.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { HtmlDialogComponent } from 'src/app/components/html-dialog/html-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { analyticsReportConversion } from 'src/app/core/handler/googleAnalytics';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Utm } from '../../../../../functions/src/models/common/Utm';
import { HubspotService } from '../../../core/services/hubspot.service';
import { CustomerService } from '../../services/customer.service';
import { Opportunity } from './../../../../../functions/src/models/opportunity/Opportunity';
import { OpportunityManagementService } from './../../../admin/services/opportunity-management.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit, OnDestroy {
  readonly MAX_DISPLAYED_GUARANTEES = 2;

  customer: Customer;
  opportunities: Opportunity[];
  customerSubscription: Subscription;
  agreesTerms = false;

  utmSignature: Utm;
  isLoading = true;
  processingSignature = false;
  processingError = false;
  step: 'opportunities' | 'terms' | 'processing' = 'opportunities';
  requestedOppsList = false;
  ip = '';

  // displayedCreditOperations: MatTableDataSource<UserCreditLine>;
  displayedCreditOperations: MatTableDataSource<any>;
  creditColumns = ['name', 'term', 'guarantees'];

  // Fee Simulator
  termOptions: number[] = [];
  simulationTerm = 18;
  simulationValue = 0;
  readonly numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });

  // Contract
  address = '';
  banksStr = '';
  contract = '';
  contractDate: moment.Moment;
  ipSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private contractService: ContractService,
    private customerService: CustomerService,
    private hubspotService: HubspotService,
    private oppsService: OpportunityManagementService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration2.svg');
    this.utmSignature = {};
  }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.customer.subscribe(async (customer: Customer) => {
      if (customer?.uid) {
        this.customer = customer;
        if (!!customer?.agreedTerms || !!customer?.concordaComTermos) {
          this.router.navigate(['/home']);
        }
        this.isLoading = true;
        this.oppsService.getOpportunities(this.customer.uid).then(opps => {
          this.opportunities = opps;
          if (!this.opportunities?.length) {

            this.requestedOppsList = true;
          }
          this.displayedCreditOperations = new MatTableDataSource(
            (this.opportunities || []).slice(0, 3).map((op) => ({
              term: op.deadlineForMonth,
              guarantees: op.guarantees?.split(',') || ['Aval'],
            }))
          );

          this.fillContract();

          this.isLoading = false;
        }).catch(err => console.error(err));

        if (!this.simulationValue) {
          this.simulationValue = customer.creditValue;
        }
        if (this.customer.agreedTerms && this.customer.showOnboarding) {
          this.step = 'processing';
          this.processingSignature = false;
          this.processingError = false;
        }

        this.ipSubscription = this.authService.getClientIP().subscribe((data) => {
          this.ip = data['ip'];
          this.fillContract();
        });
      }
    });


    // Generates all options for term dropdown
    this.termOptions = Array(118)
      .fill(0)
      .map((x, i) => i + 3);

      this.urlUtm();
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }

    if (this.ipSubscription) {
      this.ipSubscription.unsubscribe();
    }
  }

  urlUtm(): void {
    const allParams = this.activatedRoute.snapshot.queryParamMap;

    const utmParms = allParams.keys.filter((f) => f.startsWith('utm_'));
    if (utmParms?.length > 0) {

      for (const paramKey of utmParms) {
        this.utmSignature[paramKey.replace('utm_', '')] = allParams.get(paramKey);

      }
    }
  }

  acceptTerms(): void {
    this.processingError = false;
    this.processingSignature = true;
    this.step = 'processing';

    const contract: Contract = {
      html: this.contract,
      uid: this.customer.uid,
      address: this.customer.address,
      addressStr: this.address,
      banks: this.banksStr,
      cnpj: this.customer.cnpj,
      companyName: this.customer.companyName,
      cpf: this.customer.cpf,
      createdAtMillis: firestore.Timestamp.fromDate(this.contractDate.toDate()).toMillis(),
      ip: this.ip,
    };
    this.contractService.updateCustomerJourneyControl(this.customer.cnpj.replace(/[^\d]/g, ''));
    this.contractService.updateNumberCustomer(1);
    if(this.customer?.utm.source === 'OAuth - BNDES'){
      const utmSource = {
        source: 'OAuth - BNDES'
      }
      const cnpj = this.customer.cnpj.replace(/[^\d]/g, '');
      this.contractService.updateCustomerJourneyControlUtm(cnpj, utmSource)
    }


    if(this.customer.utm){
      const utmSignature =  {
        ...this.utmSignature,
        // Atribui o valor de source a utm.source
      }
      this.contractService.updateCustomerUtmSignature(this.customer.uid, utmSignature)
    }
    this.customerService
      .signTerms(contract)
      .then(() => {
        analyticsReportConversion('funil', 'aceitou-termo', 'termo-servico');
        this.processingSignature = false;
        console.log('Terms successfully signed');
      })
      .catch((err) => {
        console.error('Error signing terms', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '500px',
          data: {
            alertTitle: 'Erro',
            alertDescription: 'Ocorreu um erro ao tentar realizar a assinatura do termo. Tente novamente.',
          },
        });
        this.processingSignature = false;
        this.processingError = true;
      });
  }

  calculateFee(term: number): number {
    const fee = 0.045;
    // if (term >= 24) {
    //   fee = 0.045;
    // }
    return fee;
  }

  calculateMontlyFee(): number {
    return this.calculateFee(this.simulationTerm) / this.simulationTerm;
  }

  calculateMontlyValue(): string {
    return UtilHandler.getFormattedPrice(this.parseSimulationValue() * this.calculateMontlyFee());
  }

  calculateValue(): string {
    return UtilHandler.getFormattedPrice(this.parseSimulationValue() * this.calculateFee(this.simulationTerm));
  }

  fillContract(): void {
    this.contractService
      .getContractConfiguration()
      .then((config) => {
        this.contractDate = moment().locale('pt-BR');

        this.address =
          this.customer?.address?.street +
          ', ' +
          this.customer?.address?.number +
          ' - ' +
          this.customer?.address?.city +
          ' - ' +
          this.customer?.address?.state +
          ', ' +
          'CEP: ' +
          this.customer?.address?.zipCode;

        this.banksStr = this.customer?.banks?.map((b) => b.name).filter((name) => name !== 'Outro').join(', ');

        this.contract = config.html
          .replace(/\{\{id\}\}/g, this.customer?.uid)
          .replace(/\{\{cnpj\}\}/g, this.customer?.cnpj)
          .replace(/\{\{companyName\}\}/g, this.customer?.companyName)
          .replace(/\{\{cpf\}\}/g, this.customer?.cpf)
          .replace(/\{\{ip\}\}/g, this.ip)
          .replace(/\{\{dateStr\}\}/g, this.contractDate.format('LL'))
          .replace(/\{\{dateKey\}\}/g, this.contractDate.format('YYYY-MM-DD_HH:mm:ss:SSS'))
          .replace(/\{\{address\}\}/g, this.address)
          .replace(/\{\{banks\}\}/g, this.banksStr);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  generateGuaranteesString(guarantees, full = false): string {
    const sorted = _.sortBy(guarantees, (g) => g.length);
    if (full) {
      return sorted.join(', ');
    } else {
      let str = sorted.slice(0, this.MAX_DISPLAYED_GUARANTEES).join(', ');

      if (guarantees.length > this.MAX_DISPLAYED_GUARANTEES) {
        str += `, +${ guarantees.length - this.MAX_DISPLAYED_GUARANTEES }`;
      }

      return str;
    }
  }

  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }

  nextStep(): void {
    this.step = 'terms';
    this.openDialogTerms();
  }

  openFeesDialog(): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        alertDescription: `<p>Tarifas cobradas sobre o <strong>valor total recebido</strong>:</p>
        <ul>
          <li>4,50%: cartão de crédito e desconto de faturas</li>
          <li>4,50%: operações com prazo menor que 24 meses</li>
          <li>4,50%: operações com prazo maior que 24 meses</li>
        </ul>`,
        isOnlyConfirm: true,
      },
    });
  }

  openInfoDialog(): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        alertDescription: `Valor total de <strong>${ (100 * this.calculateFee(this.simulationTerm)).toFixed(
          1
        ) }%</strong> (${ this.calculateValue() })
        sobre o valor acordado com a instituição financeira (<strong>${ UtilHandler.getFormattedPrice(
          this.parseSimulationValue()
        )}</strong>).` + `<br>` + `O pagamento deverá ser realizado a vista, após a liberação da operação.`,
        isOnlyConfirm: true,
      },
    });
  }

  openDialogTerms(): void {
    this.dialog.open(HtmlDialogComponent, {
      data: {
        content: this.contract,
        isOnlyConfirm: true,
      },
    });
  }

  parseSimulationValue(): number {
    const valueStr = `${ this.simulationValue }`.replace('R$ ', '').replace(/\./g, '').replace(',', '.');
    return parseFloat(valueStr);
  }

  previousStep(): void {
    this.step = 'opportunities';
  }
}
