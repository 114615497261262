import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
  @Input() public tree: any;

  leafKeys = [];
  nodeKeys = [];

  ngOnInit(): void {
    const keys = Object?.keys(this.tree) ?? null;
    if (!!keys) {
      this.leafKeys = _.filter(
        keys,
        (k) =>
          !!this.tree[k] &&
          (typeof this.tree[k] === 'number' || typeof this.tree[k] === 'string' || typeof this.tree[k] === 'boolean')
      );
      this.nodeKeys = _.filter(
        keys,
        (k) => !!this.tree[k] && (Array.isArray(this.tree[k]) || typeof this.tree[k] === 'object')
      );
    }
  }
}
