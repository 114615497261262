import { MediaMatcher } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Customer } from 'functions/src/models/Customer';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { breakpoints, environmentLabels, sidenavAllowedURLs } from 'src/app/constants';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SettingsState, initialState } from 'src/app/core/settings/settings.reducer';
import { CustomerOnboardingDialogComponent } from 'src/app/customer/components/customer-onboarding/customer-onboarding.component';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { environment as env } from 'src/environments/environment';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { timestampForDate } from '../../../../../functions/src/utils/dates';
import { OpportunityManagementService } from '../../../admin/services/opportunity-management.service';
import { ContactsCustomer } from '../../../chat/models/contact';
import { ChatService } from '../../../chat/services/chat.service';
import { CreditRecurrenceComponent } from '../../../components/credit-recurrence/credit-recurrence.component';
import { FinancialInstitutionsService } from '../../../customer/services/financial-institutions.service';
import { SidebarStateService } from '../../../customer/services/sidebar-state.service';

@Component({
  selector: 'anms-template-site',
  templateUrl: './template-site.component.html',
  styleUrls: ['./template-site.component.scss'],
})
export class TemplateSiteComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  customerSubscription: Subscription;

  unreadMessagesCount = 0;
  messagesSubscription: Subscription;
  customersWithContact: ContactsCustomer[]


  @HostBinding('class') componentCssClass;
  @Input() customers: any[] = [];

  // isProd = env.production;
  isProd = env.envName === 'PROD';
  envName = env.envName;
  envLabel = environmentLabels[env.envName] || environmentLabels['DEV:LOCAL'];
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = 'assets/images/capital-white-xs.svg';
  logoFull = 'assets/images/new-capital-white.png';
  loginAction = null;
  customer: Customer = null;
  opened = false;

  hasOppsByRecurrence = false;
  // customerUID: string;
  opportunities: Opportunity[];

  readonly urlPrivacyPolicy =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Privacidade%20-%20Capital%20Empreendedor.pdf';

  mobileQuery: MediaQueryList;
  toggled = false;
  expanded = true;

  customerUid: string;
  isCustomerNoTouch: boolean;
  selectedItem: string | null = null;
  customersSubscription: any;
  hasUnreadMessages: boolean;
  cnpj: string



  constructor(
    public authService: AuthService,
    public customerService: CustomerService,
    private dialog: MatDialog,
    public overlayContainer: OverlayContainer,
    private router: Router,
    private titleService: Title,
    private oppManagementService: OpportunityManagementService,
    private financialInstitutionsService: FinancialInstitutionsService,
    private changeDetectorRef: ChangeDetectorRef,
    private chatService: ChatService,
    media: MediaMatcher,
    private sidebarStateService: SidebarStateService
  ) {
    // Listener p/ resize de tela -> Ajuste da sidebar
    this.mobileQuery = media.matchMedia(`(max-width: ${ breakpoints.lg }px)`);

    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.toggled = !this.mobileQuery.matches;
  }

  ngOnInit(): void {
    this.sidebarStateService.getCurrentSection().subscribe((res) => {
      this.selectedItem = res.title;
    })

    this.customerSubscription = this.customerService.customer.subscribe((customer) => {
      this.customer = customer;
      this.customerUid = this.customer?.uid;
      this.isCustomerNoTouch = this.customerService.checkNoTouch(this.customer);
      console.log(this.isCustomerNoTouch)
      if (customer?.showOnboarding && !this.opened) {
        this.opened = true;
        const dialogSub = this.dialog
          .open(CustomerOnboardingDialogComponent, {
            maxWidth: '828px',
            maxHeight: '100vh',
            disableClose: true,
            data: {
              uid: this.customer.uid,
              firstName: (customer.name || '').split(/\s/g)[0],
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.customerService
              .updateCustomerOnFirestore(this.customer.uid, { showOnboarding: false })
              .then(() => {
                console.log('Onboarding flag was unmarked');
              })
              .catch((err) => {
                console.error('Error unmarking onboarding flag', err);
              });

            if (dialogSub) {
              dialogSub.unsubscribe();
            }

          });

      }
      this.getOppotunities();

      if (this.customer) {
        this.checkUnreadMessages()
      }

    });
    this.subscribeToSettings();
    this.subscribeToRouterEvents();
  }

  selected(item: string) {
    return this.selectedItem = item;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreditRecurrenceComponent, {
      width: '250px',
      data: {
        customer: this.customer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }
  }

  private _mobileQueryListener(): void {
    return this.changeDetectorRef?.detectChanges();
  }

  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }

  onLoginClick(): void {
    this.router.navigate(['/entrar']);
  }

  onLogoutClick(): void {
    this.authService.logout();
  }

  toggle(): void {
    // Sidebar toggle
    if (this.mobileQuery.matches) {
      this.toggled = !this.toggled;
      this.expanded = true;
    } else {
      this.toggled = true;
      this.expanded = !this.expanded;
    }
  }

  shouldSidenavBeVisible(): boolean {
    return this.customer !== null && this.customer.agreedTerms && sidenavAllowedURLs.indexOf(this.router.url) >= 0;
  }

  private subscribeToSettings(): void {
    this.setTheme(initialState);
  }

  private setTheme(settings: SettingsState): void {
    const effectiveTheme = settings.theme.toLowerCase();
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd || event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        if (event instanceof ActivationEnd) {
          this.setPageTitle(event);
        }
      });
  }

  private setPageTitle(event: ActivationEnd): void {
    let lastChild = event.snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const { title } = lastChild.data;
    this.titleService.setTitle(title ? `${ title } - ${ env.appName }` : env.appName);
  }

  getOppotunities(): void {
    this.oppManagementService.getOpportunities(this.customerUid).then((opps) => {
      this.opportunities = opps;
      const [opportunity] = opps.sort((a, b) => a.closingDate < b.closingDate ? 1 : -1);
      const allOppsClosed = !opps?.find(opp => ![100, 0].includes(opp.stageOpportunity.defaultprobability));
      if (opportunity && allOppsClosed) {
        this.onHasOppsByRecurrence(opportunity);
      }
    });
  }

  onHasOppsByRecurrence(opp: Opportunity): void {
    const lastRecurrenceDate = this.customer.lastRecurrenceDate ? timestampForDate(this.customer.lastRecurrenceDate) : null;
    const closingDate = new Date(opp.closingDate);
    const dateOpp = !lastRecurrenceDate ? closingDate : (closingDate > lastRecurrenceDate ? closingDate : lastRecurrenceDate);
    const diffInMs = Math.abs(new Date().getTime() - dateOpp.getTime());
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    this.hasOppsByRecurrence = diffInDays >= 90;
  }

  async checkUnreadMessages(): Promise<void> {
    const customerWhatsapp = this.customer?.whatsapp?.replace(/\D/g, '')
    this.unreadMessagesCount = this.customer?.totalUnreadMessagesByCustomer || 0

    if (this.unreadMessagesCount === 0) {
      try {
        const contacts = await this.chatService.obseveContactsByCustomer(this.customer.cnpj).toPromise();

        for (const contact of contacts) {
          const contactWhatsapp = contact.whatsapp.replace(/\D/g, '');
          const data = await this.chatService.getCountCdcMessagesCustomerAsync(
            contactWhatsapp,
            this.customer.uid
          );

          if (customerWhatsapp === contactWhatsapp && data) {
            this.unreadMessagesCount += data.unreadCount;
          }
        }
      } catch (error) {
        console.error('Erro ao obter contagem de mensagens:', error);
      }
    }

  }

}


