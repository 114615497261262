import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OpenFinanceService {

  constructor(private firestore: AngularFirestore,  private http: HttpClient, private authService: AuthService) { }

  getOpenFinanceData(uid: string): Observable<any> {
    return this.firestore.collection(`customers/${uid}/openfinance`).valueChanges();
  }


  generateApiKey(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `https://integrationsapi-cpmwlhq6nq-uc.a.run.app/pluggy/generate-api-key`,
          { data: { } },
          { responseType: 'json', headers: this.authService.getHeader() },
        )
        .toPromise()
        .then(() => {
          console.log('api key gerada');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao gerar api key', err);

          reject('error-apikey-pluggy');
        });
    });
  }

  generateAccessToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `https://integrationsapi-cpmwlhq6nq-uc.a.run.app/pluggy/generate-access-token`,
          { data: { } },
          { responseType: 'json', headers: this.authService.getHeader() },
        )
        .toPromise()
        .then(() => {
          console.log('AccessToken gerado');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao gerar AccessToken', err);

          reject('error-accessToken-pluggy');
        });
    });
  }

  getLoanInfo(uid: string, itemId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `https://integrationsapi-cpmwlhq6nq-uc.a.run.app/pluggy/loan-info`,
          {
            uid,
            itemId
          },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('get loan info');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao retornar dados loan info', err);

          reject('error-loanInfo-pluggy');
        });
    });
  }

  getCredentials(): Observable<any>{
    return this.firestore.collection('configuracao').doc('pluggy').valueChanges();
  }



}
