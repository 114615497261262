<div class="container">
  <mat-card>
    <mat-card-content>
      <mat-card-title> Dashboard - Em breve... </mat-card-title>
      <section class="mt-4">
        Esta funcionalidade ainda está em desenvolvimento e estará disponívem em breve. Aguarde!
      </section>
    </mat-card-content>
  </mat-card>
</div>
