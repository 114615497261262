import { Component, OnDestroy, OnInit } from '@angular/core';

// import { analyticsReportConversion } from 'src/app/core/handler/googleAnalytics';

@Component({
  // selector: 'anms-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    //
  }
}
