<div class="assigned-wrapper" (click)="closeMenuContactSelected()">
  <div class="assigned-title" (contextmenu)="blockRightClickMouse($event)">
    <h2 *ngIf="selectedMenuItem?.state !== '' ">{{showCustomerLabel}}</h2>
    <ng-container>
      <img src="../../../assets/images/icons/add-user.svg" alt="" (click)="openPopup()" class="img-icon">
      <app-contact-form-component *ngIf="showPopup" (closePopup)="closePopup()"></app-contact-form-component>
    </ng-container>
  </div>
  <mat-tab-group>
    <mat-tab label="Clientes">
      <div class="customers-section">

        <div class="filter-section" (contextmenu)="blockRightClickMouse($event)">
          <input #cnpjInput type="text" placeholder="Pesquisar CNPJ" (input)="filterContacts()">
          <input #companyInput type="text" placeholder="Pesquisar Razão Social" (input)="filterContacts()">
          <input #dateInput type="text" placeholder="Pesquisar Data" (input)="formatDateInput($event);filterContacts()">
        </div>
        <div class="typeOrder" (contextmenu)="blockRightClickMouse($event)">
          <button mat-raised-button (click)="sortList('notifications')"
            [ngClass]="{ 'typeOrder-selected': typeSorting === 'notifications' }">
            <mat-icon>notifications</mat-icon>
          </button>
          <span class="button-spacing"></span>
          <button mat-raised-button (click)="sortList('assigned')"
            [ngClass]="{ 'typeOrder-selected': typeSorting === 'assigned' }">
            <mat-icon>font_download</mat-icon>
          </button>
          <span class="button-spacing"></span>
          <button mat-raised-button (click)="sortList('textsms')"
            [ngClass]="{ 'typeOrder-selected': typeSorting === 'textsms' }">
            <mat-icon>textsms</mat-icon>
          </button>
        </div>
        <div>
          <div class="today-section-container">
            <div class="today-section--label" (contextmenu)="blockRightClickMouse($event)">
              <span>HOJE ({{todayContacts?.length || 0}})</span>
            </div>
            <div class="today-section--content">
              <div class="w-100 p-5" *ngIf="isLoadingToday">
                <mat-progress-spinner *ngIf="isLoadingToday" [diameter]="60" class="mx-auto" color="accent"
                  mode="indeterminate"></mat-progress-spinner>
              </div>
              <div *ngFor="let contact of todayContacts; trackBy: trackByFn">
                <div
                  (contextmenu)="openMenuContactSelected($event, contact)">
                  <app-contact-item [contact]="contact" [isLoading]="isLoadingToday"
                    [isToday]="true"></app-contact-item>

                </div>
              </div>
            </div>
          </div>
          <div class="otherdays-section-container">
            <div class="otherdays-section--label" (contextmenu)="blockRightClickMouse($event)">
              <span>Outros dias ({{otherContacts?.length || 0}})</span>
            </div>
            <div class="otherdays-section--content">
              <div class="w-100 p-5" *ngIf="isLoadingOtherdays">
                <mat-progress-spinner *ngIf="isLoadingOtherdays" [diameter]="60" class="mx-auto" color="primary"
                  mode="indeterminate"></mat-progress-spinner>
              </div>
              <div *ngFor="let contact of otherContacts; trackBy: trackByFn">
                <div *ngIf="contact?.totalUnreadMessages !== undefined "
                  (contextmenu)="openMenuContactSelected($event, contact)">
                  <app-contact-item [contact]="contact" [isLoading]="isLoadingOtherdays"
                    [isToday]="false"></app-contact-item>

                </div>
              </div>
            </div>
            <div class="text-center" (contextmenu)="blockRightClickMouse($event)">
              <button mat-raised-button type=button color="primary" style="color: white; margin: 40px 20px 10px 20px;"
                (click)="loadMoreItems()" [disabled]="runLoadMore">
                <mat-icon *ngIf="!runLoadMore">arrow_downward</mat-icon>
                <mat-spinner *ngIf="runLoadMore" [diameter]="20" style="margin: 5px auto;"></mat-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="Instituições Financeiras" [disabled]="true">Instituições Financeiras</mat-tab>
  </mat-tab-group>

  <div *ngIf="isMenuOpen" class="menu-dropdown" [style.left.px]="coords.x" [style.top.px]="coords.y">
    <ul>
      <li (click)="markUnreadMessageMenuContactSelected(companySelected)"
        *ngIf="companySelected.totalUnreadMessages === 0">Marcar conversa como não lida</li>
      <li (click)="markReadMessageMenuContactSelected(companySelected)"
        *ngIf="companySelected?.totalUnreadMessages > 0">
        Marcar conversa como lida</li>
      <div class="menu-divider"></div>
      <li (click)="assignedForMe(companySelected)"
        *ngIf="!companySelected?.attributedTo || companySelected.attributedTo !== loggedUser.uid">
        Atribuir conversa para mim</li>
      <li (click)="removeAssigned(companySelected)" *ngIf="companySelected?.attributedTo">Remover atribuição</li>
      <div class="menu-divider"></div>
      <li (click)="closeMenuContactSelected()">Voltar</li>
    </ul>
  </div>
</div>