import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Customer } from '../../../../functions/src/models/Customer';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private contactCollection: AngularFirestoreCollection<Contact>;
  contacts: Observable<Contact[]>;

  constructor(private firestore: AngularFirestore) {
    this.contactCollection = this.firestore.collection<Contact>('cdc-contacts');
    this.contacts = this.contactCollection.valueChanges();
  }

  getContacts(): Observable<Contact[]> {
    return this.contacts;
  }

  addContact(contact: Contact): Promise<void> {
    const id = contact.whatsapp;
    return this.contactCollection.doc(id).set(contact);
  }

  updateContact( updatedContact: Contact): Promise<void> {
    return this.contactCollection.doc().update(updatedContact);
  }

  deleteContact(): Promise<void> {
    return this.contactCollection.doc().delete();
  }

    getCustomerByCnpj(cnpj: string): Observable<Customer | null> {
      return this.firestore.collection<Customer>('customers', ref => ref.where('cnpj', '==', cnpj).limit(1))
        .valueChanges({ idField: 'id' })
        .pipe(
          take(1), // take(1) para obter apenas o primeiro valor emitido e depois parar
          map(customers => customers.length > 0 ? customers[0] : null)
        );

  }
}

interface Contact {
  id: string;
  uid: string;
  contactName: string;
  whatsapp: string;
  description: string;
  companyName?: string;
  bankerName?: string;
  type: string;
  cnpj?: string;
  bankName?: string;
  createdAt?: Date;
}
