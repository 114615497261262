import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Document as DocumentMongoDB } from 'functions/src/models/documents/UserDocument';
import { MASKS } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import { StatsService } from '../../../admin/services/stats.service';
import { UserManagementService } from '../../../admin/services/user-management.service';
import { AgentService } from '../../services/agent.service';
@Component({
  selector: 'app-pending-documents',
  templateUrl: './pending-documents.component.html',
  styleUrls: ['./pending-documents.component.scss'],
})
export class PendingDocumentsComponent implements OnInit, OnDestroy {
  readonly MASKS = MASKS;

  usersPendingDocuments = undefined;
  isLoadingCustomers = false;
  cnpj: FormControl;
  companyName: FormControl;
  email: FormControl;
  agent: string; // Only UID
  agentSubscription: Subscription;

  constructor(
    private ums: UserManagementService,
    private agentService: AgentService,
    private userDocumentService: UserDocumentService,
    private statsService: StatsService
  ) {}

  ngOnInit(): void {
    this.agentService.redirectAccordingStatus(true, '/agents/pending-documents', '/agents/pending-documents');

    this.cnpj = new FormControl();
    this.email = new FormControl();
    this.companyName = new FormControl();
    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.agent = agent?.uid;
    });
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }

  getPendingDocuments(): void {
    this.usersPendingDocuments = [];
    this.isLoadingCustomers = true;

    const params = {
      selectedFields: ['cnpj', 'companyName', 'cnpj', 'email', 'uid'],
      cnpj: this.cnpj.value || null,
      companyName: this.companyName.value || null,
      email: this.email.value || null,
      agreedTerms: true,
      agent: this.agent,
    };

    this.statsService.getAllDocumentsPending(params).subscribe((leadsWithPendingDocuments) => {
      if (leadsWithPendingDocuments?.length > 0) {
        this.usersPendingDocuments.push(...leadsWithPendingDocuments);
      }
      this.isLoadingCustomers = false;
    });
  }

  clearAll(): void {
    this.cnpj.setValue('');
    this.email.setValue('');
    this.companyName.setValue('');
  }
}
