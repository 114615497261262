<mat-expansion-panel *ngIf="item && !documentDeleted" class="{{'mat-panel' + borderStyle}}" [style]="getPainelColorStyle()">
  <mat-expansion-panel-header class="col-12" style="vertical-align: middle;"
    (click)="getDocumentInfo(item); verifyUploadStatus(item)">
    <mat-panel-title class="col-8">
      {{ item?.name }}
    </mat-panel-title>
    <mat-panel-description class="col-4">
      <mat-label><!--style="margin-left: 100px;"-->
        <mat-chip-list>
          <mat-chip [color]="item?.situation === 'Reprovado' ? 'warn' : 'primary'"
            [selected]="item?.situation === 'Aprovado' || item?.situation === 'Reprovado'">
            {{ item?.situation }}
          </mat-chip>
        </mat-chip-list>
      </mat-label>

      <mat-icon *ngIf="item?.pendingIssueForAdmin" class="pendencia-element">*</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="d-flex flex-row">
    <mat-form-field>
      <mat-label>Ordem de exibição (ordenação crescente)</mat-label>
      <input matInput type="number" placeholder="Ordem" [value]="item.ordenation" min="0" step="1" pattern="\d+"
        onInput="validity.valid||(value=0);" [(ngModel)]="item.ordenation" />
    </mat-form-field>

    <div style="margin-left: 30px">
      <button mat-flat-button type="button" color="accent" class="btn" (click)="onSalvarOrdenacao(item?.mnemonic)"
        style="height: 40px">
        <mat-icon>check</mat-icon> Salvar ordenação
      </button>
    </div>
  </div>

  <div class="descricao">
    <div class="d-flex justify-content-between flex-row">
      <h5>Descrição</h5>
    </div>
    <div class="d-flex justify-content-between flex-column flex-md-row">
      <mat-label>{{ item?.description }}</mat-label>
    </div>
  </div>

  <div class="detalhes">
    <h5>Detalhes</h5>

    <div class="d-flex flex-column">
      <div *ngIf="item?.situation === 'Aprovado' && item?.approvalOrDisapprovalDate">
        <mat-label>
          Aprovado em
          {{
          item?.approvalOrDisapprovalDate
          ? (item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss')
          : '-'
          }}
        </mat-label>
      </div>
      <div *ngIf="item?.situation === 'Reprovado' && item?.approvalOrDisapprovalDate">
        <mat-label>
          Reprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </mat-label>

        <br />

        <mat-label *ngIf="item?.disapprovalReason"> Motivo: {{ item?.disapprovalReason }}
        </mat-label>
      </div>
    </div>

    <div style="padding-top: 10px">
      <mat-label>
        Aguardando
        {{
        item.qtyExpectedFiles < item.qtySubmittedFiles ? 0 : item.qtyExpectedFiles - item.qtySubmittedFiles }} de {{
          item.qtyExpectedFiles }} {{ item.qtyExpectedFiles> 1 ?
          'arquivos' : 'arquivo' }}
      </mat-label>
    </div>

    <div class="d-flex flex-column flex-md-row" *ngIf="item?.expirationDate?.id === 'sim'" style="padding-top: 10px">
      <mat-label> Validade: {{ item?.qtyDays }} dias</mat-label>
    </div>

    <div class="instrucoes">
      <h4>Instruções</h4>
      <div innerHtml="{{ item?.instrucoes }}" style="padding-top: 5px"></div>
    </div>

    <div class="d-flex align-items-center flex-column flex-md-row"
      *ngIf="item.financialYear?.id === 'sim' && item?.financialYears?.length > 0" style="padding-top: 5px">
      <mat-label> Anos de Exercício:&nbsp; </mat-label>
      <mat-accordion class="year-list">
        <mat-expansion-panel class="year" *ngFor="let year of item.financialYears; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ year }}
            </mat-panel-title>
            <mat-panel-description>
              {{ getDocumentStatus(item, year) }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="mb-2">
            Anexe o {{ item.name }} de {{ year }} abaixo e informe a data de emissão do documento.
          </p>
          <div *ngIf="item?.fileInfo?.length > 0">
            <div *ngFor="let file of item.fileInfo; let i = index">
              <p *ngIf="file?.financialYear === year" class="file">
                <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
                  {{ file?.fileName ? file.fileName : item.name }}
                </a>
                <mat-icon inline="true" class="ml-2 icon" (click)="onRemoveFileDocument(i, item, file)">clear</mat-icon>
                <span *ngIf="file.dateUpload">
                  &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                </span>
              </p>
            </div>
          </div>
          <div *ngIf="item.situation !== 'Aprovado'">
            <p class="file" *ngIf="item?.fileInfo?.length === 0">Nenhum arquivo anexado</p>
            <div class="attach-file">
              <span matTooltip="Limite de arquivos submetidos atingido." [matTooltipDisabled]="canUpload">
                <input type="button" mat-raised-button class="btn-success" value="Enviar arquivo"
                  (click)="onSendNewFile(item, year)" [disabled]="
                  item.situation === 'Aprovado' || getDocumentStatus(item, year) === 'Enviado'
                ">
              </span>
            </div>
          </div>

          <ng-container *ngIf="customer?.hasRecurrence === true">
            <div style="padding-top: 5px" *ngIf="item?.fileInfo.length > 0">
              <span>Houve indicação de atualização: </span>
              <mat-label [ngClass]="{
                  'status-aguardando': determineStatus(item) === 'Aguardando',
                  'status-houve-alteracao': determineStatus(item) === 'Houve atualização',
                  'status-nao-houve-alteracao': determineStatus(item) === 'Não houve atualização'
                }"> {{ determineStatus(item)}}</mat-label>

            </div>
          </ng-container>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="d-flex flex-column files" *ngIf="item.financialYear?.id === 'nao'" style="padding-top: 5px">
      <p class="mb-2">Anexe o {{ item.name }} abaixo e informe a data de emissão do documento.</p>
      <div *ngIf="item.financialYear.id === 'nao'">
        <div *ngIf="item?.fileInfo?.length > 0">
          <p class="file" *ngFor="let file of item.fileInfo">
            <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
              {{ file?.fileName ? file.fileName : item.name }}
            </a>
            <mat-icon inline="true" class="ml-2 icon" (click)="onRemoveFileDocument(null, item, file)">clear</mat-icon>
            <span *ngIf="file.dateUpload">
              &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
            </span>
          </p>
        </div>
        <div *ngIf="item?.fileInfOld?.length > 0">
          <p class="file" *ngFor="let file of item.fileInfOld">
            <a class="attached-file-link cursor-style" [href]="file.path" target="_blank">
              {{ file?.fileName ? file.fileName : item.name }}
            </a>
            <mat-icon inline="true" class="ml-2 icon" (click)="onRemoveFileDocument(null, item, file)">clear</mat-icon>
            <span *ngIf="file.dateUpload">
              &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
            </span>
          </p>
        </div>
        <div *ngIf="canUpload">
          <p class="file" *ngIf="!selectedDocument?.length || !selectedDocument[0]?.documents?.length">
            Nenhum arquivo anexado
          </p>
          <div class="attach-file">
            <span matTooltip="Limite de arquivos submetidos atingido." [matTooltipDisabled]="canUpload">
              <input type="button" mat-raised-button class="btn-success" value="Enviar arquivo"
                (click)="onSendNewFile(item, year)" [disabled]="item.situation === 'Aprovado' || !canUpload">
            </span>
          </div>
          <ng-container *ngIf="customer?.hasRecurrence === true">
            <div style="padding-top: 5px" *ngIf="item?.fileInfo.length > 0">
              <span>Houve indicação de atualização: </span>
              <mat-label [ngClass]="{
                'status-aguardando': determineStatus(item) === 'Aguardando',
                'status-houve-alteracao': determineStatus(item) === 'Houve atualização',
                'status-nao-houve-alteracao': determineStatus(item) === 'Não houve atualização'
              }"> {{ determineStatus(item)}}</mat-label>

            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="acoes">
    <div class="d-flex flex-column flex-md-row">
      <button mat-raised-button color="primary" (click)="onApproveDocuments(item.typeId)"
        *ngIf="item.situation === 'Reprovado' || item.situation === 'Enviado Parcialmente' || item.situation === 'Envio Completo'"
        class="mr-2">
        <mat-icon>thumb_up</mat-icon>
        &nbsp;Aprovar
      </button>

      <button mat-raised-button color="warn" (click)="onReprovarDocumentos(item.typeId)"
        *ngIf="item.situation === 'Envio Completo' || item.situation === 'Aprovado' || item.situation === 'Enviado Parcialmente'"
        class="mr-2">
        <mat-icon>thumb_down</mat-icon>
        &nbsp;Reprovar
      </button>

      <button mat-stroked-button color="warn"
        [class.ml-4]="item.situation === 'Reprovado' || item.situation === 'Envio Completo'" class="btn-cancel"
        (click)="onRemoverDocumento()">
        <mat-icon>delete_forever</mat-icon> &nbsp;Remover
      </button>
    </div>
  </div>

  <!-- <div class="instrucoes">
  <h4>Instruções</h4>
  <div innerHtml="{{ item?.instrucoes }}" style="padding-top: 5px"></div>
</div> -->
</mat-expansion-panel>