import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AgentLeadsService } from '../../services/agent-leads.service';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'app-agent-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
})
export class AgentLeadsComponent implements OnInit, OnDestroy {
  // Data
  leads: User[] = [];

  agent: string; // Only UID
  agentSubscription: Subscription;

  displayedColumns: string[] = [
    'name',
    'email',
    'companyName',
    'cnpj',
    'createdAt',
    'lastInteraction',
    'status',
    'actions',
  ];
  dataSource: MatTableDataSource<User>;

  // Control
  isLoadingResults = true;

  // Filters
  labels = {
    name: 'Nome',
    email: 'E-mail',
    companyName: 'Razão Social',
    cnpj: 'CNPJ',
  };
  selectedFilter = 'email';
  currentFilterValue = '';
  filterValue = '';

  // Pagination
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [10, 25, 40, 100];
  currentPage = 0;

  // Sorting
  sortField = 'createdAt';
  sortDirection = 'desc';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private agentService: AgentService,
    private agentLeadsService: AgentLeadsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoadingResults = true;

    this.agentService.redirectAccordingStatus(true, '/agents/leads', '/agents/leads');

    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.agent = agent?.uid;
      if (this.agent) {
        this.applyFilter();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
  }

  applyFilter(): void {
    this.currentFilterValue = this.filterValue;

    this.isLoadingResults = true;

    this.agentLeadsService
      .searchLeads({
        agent: this.agent,
        filterField: this.selectedFilter,
        filterValue: this.currentFilterValue,
        orderField: this.sortField,
        orderDirection: this.sortDirection,
        pageSize: this.pageSize,
        page: this.currentPage,
      })
      .then((response) => {
        this.leads = response;
        this.length = response.length;

        this.dataSource = new MatTableDataSource(this.leads);
        this.isLoadingResults = false;
      })
      .catch((err) => {
        console.error('Error getting leads of agent.', err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao buscar leads',
              alertDescription: `Ocorreu um erro ao buscar seus leads. Por favor, tente novamente mais tarde.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.isLoadingResults = false;
          });
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string): void {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  paginatorEvent(event: PageEvent): void {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.currentPage = 0;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.applyFilter();
  }

  sortData(event: Sort): void {
    this.sortDirection = event.direction || 'asc';
    this.sortField = event.active;
    this.currentPage = 0;
    this.applyFilter();
  }

  clearFilter(): void {
    this.currentFilterValue = '';
    this.filterValue = '';
    this.currentPage = 0;
    this.applyFilter();
  }
}
