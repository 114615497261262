<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Relatórios - Clientes</span>
      </mat-card-title>

      <div class="row">
        <div class="col-12">
          <!-- select report -->
          <mat-form-field color="primary" style="flex: 1">
            <mat-label>Escolha o relatório</mat-label>
            <mat-select [formControl]="selectedReport" (ngModelChange)="onChangeFieldList(selectedReport.value)">
              <mat-option *ngFor="let report of reportList; let i = index" [value]="report.name">{{
                report.label
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <form [formGroup]="filterFormGroup" *ngIf="selectedReport?.value">
        <h4 class="m-3">Filtros:</h4>

        <div class="row">
          <div class="col-12" *ngIf="selectedReport.value === 'opportunities'">
            <mat-form-field class="flex-fill" color="primary" style="flex: 1"
              matTooltip="Escolha de quais instituições serão exibidas as oportunidades">
              <mat-label>Instituições</mat-label>
              <input type="text" matInput [matAutocomplete]="auto" formControlName="userControl" />
            </mat-form-field>

            <mat-autocomplete class="col-md-6" #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let i of InstitutionsFilter | async" [value]="selectedInstitutions">
                <div (click)="optionClicked($event, i)">
                  <mat-checkbox [checked]="i.selected" (change)="toggleSelection(i)" (click)="$event.stopPropagation()">
                    {{ i.name }}
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="col-12 d-flex align-items-center" *ngIf="selectedReport.value === 'customers'">
            <!-- filter by guarantees -->
            <mat-form-field color="primary" style="flex: 1; max-width: 180px" class="mr-2"
              matTooltip="Escolha o operador para as garantias">
              <mat-label>Filtro</mat-label>
              <mat-select formControlName="guaranteesOperator"
                (selectionChange)="selectedGuaranteesOperatorChange($event)">
                <mat-option value="exactly">É exatamente</mat-option>
                <mat-option value="yes-all">Possui todos</mat-option>
                <mat-option value="yes-any">Possui algum</mat-option>
                <mat-option value="not-all">Não possui nenhum</mat-option>
                <mat-option value="not-any">Não possui algum</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field color="primary" class="flex-fill" matTooltip="Selecione o(s) tipo(s) de garantia(s)">
              <mat-label>Garantias</mat-label>
              <mat-select multiple style="flex: 1" formControlName="selectedGuarantees"
                (selectionChange)="selectedGuaranteesChange($event)">
                <mat-option *ngFor="let guarantee of guarantees" [value]="guarantee">{{ guarantee.name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isTypeOfAnticipationEnabled" color="primary" class="ml-2 flex-fill"
              matTooltip="Selecione o(s) tipo(s) de antecipação(ões)">
              <mat-label>Tipo de Antecipação de Recebíveis</mat-label>
              <mat-select multiple style="flex: 1" formControlName="selectedAnticipations">
                <mat-option *ngFor="let anticipation of typeOfAnticipationList" [value]="anticipation">{{
                  anticipation.value
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- filter by banks -->
          <div class="col-12 d-md-flex justify-content-between m-0 p-0" *ngIf="selectedReport.value === 'customers'">
            <mat-form-field color="primary" class="col-md-3" matTooltip="Escolha o operador para os bancos do usuário">
              <mat-label>Filtro</mat-label>
              <mat-select formControlName="banksOperator" (selectionChange)="
                  $event.value
                    ? filterFormGroup.controls['selectedBanks'].enable()
                    : filterFormGroup.controls['selectedBanks'].disable()
                ">
                <mat-option value="exactly">É exatamente</mat-option>
                <mat-option value="yes-all">Possui todos</mat-option>
                <mat-option value="yes-any">Possui algum</mat-option>
                <mat-option value="not-all">Não possui nenhum</mat-option>
                <mat-option value="not-any">Não possui algum</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field color="primary" class="col-md-9" matTooltip="Escolha os bancos do usuário">
              <mat-label>Bancos do usuário</mat-label>
              <mat-select multiple style="flex: 1" formControlName="selectedBanks">
                <mat-option *ngFor="let bank of banks" [value]="bank.value">{{ bank.label }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- filter by revenue -->
          <div class="col-12 d-md-flex justify-content-between m-0 p-0"
            *ngIf="selectedReport.value === 'customers' || selectedReport.value === 'opportunities'">
            <mat-form-field color="primary" class="col-md-2" matTooltip="Escolha o operador para o faturamento">
              <mat-label>Filtro</mat-label>
              <mat-select formControlName="revenueOperator" (selectionChange)="
                  $event.value
                    ? filterFormGroup.controls['revenue'].enable()
                    : filterFormGroup.controls['revenue'].disable()
                ">
                <mat-option *ngFor="let operator of operators" [value]="operator.value">{{
                  operator.label
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-md-10" color="primary" matTooltip="Digite o valor do faturamento">
              <mat-label>Faturamento</mat-label>
              <input matInput type="text" name="revenue" [textMask]="{ mask: numberMask }" formControlName="revenue" />
            </mat-form-field>
          </div>

          <!-- filter by taxRegimeBDC -->
          <div class="col-12 col-md-4" *ngIf="selectedReport.value === 'customers'">
            <mat-form-field color="primary" style="flex: 1" matTooltip="Escolha o tipo de Empresa">
              <mat-label>Tipo de Empresa</mat-label>
              <mat-select formControlName="selectedTaxRegimeOption">
                <mat-option *ngFor="let option of taxRegimesList" [value]="option">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- filter by postal code -->
          <div class="col-12 col-md-4" *ngIf="selectedReport.value === 'customers'">
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o início do CEP desejado">
              <mat-label>CEP</mat-label>
              <input matInput type="text" name="postalCode" formControlName="zipCode"
                [textMask]="{ mask: MASKS.cep.textMask, guide: false }" />
            </mat-form-field>
          </div>

          <!-- filter by CNAE -->
          <div class="col-12 col-md-4" *ngIf="selectedReport.value === 'customers'">
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o início do CNAE desejado">
              <mat-label>CNAE</mat-label>
              <input matInput type="text" name="cnae" formControlName="cnae"
                [textMask]="{ mask: formatCnae, guide: false }" />
            </mat-form-field>
          </div>

          <!-- filter by razao social -->
          <div class="col-12" *ngIf="selectedReport.value === 'pendingDocuments'">
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o trecho da razão social desejada">
              <mat-label>Razão Social</mat-label>
              <input matInput type="text" name="companyName" formControlName="companyName" />
            </mat-form-field>
          </div>

          <!-- filter by cnpj -->
          <div class="col-12 col-lg-6" *ngIf="selectedReport.value === 'pendingDocuments'">
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o trecho do CNPJ desejado">
              <mat-label>CNPJ</mat-label>
              <input matInput type="text" name="cnpj" formControlName="cnpj"
                [textMask]="{ mask: MASKS.cnpj.textMask, guide: false }" />
            </mat-form-field>
          </div>

          <!-- filter by email -->
          <div class="col-12 col-lg-6" *ngIf="selectedReport.value === 'pendingDocuments'">
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o trecho do email desejado">
              <mat-label>E-mail</mat-label>
              <input matInput type="text" name="email" formControlName="email" />
            </mat-form-field>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <div class="flex-fill mr-2" *ngIf="selectedReport.value !== 'pendingDocuments'">
              <!-- filter by revenue -->
              <mat-form-field color="primary" style="flex: 1" matTooltip="Escolha a situação do termo de serviço">
                <mat-label>Assinou termo?</mat-label>
                <mat-select formControlName="selectedTermsOption"
                (selectionChange)="selectedTermsOption($event)">
                <mat-option *ngFor="let option of agreedTermsOptions"[value]="option.id ">{{option.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex-fill mr-2 ml-2" *ngIf="selectedReport.value === 'customers'">
              <!-- filter by agent -->
              <mat-form-field color="primary" style="flex: 1" matTooltip="Escolha a situação do assessor">
                <mat-label>Possui assessor?</mat-label>
                <mat-select formControlName="selectedAgentOption">
                  <mat-option [value]="true">Sim</mat-option>
                  <mat-option [value]="false">Não</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex-fill ml-2"
              *ngIf="selectedReport.value === 'customers' || selectedReport.value === 'opportunities'">
              <!-- filter by step -->
              <mat-form-field color="primary" style="flex: 1" matTooltip="Escolha quais fases serão exibidas">
                <mat-label>Etapas</mat-label>
                <mat-select multiple formControlName="selectedSteps">
                  <mat-option *ngFor="let step of steps" [value]="step">{{ step }}%</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- filter by score
          <div class="col-12 d-flex justify-content-between" *ngIf="selectedReport.value !== 'pendingDocuments'">
            <div class="flex-fill mr-2">
              <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o score mínimo">
                <mat-label>Score Mínimo</mat-label>
                <input
                  matInput
                  type="number"
                  name="scoreMinimo"
                  formControlName="scoreMin"
                  [min]="0"
                  [max]="numberMax()"
                />
              </mat-form-field>
            </div>

            <div class="flex-fill ml-2">
              <mat-form-field color="primary" style="flex: 1" matTooltip="Digite o score máximo">
                <mat-label>Score Máximo</mat-label>
                <input matInput type="number" name="scoreMaximo" formControlName="scoreMax" [min]="numberMin()" />
              </mat-form-field>
            </div>
          </div>
          -->
          <!-- filter by date lastInteraction-->
          <div class="col-12 d-flex justify-content-between" *ngIf="selectedReport.value === 'customers'">
            <mat-form-field class="flex-fill mr-2">
              <mat-label style="font-weight: 500">Data da última interação (Início)</mat-label>
              <input matInput [matDatepicker]="lastInteractionStart" placeholder="Data da última interação (Início)"
                name="lastInteractionStart" formControlName="lastInteractionStartCtrl"
                (focus)="lastInteractionStart.open()" />
              <mat-datepicker-toggle matSuffix [for]="lastInteractionStart"></mat-datepicker-toggle>
              <mat-datepicker #lastInteractionStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="flex-fill ml-2">
              <mat-label style="font-weight: 500">Data da última interação (Fim)</mat-label>
              <input matInput [matDatepicker]="lastInteractionEnd" placeholder="Data da última interação (Fim)"
                name="lastInteractionEnd" formControlName="lastInteractionEndCtrl"
                (focus)="lastInteractionEnd.open()" />
              <mat-datepicker-toggle matSuffix [for]="lastInteractionEnd"></mat-datepicker-toggle>
              <mat-datepicker #lastInteractionEnd></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- filter by date termSignature-->
          <div class="col-12 d-flex justify-content-between" *ngIf="selectedReport.value !== 'pendingDocuments'">
            <mat-form-field class="flex-fill mr-2">
              <mat-label style="font-weight: 500">Data de aceite do termo (Início)</mat-label>
              <input matInput [matDatepicker]="termsSignatureDateStart" placeholder="Data de aceite do termo (Início)"
                name="termsSignatureDateStart" formControlName="termsSignatureDateStartCtrl"
                (focus)="termsSignatureDateStart.open()" />
              <mat-datepicker-toggle matSuffix [for]="termsSignatureDateStart"></mat-datepicker-toggle>
              <mat-datepicker #termsSignatureDateStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="flex-fill ml-2">
              <mat-label style="font-weight: 500">Data de aceite do termo (Fim)</mat-label>
              <input matInput [matDatepicker]="termsSignatureDateEnd" placeholder="Data de aceite do termo (Fim)"
                name="termsSignatureDateEnd" formControlName="termsSignatureDateEndCtrl"
                (focus)="termsSignatureDateEnd.open()" />
              <mat-datepicker-toggle matSuffix [for]="termsSignatureDateEnd"></mat-datepicker-toggle>
              <mat-datepicker #termsSignatureDateEnd></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- filter by date createdAt-->
          <div class="col-12 d-flex justify-content-between" *ngIf="selectedReport.value !== 'pendingDocuments'">
            <mat-form-field class="flex-fill mr-2">
              <mat-label style="font-weight: 500">Data de cadastro (Início)</mat-label>
              <input matInput [matDatepicker]="timeCreatedStart" placeholder="Data de cadastro (Início)"
                name="timeCreatedStart" formControlName="timeCreatedStartCtrl" (focus)="timeCreatedStart.open()" />
              <mat-datepicker-toggle matSuffix [for]="timeCreatedStart"></mat-datepicker-toggle>
              <mat-datepicker #timeCreatedStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="flex-fill ml-2">
              <mat-label style="font-weight: 500">Data de cadastro (Fim)</mat-label>
              <input matInput [matDatepicker]="timeCreatedEnd" placeholder="Data de cadastro (Fim)"
                name="timeCreatedEnd" formControlName="timeCreatedEndCtrl" (focus)="timeCreatedEnd.open()" />
              <mat-datepicker-toggle matSuffix [for]="timeCreatedEnd"></mat-datepicker-toggle>
              <mat-datepicker #timeCreatedEnd></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6" *ngIf="selectedReport.value !== 'pendingDocuments'">
            <!-- filter by Origin -->
            <mat-form-field color="primary" style="flex: 1" matTooltip="Digite a origem">
              <mat-label>Origem</mat-label>
              <input matInput type="text" name="origem" formControlName="origem" />
            </mat-form-field>
          </div>

          <div class="flex-fill mr-2 ml-2" *ngIf="selectedReport.value === 'customers'">
            <!-- filter by hasRecurrence -->
            <mat-form-field color="primary" style="flex: 1" matTooltip="Escolha a situação do cliente recorrente">
              <mat-label>Cliente recorrente?</mat-label>
              <mat-select formControlName="hasRecurrence"
              >
                
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

         
          <div class="col-12 d-flex justify-content-between" *ngIf="filterFormGroup.get('hasRecurrence').value === true && selectedReport.value === 'customers'">
            <mat-form-field class="flex-fill mr-2">
              <mat-label style="font-weight: 500">Data recorrência (início)</mat-label>
              <input matInput [matDatepicker]="lastRecurrenceDateStart" placeholder="Data recorrência (Início)"
                name="lastRecurrenceDateStart" formControlName="lastRecurrenceDateStartCtrl"
                (focus)="lastRecurrenceDateStart.open()" />
              <mat-datepicker-toggle matSuffix [for]="lastRecurrenceDateStart"></mat-datepicker-toggle>
              <mat-datepicker #lastRecurrenceDateStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="flex-fill ml-2">
              <mat-label style="font-weight: 500">Data recorrência (fim)</mat-label>
              <input matInput [matDatepicker]="lastRecurrenceDateEnd" placeholder="Data recorrência (Fim)"
                name="lastRecurrenceDateEnd" formControlName="lastRecurrenceDateEndCtrl"
                (focus)="lastRecurrenceDateEnd.open()" />
              <mat-datepicker-toggle matSuffix [for]="lastRecurrenceDateEnd"></mat-datepicker-toggle>
              <mat-datepicker #lastRecurrenceDateEnd></mat-datepicker>
            </mat-form-field>
          </div>
        
       

          <div class="col-6" *ngIf="selectedReport.value === 'opportunities'">
            <!-- filter by Responsável -->
            <mat-form-field color="primary" style="flex: 1" >
              <mat-label>Responsável</mat-label>
              <input matInput type="text" name="contactingName" formControlName="contactingNameCtrl" />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field color="primary" style="flex: 1" class="mr-3">
              <mat-label>Campos a buscar</mat-label>
              <mat-select [formControl]="selectedFields" multiple (openedChange)="onSelectMenuOpened($event)">
                <div class="select-options" *ngIf="isMenuOpen">
                  <mat-form-field color="primary" style="flex: 1">
                    <input matInput placeholder="Pesquisar campo" (input)="filterFields($event.target.value)">
                  </mat-form-field>
                  <button mat-raised-button (click)="selectAllFields()">
                  Selecionar tudo
                  </button>

                  <button mat-raised-button (click)="clearAllFields()">
                    Limpar seleções
                  </button>

                </div>
                <mat-option *ngFor="let field of filteredFieldsList" [value]="field?.name">
                  {{ field?.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="text-center">
          <button mat-raised-button color="primary" (click)="getData()"
            [disabled]="selectedReport.value && isLoadingCustomers">
            Obter dados
          </button>
        </div>
      </form>

      <div class="w-100 p-5" *ngIf="isLoadingCustomers">
        <mat-progress-spinner *ngIf="isLoadingCustomers" [diameter]="60" class="mx-auto" color="primary"
          mode="indeterminate"></mat-progress-spinner>
      </div>

      <div class="my-5" *ngIf="!isLoadingCustomers && customers?.length === 0">
        <p class="text-center">
          {{ selectedReport.value === 'customers' ? 'Nenhum usuário encontrado' : 'Nenhuma oportunidade encontrada' }}.
        </p>
      </div>

      <div class="my-5" *ngIf="!isLoadingCustomers && customers === null">
        <p class="text-center">
          Falha ao obter a lista de
          {{ selectedReport.value === 'customers' ? 'usuários' : 'oportunidades' }}.
        </p>
        <p class="text-center">
          <button mat-raised-button color="accent" (click)="getData()">Tentar novamente</button>
        </p>
      </div>

      <div [class.d-none]="isLoadingCustomers || (!customers?.length && !usersPendingDocuments?.length)">
        <div class="d-flex align-items-center" *ngIf="selectedReport.value !== 'pendingDocuments'">
          <mat-form-field *ngIf="typeFile" color="primary" style="flex: 1; max-width: 100px" class="mr-3">
            <mat-label>Formato</mat-label>
            <mat-select [formControl]="selectedType" [compareWith]="compareSelectedType">
              <mat-option *ngFor="let type of typeFile" [value]="type">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button mat-stroked-button class="mx-1" color="primary" (click)="exportList()"
              [disabled]="!this.selectedFields?.value?.length">
              <mat-icon class="mr-2">get_app</mat-icon> Exportar
            </button>

            <button mat-raised-button class="mx-1" color="primary" (click)="saveReport(); isToSendReportToIF = true"
              [disabled]="!selectedFields?.value?.length">
              Enviar por email
            </button>
          </div>
        </div>

        <mat-card *ngIf="isToSendReportToIF" class="card-border">
          <mat-card-content>
            <h3>Envio do relatório por e-mail</h3>
            <hr class="mb-3" />

            <div class="row mb-4">
              <div class="col-12">
                <app-email-card [footer]="footer" [subject]="subject" [message]="message"
                  (onCancel)="cancelMessage()"></app-email-card>
              </div>
            </div>

            <hr class="mb-3" />

            <div class="row">
              <div class="col-12 col-md-4 d-flex align-items-center">
                <div class="mx-auto text-center">
                  <p>Carregamento do relatório:</p>
                  <div *ngIf="percentage | async as pct" class="mx-auto text-center">
                    <progress class="progress is-info" [value]="pct" max="100" style="max-width: 100%"></progress>
                    {{ pct | number }}%
                  </div>

                  <div *ngIf="fileURL | async as url" class="mt-4">
                    <a mat-stroked-button color="accent" [href]="url" target="_blank" rel="noopener">
                      <mat-icon class="mr-2">get_app</mat-icon> Baixar Arquivo
                    </a>
                    <input type="hidden" id="urlImageCtrl" #urlImageCtrl [value]="url" />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-8">
                <mat-form-field>
                  <mat-label>Link para o relatório:</mat-label>
                  <textarea matInput mat-autosize matAutosizeMinRows="1.1" readonly [value]="fileURLStr"></textarea>
                </mat-form-field>
                <div class="text-center">
                  <button mat-stroked-button color="accent" [cdkCopyToClipboard]="fileURLStr">
                    <span class="d-flex align-items-center mx-1"> <i class="far fa-copy mr-2"></i> Copiar link </span>
                  </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div #divTable [class.table-container]="selectedReport.value !== 'pendingDocuments'"
          [class.mat-elevation-z3]="selectedReport.value !== 'pendingDocuments'">
          <table mat-table [multiTemplateDataRows]="selectedReport.value === 'opportunities'" [dataSource]="dataSource"
            matSort [matSortActive]="sortField" [matSortDirection]="sortDirection" matSortDisableClear
            *ngIf="selectedReport.value !== 'pendingDocuments'">
            <ng-container *ngFor="let field of fieldsList">
              <ng-container matColumnDef="{{ field.name }}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                  {{ field.label }}
                </th>

                <td mat-cell *matCellDef="let row" class="no-border">
                  <!----- Fields default------>
                  <span *ngIf="
                      ![
                        'createdAt',
                        'agreedTermsDate',
                        'termsCancellationDate',
                        'cnaes',
                        'stepsOppsAndIFs',
                        'foundationDate',
                        'lastInteraction',
                        'cnpj',
                        'lastContactDate'
                      ].includes(field.name) && !monetaryFields.includes(field.name)
                    ">
                    {{ row[field.name] || '-' }}
                  </span>

                  <!----- Fields type Date------>
                  <span
                    *ngIf="['createdAt', 'agreedTermsDate', 'foundationDate', 'lastInteraction', 'termsCancellationDate', 'lastContactDate'].includes(field.name)">
                    {{ row[field.name] ? (row[field.name] | date : 'yyyy-MM-dd') : '-' }}
                  </span>

                  <!----- Field type monetay------>
                  <span *ngIf="monetaryFields.includes(field.name)">
                    {{ row[field.name] | currency : 'R$' || '-' }}
                  </span>

                  <!----- Field CNAEs------>
                  <span *ngIf="['cnaes'].includes(field.name)" style="display: block; min-width: 700px">
                    {{ !row[field.name] ? '-' : '' }}
                    <p class="mb-1" *ngFor="let cnae of row[field.name]" [class.bold]="cnae?.IsMain">
                      {{ cnae.Code }} - {{ cnae.Activity }}
                    </p>
                  </span>

                  <!----- Field CNPJ------>
                  <span *ngIf="field.name === 'cnpj'">
                    <a style="color: royalblue" fontSet="fab" [routerLink]="'/admin/clientes/detalhes/' + row.uid "
                      target="_blank">
                      {{ row[field.name] }}
                    </a>
                  </span>


                  <!----- Field steps of Opps and IFs------>
                  <span *ngIf="['stepsOppsAndIFs'].includes(field.name)" style="display: block; min-width: 700px">
                    <p class="mb-1" *ngFor="let stepAndIFs of row[field.name]">
                      {{ stepAndIFs }}
                    </p>
                  </span>
                </td>
              </ng-container>
            </ng-container>

            <!-- ExpandedDetail Column -->
            <ng-container matColumnDef="reportProgress" *ngIf="selectedReport.value === 'opportunities'">
              <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns?.length">
                <div class="d-flex align-items-start pt-2 pl-4" [style.width]="divTable.offsetWidth - 20 + 'px'"
                  style="position: sticky; left: 0; margin-left: -80px; font-size: 0.9em">
                  <mat-form-field class="mr-2" color="accent">
                    <textarea matInput placeholder="Reportar andamento" mat-autosize matAutosizeMinRows="1.1"
                      matAutosizeMaxRows="2.5" [(ngModel)]="row.message"></textarea>
                  </mat-form-field>

                  <div>
                    <button mat-stroked-button color="accent" type="button" style="font-size: 0.9em !important"
                      [disabled]="!row.message?.trim()" (click)="createNote(row)">
                      Enviar <mat-icon>send</mat-icon>
                    </button>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="selectedFields?.value || []"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedFields?.value || []"></tr>
            <div *ngIf="selectedReport.value === 'opportunities'">
              <tr mat-row *matRowDef="let row; columns: ['reportProgress']"></tr>
            </div>
          </table>

          <div *ngIf="selectedReport.value === 'pendingDocuments'">
            <div *ngFor="let lead of usersPendingDocuments">
              <mat-card class="mat-card mat-elevation-z8">
                <mat-card-header class="pt-4">
                  <mat-card-title>{{ lead.companyName + ' - ' + lead.cnpj }}</mat-card-title>
                  <mat-card-subtitle>{{ lead.email }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="p-3">
                  <ul *ngFor="let doc of lead.pendingDocuments">
                    <li>
                      {{ doc.name }} - <strong>{{ doc.situation }}</strong>
                    </li>
                  </ul>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
        <mat-paginator [class.d-none]="selectedReport.value === 'pendingDocuments'"
          [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
