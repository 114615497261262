import { firestore } from 'firebase';
import * as flat from 'flat';
import _ from 'lodash';

export const parseDateToTimestamp = (date: any): firestore.Timestamp | undefined => {
  return date
    ? date.seconds || date._seconds
      ? new firestore.Timestamp(date.seconds || date._seconds, date.nanoseconds || date._nanoseconds || 999000000)
      : Object.keys(date).length > 0
      ? date
      : undefined
    : undefined;
};

export const fillTemplate = (template: string, object: Record<string, any>): string => {
  const flatObj = flat(object) as Record<string, string>;
  let result = template;
  _.toPairs(flatObj).forEach(([key, value]) => {
    result = result.split(`{${key}}`).join(value); // substitui replaceAll
  });
  return result;
};
