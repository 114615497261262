import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Customer } from '../../../../../functions/src/models/Customer';
import { User } from '../../../../../functions/src/models/User';
import {
  Document as DocumentMongoDB
} from '../../../../../functions/src/models/documents/UserDocument';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { UploadDialogComponent } from '../../../components/upload-dialog/upload-dialog.component';
import { UserDocumentService } from '../../../customer/services/user-document.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-expansion-doc-item',
  templateUrl: './expansion-doc-item.component.html',
  styleUrls: ['../user-detail.component.scss', './expansion-doc-item.component.scss']
})
export class ExpansionDocItemComponent implements OnInit {
  @Input() matPainelColor: '#F4F4F4' | '#FDFDFD' | '' = '#FDFDFD';
  @Input() borderStyle: '-first-child' | '-last-child' | '' = '';
  @Input() item: DocumentMongoDB;
  @Input() customer: Customer;
  @Input() loggedUser: Partial<User>;
  @Output() delete = new EventEmitter();

  documentSelected: DocumentMongoDB;
  selectedDocument: any[];
  canUpload: boolean;
  uploadYearDocument: boolean;
  documentDeleted: boolean;
  constructor(
    private ums: UserManagementService,
    private userDocumentService: UserDocumentService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    //
  }
  getPainelColorStyle(): string {
    return `background-color: ${ this.matPainelColor };`
  }

  getDocumentInfo(doc: DocumentMongoDB): void {
    this.documentSelected = doc;
    this.selectedDocument = [];
    console.log(this.documentSelected)
    if (doc?.financialYear?.id === 'sim') {
      doc?.financialYears
        .slice()
        .reverse()
        .forEach((year) => {
          let documents = [];
          if (doc?.fileInfo) {
            documents = doc?.fileInfo.filter((item) => item.financialYear === year);
          }
          this.selectedDocument.push({
            year: year,
            status:
              documents.length > 0
                ? (doc?.minimumDocuments || 1) > documents.length
                  ? 'Enviado Parcialmente'
                  : 'Enviado'
                : 'Pendente',
            documents: documents,
          });
        });
    } else {
      this.selectedDocument.push({
        documents: doc?.fileInfo?.map((file) => file) || [],
      });
    }
  }

  verifyUploadStatus(item: DocumentMongoDB, year = ''): void {
    let yearDocumentsUploaded = [];
    if (year !== '') {
      if ((item?.fileInfo !== undefined || item?.fileInfo !== null) && item.fileInfo.length > 0) {
        yearDocumentsUploaded = item?.fileInfo?.filter((a) => a.financialYear === year);
      }
    }
    if (item.qtyFinancialYear > 0) {
      if (
        item.situation === 'Reprovado' ||
        yearDocumentsUploaded.length < (item?.maximumDocuments || item?.minimumDocuments || 1) ||
        item.qtyExpectedFiles / item.qtyFinancialYear > yearDocumentsUploaded.length
      ) {
        this.canUpload = true;
      } else {
        this.canUpload = false;
      }
    } else {
      if (
        item.situation === 'Reprovado' ||
        item.qtySubmittedFiles < (item?.maximumDocuments || item?.minimumDocuments || 1) ||
        item.qtyExpectedFiles > item.qtySubmittedFiles
      ) {
        this.canUpload = true;
      } else {
        this.canUpload = false;
      }
    }
  }

  onSalvarOrdenacao(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Ordem de exibição',
          alertDescription: 'Deseja realmente alterar a ordem de exibição desse documento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // this.userDocumentService.setDocuments(this.documents, this.customer.uid);
        }
      });
  }

  getDocumentStatus(item: DocumentMongoDB, year = ''): string {
    const files = year !== '' ? item?.fileInfo?.filter((f) => f.financialYear === year) : item.fileInfo;
    const minimunDocuments = (item?.minimumDocuments || 1) * (year === '' ? item.qtyFinancialYear : 1);
    return files.length > 0 ? (minimunDocuments > files.length ? 'Enviado Parcialmente' : 'Enviado') : 'Pendente';
  }

  onRemoveFileDocument(fileIndex = null, item: DocumentMongoDB, file: DocumentMongoDB['fileInfo'][0]): void {
    // const doc = this.documents.find((d) => d.typeId === item.typeId);

    item.fileInfo = item.fileInfo.filter((f, i) => f.path !== file.path && fileIndex !== i);
    item.qtySubmittedFiles = item.fileInfo.length ?? 0;
    if (item.fileInfo.length === 0) {
      item.situation = 'Pendente';
      item.clicksignDocumentKey = null;
      item.clicksignSignatureRequestKey = null;
    }
    item.uid = this.customer.uid;
    this.userDocumentService.patchDocument(item);
  }

  onSendNewFile(item: DocumentMongoDB, year = ''): void {
    const confirmUpload = this.dialog
      .open(UploadDialogComponent, {
        maxWidth: '600px',
        data: {
          item: item,
          exerciseYear: year,
          customer: this.customer,
          dataUserDocuments: [item],
          hasEmissionDate: false,
        },
      })
      .afterClosed()
      .subscribe((doc) => {
        console.log('Upload Dialog closed.');
        item = doc;
      });
    this.uploadYearDocument = year === '' ? false : true;
  }

  determineStatus(doc: DocumentMongoDB): string {
    if (doc.hasNoAlterationRec === true) {
      return 'Não houve atualização';
    }
    if (doc.fileInfo.length > 0 && doc.fileInfOld.length > 0) {
      const lastOldDateUpload = doc.fileInfOld.map((f) => f.dateUpload).sort()[0];
      for (const file of doc.fileInfo) {
        if (doc.minimumDocuments === doc.fileInfo.length && file.dateUpload && lastOldDateUpload && new Date(file.dateUpload) > new Date(lastOldDateUpload)) {
          return 'Houve atualização';
        }
      }

    }
    return 'Aguardando';
  }

  onApproveDocuments(typeId: string): void {
    // aprovar documentos

    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Aprovar documento',
          alertDescription: 'Deseja realmente aprovar esse documento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const document = { ...this.item };
          document.situation = 'Aprovado';
          document.approvalOrDisapprovalDate = new Date();
          document.pendingIssueForAdmin = false;
          document.userAproReprov = this.loggedUser.uid;
          document.uid = document?.uid || this.customer.uid;
          this.userDocumentService.patchDocument(document).then(() => {
            this.ums.aprovarDocumentosUsuario(this.customer.uid, document);
            this.item = document

          });
        }
      });
  }

  onReprovarDocumentos(typeId: string): void {
    // reprovar documento

    const dataTextDescription = {
      placeholder: 'Motivo',
      errorMessage: 'É obrigatório informar um motivo.',
      dataTextCtrl: '',
    };
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Reprovar documento',
          alertDescription: 'Informe abaixo o motivo pelo qual está reprovando o documento.',
          hasTextDescription: true,
          dataTextDescription: dataTextDescription,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.onSalvardocsRep(typeId, dataTextDescription.dataTextCtrl);
          const document = { ...this.item };
          document.situation = 'Reprovado';
          document.approvalOrDisapprovalDate = new Date();
          document.disapprovalReason = dataTextDescription.dataTextCtrl;
          document.pendingIssueForAdmin = false;
          document.pendingIssueForUser = true;
          document.email = this.customer.email;
          document.userAproReprov = this.loggedUser.uid;
          document.uid = this.customer.uid;
          this.userDocumentService.patchDocument(document)
            .then(() => {
              this.item = document
            });
          this.userDocumentService.setDocumentoReprovado(document);
        }
      });
  }

  onSalvardocsRep(typeId: string, dataTextDescription: string): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Deseja manter algum documento?',
          alertDescription: 'Deseja realmente manter algum documento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (result) {
            const document = { ...this.item };
            document.incomplete = true;
            document.situation = 'Reprovado';
            document.approvalOrDisapprovalDate = new Date();
            document.disapprovalReason = dataTextDescription;
            document.pendingIssueForAdmin = false;
            document.pendingIssueForUser = true;
            document.email = this.customer.email;
            document.userAproReprov = this.loggedUser.uid;
            document.uid = this.customer.uid;
            this.userDocumentService.patchDocument(document)
              .then(() => {
                this.item = document
              });
            this.userDocumentService.setDocumentoReprovado(document);
          }
        }
      });
  }

  onRemoverDocumento(): void {
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Remover tipo documento',
          alertDescription:
            `Deseja realmente remover esse tipo de documento da lista deste usuário? <br>` +
            ` - Obs.: Todos os documentos associados à ele serão excluídos.`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // remove o documento selecionado do array.
          const exclusedDocument = this.item;

          if (!!exclusedDocument) {
            this.userDocumentService.deleteDocument(this.customer.uid, exclusedDocument?.typeId).then(() => {
              if (exclusedDocument?.expirationControlId) {
                this.ums.deleteControleVencimento(exclusedDocument?.expirationControlId);
                this.documentDeleted = true
              }

              this.delete.emit();

            });
          }        }
      });
  }
}
