<mat-dialog-content class="dialog-container">
  <div class="first-step" >
    <span class="close ml-4" (click)="closeModal()">&times;</span>
    <h2 class="dialog-title">Agilize seu crédito com o Open Finance!</h2>
    <div class="dialog-description">
      <p><strong>Open finance</strong> é a prática de compartilhar dados financeiros entre instituições de forma segura e transparente, permitindo aos usuários acessar suas informações financeiras de maneira rápida e conveniente, agilizando todo o processo de análise de crédito.</p>
      <p class="highlight">É um processo seguro devido à criptografia robusta, protocolos de segurança e controle do usuário sobre quais dados são compartilhados e com quem.</p>
    </div>
    <button  class="open-finance-btn" id="open-finance-btn" onclick="startPluggyConnect(this.getAttribute('data-access-token'), this.getAttribute('data-customer-uid'), this.getAttribute('data-url-functions'))">Começar</button>
  </div>
</mat-dialog-content>