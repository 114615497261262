<mat-card>
  <mat-card-content class="card-name">
      <h3>Informações da empresa</h3>

          <div class="info-section">
              <div class="info-item">
                  <p>Razão social</p>
                  <span >{{customer?.companyName}}</span>
                  <div >
                      <p>Regime tributário</p>
                      <span>{{ customer?.taxRegime?.name }}</span>
                    </div>
              </div>
              <div class="info-item">
                  <p>CNPJ</p>
                  <span >{{customer?.cnpj}}</span>
                  <p>Finalidade do crédito</p>
                  <span >{{customer?.purpose?.name}}</span>
              </div>

    </div>
  </mat-card-content>
</mat-card>


<mat-card>
  <mat-card-content class="card-name">
      <h3>Endereço</h3>
      <div class="info-section">
          <div class="info-item">
              <p>Endereço</p>
              <span >{{ customer?.address?.street || '-' }},
                  {{ customer?.address?.number || '-' }}</span>
              <p>Complemento</p>
              <span >{{ customer?.address?.extraInfo || '-' }}</span>
              <p>Cidade</p>
              <span >{{ customer?.address?.city || '-' }}</span>
          </div>
          <div class="info-item">
              <p>CEP</p>
              <span >{{ customer?.address?.zipCode || '-' }}</span>
              <p>Bairro</p>
              <span >{{ customer?.address?.neighborhood || '-' }}</span>
              <p>Estado</p>
              <span >{{ customer?.address?.state || '-' }}</span>
          </div>
      </div>
  </mat-card-content>
</mat-card>

<div class="text-wrapper">
  <p class="text">Algum dado está incorreto? Entre em contato para corrigir a situação:</p>
    <a mat-flat-button class="action-button" type="button"  routerLink="/mensagens"> Fale conosco</a>
</div>

