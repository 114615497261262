<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <span style="flex: 1">Relatórios - Operadores</span>
      </mat-card-title>

      <div class="d-flex align-items-center">
        <mat-form-field color="primary" style="flex: 1" class="mr-3">
          <mat-label>Escolha o relatório</mat-label>
          <mat-select [formControl]="selectedReport">
            <mat-option *ngFor="let report of reportList; let i = index" [value]="report.name">{{
              report.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <button
          mat-raised-button
          color="primary"
          (click)="getData()"
          [disabled]="
            this.selectedReport.value === 'bankers-and-ops'
              ? this.selectedReport.value && !this.isLoadingBankers && !this.selectedInstitutions.length
              : !this.selectedReport.value && !this.isLoadingBankers
          "
        >
          Obter dados
        </button>
      </div>

      <div class="text-center" *ngIf="this.selectedReport.value === 'bankers-and-ops'">
        <mat-form-field
          color="primary"
          style="flex: 1"
          class="mr-3"
          matTooltip="Escolha de quais instituições serão exibidas as oportunidades"
        >
          <mat-label>Instituições</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" [formControl]="userControl" />
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let i of InstitutionsFilter | async" [value]="selectedInstitutions">
            <div (click)="optionClicked($event, i)">
              <mat-checkbox [checked]="i.selected" (change)="toggleSelection(i)" (click)="$event.stopPropagation()">
                {{ i.name }}
              </mat-checkbox>
            </div>
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="w-100 p-5" *ngIf="isLoadingBankers">
        <mat-progress-bar
          *ngIf="isLoadingBankers"
          class="mx-auto"
          color="primary"
          [mode]="loadingBankersOpsProgress > 0 ? 'determinate' : 'query'"
          [value]="loadingBankersOpsProgress"
        ></mat-progress-bar>
      </div>

      <div class="table-container" *ngIf="!isLoadingBankers && bankers?.length === 0">
        <p class="text-center">Nenhum operador encontrado.</p>
      </div>

      <div class="table-container" *ngIf="!isLoadingBankers && bankers === null">
        <p class="text-center">Falha ao obter a lista de operadores.</p>
        <p class="text-center">
          <button mat-raised-button color="accent" (click)="getData()">Tentar novamente</button>
        </p>
      </div>

      <div [class.d-none]="isLoadingBankers || !bankers?.length">
        <div class="d-flex align-items-center">
          <mat-form-field color="primary" style="flex: 1" class="mr-3">
            <mat-label>Campos a exibir/exportar</mat-label>
            <mat-select [formControl]="selectedFields" multiple>
              <mat-option *ngFor="let field of fieldsList" [value]="field.name">{{ field.label }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="typeFile" color="primary" style="flex: 1; max-width: 150px" class="mr-3">
            <mat-label>Formato</mat-label>
            <mat-select [formControl]="selectedType" [compareWith]="compareSelectedType">
              <mat-option *ngFor="let type of typeFile" [value]="type">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="exportList()"
              [disabled]="!this.selectedFields?.value?.length"
            >
              <mat-icon class="mr-2">get_app</mat-icon> Exportar
            </button>
          </div>
        </div>

        <div class="table-container mat-elevation-z3">
          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            [matSortActive]="sortField"
            [matSortDirection]="sortDirection"
            matSortDisableClear
          >
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Nome</th>
              <td mat-cell *matCellDef="let row">
                {{ row.name || '-' }}
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail</th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <!-- Phone Column -->
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Telefone</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.phone || '-' }}
              </td>
            </ng-container>

            <!-- Institution Column -->
            <ng-container matColumnDef="institution">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Instituição</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.institution || '-' }}
              </td>
            </ng-container>

            <!-- Revenues Columns -->
            <ng-container matColumnDef="minRevenueStr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Faturamento mínimo</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.revenueRange?.minRevenueStr || '-' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="maxRevenueStr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Faturamento máximo</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.revenueRange?.maxRevenueStr || '-' }}
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Status</th>
              <td mat-cell *matCellDef="let row">
                {{ row.status === 'approved' ? 'Aprovado' : 'Não aprovado' || '-' }}
              </td>
            </ng-container>

            <!-- Operation Name Column -->
            <ng-container matColumnDef="opName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Nome Cliente</th>
              <td mat-cell *matCellDef="let row">
                {{ row.opName || '-' }}
              </td>
            </ng-container>

            <!-- Operation CapitalValue Column -->
            <ng-container matColumnDef="opCapitalValue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Valor Capital</th>
              <td mat-cell *matCellDef="let row">
                {{ (row.opCapitalValue | currency: 'R$') || '-' }}
              </td>
            </ng-container>

            <!-- Operation Revenue Column -->
            <ng-container matColumnDef="opRevenue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Faturamento</th>
              <td mat-cell *matCellDef="let row">
                {{ (row.opRevenue | currency: 'R$') || '-' }}
              </td>
            </ng-container>

            <!-- Oparation Email Column -->
            <ng-container matColumnDef="opEmail">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Email Cliente</th>
              <td mat-cell *matCellDef="let row">
                {{ row.opEmail || '-' }}
              </td>
            </ng-container>

            <!-- Razão Social Column -->
            <ng-container matColumnDef="rzSocial">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Razão Social</th>
              <td mat-cell *matCellDef="let row">
                {{ row.rzSocial || '-' }}
              </td>
            </ng-container>

            <!-- Razão Social Column -->
            <ng-container matColumnDef="cnpj">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>CNPJ</th>
              <td mat-cell *matCellDef="let row">
                {{ row.cnpj || '-' }}
              </td>
            </ng-container>

            <!-- Lead Quantity Column -->
            <ng-container matColumnDef="leadQuantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Quantidade de Leads</th>
              <td mat-cell *matCellDef="let row; let i = index" class="text-right">
                {{ row.leadQuantity || 0 }}
              </td>
            </ng-container>

            <!-- Lead Name Column -->
            <ng-container matColumnDef="leadName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Nome do Lead</th>
              <td mat-cell *matCellDef="let row">
                {{ row.leadName || '-' }}
              </td>
            </ng-container>

            <!-- Lead Email Column -->
            <ng-container matColumnDef="leadEmail">
              <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>E-mail do Lead</th>
              <td mat-cell *matCellDef="let row">{{ row.leadEmail }}</td>
            </ng-container>

            <!-- Lead Signed Term Column -->
            <ng-container matColumnDef="signedTerm">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Assinou termo em</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{ row.signedTerm || '-' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="selectedFields?.value || []"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedFields?.value || []"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
