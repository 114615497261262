<div style="position: relative;">

  <div *ngIf="!isLoading" class="customer-card">
    <img src="{{contact.photoWhatsapp}}">
    <div [class]="isToday ? 'customer-card--text--green': 'customer-card--text'" (click)="onContactSelected(contact)">
      <p>{{ contact.companyName }} </p>
      <span>{{ contact.cnpj }}</span>
    </div>
    <div class="date-container" (click)="onContactSelected(contact)">
      <span *ngIf="contact?.lastContactStr" [class]="isToday ?'date--green' : 'date'">{{contact.lastContactStr}}</span>
      <span *ngIf="contact.totalUnreadMessages > 0"
        [class]="isToday ? 'notification-today':'notification-otherdays'">
        {{contact.totalUnreadMessages}}
      </span>
      <span *ngIf="contact.attributedToNotification === true" class="notification-attributed">
        A
      </span>
    </div>
  </div>

</div>