<div class="container py-2 px-2 h-100">
  <div class="mx-2 mt-4 mb-3">
    <div class="d-flex align-items-center">
      <div style="flex: 1">
        <h3 style="font-weight: bold; margin: 0">Anotações</h3>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="isCreateMode = true">
          <span class="d-none d-sm-inline d-xl-none">Nova </span><mat-icon>post_add</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-divider class="my-2 mb-3"></mat-divider>

  <div class="text-center">
    <button *ngIf="this.notes?.length > 0" mat-raised-button color="accent" type="button" (click)="viewMoreNotes()">
      Ver mais <mat-icon>search</mat-icon>
    </button>
  </div>

  <div class="text-center my-4" *ngIf="isLoadingNotes">
    <mat-spinner diameter="80" class="mx-auto"></mat-spinner>
  </div>

  <mat-card *ngIf="isCreateMode">
    <mat-card-content>
      <h3 style="font-weight: 500">Nova Anotação</h3>

      <mat-form-field class="w-100">
        <textarea
          matInput
          placeholder="Texto"
          mat-autosize
          matAutosizeMinRows="3"
          [(ngModel)]="newNote.message"
          required
        ></textarea>
      </mat-form-field>

      <div class="text-center">
        <button mat-raised-button type="button" (click)="cancelNote()" class="m-1">
          <mat-icon>clear</mat-icon> Cancelar
        </button>
        <button
          mat-raised-button
          color="accent"
          type="button"
          [disabled]="!newNote.message"
          (click)="createNote()"
          class="m-1"
        >
          Publicar <mat-icon class="ml-2">send</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!isLoadingNotes">
    <p class="p-4 m-3 text-center" *ngIf="!this.notes?.length">Ainda não há nenhuma anotação.</p>

    <mat-card *ngFor="let note of notes; let i = index" class="my-2 note">
      <mat-card-content class="p-3">
        <div class="d-flex justify-content-between" style="white-space: pre-line" *ngIf="!editMode[i]?.enabled">
          <div class="content">
            {{ note.message }}
          </div>
          <div *ngIf="note.attachments?.length">
            <a style="color: blue" href="{{ attachment.contentUrl }}" *ngFor="let attachment of note.attachments">{{
              attachment.fileName
            }}</a>
          </div>
        </div>

        <div *ngIf="editMode[i]?.enabled">
          <mat-form-field class="w-100">
            <textarea
              matInput
              placeholder="Texto"
              mat-autosize
              matAutosizeMinRows="3"
              [(ngModel)]="editMode[i].data.message"
              required
            ></textarea>
          </mat-form-field>

          <div class="text-center">
            <button mat-raised-button type="button" (click)="cancelEditNote(i)" class="m-1">
              <mat-icon>clear</mat-icon> Cancelar
            </button>
            <button
              mat-raised-button
              color="accent"
              type="button"
              [disabled]="!editMode[i].data.message"
              (click)="saveNote(note.id, i)"
              class="m-1"
            >
              <mat-icon>save</mat-icon> Salvar
            </button>
          </div>
        </div>

        <hr />

        <mat-card-footer class="d-flex px-3 pb-2">
          <div style="flex: 1">
            {{ note.createdBy?.name }} <br />
            <div class="date-label">Criado em: {{ note.createdAt?.toDate().toLocaleString() }}</div>
            <div class="date-label">Atualizado em: {{ note?.lastUpdate?.toDate().toLocaleString() }}</div>
          </div>
          <div *ngIf="note.createdBy?.uid === createdBy?.uid">
            <button mat-button class="action-button" matTooltip="Editar anotação" (click)="editMode[i].enabled = true">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-card-footer>
      </mat-card-content>
    </mat-card>
  </div>
</div>
