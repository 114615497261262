import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Banker } from '../../../../functions/src/models/Banker';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth/auth.service';


@Injectable({
    providedIn: 'root',
})
export class LinkedinService {

    constructor(private angularFire: AngularFirestore, private http: HttpClient, private authService: AuthService, private dialog: MatDialog,) { }

    createAccessTokenLinkedin(authorizationCode: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const data = { authorizationCode };
            this.http
                .post(
                    `${ environment.functionsUrl }/integrationsapi/linkedin/accessToken`,
                    { data },
                    { responseType: 'json', headers: this.authService.getHeader() }
                )
                .toPromise()
                .then((response: any) => {

                    console.log(response)
                    const accessToken = response.accessToken;
                    resolve(accessToken);
                })
                .catch((err) => {
                    console.error('Erro ao gerar Access Token', err.response ? err.response.data : err.message);
                    reject('error-access-token');
                });
        });
    }

    getDataLinkedin(data: any): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const headers: HttpHeaders = this.authService.getHeader();
            this.http
                .post<any>(
                    `${ environment.functionsUrl }/integrationsapi/linkedin/get-data`,
                    { data },
                    { headers }
                )
                .toPromise()
                .then((response) => {
                    if (response && response.data.elements[0]['handle~'].emailAddress) {
                        const emailAddress = response.data.elements[0]['handle~'].emailAddress;
                        resolve(emailAddress);
                    } else {
                        console.error('Response from LinkedIn API is missing expected data.');
                        reject('error-get-data-linkedin');
                    }
                })
                .catch((err) => {
                    console.error('Error Get Data Linkedin', err);
                    reject('error-get-data-linkedin');
                });
        });
    }

    getProfileLinkedin(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(
                    `${ environment.functionsUrl }/integrationsapi/linkedin/get-profile`,
                    { data },
                    { responseType: 'json', headers: this.authService.getHeader() }
                )
                .toPromise()
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        console.error('Response from LinkedIn API is missing expected data.');
                        reject('error-get-profile-linkedin');
                    }
                })
                .catch((err) => {
                    console.error('Error Get Profile Linkedin', err);
                    reject('error-get-profile-linkedin');
                });
        });
    }



    async createBanker(bankerData: Banker, password: string, loggedAdmin: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const banker: Banker = {
                ...bankerData,
                isActive: true,
                manuallyCreated: true,
                notifications: {
                    email: {
                        chatMessages: true,
                        opportunityClosed: true
                    },
                    statusChanged: true,
                    opportunityCreate: true,
                    opportunityUpdate: true,
                    opportunityAccepted: true,
                    opportunityRejected: true,
                },
                status: 'approved',
            };

            this.http
                .post(
                    `${ environment.functionsUrl }/banker/create-manually`,
                    { banker: { ...banker, password }, admin: loggedAdmin, redirect: `${ environment.baseURL }/bankers/login` },
                    { responseType: 'json', headers: this.authService.getHeader() }
                )
                .toPromise()
                .then(() => {
                    return resolve('success');
                })
                .catch((err) => {
                    console.error('Problemas ao tentar criar um novo banker.', err);
                    if (err.error) {
                        if (err.error.isExistentAuth) {
                            return resolve('email-in-use');
                        } else if (err.error === 'error-sending-email') {
                            return resolve('error-sending-email');
                        } else {
                            return reject(err);
                        }
                    } else {
                        return reject(err);
                    }
                });
        });
    }
}
