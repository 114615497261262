import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserNote } from 'functions/src/models/Notes';
import { User } from 'functions/src/models/User';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { NotesService } from 'src/app/admin/services/notes.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { environment } from 'src/environments/environment';
import { ForwardNotesComponent } from '../forward-notes/forward-notes.component';

@Component({
  selector: 'app-user-notes-dialog',
  templateUrl: './user-notes-dialog.component.html',
  styleUrls: ['./user-notes-dialog.component.scss'],
})
export class UserNotesDialogComponent implements OnInit, OnDestroy {
  agent: string;
  agentName?: string;
  agentEmail?: string;

  customerData: User;

  customer: string;
  notificationData: any;
  type: UserNote['type'];
  createdBy: UserNote['createdBy'];

  isCreateMode = false;
  isLoadingNotes = true;

  newNote: UserNote = {
    message: '',
  };
  searchValue = '';

  notes: UserNote[] = [];
  filteredNotes: UserNote[] = [];
  notesSubscription: Subscription;

  readonly typeLabels = {
    ADMIN: 'Capital Empreendedor',
    AGENT: 'Assessor',
    USER: 'Cliente',
  };

  constructor(private dialog: MatDialog, private notesService: NotesService, @Inject(MAT_DIALOG_DATA) data) {
    this.agent = data.agent;
    this.customer = data.customer;
    this.notificationData = data.notificationData;
    this.type = data.type;
    this.createdBy = data.createdBy;
    this.customerData = data.customerData;
    this.agentEmail = data.agentEmail;
    this.agentName = data.agentName;
  }

  ngOnInit(): void {
    this.notesSubscription = this.notesService
      .getAllUserNotes(this.customer /*,  this.type !== 'ADMIN' ? this.agent : undefined */)
      .subscribe((notes) => {
        this.notes = notes;
        this.searchNotes();
        this.isLoadingNotes = false;
      });
  }

  ngOnDestroy(): void {
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNewNote();
  }

  clearNewNote(): void {
    this.newNote.message = '';
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchNotes();
  }

  forwardNote(noteText): void {
    this.dialog.open(ForwardNotesComponent, {
      width: '70%',
      data: {
        customer: this.customer,
        customerData: this.customerData,
        noteText: noteText,
        noteFrom: 'USER',
      },
    });
  }

  searchNotes(): void {
    if (this.searchValue) {
      this.filteredNotes = _.filter(
        this.notes,
        (n) => n.message?.toUpperCase().indexOf(this.searchValue.toUpperCase()) >= 0
      );
    } else {
      this.filteredNotes = this.notes;
    }
  }

  createNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar Mensagem',
          alertDescription: 'Deseja realmente enviar essa mensagem?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.notificationData = {
          type: this.type,
          name: this.createdBy.name,
          companyName: this.customerData?.companyName || null,
          content: this.newNote.message,
          recipients: [],
          link: {
            toAdmin: `${environment.baseURL}/admin/clientes/detalhes/${this.customer}`,
            toAgent: `${environment.baseURL}/agents/lead/${this.customer}`,
            toUser: `${environment.baseURL}/mensagens`,
          },
        };

        if (this.type !== 'AGENT' && this.agentEmail) {
          this.notificationData.recipients.push({
            Name: this.agentName,
            Email: this.agentEmail,
            type: 'AGENT',
          });
        }

        if (this.type !== 'USER') {
          this.notificationData.recipients.push({
            Name: this.customerData.name,
            Email: this.customerData.email,
            type: 'USER',
          });
        }

        this.newNote = {
          ...this.newNote,
          type: this.type,
          agent: this.agent,
          createdBy: this.createdBy,
        };
        this.notesService
          .createUserNote(this.customer, this.newNote, this.notificationData)
          .then(() => {
            this.isCreateMode = false;
            this.clearNewNote();
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Mensagem Enviada',
                alertDescription: 'A mensagem foi enviada com sucesso.',
                isOnlyConfirm: true,
              },
            });
          })
          .catch((err) => {
            console.error('Error creating note', err);

            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao enviar',
                alertDescription: 'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
          })
          .finally(() => {
            if (confirmSubscription) {
              confirmSubscription.unsubscribe();
            }
          });
      });
  }
}
