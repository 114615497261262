export const SETTINGS_KEY = 'SETTINGS';

export const NIGHT_MODE_THEME = 'BLACK-THEME';

export const initialState: SettingsState = {
  theme: 'DEFAULT-THEME',
  autoNightMode: false,
  pageAnimations: true,
  pageAnimationsDisabled: false,
  elementsAnimations: true,
};

export const selectorSettings = (state) => <SettingsState>(state.settings || { theme: '' });

export function settingsReducer(state: SettingsState = initialState): SettingsState {
  return state;
}

export interface SettingsState {
  theme: string;
  autoNightMode: boolean;
  pageAnimations: boolean;
  pageAnimationsDisabled: boolean;
  elementsAnimations: boolean;
}
