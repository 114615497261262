<div class="container">
  <mat-card *ngIf="isLoading">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="accent"
          type="button"
          [routerLink]="'/admin/operadores/info/' + bankerCode"
          matTooltip="Voltar à visualização"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Editar Operador de Crédito</span>
      </mat-card-title>
      <section class="my-5">
        <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isLoading">
    <mat-card-content>
      <mat-card-title class="mb-4 d-flex align-items-center">
        <a
          mat-mini-fab
          color="accent"
          type="button"
          [routerLink]="'/admin/operadores/info/' + bankerCode"
          matTooltip="Voltar à visualização"
          class="mr-3"
        >
          <mat-icon>chevron_left</mat-icon>
        </a>
        <span style="flex: 1">Editar Operador de Crédito</span>
      </mat-card-title>
      <section class="mt-4 banker-profile-form">
        <form [formGroup]="editBankerFormGroup">
          <div class="fields">
            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input matInput type="email" placeholder="E-mail" formControlName="emailCtrl" required />
                <mat-error *ngIf="editBankerFormGroup.controls['emailCtrl'].hasError('required')">
                  Informe o e-mail do operador.
                </mat-error>
                <mat-error *ngIf="editBankerFormGroup.controls['emailCtrl'].hasError('email')">
                  O e-mail deve ser válido.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input matInput placeholder="Nome completo" formControlName="nameCtrl" required name="fullname" />
                <mat-error *ngIf="editBankerFormGroup.controls['nameCtrl'].hasError('required')">
                  Informe o nome completo do operador.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input
                  matInput
                  placeholder="WhatsApp"
                  formControlName="whatsappCtrl"
                  name="whatsapp"
                  phone
                  #phone
                  [textMask]="{ mask: formatPhone, guide: false }"
                  required
                />
                <mat-error *ngIf="editBankerFormGroup.controls['whatsappCtrl'].hasError('required')">
                  Informe o WhatsApp do operador para contato.
                </mat-error>
                <mat-error
                  *ngIf="
                    editBankerFormGroup.controls['whatsappCtrl'].hasError('telefone') ||
                    editBankerFormGroup.controls['whatsappCtrl'].hasError('minlength')
                  "
                >
                  Telefone inválido.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary" class="w-100">
                <input
                  matInput
                  placeholder="Telefone"
                  formControlName="phoneCtrl"
                  name="phone"
                  phone
                  #phone
                  [textMask]="{ mask: formatPhone, guide: false }"
                />
                <mat-error *ngIf="editBankerFormGroup.controls['phoneCtrl'].hasError('telefone')">
                  Telefone inválido.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <mat-label>Instituição Financeira</mat-label>
                <mat-select
                  placeholder="Instituição Financeira"
                  formControlName="institutionCtrl"
                  name="institution"
                  required
                >
                  <mat-option *ngFor="let institution of institutions" [value]="institution.nomeNoSistema">
                    {{ institution.nome }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="editBankerFormGroup.controls['institutionCtrl'].hasError('required')">
                  Selecione a instituição financeira.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input
                  matInput
                  placeholder="CEP"
                  formControlName="postalCodeCtrl"
                  #postalCode="matInput"
                  name="postalCode"
                  [textMask]="{ mask: MASKS.cep.textMask, guide: false }"
                  required
                  minlength="10"
                  (blur)="getAddressFromPostalCode()"
                  (keyup)="postalCode.value?.length === 10 ? getAddressFromPostalCode() : false"
                />
                <mat-error *ngIf="editBankerFormGroup.get('postalCodeCtrl')?.hasError('required')">
                  O CEP é obrigatório.
                </mat-error>
                <mat-error
                  *ngIf="
                    !editBankerFormGroup.get('postalCodeCtrl')?.hasError('required') &&
                    editBankerFormGroup.get('postalCodeCtrl')?.hasError('minlength')
                  "
                >
                  Informe um CEP válido.
                </mat-error>
                <mat-error
                  *ngIf="
                    !editBankerFormGroup.get('postalCodeCtrl')?.hasError('required') &&
                    editBankerFormGroup.get('postalCodeCtrl')?.hasError('incorrect')
                  "
                >
                  O CEP informado não foi encontrado.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field d-flex align-items-center">
              <mat-form-field color="primary">
                <input matInput placeholder="Endereço" formControlName="streetCtrl" required name="street" />
                <mat-error *ngIf="editBankerFormGroup.get('streetCtrl')?.hasError('required')">
                  O endereço é obrigatório.
                </mat-error>
              </mat-form-field>

              <div style="width: 35px; text-align: right">
                <span
                  class="help-button"
                  matTooltipPosition="left"
                  matTooltip='Os campos "Endereço", "Bairro", "Cidade" e "Estado" são preenchidos automaticamente a partir do CEP informado.'
                >
                  <i class="fas fa-question d-inline-block" style="margin: 0 auto"></i>
                </span>
              </div>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input
                  matInput
                  type="number"
                  placeholder="Número"
                  formControlName="numberCtrl"
                  required
                  name="number"
                />
                <mat-error *ngIf="editBankerFormGroup.get('numberCtrl')?.hasError('required')">
                  O número é obrigatório.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input matInput placeholder="Complemento" formControlName="extraInfoCtrl" name="extraInfo" />
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input matInput placeholder="Bairro" formControlName="neighborhoodCtrl" required name="neighborhood" />
                <mat-error *ngIf="editBankerFormGroup.get('neighborhoodCtrl')?.hasError('required')">
                  O bairro é obrigatório.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input matInput placeholder="Cidade" formControlName="cityCtrl" required name="city" />
                <mat-error *ngIf="editBankerFormGroup.get('cityCtrl')?.hasError('required')">
                  A cidade é obrigatória.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input matInput placeholder="Estado" formControlName="stateCtrl" required name="state" />
                <mat-error *ngIf="editBankerFormGroup.get('stateCtrl')?.hasError('required')">
                  O estado é obrigatório.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input
                  matInput
                  placeholder="Faturamento mínimo"
                  formControlName="minRevenueCtrl"
                  [textMask]="{ mask: numberMask }"
                  name="minRevenue"
                />
                <!-- <mat-error *ngIf="editBankerFormGroup.get('minRevenueCtrl')?.hasError('required')">
                  O faturamento mínimo é obrigatório.
                </mat-error> -->
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field color="primary">
                <input
                  matInput
                  placeholder="Faturamento máximo"
                  formControlName="maxRevenueCtrl"
                  [textMask]="{ mask: numberMask }"
                  name="maxRevenue"
                />
                <!-- <mat-error *ngIf="editBankerFormGroup.get('maxRevenueCtrl')?.hasError('required')">
                  O faturamento máximo é obrigatório.
                </mat-error> -->
              </mat-form-field>
            </div>

            <div class="mt-3 mb-4 text-right tip">* Campos obrigatórios</div>

            <p class="mt-3">
              <strong>Atenção:</strong> Preencha o campo abaixo somente se desejar alterar a senha do operador.
            </p>

            <div class="field d-flex align-items-center">
              <mat-form-field color="primary" class="w-100 mr-3">
                <input
                  matInput
                  [type]="showPassword ? 'text' : 'password'"
                  placeholder="Senha"
                  [formControl]="editBankerFormGroup.controls['passwordCtrl']"
                />
                <button
                  mat-button
                  type="button"
                  matSuffix
                  mat-icon-button
                  aria-label="Toggle password"
                  (click)="showPassword = !showPassword"
                >
                  <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error *ngIf="editBankerFormGroup.controls['passwordCtrl'].hasError('minlength')">
                  A senha deve ter 8 caracteres ou mais.
                </mat-error>
              </mat-form-field>
              <div>
                <button
                  mat-raised-button
                  type="button"
                  color="accent"
                  [disabled]="isProcessing"
                  (click)="generatePassword()"
                >
                  Gerar
                </button>
              </div>
            </div>

            <div class="field">
              <mat-checkbox
                color="primary"
                (click)="
                  editBankerFormGroup
                    .get('shouldNotifyPasswordChangeCtrl')
                    .setValue(!editBankerFormGroup.get('shouldNotifyPasswordChangeCtrl').value)
                "
                [checked]="!!editBankerFormGroup.get('shouldNotifyPasswordChangeCtrl').value"
                name="shouldNotifyPasswordChange"
                [disabled]="!editBankerFormGroup.controls['passwordCtrl'].value"
              >
                Notificar operador da alteração de senha?
              </mat-checkbox>
            </div>
          </div>
        </form>

        <div class="p-3">
          <hr style="background-color: #9e9e9e; height: 2px" class="mt-3 mb-4" />

          <mat-card-title>Regionalização:</mat-card-title>

          <div class="field my-4">
            <mat-slide-toggle
              matTooltip="Operador regionalizado é aquele que atende pela instituição apenas em uma determinada região."
              [(ngModel)]="isRegionalized"
              color="primary"
            >
              <span class="d-inline-flex text-break"
                >Regionalização {{ isRegionalized ? 'ativada' : 'desativada' }}</span
              >
            </mat-slide-toggle>
          </div>

          <div [class.d-none]="!isRegionalized">
            <div>
              <div class="field my-3">
                <p style="font-size: 0.9em">
                  <strong>OBS: </strong> Se, para uma dada localidade, o CEP for combinado com outro critério, o cliente
                  deve atender tanto ao CEP quanto ao outro critério para que a localidade seja válida.
                </p>
              </div>

              <div class="field">
                <mat-form-field color="primary">
                  <input
                    matInput
                    [formControl]="createEditFormGroupLocality.controls['ifsLocalityNameCtrl']"
                    placeholder="Nome"
                    required
                  />
                  <mat-error *ngIf="createEditFormGroupLocality.controls['ifsLocalityNameCtrl'].hasError('required')">
                    Digite um nome.
                  </mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="criteria" class="field">
                <mat-form-field color="primary">
                  <mat-label>Critério</mat-label>
                  <mat-select [formControl]="createEditFormGroupLocality.controls['ifsLocalityTypeCtrl']">
                    <mat-option></mat-option>
                    <mat-option mat-option *ngFor="let c of criteria" [value]="c.id">
                      {{ c.name }}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="createEditFormGroupLocality.controls['ifsLocalityTypeCtrl'].hasError('required')">
                    Informe o tipo dessa localidade.
                  </mat-error> -->
                </mat-form-field>
              </div>

              <div class="field" *ngIf="createEditFormGroupLocality.controls['ifsLocalityTypeCtrl'].value === 'region'">
                <mat-form-field color="primary">
                  <mat-label>Região</mat-label>
                  <mat-select [formControl]="createEditFormGroupLocality.controls['ifsLocalityRegionsCtrl']">
                    <mat-option *ngFor="let r of regions" [value]="r">{{ r?.name }}</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="createEditFormGroupLocality.controls['ifsLocalityRegionsCtrl'].hasError('required')"
                  >
                    Informe a região.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="field">
                <mat-form-field
                  *ngIf="createEditFormGroupLocality.controls['ifsLocalityTypeCtrl'].value === 'state' && states"
                  color="primary"
                >
                  <mat-label>Estado</mat-label>
                  <mat-select [formControl]="createEditFormGroupLocality.controls['ifsLocalityStateCtrl']">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let state of states" [value]="state" (click)="getCitiesforUF(state?.id)"
                      >{{ state?.name }} ({{ state?.abdr }})</mat-option
                    >
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroupLocality.controls['ifsLocalityStateCtrl'].hasError('required')">
                    Informe o estado.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="field">
                <mat-form-field
                  *ngIf="createEditFormGroupLocality.controls['ifsLocalityTypeCtrl'].value === 'state' && cities"
                  color="primary"
                >
                  <mat-label>Cidade</mat-label>
                  <mat-select
                    [formControl]="createEditFormGroupLocality.controls['ifsLocalityCityCtrl']"
                    [disabled]="!createEditFormGroupLocality.controls['ifsLocalityStateCtrl']?.value"
                  >
                    <mat-option></mat-option>
                    <mat-option *ngFor="let c of cities" [value]="c">{{ c.name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="createEditFormGroupLocality.controls['ifsLocalityCityCtrl'].hasError('required')">
                    Informe a cidade.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="d-flex">
                <mat-form-field color="primary" style="flex: 2; margin-right: 1em">
                  <input
                    matInput
                    [formControl]="createEditFormGroupLocality.controls['ifsLocalityZipCodeCtrl']"
                    placeholder="CEP"
                    #postalCode="matInput"
                    name="postalCode"
                    [textMask]="{ mask: MASKS.cep.textMask, guide: false }"
                  />
                  <mat-error
                    *ngIf="createEditFormGroupLocality.controls['ifsLocalityZipCodeCtrl']?.hasError('required')"
                  >
                    O CEP é obrigatório.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      !createEditFormGroupLocality.controls['ifsLocalityZipCodeCtrl']?.hasError('required') &&
                      createEditFormGroupLocality.controls['ifsLocalityZipCodeCtrl']?.hasError('minlength')
                    "
                  >
                    Informe um CEP válido.
                  </mat-error>
                </mat-form-field>

                <mat-form-field style="flex: 1" color="primary">
                  <input
                    matInput
                    type="number"
                    placeholder="Quantos dígitos?"
                    [formControl]="createEditFormGroupLocality.controls['ifsLocalityZipCodeQTDNumberCtrl']"
                    matTooltip="Quantos primeiros dígitos (considerando apenas números) serão utilizados para verificar se a oportunidade pertence à região do operador. Recomendado: 3."
                  />
                  <!-- <mat-error *ngIf="createEditFormGroupLocality.controls['ifsLocalityZipCodeQTDNumberCtrl'].hasError('required')">
                    O número de dígitos a comparar é obrigatório.
                  </mat-error> -->
                  <mat-error
                    *ngIf="createEditFormGroupLocality.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.min"
                  >
                    O número de dígitos mínimo é
                    {{ createEditFormGroupLocality.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.min?.min }}.
                  </mat-error>
                  <mat-error
                    *ngIf="createEditFormGroupLocality.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.max"
                  >
                    O número de dígitos máximo é
                    {{ createEditFormGroupLocality.controls['ifsLocalityZipCodeQTDNumberCtrl'].errors?.max?.max }}.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="d-flex justify-content-end" style="margin-bottom: 0.6em">
                <button
                  mat-raised-button
                  class="btn"
                  color="accent"
                  type="button"
                  (click)="onAddNewLocality()"
                  [disabled]="isLocalityInvalid()"
                >
                  <mat-icon>add</mat-icon> &nbsp;Adicionar
                </button>
              </div>
            </div>

            <hr class="my-4" />

            <div class="my-3">
              <mat-card-title>Localidades</mat-card-title>
              <mat-card-subtitle>O cliente deve pertencer a pelo menos uma das localidades abaixo</mat-card-subtitle>
            </div>

            <div *ngIf="regionalization" class="mt-4">
              <mat-accordion class="example-headers-align">
                <mat-expansion-panel *ngFor="let r of regionalization; let i = index">
                  <mat-expansion-panel-header>
                    {{ r.name }}
                  </mat-expansion-panel-header>

                  <div>
                    <div class="d-flex justify-content-end mb-2">
                      <button mat-stroked-button color="warn" type="button" (click)="removeLocality(r)" [disabled]="">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>

                    <mat-form-field *ngIf="r?.region" color="primary">
                      <mat-label>Região</mat-label>
                      <mat-select [(ngModel)]="r.region" name="Região" [compareWith]="compareName">
                        <mat-option *ngFor="let region of regions" [value]="region">
                          {{ region.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="r.state" color="primary">
                      <mat-label>Estado</mat-label>

                      <mat-select [(ngModel)]="r.state" name="Estado" [compareWith]="compareName">
                        <mat-option
                          *ngFor="let state of states"
                          (click)="getCitiesListforUF(r?.state?.id, i)"
                          [value]="state"
                          >{{ state.name }} ({{ state.abdr }})
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="r?.city" color="primary">
                      <mat-label>Cidade</mat-label>

                      <mat-select [(ngModel)]="r.city" name="Cidade" [compareWith]="compareName">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let city of citiesList[i]" [value]="city">
                          {{ city.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div class="d-flex justify-content-between">
                      <mat-form-field *ngIf="r?.zipCode" color="primary">
                        <input
                          matInput
                          [(ngModel)]="r.zipCode"
                          name="zipCode"
                          placeholder="CEP"
                          [textMask]="{ mask: MASKS.cep.textMask, guide: false }"
                          [value]="r?.zipCode"
                        />
                        <mat-error *ngIf="r.zipCode === '' || r.zipCode === null"> Informe o CEP. </mat-error>
                      </mat-form-field>

                      <mat-form-field class="col-12 col-md-3" *ngIf="r?.zipCodeDigitQuantity" color="primary">
                        <input
                          matInput
                          type="number"
                          max="8"
                          maxlength="1"
                          [(ngModel)]="r.zipCodeDigitQuantity"
                          name="zipCode"
                          placeholder="Quantidade de Digitos"
                          [value]="r?.zipCodeDigitQuantity"
                        />
                        <mat-error *ngIf="r.zipCodeDigitQuantity === null">
                          Informe a quantidade de degitos do CEP.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <button
            mat-raised-button
            *ngIf="!isProcessing"
            color="primary"
            class="btn-block btn-lg"
            type="submit"
            [disabled]="!shouldButtonEnable()"
            (click)="editBanker()"
          >
            Salvar dados do operador
          </button>
          <mat-spinner [diameter]="40" color="primary" *ngIf="isProcessing" class="mx-auto"></mat-spinner>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
</div>
