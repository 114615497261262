import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { Customer, NewOtherInstitution } from 'functions/src/models/Customer';
import _moment from 'moment';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BasicStructure } from '../../../../functions/src/models/common/BasicStructure';
import { Utm } from '../../../../functions/src/models/common/Utm';
import { CadastrosGeraisService } from '../../admin/cadastros-gerais/cadastros-gerais.service';
import { CustomerService } from '../../customer/services/customer.service';
import { TemplateSignupService } from '../../layouts/templates/template-signup/template-signup.service';

@Component({
  selector: 'app-credit-recurrence',
  templateUrl: './credit-recurrence.component.html',
  styleUrls: ['./credit-recurrence.component.scss']
})
export class CreditRecurrenceComponent implements OnInit, OnDestroy {
  @Input() proposalId: string;
  @Input() bndesSource: string;


  // Config and general/common control
  signupConfigSubscription: Subscription;
  generalConfigSubscription: Subscription;
  getTaxRegimesSubscription: Subscription;
  getCardMachinesSubscription: Subscription;
  subscriptions: Subscription;
  config: any;
  validateParams = false;
  utm: Utm;

  // Auto-Complete
  separatorKeysCodes: number[] = [ENTER, COMMA];
  submitted = false;
  visible = true;
  selectable = true;
  removable = true;
  filteredNewOtherInstitutions: Observable<(NewOtherInstitution | string)[]>;
  newOtherInstitutions: NewOtherInstitution[] = [];
  allNewOtherInstitutions: NewOtherInstitution[] = [];

  @ViewChild('newOtherInstitutionInput') newOtherInstitutionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;



  readonly steps = ['company', 'financial', 'documents'];
  step = 'company';
  privacyPolicyUrl: string;

  readonly selectTypeOfAnticipationOperator = [
    { id: 'cartao', value: 'Cartão' },
    { id: 'boleto', value: 'Boleto' },
    { id: 'nota_fiscal', value: 'Notas Fiscais' },
  ];

  readonly selectTypeMaritalStatus = [
    { id: 'solteiro', value: 'Solteiro(a)' },
    { id: 'casado', value: 'Casado(a)' },
    { id: 'separado', value: 'Separado(a)' },
    { id: 'divorciado', value: 'Divorciado(a)' },
    { id: 'viuvo', value: 'Viúvo(a)' },
  ];

  readonly selectTypeOfInvoices = [
    { id: 'nota_servico', value: 'Notas de Serviço' },
    { id: 'nota_produto', value: 'Notas de Produto' },

  ];

  // Forms
  companyDataFormGroup: FormGroup;
  financialDataFormGroup: FormGroup;
  creditDataFormGroup: FormGroup;
  customer: Customer;

  // Company Section
  agent: string;
  CESource: string;
  // Financial Section
  hasOtherInstitutions = false;

  // Credit Section
  hasOnlyAvalGuarantee = true;

  // Type Guarantee Antecipation
  hasTypeOfAnticipation = false;

  // Type Guarantee Antecipation Card
  hasTypeOfAnticipationCard = false;

  // Type Guarantee Antecipation Nota fiscal
  hasTypeOfInvoices = false;

  // MEI Section
  hasTaxRegimeMei = false;

  // Masks and validation
  readonly MASKS = MASKS;
  formatPhone = UtilHandler.formatPhone;
  readonly numberMask = createNumberMask({
    thousandsSeparatorSymbol: '.',
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });
  texts: { title: string; subtitle: string; stageId: string }[];
  runReprocesseRecurrence = false;

  passwordMatchValidator(formGroup: FormGroup): void {
    const error =
      formGroup.get('passwordCtrl').value === formGroup.get('confirmCtrl').value ? null : { mismatch: true };
    formGroup.get('confirmCtrl').setErrors(error);
  }
  lastNameMatchValidatior(formGroup: FormGroup): void {
    const error =
      formGroup
        .get('nameCtrl')
        .value?.split(' ')
        .filter((value) => value).length > 1
        ? null
        : { invalidlastname: true };
    formGroup.get('nameCtrl').setErrors(error);
  }

  constructor(
    private customerService: CustomerService,
    private cadastrosGeraisService: CadastrosGeraisService,
    // private instFinanceirasAutocomplete: InstituicaoFinanceiraService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private signupService: TemplateSignupService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.subscriptions = new Subscription();
    this.utm = {};

 
  }
  
  ngOnInit(): void {
    this.customer = this.data.customer;
    this.initializeForms();
    this.subscriptions.add(
      this.customerService.getGeneralConfiguration().subscribe((config) => {
        this.privacyPolicyUrl = config.privacyPolicyUrl;
      })
    );
    this.signupService.setStep(this.step);
    this.subscriptions.add(
      this.customerService.getSignUpConfiguration().subscribe((config) => {
        this.config = config;
        this.config.minRevenue = config?.minRevenue ?? 360000;
        this.config.minRevenueStr = config?.minRevenueStr ?? 'R$ 360.000';
      })
    );
    this.getCardMachinesSubscription = this.cadastrosGeraisService.getMaquinasAtivas().subscribe((maquinas) => {
      maquinas.map((maquina) => {
        maquina['id'] = maquina.mnemonico;
        maquina['name'] = maquina.nome;
        delete maquina.ativo;
        delete maquina.mnemonico;
        delete maquina.nome;
      });
      this.config.cardMachines = maquinas;
    });
    this.getTaxRegimesSubscription = this.cadastrosGeraisService.getRegimesAtivos().subscribe((regimes) => {
      regimes.map((regime) => {
        regime['id'] = regime.mnemonico;
        regime['name'] = regime.nome;

        delete regime.ativo;
        delete regime.mnemonico;
        delete regime.nome;
      });

      this.config.taxRegimes = regimes;
    });

  }
  ngOnDestroy(): void {
    if (this.signupConfigSubscription) {
      this.signupConfigSubscription.unsubscribe();
    }
    if (this.getTaxRegimesSubscription) {
      this.getTaxRegimesSubscription.unsubscribe();
    }
    if (this.getCardMachinesSubscription) {
      this.getCardMachinesSubscription.unsubscribe();
    }
  }

  

  areAllFormsValid(): boolean {
    return (
      this.companyDataFormGroup.valid &&
      this.financialDataFormGroup.valid &&
      this.creditDataFormGroup.valid
    );
  }

  async checkFinancialFields(): Promise<void> {
    this.validateParams = true;
    const revenue = parseFloat((this.companyDataFormGroup.get('revenueCtrl').value || '1').replace(/\D/g, ''));
    const debt = parseFloat((this.financialDataFormGroup.get('debtCtrl').value || '0').replace(/\D/g, ''));

    const maxDebtPercentual = this.config?.maxDebtPercentual || 30;

    const debtRate = debt / revenue;
    const debtThreshold = maxDebtPercentual / 100;

    const taxRegime = this.financialDataFormGroup.get('taxRegimeCtrl').value;

    if (debtRate > debtThreshold) {
      this.showMessage(
        'Endividamento elevado',
        `Infelizmente no momento só conseguimos atender empresas com dívida atual de no máximo ${ maxDebtPercentual }% do faturamento.`
      );
    } else if (taxRegime?.limiteNum && revenue > taxRegime?.limiteNum) {
      this.showMessage(
        'Faturamento Anual Incorreto',
        `O faturamento preenchido na tela anterior está incorreto, pois, segundo a legislação atual, empresas do regime tributário <strong>${ taxRegime.name }</strong>
        tem o faturamento máximo de <strong>${ taxRegime.limite }</strong>.
        Basta retornar à tela anterior, corrigir o <strong>Faturamento Anual</strong> e você poderá seguir buscando o melhor crédito para sua empresa aqui na <strong>Capital Empreendedor!</strong>`
      );
    }
    this.validateParams = false;
  }

  formatMoney(str: string): string {
    if (str?.indexOf(',') < 0) {
      return str + ',00';
    } else if (str?.indexOf(',') === str.length - 1) {
      return str + '00';
    } else if (str?.indexOf(',') === str.length - 2) {
      return str + '0';
    } else {
      return str || '';
    }
  }

  initializeForms(): void {
    this.companyDataFormGroup = this.formBuilder.group(
      {
        whatsappCtrl: new FormControl(this.customer.whatsapp ?? '', [
          Validators.required,
          NgBrazilValidators.telefone,
          Validators.minLength(15),
        ]),
        revenueCtrl: new FormControl('', [Validators.required]),
        websiteCtrl: new FormControl(this.customer.website ?? ''),
      },
    );

    this.financialDataFormGroup = this.formBuilder.group({
      debtCtrl: new FormControl('', [Validators.required]),
      taxRegimeCtrl: new FormControl('', [Validators.required]),
  
    });

    this.creditDataFormGroup = this.formBuilder.group({
      creditValueCtrl: new FormControl('', [Validators.required]),
      purposeCtrl: new FormControl('', [Validators.required]),
      guaranteesCtrl: new FormControl('', [Validators.required]),
      typeOfAnticipationCtrl: new FormControl('', [Validators.required]),
      // creditCardCtrl: new FormControl(this.customer?.creditCard ?? ''),
      creditCardCtrl: new FormControl('', [Validators.required]),
      typeOfInvoicesCtrl: new FormControl('', [Validators.required]),
      guaranteesValueCtrl: new FormControl('0', [Validators.required]),
    });

  }

  onChangeGuarantees(event: MatSelectChange): void {
    // if has type guarantee
    if (event.value.length && event.value.find((g) => g.id === 'boleto')) {
      this.hasTypeOfAnticipation = true;
    } else {
      this.hasTypeOfAnticipation = false;
      this.hasTypeOfAnticipationCard = false;
      this.hasTypeOfInvoices = false;
      this.creditDataFormGroup.get('typeOfAnticipationCtrl').clearValidators();
      this.creditDataFormGroup.get('typeOfAnticipationCtrl').setValue(null);
      this.creditDataFormGroup.get('creditCardCtrl').clearValidators();
      this.creditDataFormGroup.get('creditCardCtrl').setValue(null);
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
      this.creditDataFormGroup.updateValueAndValidity();
    }

    // if has only aval
    if (event.value.length === 1 && event.value[0].id === 'avalista') {
      this.hasOnlyAvalGuarantee = true;
      this.creditDataFormGroup.get('guaranteesValueCtrl').setValue(0);
      this.showMessage(
        'Tem certeza? ',
        'Usando garantia na operação de crédito, sua chance de tomada cresce em até 50%.'
      );
    } else {
      this.hasOnlyAvalGuarantee = false;
      this.creditDataFormGroup.get('guaranteesValueCtrl').setValue('');
    }
  }

  onChangeAntecipation(event: MatSelectChange): void {
    // if has antecipation of card
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'cartao')) {
      this.hasTypeOfAnticipationCard = true;
    } else {
      this.hasTypeOfAnticipationCard = false;
      this.creditDataFormGroup.get('creditCardCtrl').clearValidators();
      this.creditDataFormGroup.updateValueAndValidity();
      this.creditDataFormGroup.get('creditCardCtrl').setValue(null);
    }
    // if has antecipation of Nota fiscal
    if (event.value.length && event.value.find((model: BasicStructure) => model.id === 'nota_fiscal')) {
      this.hasTypeOfInvoices = true;
    } else {
      this.hasTypeOfInvoices = false;
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').clearValidators();
      this.creditDataFormGroup.updateValueAndValidity();
      this.creditDataFormGroup.get('typeOfInvoicesCtrl').setValue(null);
    }
  }



  showMessage(title, message): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      maxWidth: '600px',
      data: {
        alertTitle: title,
        alertDescription: message,
        isOnlyConfirm: true,
      },
    });
  }


  updateCustomer(): void {
    this.runReprocesseRecurrence = true;
    const date = new Date();
    const customerData: Customer = {
      whatsapp: this.companyDataFormGroup.value.whatsappCtrl.trim(),
      website: this.companyDataFormGroup.controls.websiteCtrl?.value?.trim()?.toLowerCase() ?? null,
      revenue: parseFloat(
        (this.companyDataFormGroup.controls.revenueCtrl.value.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      revenueStr: this.formatMoney(this.companyDataFormGroup.controls.revenueCtrl.value.trim()),
      debt: parseFloat(
        (this.financialDataFormGroup.controls.debtCtrl.value?.trim() || '0').replace(/[^\,\d]/g, '').replace(/\,/g, '.')
      ),
      debtStr: this.formatMoney(this.financialDataFormGroup.controls.debtCtrl.value?.trim()),
      taxRegime: this.financialDataFormGroup.controls.taxRegimeCtrl.value,
      creditValue: parseFloat(
        (this.creditDataFormGroup.controls.creditValueCtrl.value.trim() || '0')
          .replace(/[^\,\d]/g, '')
          .replace(/\,/g, '.')
      ),
      creditValueStr: this.formatMoney(this.creditDataFormGroup.controls.creditValueCtrl.value.trim()),
      purpose: this.creditDataFormGroup.controls.purposeCtrl.value,
      guarantees: this.creditDataFormGroup.controls.guaranteesCtrl.value,
      typeOfAnticipation: this.creditDataFormGroup.controls.typeOfAnticipationCtrl?.value ?? null,
      creditCard: this.creditDataFormGroup.controls.creditCardCtrl?.value ?? null,
      typeOfInvoices: this.creditDataFormGroup.controls.typeOfInvoicesCtrl?.value ?? null,
      guaranteesValue: parseFloat(
        (!this.hasOnlyAvalGuarantee ? this.creditDataFormGroup.controls.guaranteesValueCtrl.value || '0' : '0')
          .trim()
          .replace(/[^\,\d]/g, '')
          .replace(/\,/g, '.')
      ),
      guaranteesValueStr: this.formatMoney(
        (!this.hasOnlyAvalGuarantee ? this.creditDataFormGroup.controls.guaranteesValueCtrl.value || '0' : '0').trim()
      ),
      hasRecurrence: true,
      lastRecurrenceDate: firestore.Timestamp.now(),
      recurrenceDate: firestore.Timestamp.fromDate(
        _moment(date).toDate()
      )
    };

    // Chamar o serviço de atualização do cliente
    this.customerService.updateCustomerByRecurrence(this.customer.uid, customerData)
      .then(() => {
        // Sucesso na atualização
        console.log('Cliente atualizado com sucesso.');
      })
      .catch((error) => {
        // Erro na atualização
        console.error('Problemas na alteração do cliente.', error);
      }).finally(() => {
        this.runReprocesseRecurrence = false;
        this.dialog.closeAll();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/documentacao']);        
      });
  }


  // Metodo para validação, Auto-Complete
  isValidForm() {
   
    if (this.financialDataFormGroup.invalid || this.companyDataFormGroup.invalid) {
      return true;
    }
   
    return false;
  }

  isValid() {
    if (this.creditDataFormGroup.invalid) {
      return true;
    }
    return false;
  }

}
