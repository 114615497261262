import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { User } from 'functions/src/models/User';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MenuItems } from '../../../admin/components/menu-items/menu-items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class AppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  user: User;
  userSubscription: Subscription;
  loggedUserSubscription: Subscription;

  status = false;
  clickEvent(): void {
    this.status = !this.status;
  }

  subclickEvent(): void {
    this.status = true;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems, public authService: AuthService) {
    this.mobileQuery = media.matchMedia('(min-width: 720px)');
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.subscribeToIsAuthenticated();
  }

  private subscribeToIsAuthenticated(): void {
    this.userSubscription = this.authService.user.subscribe((user) => {
      if (user && !user.isAnonymous) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  private _mobileQueryListener(): void {
    return this.changeDetectorRef.detectChanges();
  }
}
