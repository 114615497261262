import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateBndesSignupService } from './template-bndes.service';

@Component({
  selector: 'app-template-bndes-signup',
  templateUrl: './template-bndes.component.html',
  styleUrls: ['./template-bndes.component.scss'],
})
export class TemplateBndesSignupComponent implements OnInit, OnDestroy {
  image = '';
  imageSubscription: Subscription;

  constructor(private templateBndesSignupService: TemplateBndesSignupService) {}

  ngOnInit(): void {
    this.imageSubscription = this.templateBndesSignupService.imagePath.subscribe((image) => {
      this.image = image;
    });
  }

  ngOnDestroy(): void {
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }
  }
}
