import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { firestore } from 'firebase';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';



@Component({
  selector: 'app-partner-logo-file-upload',
  templateUrl: './partner-logo-file-upload.component.html',
  styleUrls: ['./partner-logo-file-upload.component.scss']
})
export class PartnerLogoFileUploadComponent implements OnInit, OnDestroy {
  @Input() compact: boolean;
  @Input() maximumDocuments = 1;
  @Input() submitedFilesCount: number;
  @Input() emissionDatesCtrl: boolean;
  @Input() fileStatus: boolean;
  @Input() hasEmissionDate = true;
  @Output() completedUpload = new EventEmitter();

 

  multipleDocuments: boolean;
  uploadMetadata: Array<any> = [];
  emissionDate: FormGroup;

  maxDate: Date = new Date();

  // Main task
  task: AngularFireUploadTask;
  // Progress monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;
  // Download URL
  downloadURL: Observable<string>;
  // State for appdropzone CSS toggling
  isHovering: boolean;
  percentageFileSubscription: Subscription;
 
  

  constructor(private storage: AngularFireStorage, private fb: FormBuilder, private dialog: MatDialog, private cdr: ChangeDetectorRef) { 
    
  }

  ngOnInit() {
    this.emissionDate = this.fb.group({
      emissionDates: this.fb.array([]),
    });

    this.multipleDocuments = this.maximumDocuments === 1 ? false : true;
    this.completedUpload.emit(false);
  }

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  ngOnDestroy(): void {
    if (this.percentageFileSubscription) {
      this.percentageFileSubscription.unsubscribe();
    }
  }

  startUpload(fileList: FileList): void {
    if (this.maximumDocuments >=
      fileList.length + this.uploadMetadata.length + (this.fileStatus ? this.submitedFilesCount : 0)) {
      let isValid = true;
      for (let index = 0; index < fileList.length; index++) {
        const mimeType = fileList[index].type;
        if (mimeType.match(/image\/*/) === null ) {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Tipo de Arquivo Inválido',
              alertDescription:
                'Somente arquivos do tipo imagem são permitidos.',
              isOnlyConfirm: true,
            },
          });
          isValid = false;
          return;
        }
      }
      if (isValid) {
        if (!this.uploadMetadata?.length) {
          this.uploadMetadata = Array.from(fileList).map((file) => {
            this.createFileEmissionDateForm();
            return this.handleUploadLogo(file);
          });
        } else {
          Array.from(fileList).forEach((file) => {
            this.createFileEmissionDateForm();
            return this.uploadMetadata.push(this.handleUploadLogo(file));
          });
        }
      }
    } else {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Quantidade de documentos excedida',
          alertDescription:
            'A quantidade de documentos submetidos para upload e já submetidos 			é superior a quantidade permitida.',
          isOnlyConfirm: true,
        },
      });
    }
  }

  handleUploadLogo(file: File): any {

    console.log('handleUpload chamado com:', file);
    const fileName = file ? file.name : 'unknown';  // Verifica se file é nulo
const path = `partnership-upload-logo/${new Date().getTime()}_${fileName}`;

    // Totally optional metadata
    const customMetadata = { app: 'Capital Empreendedor' };
    const fileData: any = { file, uploadDate: firestore.Timestamp.fromDate(new Date()) };

    // The main task
    fileData.task = this.storage.upload(path, file, { customMetadata });

    // Progress monitoring
    fileData.percentage = fileData.task.percentageChanges();
    fileData.snapshot = fileData.task.snapshotChanges().pipe(
      finalize(() => {
        fileData.downloadURLObservable = this.storage.ref(path).getDownloadURL();
        if (fileData?.downloadURLObservable) {
          const subs = fileData.downloadURLObservable.subscribe((url) => {
            fileData.url = url;
            console.log('URL do logo emitida:', url);
            if (!this.hasEmissionDate) {
              this.checkIfAllDocumentsAreCompleted();
            }
            if (subs) {
              subs.unsubscribe();
              console.log('Subscrição do logo completada');
            }
          });
        }
      })
    );
    return fileData;
  }
 
  removeFile(index): void {
    console.log('this.uploadMetadata',this.uploadMetadata)
    this.uploadMetadata.splice(index, 1);
    
    (this.emissionDate.get('emissionDates') as FormArray).removeAt(index);
    this.ngOnInit()
    this.cdr.detectChanges()
    
  }
  
  

  // Determines if the upload task is active
  isActive(snapshot): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  getImageUrl(): string {
    if (this.uploadMetadata && this.uploadMetadata.length > 0 && this.uploadMetadata[0].url !== undefined && this.uploadMetadata[0].url !== null) {
      console.log('uploadMetadata ', this.uploadMetadata);
      return this.uploadMetadata[0].url;
    } else {
      return null;
    }
  }

  createFileEmissionDateForm(): void {
    this.completedUpload.emit(false);
    const emissionDateCtrl = this.fb.group({
      emissionDateCtrl: new FormControl('', Validators.required),
    });
    (this.emissionDate.get('emissionDates') as FormArray).push(emissionDateCtrl);
  }

  handleLongFileName(fileName): string {
    const fileNameRegex = /(^.+)(\.[A-Za-z0-9]+$)/.exec(fileName);
    return fileNameRegex[1].slice(0, 27) + '...' + fileNameRegex[1].slice(-1) + fileNameRegex[2];
  }

  checkIfAllDocumentsAreCompleted(): void {
    const emissionDatesCtrls = (this.emissionDate.get('emissionDates') as FormArray).controls;

    let hasAllEmissionDates = true;
    let emissionDateCount = 0;
    if (this.hasEmissionDate) {
      for (let index = 0; index < emissionDatesCtrls.length; index++) {
        if (emissionDatesCtrls[index].value?.emissionDateCtrl) {
          const dateString = emissionDatesCtrls[index].value.emissionDateCtrl;
          const timestamp = firestore.Timestamp.fromDate(new Date(dateString));
          this.uploadMetadata[index].emissionDate = timestamp;
          if (hasAllEmissionDates) {
            emissionDateCount++;
          }
        } else {
          hasAllEmissionDates = false;
        }
      }

      if (emissionDateCount === emissionDatesCtrls.length) {
        this.completedUpload.emit(true);
      }
    } else {
      this.completedUpload.emit(this.uploadMetadata?.length && !this.uploadMetadata.find((file) => !file.url));
    }
  }

  setURL(index, url): boolean {
    this.uploadMetadata[index].downloadURL = url;
    return true;
  }

  
  // onFileChange(event: any) {
  //   const file = event.target.files[0];
  //   this.createFormGroup.patchValue({
  //     logoCtrl: file, 
  //   });
  // }
}

