<div
  class="appDropZone"
  appDropZone
  (hovered)="toggleHover($event)"
  (dropped)="startUpload($event)"
  [class.hovering]="isHovering"
  [style.max-height]=""
>
  <div class="file pointer py-4 px-3">
    <label class="file-label d-flex flex-column pointer">
      <input
        class="file-input"
        type="file"
        accept="image/*, application/pdf"
        [multiple]="multipleDocuments"
        (change)="startUpload($event.target.files)"
        style="display: none"
      />
      <span class="pointer">
        
        <img src="../../../assets/images/icons/upload.png" alt="">
      </span>
      <span class="file-cta pointer">
        <span class="file-label"> Arraste seu arquivo para cá ou clique aqui</span>
      </span>
      
    </label>
  </div>
</div>
<div>
  <div *ngFor="let file of uploadMetadata; let i = index" class="uploaded-document" style="border: none">
    <div class="d-flex align-items-center">
      <mat-icon class="file-icon" color="accent">insert_drive_file</mat-icon>
      <span class="file-data">
        <span *ngIf="!file.url" class="uploaded-file-name">
          <span class="file-name" *ngIf="file.file.name.length < 35">{{ file.file.name }}</span>
          <span class="file-name" *ngIf="file.file.name.length >= 35">{{ handleLongFileName(file.file.name) }}</span>
        </span>
        <span *ngIf="file.url" class="uploaded-file-name">
          <a
            class="file-name"
            *ngIf="file.url && file.file.name.length < 35 && setURL(i, file.url)"
            [href]="file.url"
            target="_blank"
            >{{ file.file.name }}</a
          >
          <a
            class="file-name"
            *ngIf="file.file.name.length >= 35 && file.url && setURL(i, file.url)"
            [href]="file.url"
            target="_blank"
            >{{ handleLongFileName(file.file.name) }}</a
          >
        </span>
        <span *ngIf="file.snapshot | async as snap" class="mx-auto text-center" class="uploaded-file-size">
          <span class="file-size" *ngIf="!isActive(snap)">
            {{ file.file.size | fileSize }}
          </span>
          <span *ngIf="file.percentage | async as pct" class="progress-bar">
            <progress
              *ngIf="pct !== 100"
              class="progress is-info"
              [value]="pct"
              max="100"
              style="max-width: 100%"
            ></progress>
          </span>
          <span class="file-size load" *ngIf="isActive(snap)" class="mx-auto text-center">
            {{ snap?.bytesTransferred | fileSize }} de {{ snap?.totalBytes | fileSize }}
          </span>
          <br />
        </span>
      </span>
      <mat-icon class="file-remove" (click)="removeFile(i)">close</mat-icon>
    </div>

    <div class="date-picker" [formGroup]="emissionDate" *ngIf="file?.url && hasEmissionDate">
      <div formArrayName="emissionDates" class="emission-date">
        <mat-form-field [formGroupName]="i" class="w-100">
          <mat-label>Data da emissão</mat-label>
          <input
            matInput
            [max]="maxDate"
            [matDatepicker]="dp"
            (dateChange)="checkIfAllDocumentsAreCompleted(i)"
            formControlName="emissionDateCtrl"
            name="dateEmission"
            required
            (click)="dp.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="dp" style="font-size: 18px !important"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
