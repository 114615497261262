<div>
  <h2 *ngIf="showText">{{text}}</h2>
  <div [ngClass]="{'carousel-container': true, 'container-reverse': styleType === 'column-reverse'}">
  <div class="carousel-nav">
    <button *ngIf="showNavButtons()" (click)="prev()" class="nav-button" [disabled]="currentIndex === 0">
      <i class="fas fa-chevron-left"></i>
    </button>
    <div class="carousel-wrapper" #carouselWrapper>
      <div class="carousel" [style.transform]="getTransform()">
        <ng-container *ngFor="let item of items">
          <div *ngIf="customer.revenue < item.revenueMax && customer.revenue > item.revenueMin" class="carousel-item">
            <img [src]="item.imageLink" [alt]="item.text">
            <h3>{{ item.text }}</h3>
            <p>{{ item.subText }}</p>
            <button class="link-button" [href]="item.link" (click)="openExternalLink(item.link)" target="_blank" rel="noopener noreferrer">
              Ver mais ➜
            </button>

          </div>
        </ng-container>
      </div>
    </div>
    <button *ngIf="showNavButtons()" (click)="next()" class="nav-button" [disabled]="currentIndex >= maxIndex()">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
</div>


