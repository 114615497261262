<div class="container-fluid" *ngIf="banker">
  <h1 class="mb-4">Oportunidades Ativas</h1>

  <app-filter-oportunity [isLoadingResults]="isLoadingResults" 
  [minRevenue]="minRevenue"
  [maxRevenue]="maxRevenue"
    (applyFilter)="applyFilter($event)"></app-filter-oportunity>

  <div class="w-100 p-5" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults" [diameter]="60" class="mx-auto" color="primary"></mat-spinner>
  </div>

  <div class="table-container" *ngIf="!isLoadingResults && !opportunities">
    <p class="text-center">Nenhuma oportunidade encontrada.</p>
  </div>

  <div class="table-container" *ngIf="!isLoadingResults && opportunities">
    <table mat-table multiTemplateDataRows [dataSource]="dataSource" matSort  (matSortChange)="sortData($event)"
      matSortActive="companyName" [matSortDirection]="sortDirection" matSortDisableClear class="table-generic-styles">
      <!-- Company Name Column -->
      <ng-container matColumnDef="companyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear style="text-align: left">
          Razão Social
        </th>
        <td mat-cell [routerLink]="'/bankers/opportunity/' + row.uid" *matCellDef="let row" class="companyName">
          {{ row.companyName }}
        </td>
      </ng-container>

      <!-- CNPJ Column -->
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>CNPJ</th>
        <td mat-cell *matCellDef="let row" class="no-border">{{ row.cnpj }}</td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Criação</th>
        <td mat-cell *matCellDef="let row" class="no-border">
          {{ row.termsSignatureDate ? (row.termsSignatureDate | date) : '-' }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="creditValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Valor</th>
        <td mat-cell *matCellDef="let row" class="no-border">
          {{ (row.creditValue | currency: 'R$') || '-' }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="revenue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Faturamento</th>
        <td mat-cell *matCellDef="let row" class="no-border">
          {{ (row.revenue | currency: 'R$') || '-' }}
        </td>
      </ng-container>

      <!-- Status Score -->
      <!-- <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Score</th>
        <td mat-cell *matCellDef="let row" class="no-border">
          {{ row.score || '-' }}
        </td>
      </ng-container> -->

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Status</th>
        <td mat-cell *matCellDef="let row" class="no-border">
          {{ row.status }}
        </td>
      </ng-container>

      <!-- ExpandedDetail Column -->
      <ng-container matColumnDef="reportProgress">
        <td id="reportar" mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="d-flex align-items-center pt-2" [style.height]="'unset'">
            <!-- <div style="padding-left: 0.6rem"></div> -->
            <mat-form-field class="mr-2" style="width: 100%" color="accent">
              <textarea class="input-class" matInput placeholder="Reportar andamento"
                [(ngModel)]="row.newMessage"></textarea>
            </mat-form-field>
            <div>
              <button mat-stroked-button color="accent" type="button" style="font-size: 0.9em !important"
                [disabled]="!row.newMessage?.trim()" (click)="createNote(row)">
                <mat-icon>send</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: ['reportProgress']"></tr>
    </table>
  </div>
  <mat-paginator #paginator [length]="pageLength" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" ></mat-paginator>
</div>