import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { Contract } from 'functions/src/models/Contract';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private authService: AuthService, private angularFirestore: AngularFirestore, private http: HttpClient) {}

  getContract(uid: string): Observable<Contract> {
    return this.angularFirestore.doc<Contract>(`contract/${uid}`).valueChanges();
  }

  sendEmailToAccountant(accountantEmail: string, companyName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/email/notify-accountant`,
          { accountantEmail, companyName },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('E-mail succesfully sent to accountant');
          resolve();
        })
        .catch((err) => {
          console.error('Error sending e-mail to accountant', err);
          reject(err);
        });
    });
  }

  updateContractOnFirestore(uid: string, data: Contract): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const contractRef: AngularFirestoreDocument<Contract> = this.angularFirestore.doc(`customers/${uid}`);

        contractRef
          .set(
            {
              ...data,
              lastUpdate: firestore.Timestamp.now(),
            },
            { merge: true }
          )
          .then(() => {
            console.log('Contract data updated on database');
            resolve();
          })
          .catch((err) => {
            console.error('Error getting contract data', err);
            reject(err);
          });
      } else {
        reject(new Error('Missing customer uid'));
      }
    });
  }
}
