import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from 'functions/src/models/Customer';
import _ from 'lodash';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { DocumentAction, Document as DocumentMongoDB } from '../../../../../functions/src/models/documents/UserDocument';
import { Opportunity } from '../../../../../functions/src/models/opportunity/Opportunity';
import { InstituicaoFinanceira } from '../../../admin/instituicao-financeira/instituicao-financeira';
import { InstituicaoFinanceiraService } from '../../../admin/instituicao-financeira/instituicao-financeira.service';
import { ContactService } from '../../../admin/services/contact.service';
import { OpportunityManagementService } from '../../../admin/services/opportunity-management.service';
import { ClicksignDialogComponent } from '../../../components/clicksign-widget/clicksign-dialog.component';
import { AuthService } from '../../../core';
import { ClicksignService } from '../../services/clicksign.service';
import { UserDocumentService } from '../../services/user-document.service';
import { ContractService } from './../../services/contract.service';


@Component({
  selector: 'app-customer-onboarding',
  templateUrl: './customer-onboarding.component.html',
  styleUrls: ['./customer-onboarding.component.scss'],
})
export class CustomerOnboardingDialogComponent implements OnInit, OnDestroy {
  readonly MASKS = MASKS;
  readonly formatPhone = UtilHandler.formatPhone;

  customer: Customer;
  customerSubscription: Subscription;
  firstName: string;
  step:   'hello' | 'rate' | 'scrOne' | 'scrTwo'| 'scrThree' | 'accountant' | 'whats-next' = 'hello';
  emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  phoneCtrl = new FormControl('', [Validators.required, NgBrazilValidators.telefone]);
  configSubscription: Subscription;
  revenueDeclarationMonths = 36;
  allowContactAccountant = false;
  hasTemplate = false;
  displayedColumns: string[] = ['nome', 'situacao', 'ativo', 'acao'];
  documentSelected: DocumentMongoDB;
  documents: DocumentMongoDB[];
  documentsLength: number;
  acceptedDocuments: number;
  documentsToShowOnList: DocumentMongoDB[];
  isClicksignWidgetOpen: boolean;
  documentsToSign: DocumentMongoDB[];
  showTextAfterSign: boolean;
  requestSignatureKey: string;
  groupByStatus = true;

  emailTouched = false;
  phoneTouched = false;
  isMobile: boolean;
  mediaQueryList: MediaQueryList;

  // to do: colocar isso em models ou serviço
  documentsStatusGroups: {
    key: string;
    text: string;
    docs: DocumentMongoDB[];
    color: string;
    count?: number;
  }[] = [
      { key: 'Pendente', text: 'Documentos pendentes', color: '#B9B9B9', docs: [] },
      { key: 'Em Progresso', text: 'Documentos em progresso', color: '#B9B9B9', docs: [] },
      // { key: 'Enviado Parcialmente' , text : 'Documentos Pendentes' },
      { key: 'Envio Completo', text: 'Documentos sob análise', color: '#6B98F0', docs: [] },
      { key: 'Aprovado', text: 'Documentos aprovados', color: '#05C46B', docs: [] },
      { key: 'Reprovado', text: 'Documentos rejeitados', color: '#F06B6B', docs: [] },
    ];








  constructor(
    private contractService: ContractService,
    private customerService: CustomerService,
    private opportunityService: OpportunityManagementService,
    private instituicaoFinanceiraService: InstituicaoFinanceiraService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CustomerOnboardingDialogComponent>,
    private router: Router,
    public authService: AuthService,
    private contactService: ContactService,
    private clicksignService: ClicksignService,
    private userDocumentService: UserDocumentService,
    private mediaMatcher: MediaMatcher,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.customer = data.customer;
    this.firstName = data.firstName;
    this.acceptedDocuments = 0;
    this.documents = [];
    this.documentsToShowOnList = [];
    this.isClicksignWidgetOpen = false;
    this.documentsToSign = [];
    this.showTextAfterSign = false;
    this.mediaQueryList = this.mediaMatcher.matchMedia('(max-width: 600px)');
    this.isMobile = this.mediaQueryList.matches;

  }

  ngOnInit(): void {
    this.configSubscription = this.customerService.getSignUpConfiguration().subscribe((config) => {
      this.revenueDeclarationMonths = config.revenueDeclarationMonths || 36;
    });

    this.customerSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;
      if (this.customer?.uid) {
        this.getDocuments();
      }
    });

    this.opportunityService.getOpportunities(this.customer.uid).then((opportunities: Opportunity[]) => {

      this.instituicaoFinanceiraService.getInstituicoesFinanceiras().subscribe((institutions: InstituicaoFinanceira[]) => {

        const matchedOpps = opportunities.filter((opp: Opportunity) => {
          return institutions.some((institution: InstituicaoFinanceira) => {
            return institution.clicksignTemplateKey !== '' && opp.institutionName === institution.nome;
          });
        });

        this.hasTemplate = matchedOpps.length > 0;
      }, (error) => {
        console.error('Error Occurred - Get Institutions: ', error);
      });
    })
    .catch((error) => {
      console.error('Error Occurred - Get Opportunities: ', error);
    });

    this.mediaQueryList.addEventListener('change', this.handleMediaQueryChange);
  }

  ngOnDestroy(): void {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }

    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }

    this.mediaQueryList.removeEventListener('change', this.handleMediaQueryChange);
  }

  handleMediaQueryChange = (event: MediaQueryListEvent) => {
    this.isMobile = event.matches;
  }

  updateStepHello(): void {
    if (!this.customer.noTermsCustomer) {
      this.step = 'rate';
    } else {
      this.step = !this.documentsToSign.length ? 'accountant' : 'scrOne';
    }
  }

  validateSendEmailContract(): boolean {
    const guaranteesIds = this.customer.guarantees.map((guarantee) => guarantee.id);
    const revenueGreaterThan240000 = this.customer.revenue > 240000;
    const companyAgeGreaterThan2 = this.customer.companyInformation?.age > 2;

    const hasVehicleOrProperty = guaranteesIds.some((id) => id.includes('veiculos') || id.includes('imovel'));
    const hasDesiredGuarantee = guaranteesIds.some((id) => id.includes('avalista') || id.includes('boleto'));

    return (revenueGreaterThan240000 && (hasVehicleOrProperty || (hasDesiredGuarantee && companyAgeGreaterThan2)));
  }

  saveAccountant(): void {
    if (this.allowContactAccountant && this.emailCtrl.valid && this.phoneCtrl.valid) {
      const accountantData: Customer['accountantData'] = {
        email: (this.emailCtrl.value || '').trim(),
        phone: (this.phoneCtrl.value || '').trim(),
      };

      const contact = {
        contactName: 'Contador',
        whatsapp: accountantData.phone.replace(/\D/g, ''),
        description: 'Contato do contador',
        type: 'customer',
        cnpj: this.customer.cnpj,
        isContactMain: false,
        bankName: '',
        id: '',
        companyName: this.customer.companyName,
        uid: this.customer.uid,
        createdAt: new Date(),
      };

      // Verifique se o número de telefone é diferente do this.customer.whatsapp
      if (accountantData.phone !== this.customer.whatsapp) {
        this.customerService
          .updateCustomerOnFirestore(this.customer.uid, { accountantData })
          .then(() => {
            console.log('Accountant data was saved');


            this.contactService.addContact(contact)
            try {
              console.log('Accountant contact was saved');
            } catch (err) {
              console.error('Error save contact accountant', err);
            }
            

            if (this.validateSendEmailContract()) {
              try {
                this.contractService.sendEmailToAccountant(accountantData.email, this.customer.companyName);
                this.step = 'whats-next';
              } catch (err) {
                console.error('Error notifying accountant', err);
              }
            } else {
              this.onExit();
            }
          })
          .catch((err) => {
            console.error('Error saving accountant', err);
            this.dialog.open(AlertDialogComponent, {
              data: {
                alertTitle: 'Erro',
                alertDescription: 'Erro ao salvar dados do contador, tente novamente.',
                isOnlyConfirm: true,
              },
            });
          });
      } else {
        this.dialog.open(AlertDialogComponent, {
          data: {
            alertTitle: 'Número de telefone inválido',
            alertDescription: 'O número de telefone do contador deve ser diferente do WhatsApp da empresa.',
            isOnlyConfirm: true,
          },
        });
      }
    } else {
      this.emailCtrl.markAsTouched();
      this.phoneCtrl.markAsTouched();
      this.emailCtrl.updateValueAndValidity();
      this.phoneCtrl.updateValueAndValidity();

      this.dialog.open(AlertDialogComponent, {
        data: {
          alertTitle: 'Dados incompletos',
          alertDescription: 'Preencha todas as informações do contador para continuar.',
          isOnlyConfirm: true,
        },
      });
    }
  }


  goToDocumentation(): void {
    this.onExit();
    this.router.navigate(['/documentacao'], {
      queryParams: {
        tab: 'documents',
      },
    });
  }

  onExit(): void {
    this.dialogRef.close(false);
  }

  updateDocumentsGroupStatus(documents: DocumentMongoDB[]): void {
    const docs = documents.map((d) => {
      if (d.situation === 'Enviado Parcialmente') {
        d.situation = 'Pendente';
      }
      if (d.situation === 'Assinado - Aguardando Atualização Webhook') {
        d.situation = 'Em Progresso';
      }
      return d;
    });
    const docRecord = _.groupBy(docs, (doc) => doc.situation);

    this.documentsStatusGroups.forEach((docGroup) => {
      docGroup.docs = docRecord[docGroup.key] ?? [];
      docGroup.count = docGroup.docs.length;
    });
  }


  removesFilesToSignFromList(documentsList: DocumentMongoDB[]): DocumentMongoDB[] {
    const documents = documentsList?.filter((document) => {
      if (document?.documentAction === 'sign') {
        return (
          !!document.fileInfo?.find((info) => !!info?.path) &&
          document.qtySubmittedFiles === document.qtyExpectedFiles &&
          document.situation !== 'Pendente'
        );
      } else {
        return true;
      }
    });
    return documents;
  }

  getDocuments(): void {
    const { uid } = this.customer;
    this.userDocumentService.getDocuments({ uid }).then((documents) => {
      console.log(`User documents subscribe ${ new Date().toISOString() }`);
      this.documents = documents
        .map((d) => {
          if (d.financialYear?.id === 'sim') {
            const currentYear = new Date().getFullYear();
            let financialYears: DocumentMongoDB['financialYears'] = null;
            const anoExercicioCorrente = d.anoExercicioCorrente ?? false;
            let countYear = 0;

            while (countYear < d.qtyFinancialYear) {
              let financialYear;

              if (anoExercicioCorrente || (anoExercicioCorrente && d.financialYears?.includes(currentYear.toString()))) {
                financialYear = (currentYear - countYear).toString();
                countYear++;
              } else {
                countYear++;
                financialYear = (currentYear - countYear).toString();
              }

              if (countYear === 1) {
                financialYears = [financialYear];
              } else {
                if (financialYears?.length > 0 && !financialYears.includes(financialYear)) {
                  console.log('year', financialYear);
                  financialYears.push(financialYear);
                }
              }
            }

            d.financialYears = financialYears;
          }
          return d;
        })
        ?.sort((o1, o2) => {
          if (o1.ordenation > o2.ordenation) {
            return 1;
          } else if (o1.ordenation < o2.ordenation) {
            return -1;
          } else {
            return 0;
          }
        });

      this.documentsLength = this.documents?.length || 0;

      this.acceptedDocuments = _.filter(this.documents, (d) => d?.situation === 'Aprovado').length;

      this.documentsToShowOnList = this.removesFilesToSignFromList(this.documents);
      this.updateDocumentsGroupStatus(this.documentsToShowOnList);

      this.documentsToSign = this.documents?.filter(
        (doc) =>
          doc?.documentAction === DocumentAction.SIGN &&
          !doc?.fileInfo?.find((info) => !!info?.path) &&
          doc?.qtySubmittedFiles < doc?.qtyExpectedFiles &&
          doc?.situation === 'Pendente'
      );
    });
  }

  async openClicksignWidget(): Promise<void> {
    this.isClicksignWidgetOpen = true;
  
    try {
      const requestSignatureKeyOne = await this.processDocumentsToSign();
      const requestSignatureKeyTwo = await this.processDocumentsToSign();

      console.log(requestSignatureKeyOne);
      const requestSignatureKey = requestSignatureKeyTwo

      const dialogRef = this.dialog.open(ClicksignDialogComponent, {
        closeOnNavigation: false,
        disableClose: false,
        hasBackdrop: true,
        width: '100%',
        height: '90%',
        data: {
          requestSignatureKey,
        },
      });
  
      const data = await dialogRef.afterClosed().toPromise();
  
      if (!!data) {
        console.log(data);
        if (data === 'signed') {
          this.showTextAfterSign = true;
          this.documentsToSign = [];
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isClicksignWidgetOpen = false;
    }
  }
  

  async processDocumentsToSign(): Promise<string> {
    this.requestSignatureKey = await this.clicksignService.requestSignatureKey({
      uid: this.customer.uid,
      documentsId: this.documentsToSign.map((d) => d.typeId),
    });
    this.step = 'accountant';
    return this.requestSignatureKey;
  }


}


