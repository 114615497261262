import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '../../../../functions/src/models/Customer';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class ReceitaWSService {
  constructor(private authService: AuthService, private http: HttpClient) { }
  requestDataReceitaWS(cnpj: string): Promise<Partial<Customer>> {
    console.log('ReceitaWs Service - consulta cnpj - ' + cnpj)
    return this.http
      .post<Partial<Customer>>(
        `${ environment.functionsUrl }/receitaws/consultar-cnpj-onboard`,
        {
          cnpj,
        },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise()
      .then(res => {
        console.log('request receita ws by success - cnpj' + cnpj,res)
        return res
      })
      .catch((error) => {
        console.error('Error request receita ws by - ' + cnpj + ':', error);
        throw error
      });
  }
}