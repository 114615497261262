import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { firestore } from 'firebase';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  options = [
    {
      value: 30,
      text: '30 dias',
    },
    {
      value: 60,
      text: '60 dias',
    },
    {
      value: 90,
      text: '90 dias',
    },
    {
      value: 180,
      text: '180 dias',
    },
    {
      value: 'all',
      text: 'Todas',
    },
  ];

  averageClosingTime: number;
  daysRangeSelected: number | 'all';

  isLoading = false;

  columnsCredit: string[] = ['institution', 'company', 'createdAt', 'closingDate', 'timeToMoney', 'actions'];
  dataSourceCredit: MatTableDataSource<any>;
  dataSourceTable: any;

  private matSortCredit: MatSort;
  private paginatorCredit: MatPaginator;
  @ViewChild('matSortCredit', { static: false }) set matSort(ms1: MatSort) {
    this.matSortCredit = ms1;
    this.dataSourceCredit.sort = this.matSortCredit;
  }
  @ViewChild('paginatorCredit', { static: false }) set matPaginator(mp1: MatPaginator) {
    this.paginatorCredit = mp1;
    this.dataSourceCredit.paginator = this.paginatorCredit;
  }

  constructor(private statsService: StatsService, private dialog: MatDialog) {
    this.dataSourceCredit = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    // this.loadStats('all');
  }

  loadStats(params: number | 'all'): void {
    this.isLoading = true;
    this.statsService
      .getOpportunitiesAverageClosingTime(params)
      .then((obj) => {
        // passei os credit para a tabela
        this.dataSourceTable = obj.opportunities.map((opp) => ({
          ...opp,
          termsSignatureDate: opp.termsSignatureDate ? firestore.Timestamp.fromMillis(opp.termsSignatureDate) : null,
          closingDate: opp.closingDate ? firestore.Timestamp.fromMillis(opp.closingDate) : null,
        }));
        this.dataSourceCredit = new MatTableDataSource(this.dataSourceTable);
        this.dataSourceCredit.sort = this.matSortCredit;
        this.dataSourceCredit.paginator = this.paginatorCredit;

        this.averageClosingTime = Math.ceil(obj.result);
        this.isLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.dialog
          .open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao obter estatísticas',
              alertDescription: `Um problema ocorreu ao obter os dados.`,
              isOnlyConfirm: true,
            },
          })
          .afterClosed()
          .toPromise()
          .then(() => {
            this.isLoading = false;
          });
      });
  }

  getData(): void {
    this.loadStats(this.daysRangeSelected);
  }
}
