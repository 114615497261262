import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Banker } from 'functions/src/models/Banker';
import { BankerNote } from 'functions/src/models/Notes';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { NotesService } from '../../../admin/services/notes.service';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'app-opportunity-notes',
  templateUrl: './opportunity-notes.component.html',
  styleUrls: ['./opportunity-notes.component.scss'],
})
export class OpportunityNotesComponent implements OnInit, OnDestroy {
  banker: Banker;
  customer: string;
  notificationData: any;

  isCreateMode = false;
  isLoadingNotes = true;

  newNote: BankerNote = {
    message: '',
  };
  searchValue = '';

  notes: BankerNote[] = [];
  filteredNotes: BankerNote[] = [];
  notesSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private opportunityService: OpportunityService,
    private notesService: NotesService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.banker = data.banker;
    this.customer = data.customer;
    this.notificationData = data.notificationData;
  }

  ngOnInit(): void {
    this.notesSubscription = this.notesService
      .getBankerComments({
        customerUid: this.customer,
        isSenderAdmin: '',
        institution: this.banker.institution.name,
        senderEmail: this.banker.email,
      })
      .subscribe((notes) => {
        this.notes = notes;
        this.searchNotes();
        this.isLoadingNotes = false;
      });
  }

  ngOnDestroy(): void {
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNewNote();
  }

  clearNewNote(): void {
    this.newNote.message = '';
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchNotes();
  }

  createNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar mensagem',
          alertDescription: 'Deseja realmente enviar essa mensagem?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const note: BankerNote = {
            ...this.newNote,
            type: 'BANKER',
            banker: this.banker.uid,
            createdBy: {
              name: this.banker.name,
              email: this.banker.email,
            },
          };

          this.opportunityService
            .createNote(this.customer, this.banker.institution.name, note, this.notificationData)
            .then(() => {
              this.isCreateMode = false;
              this.clearNewNote();
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Mensagem enviada',
                  alertDescription: 'A mensgagem foi enviada com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao enviar',
                  alertDescription: 'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  searchNotes(): void {
    if (this.searchValue) {
      this.filteredNotes = _.filter(
        this.notes,
        (n) => n.message?.toUpperCase().indexOf(this.searchValue.toUpperCase()) >= 0
      );
    } else {
      this.filteredNotes = this.notes;
    }
  }
}
