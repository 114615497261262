<div class="content d-flex flex-column flex-md-row align-items-md-center">




  <section *ngIf="step === 'hello'" class="text-div ml-md-4">
    <div class="progress-wrapper">
      <img src="assets/images/progresso.png" />
    </div>
    <div class="content-wrapper">

      <div class="image-wrapper">
        <img *ngIf="isMobile; else desktopImage"
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor-staging.appspot.com/o/assets%2Fimage%2FMask%20group.png?alt=media&token=2937570c-41de-4778-93c7-438a67a736ef"
          alt="Imagem" />
        <ng-template #desktopImage>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor-staging.appspot.com/o/assets%2Fimage%2FFrame%2079.png?alt=media&token=200a1a93-e622-45c5-8b1c-fa53f99eef13"
            alt="Imagem" class="image" />
        </ng-template>
      </div>
      <div class="text-content">
        <h1>
          <strong class="color-green">Boas vindas!</strong>
        </h1>
        <p>
          A <b class="color-orange">Capital Empreendedor</b> é a ponte entre você e mais de 360 instituições financeiras
          no Brasil inteiro!
        </p>
        <p>
          Com o nosso <b class="color-orange">cadastro único</b>, basta enviar os documentos solicitados e <b>vamos
            combinar o seu pedido com <b class="color-orange">TODAS</b> as oportunidades possíveis que se encaixem no
            seu perfil e objetivo.</b>
        </p>



        <div class="text-center d-flex align-items-center" style="margin-top: 30px; margin-left: 56px;">
          <button mat-flat-button class="action-button" type="button" (click)="updateStepHello()">
            Próximo
          </button>
        </div>
      </div>

    </div>

  </section>





  <section *ngIf="step === 'rate'" class="text-div ml-md-4">
    <div class="progress-wrapper">
      <img src="assets/images/progresso 2.png" />
    </div>
    <div class="content-wrapper">
      <div class="image-wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor-staging.appspot.com/o/assets%2Fimage%2Fg10.png?alt=media&token=4404c64c-70f8-4c71-9e9a-cb6637191189"
          alt="Imagem" class="image" />
      </div>
      <div class="text-content">

        <h1>
          <strong class="color-green">Taxa de abertura de crédito</strong>
        </h1>
        <p>
          O <b class="color-orange">processo é gratuito </b>, você paga uma <b> taxa de abertura de crédito</b> apenas
          <b class="color-orange">SE e QUANDO </b> decidir tomar o crédito com nossos parceiros efetivamente.
        </p>

        <div class="text-center d-flex align-items-center" style="margin-top: 30px; margin-left: 56px;">
          <ng-container *ngIf="!documentsToSign.length > 0">
            <button mat-flat-button class="action-button" type="button" (click)="this.step = 'accountant'">
              Próximo
            </button>
          </ng-container>

          <ng-container *ngIf="documentsToSign.length > 0">
            <button mat-flat-button class="action-button" type="button" (click)="this.step = 'scrOne'">
              Próximo
            </button>
          </ng-container>
        </div>

      </div>
    </div>

  </section>





  <section *ngIf="step === 'scrOne'" class="text-div ml-md-4">
    <div class="progress-wrapper">
      <img src="assets/images/progresso 3.png" />
    </div>
    <div class="content-wrapper">

      <div class="image-wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2FFrame%2078.png?alt=media&token=20b0050b-6877-4550-a7da-8e247799faec"
          alt="Imagem" class="image" />
      </div>
      <div class="text-content">
        <h1>
          <strong class="color-green">Autorização para consulta no Banco Central</strong>
        </h1>
        <p>
          Todo processo para obter crédito passa por várias análises feitas por cada instituição financeira. Por isso,
          <b class="color-orange"> são solicitados alguns documentos </b>,
          inclusive um <b> muito importante</b> para todas as instituições financeiras: a <b
            class="color-orange">Autorização para Consulta SCR</b> (sistema de informações de crédito do Banco Central).
        </p>

        <div class="text-center d-flex align-items-center" style="margin-left: 56px;">
          <button mat-flat-button class="action-button" type="button" (click)="this.step = 'scrTwo'">
            Próximo
          </button>
        </div>
      </div>


    </div>

  </section>






  <section *ngIf="step === 'scrTwo'" class="text-div ml-md-4">
    <div class="progress-wrapper">
      <img src="assets/images/progresso 3.png" />
    </div>
    <div class="content-wrapper">

      <div class="image-wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2FFrame%2078.png?alt=media&token=20b0050b-6877-4550-a7da-8e247799faec"
          alt="Imagem" class="image" />
      </div>

      <div class="text-content">
        <h1>
          <strong class="color-green">Autorização para consulta no Banco Central</strong>
        </h1>
        <p>
          Esse é o documento que autoriza <b>a consulta de suas informações </b> em todas as organizações financeiras
          regulamentadas
          pelo Banco Central e contempla <b class="color-orange">mais um ponto de avaliação </b> de sua proposta de
          crédito.
        </p>


        <ng-container>
          <div class="text-center d-flex align-items-center" style="margin-top: 30px; margin-left: 56px;">
            <button mat-flat-button class="action-button" type="button" (click)="this.step = 'scrThree'">
              Próximo
            </button>
          </div>
        </ng-container>
      </div>
    </div>

  </section>





  <section *ngIf="step === 'scrThree'" class="text-div ml-md-4">
    <div class="progress-wrapper">
      <img src="assets/images/progresso 3.png" />
    </div>
    <div class="content-wrapper">

      <div class="image-wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2FFrame%2078.png?alt=media&token=20b0050b-6877-4550-a7da-8e247799faec"
          alt="Imagem" class="image" />
      </div>
      <div class="text-content">
        <h1>
          <strong class="color-green">Autorização para consulta no Banco Central</strong>
        </h1>
        <p>
          Utilizamos uma <b class="color-orange">autorização única</b>, para que você não precise assinar uma para cada
          instituição financeira.
        </p>
        <p>
          <b> Recomendamos fortemente </b> a assinatura ainda nesta tela. Desta forma, <b class="color-orange">você
            poderá receber ofertas mais rápido!</b>
        </p>




      </div>
    </div>
    <div class="bnt-sign">
      <button mat-flat-button class="button-sign" type="button" [disabled]="isClicksignWidgetOpen"
        (click)="openClicksignWidget()">
        Assinar autorização
      </button>
      <div class="text-center my-3" *ngIf="isClicksignWidgetOpen">
        <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
      </div>
    </div>
    <div class="sing" style="margin-top: 12px; margin-left: 449px;">
      <span class="clickable" (click)="this.step = 'accountant'">Assinar depois</span>
    </div>
  </section>




  <section *ngIf="step === 'accountant'" class="text-div ml-md-4">
    <div class="progress-wrapper">
        <img src="assets/images/progresso 4.png" />
      </div>
    <div class="content-wrapper">
      
      <div class="image-wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor-staging.appspot.com/o/assets%2Fimage%2FFrame%2080.png?alt=media&token=dc4b62d4-13b6-4bbb-aad0-b3ed7519044a"
          alt="Imagem" class="image" />
      </div>
      <div class="text-content">
        <h1>
          <strong class="color-green">Autorize seu contador a nos enviar seus documentos</strong>
        </h1>
        <p>
          Adicione as informações de contato do seu contador e tenha <b>ajuda extra </b> com seus documentos. <b
            class="color-orange">Isso torna seu processo ainda mais fácil!</b>
        </p>
        <div class="row w-100">
          <div class="field-container col-12 col-lg-6">
            <div>
              <input class="field-" placeholder="Email do contador*" name="email" required [formControl]="emailCtrl"
                (blur)="emailTouched = true" />
              <mat-error class="mat-error" *ngIf="emailTouched && emailCtrl.hasError('required')">O e-mail é
                obrigatório</mat-error>
              <mat-error class="mat-error" *ngIf="emailTouched && emailCtrl.hasError('email')">E-mail
                inválido</mat-error>
            </div>
            <div>
              <input class="field-" placeholder="Celular do contador*" name="phone" required [formControl]="phoneCtrl"
                [textMask]="{ mask: formatPhone, guide: false }" (blur)="phoneTouched = true" />
              <mat-error class="mat-error" *ngIf="phoneTouched && phoneCtrl.hasError('required')">O celular é
                obrigatório</mat-error>
              <mat-error class="mat-error" *ngIf="phoneTouched && phoneCtrl.hasError('telefone')">Celular
                inválido</mat-error>
            </div>
          </div>
        </div>
        <div style="margin-top: 32px;">
          <mat-checkbox class="ml-2 my-3 checkbox" [(ngModel)]="allowContactAccountant">
            <span class="pre-wrap text-checkbox" [ngClass]="{'checked': allowContactAccountant}">Eu autorizo a Capital
              Empreendedor a solicitar ao meu contador os dados para o
              andamento do meu pedido
            </span>
          </mat-checkbox>
        </div>
        <div class="text-center d-flex align-items-center" style="margin-top: 30px; margin-left: 56px;">

          <span class="clickable"
            (click)="this.customer.revenue > 360000 ? (this.step = 'whats-next') : onExit()">Pular</span>

          <button mat-flat-button class="action-button mr-1"
            [ngClass]="{'disabled-button': !allowContactAccountant || emailCtrl.invalid || phoneCtrl.invalid}"
            type="button" (click)="saveAccountant()"
            [disabled]="!allowContactAccountant || emailCtrl.invalid || phoneCtrl.invalid">
            Enviar
          </button>
        </div>
      </div>
    </div>
  </section>





  <section *ngIf="step === 'whats-next'" class="text-div ml-md-4">
<div class="progress-wrapper">
        <img src="assets/images/progresso 5.png" />
      </div>
    <div class="content-wrapper">
      
      <div class="image-wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor-staging.appspot.com/o/assets%2Fimage%2Fg11.png?alt=media&token=412a45eb-4887-48a1-8a72-839958abf902"
          alt="Imagem" class="image" />
      </div>
      <div class="text-content">
        <h1>
          <strong class="color-green">Próximos passos</strong>
        </h1>
        <p>
          Estamos prontos para iniciar a busca pelo seu crédito ideal! <b class="color-orange"> Alguns documentos podem
            ser solicitados para análise </b>, basta enviá-los na aba <b> “Meus documentos”</b>.
        </p>
        <p>
          Quanto <b class="color-orange">mais rápido</b>, <b>maiores suas chances de conseguir o MELHOR crédito!</b>
        </p>


        <div class="text-center d-flex align-items-center" style="margin-left: 56px;">
          <button mat-flat-button class="action-button" type="button" (click)="onExit()">
            Finalizar
          </button>
        </div>

      </div>
    </div>
  </section>





</div>