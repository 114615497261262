import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { ContactReceived } from '../../../chat/models/contact';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { InstituicaoFinanceiraService } from '../../instituicao-financeira/instituicao-financeira.service';
import { ContactService } from '../../services/contact.service';

export interface NewOtherInstitution {
  nomeNoSistema: string;
  name: string;
  type: string;
}

@Component({
  selector: 'app-contact-form-component',
  templateUrl: './contact-form-component.component.html',
  styleUrls: ['./contact-form-component.component.scss']
})
export class ContactFormComponentComponent implements OnInit {
  @Output() closePopup: EventEmitter<any> = new EventEmitter<any>();
  @Input() initialContact: ContactReceived;
  contactForm: FormGroup;
  filteredNewOtherInstitutions: Observable<(NewOtherInstitution | string)[]>;
  newOtherInstitutions: NewOtherInstitution[] = [];
  allNewOtherInstitutions: NewOtherInstitution[] = [];
  @ViewChild('newOtherInstitutionInput') newOtherInstitutionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  submitted = false;
  visible = true;
  selectable = true;
  removable = true;
  newOtherInstitution: string;
  isInputBank = true;
    readonly MASKS = MASKS;
    formatPhone = UtilHandler.formatPhone;

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private instFinanceirasAutocomplete: InstituicaoFinanceiraService,
    private dialog: MatDialog,
  ) {
    this.contactForm = this.formBuilder.group({
      contactNameCtrl: ['', Validators.required],
      whatsappCtrl: ['', [Validators.required, NgBrazilValidators.telefone, Validators.minLength(15)]],
      descriptionCtrl: ['', Validators.required],
      typeCtrl: ['customer', Validators.required],
      cnpjCtrl: [''],
      bankNameCtrl: [''],
    });

    this.contactForm.get('typeCtrl').valueChanges.subscribe((value) => {
      if (value === 'banker') {
        this.contactForm.get('bankNameCtrl').setValidators([Validators.required]);
        this.contactForm.get('cnpjCtrl').clearValidators();
      } else if (value === 'customer') {
        this.contactForm.get('cnpjCtrl').setValidators([Validators.required, NgBrazilValidators.cnpj, Validators.minLength(13)]);
        this.contactForm.get('bankNameCtrl').clearValidators();
      }

      this.contactForm.get('bankNameCtrl').updateValueAndValidity();
      this.contactForm.get('cnpjCtrl').updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    if (this.initialContact) {
      this.initializeForm();
    }
    this.instFinanceirasAutocomplete.getFinancialInstitutionAutocomplete().subscribe((data) => {
      this.allNewOtherInstitutions = data;
    
      const bankNameCtrl = this.contactForm?.get('bankNameCtrl');
    
      if (bankNameCtrl) {
        this.filteredNewOtherInstitutions = bankNameCtrl.valueChanges.pipe(
          startWith(''),
          debounceTime(200),
          map((value: string) => {
            if (value && value.length >= 3) {
              return this._filter(value);
            } else {
              return [];
            }
          })
        );
      }
    });
  }

  initializeForm(): void {
    this.contactForm = this.formBuilder.group({
      contactNameCtrl: [this.initialContact.displayName || '', Validators.required],
      whatsappCtrl: [this.initialContact.phone || '', [Validators.required]],
      descriptionCtrl: [this.initialContact.description || '', Validators.required],
      typeCtrl: [this.initialContact ? 'customer' : '', Validators.required],
      cnpjCtrl: [this.initialContact.cnpj || '', [Validators.required]]
    });
  }


  onOverlayClick(event: MouseEvent) {
  // Check if the click occurred on the overlay (not the pop-up itself)
  if (event.target === event.currentTarget) {
    // Emit the signal to close the pop-up
    this.closePopup.emit();
  }
}

onPopUpClick(event: MouseEvent) {
  // Prevent clicks on the pop-up from propagating to the overlay
  event.stopPropagation();
}


  private formatCnpj(cnpj: string): string {
    // Remove all non-numeric characters from the CNPJ
    const numericCnpj = cnpj.replace(/\D/g, '');

    // Check if the CNPJ has 14 digits, if not, return the original value
    if (numericCnpj.length !== 14) {
      return cnpj;
    }

    // Format the CNPJ with dots, dashes, and slash
    return `${numericCnpj.slice(0, 2)}.${numericCnpj.slice(2, 5)}.${numericCnpj.slice(5, 8)}/${numericCnpj.slice(8, 12)}-${numericCnpj.slice(12)}`;
  }




  async onSubmit() {

    if (this.contactForm.invalid) {
      return;
    }

    const contact = {
      contactName: this.contactForm.get('contactNameCtrl').value,
      whatsapp: this.contactForm.get('whatsappCtrl').value.replace(/\D/g, ''),
      description: this.contactForm.get('descriptionCtrl')?.value?.replace(/\D/g, '') ?? null,
      type: this.contactForm.get('typeCtrl').value,
      cnpj: this.contactForm.get('cnpjCtrl').value ,
      isContactMain: false,
      bankName: '',
      id: '',
      companyName: '',
      uid: '',
      createdAt: new Date(),
    };

    const type = this.contactForm.get('typeCtrl').value;

    if (type === 'banker') {
      contact.bankName = this.contactForm.get('bankNameCtrl').value?.name;
      delete contact.cnpj;
    } else if (type === 'customer') {
      const cnpjInput = this.contactForm.get('cnpjCtrl').value;
      const numericCnpj = cnpjInput.replace(/\D/g, ''); // Remove all non-numeric characters from the CNPJ

      // Check if the CNPJ has 14 digits, if not, return the original value
      if (numericCnpj.length !== 14) {
        return;
      }

      // Format the CNPJ with dots, dashes, and slash
      contact.cnpj = `${numericCnpj.slice(0, 2)}.${numericCnpj.slice(2, 5)}.${numericCnpj.slice(5, 8)}/${numericCnpj.slice(8, 12)}-${numericCnpj.slice(12)}`;

      this.contactService.getCustomerByCnpj(contact.cnpj).subscribe((customer) => {
        if (customer) {
          contact.companyName = customer.companyName;
          contact.uid = customer.uid;
          delete contact.bankName;

          this.contactService.addContact(contact)
            .then(() => {
              console.log('Contato adicionado com sucesso!');
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Sucesso ao  salvar o contato' ,
                  alertDescription: 'Contato foi salvo com sucesso.',
                  isOnlyConfirm: true,
                },

              });
              this.closePopup.emit();
            })
            .catch((error) => {
              console.error('Erro ao adicionar contato:', error);
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao  salvar o contato' ,
                  alertDescription: 'Ocorreu um erro ao salvar o contato',
                  isOnlyConfirm: true,
                },

              });

            });
        } else {
          console.error('Cliente não encontrado com o CNPJ fornecido.');

          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro: cliente não encontrado com o CNPJ fornecido.' ,
              alertDescription: 'Cliente não encontrado com o CNPJ fornecido.',
              isOnlyConfirm: true,
            },

          });

        }
      });
    }
  }


  onClose() {
    this.closePopup.emit();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const alreadySelected = this.newOtherInstitutions.some(
      (selected) => selected.name.toLowerCase() === value.trim().toLowerCase()
    );

    if ((value || '').trim() && !alreadySelected) {
      const formattedValue = this.capitalize(value.trim());
      this.newOtherInstitutions.push({ nomeNoSistema: '', name: formattedValue, type: '' });
    }

    input.value = null;
  }

  private capitalize(value: string): string {
    const words = value.split(' ');
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(' ');
  }

  remove(newOtherInstitution: NewOtherInstitution): void {
    const index = this.newOtherInstitutions.indexOf(newOtherInstitution);
    if (index >= 0) {
      this.newOtherInstitutions.splice(index, 1);
    }
    this.isInputBank = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value;

    const alreadySelected = this.newOtherInstitutions.some(
      (selected) => selected.name.toLowerCase() === selectedOption.name.toLowerCase()
    );

    if (!alreadySelected) {
      this.newOtherInstitutions.push(selectedOption);
      this.newOtherInstitutionInput.nativeElement.value = '';
      this.contactForm.controls.bankNameCtrl.setValue(selectedOption);
      this.isInputBank = false;
    }
  }

  private _filter(value: string): NewOtherInstitution[] {
    const filterValue = value.toLowerCase();
    return this.allNewOtherInstitutions.filter((newOtherInstitution) => {
      const alreadySelected = this.newOtherInstitutions.some(
        (selected) => selected.name.toLowerCase() === newOtherInstitution.name.toLowerCase()
      );
      return !alreadySelected && newOtherInstitution.name.toLowerCase().includes(filterValue);
    });
  }
}
