import { Opportunity } from './../../../../../functions/src/models/opportunity/Opportunity';
import { OpportunityManagementService } from './../../../admin/services/opportunity-management.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'functions/src/models/Customer';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { TemplateSignupService as ImageService } from 'src/app/layouts/templates/template-signup/template-signup.service';

@Component({
  selector: 'app-email-information',
  templateUrl: './email-information.component.html',
  styleUrls: ['./email-information.component.scss'],
})
export class EmailInformationComponent implements OnInit, OnDestroy {
  customer: Customer;
  customerSubscription: Subscription;
  opportunities: Opportunity[];
  preApprovedLimit = 'R$ 0,00';

  isProcessing = false;

  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private oppsService: OpportunityManagementService,
    // private dialog: MatDialog,
    private imageService: ImageService,
    private router: Router
  ) {
    this.imageService.setImagePath('assets/images/signup/illustration2.svg');
  }

  ngOnInit(): void {
    this.customerSubscription = this.customerService.customer.subscribe((customer: Customer) => {
      if (customer && customer.uid) {
        this.customer = customer;
        this.preApprovedLimit = UtilHandler.getFormattedPrice((customer.revenue || 0) * 0.3 - (customer.debt || 0));

        this.oppsService
          .createOpportunities(customer.uid, null, 'automatic')
          .then((res) => {
            console.log('Success on processing opps');
            this.opportunities = res;
          })
          .catch((err) => {
            console.error('Error processing opps', err);
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  resendConfirmationEmail(): void {
    this.isProcessing = true;

    this.authService
      .sendEmailVerification()
      .then((r) => {
        this.isProcessing = false;
        console.log('Resend e-mail', r);
      })
      .catch((err) => {
        this.isProcessing = false;
        console.error('Resend e-mail error', err);
      });
  }

  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }
}
