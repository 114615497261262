<li class="tree-item" *ngIf="(isArray(node) && node.length) || (isObject(node) && !isEmpty(node))">
  <button mat-icon-button [attr.aria-label]="'toggle tree node data'" (click)="toggle()" [id]="label">
    <mat-icon class="mat-icon-rtl-mirror">
      {{ expanded ? 'expand_more' : 'chevron_right' }}
    </mat-icon>
  </button>
  <span style="font-weight: 500">{{ label }}</span>
</li>
<ul *ngIf="expanded">
  <div *ngFor="let nk of nodeKeys">
    <app-tree-parent *ngIf="!isArray(node)" [label]="nk" [node]="node[nk]" [id]="'parent-' + nk"></app-tree-parent>

    <div *ngIf="isArray(node)">
      <app-tree-parent
        *ngIf="isArray(node[nk]) || isObject(node[nk])"
        [label]="isArray(node[nk]) ? '[ ... ]' : '{ ... }'"
        [node]="node[nk]"
        [id]="'parent-' + nk"
      ></app-tree-parent>
      <div *ngFor="let element of node; let i = index">
        <app-tree-child *ngIf="isScalar(element)" [data]="element"></app-tree-child>
      </div>
    </div>
  </div>

  <app-tree-child *ngFor="let lk of leafKeys" [label]="lk" [data]="node[lk]"></app-tree-child>
</ul>
