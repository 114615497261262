import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OpportunityPhasesEntity } from '../../../../functions/src/models/opportunity/OpportunityPhases';
import { timestampForDate } from '../../../../functions/src/utils/dates';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit {
  public alertTitle: string;
  timestampForDate = timestampForDate;
  displayedColumns: string[] = ['stageChangeDate', 'stageNumber', 'stageName', 'createdBy', 'rejectReason'];
  dataSource = new MatTableDataSource<OpportunityPhasesEntity>([]);

  constructor(private dialogRef: MatDialogRef<TableDialogComponent>, @Inject(MAT_DIALOG_DATA) data: {
    alertTitle: string;
    dataSource: OpportunityPhasesEntity[];
  }) {
    this.alertTitle = data.alertTitle;
    this.dataSource = new MatTableDataSource<OpportunityPhasesEntity>(data.dataSource);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

  onGoBack(): void {
    this.dialogRef.close();
  }
}
