<div *ngIf="partnersDoc?.length > 0" style="margin-top: 20px;">
  <div *ngFor="let item of partnersDoc;let i = index">
    <mat-accordion>
      <mat-expansion-panel [class]="'mat-panel' + getBorderStyle(partnersDoc.length,i)" style="border-color: #00C46C;">
        <mat-expansion-panel-header>
          <mat-panel-title class="col-8">
            Documentos do sócio {{i + 1}}
          </mat-panel-title>
          <mat-panel-description class="col-4">
            <mat-label><!--style="margin-left: 100px;"-->
              <mat-chip-list>
                <mat-chip [color]="item?.situation === 'Reprovado' ? 'warn' : 'primary'"
                  [selected]="item?.situation === 'Aprovado' || item?.situation === 'Reprovado'">
                  {{ item?.situation }}
                </mat-chip>
              </mat-chip-list>
            </mat-label>

            <mat-icon *ngIf="item?.pendingIssueForAdmin" class="pendencia-element">*</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="mb-4">
          <div class="col-12 d-flex mr-0 ml-0 p-0">
            <div class="col-6 mr-0 ml-0 p-0">
              <h4>Nome: <mat-label class="label">{{item.partner}}</mat-label></h4>
            </div>
            <div class="col-6 mr-0 ml-0 p-0">
              <h4>Sócio admistrativo: <mat-label class="label">{{item.socioQual ? hasPartnerAdmin(item.socioQual) :
                  '-'}}</mat-label></h4>
            </div>
          </div>
          <div class="col-12 d-flex mr-0 ml-0 p-0">
            <div class="col-6 mr-0 ml-0 p-0">
              <h4>CPF: <mat-label class="label">{{ item.socioCPF ? socioCPF : '-'}}</mat-label>
              </h4>
            </div>
          </div>

          <div>
            <div *ngFor="let doc of item.docs; let idx = index">
              <app-expansion-doc-item [item]="doc" [customer]="customer" [loggedUser]="loggedUser"
                [borderStyle]="getBorderStyle(item.docs.length,idx)"
                [matPainelColor]="'#F4F4F4'"
                (delete)="onDeleteDoc(doc.mnemonic)"></app-expansion-doc-item>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>