export class MoneyplusEntity{
  erro?: boolean;
  mensagemOperador?: string;
  periodo?: string;
  uid?: string;
  resumoDoCliente?: {
    cnpjDaIfSolicitante?: string;
    codigoDoCliente?: string;
    coobrigacaoAssumida?: number;
    coobrigacaoAssumidaSpecified?: boolean;
    coobrigacaoRecebida?: number;
    coobrigacaoRecebidaSpecified?: boolean;
    dataBaseConsultada?: string;
    dataInicioRelacionamento?: string;
    listaDeMensagensDeValidacao?: [
      {
        codigo?: string;
        mensagem?: string;
      }
    ];
    listaDeResumoDasOperacoes?: [
      {
        listaDeVencimentos?: [
          {
            codigoVencimento?: string;
            valorVencimento?: number;
            valorVencimentoSpecified?: boolean;
          }
        ];
        Modalidade?: string;
        VariacaoCambial?: string;
      }
    ];
    quantidadeDeInstituicoes?: number;
    quantidadeDeOperacoes?: number;
    quantidadeOperacoesDiscordancia?: number;
    quantidadeOperacoesSubJudice?: number;
    responsabilidadeTotalDiscordancia?: number;
    responsabilidadeTotalDiscordanciaSpecified?: boolean;
    responsabilidadeTotalSubJudice?: number;
    responsabilidadeTotalSubJudiceSpecified?: boolean;
    riscoIndiretoVendor?: number;
    riscoIndiretoVendorSpecified?: boolean;
    tipoDoCliente?: string;
  };
  resumoDoClienteTraduzido?: {
    dtInicioRelacionamento?: string;
    qtdeInstituicoes?: number;
    qtdeOperacoes?: number;
    qtdeOperacoesDiscordancia?: number;
    vlrOperacoesDiscordancia?: number;
    qtdeOperacoesSobJudice?: number;
    vlrOperacoesSobJudice?: number;
    carteiraVencer?: number;
    carteiraVencerAte30diasVencidosAte14dias?: number;
    carteiraVencer31a60dias?: number;
    carteiraVencer61a90dias?: number;
    carteiraVencer91a180dias?: number;
    carteiraVencer181a360dias?: number;
    carteiraVencerAcima360dias?: number;
    carteiraVencerPrazoIndeterminado?: number;
    moedaEstrangeiraValor?: number;
    moedaEstrangeiraValor15a30dias?: number;
    moedaEstrangeiraValor31a60dias?: number;
    moedaEstrangeiraValor61a90dias?: number;
    moedaEstrangeiraValor91a180dias?: number;
    moedaEstrangeiraValor181a360dias?: number;
    moedaEstrangeiraValorAcima360dias?: number;
    carteiraVencido?: number;
    carteiraVencido15a30dias?: number;
    carteiraVencido31a60dias?: number;
    carteiraVencido61a90dias?: number;
    carteiraVencido91a180dias?: number;
    carteiraVencido181a360dias?: number;
    carteiraVencidoAcima360dias?: number;
    prejuizo?: number;
    prejuizoAte12meses?: number;
    prejuizoAcima12meses?: number;
    carteiradeCredito?: number;
    repasses?: number;
    coobrigacoes?: number;
    responsabilidadeTotal?: number;
    creditosaLiberar?: number;
    limitesdeCredito?: number;
    limitesdeCreditoAte360dias?: number;
    limitesdeCreditoAcima360dias?: number;
    riscoIndiretoVendor?: number;
    riscoTotal?: number;
  };
  resumoModalidade?: [
    {
      tipo?: string;
      modalidade?: string;
      dominio?: string;
      subdominio?: string;
      valorVencimento?: number;
    }
  ];
  msg?: string;
  result?: boolean;
  HasError?: boolean;
  messages?: [
    {
      messageType?: number;
      code?: string;
      context?: string;
      description?: string;
      field?: string;
    }
  ]
}