<div class="container">
  <mat-card *ngIf="!isLoading">
    <mat-card-content>
      <mat-card-title class="mb-4">
        <div class="row" style="align-items: baseline">
          <div>
            <i
              class="fa fa-user-circle"
              aria-hidden="true"
              style="font-size: 1.7em; padding-left: 1.7rem; color: #00a659"
            ></i>
          </div>

          <div style="font-size: 1.4em; padding-bottom: 0%; padding-left: 1rem; color: #00a659">Minha Conta</div>
        </div>
      </mat-card-title>

      <mat-card
        *ngIf="banker && banker.changedPassword"
        style="margin-bottom: 24px; border: solid 1px #828282; border-left: solid 4px #00a659"
        ><mat-card-content>
          <div class="row">
            <div class="col-12 col-lg-6 overflow-auto">
              <p><strong>Instituição:</strong> {{ banker.institution.label || banker.institution.name || '-' }}</p>
              <p><strong>Nome:</strong> {{ banker.name || '-' }}</p>
              <p><strong>E-mail:</strong> {{ banker.email || '-' }}</p>
              <p><strong>WhatsApp:</strong> {{ banker.whatsapp || '-' }}</p>
              <p><strong>Telefone:</strong> {{ banker.phone || '-' }}</p>
              <p><strong>Criado em:</strong> {{ banker.createdAt?.toDate().toLocaleString() || '-' }}</p>
            </div>
            <div class="col-12 col-lg-6">
              <p><strong>CEP:</strong> {{ banker.address?.postalCode || '-' }}</p>
              <p><strong>Endereço:</strong> {{ banker.address?.street || '-' }}, {{ banker.address?.number || '-' }}</p>
              <p><strong>Complemento:</strong> {{ banker.address?.extraInfo || '-' }}</p>
              <p><strong>Bairro:</strong> {{ banker.address?.neighborhood || '-' }}</p>
              <p><strong>Cidade:</strong> {{ banker.address?.city || '-' }}</p>
              <p><strong>Estado:</strong> {{ banker.address?.state || '-' }}</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-expansion-panel *ngIf="agent" style="margin-bottom: 24px">
        <mat-expansion-panel-header>
          <mat-panel-title> Alterar Informaçães Pessoais </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <form [formGroup]="personalInformationFormGroup">
            <div class="fields">
              <div class="field">
                <mat-form-field color="accent" class="w-100">
                  <input matInput readonly type="email" placeholder="E-mail" formControlName="emailCtrl" />
                <!-------
                    <mat-error *ngIf="personalInformationFormGroup.controls['emailCtrl'].hasError('required')">
                      Informe seu e-mail.
                    </mat-error>
                    <mat-error *ngIf="personalInformationFormGroup.controls['emailCtrl'].hasError('email')">
                      O e-mail deve ser válido.
                    </mat-error>
                    ----->
                </mat-form-field>
              </div>

              <div class="field">
                <mat-form-field color="accent" class="w-100">
                  <input matInput placeholder="Nome completo" formControlName="nameCtrl" required name="fullname" />
                  <mat-error *ngIf="personalInformationFormGroup.controls['nameCtrl'].hasError('required')">
                    Informe seu nome completo.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="field">
                <mat-form-field color="accent" class="w-100">
                  <input
                    matInput
                    placeholder="Telefone"
                    formControlName="phoneCtrl"
                    name="phone"
                    phone
                    #phone
                    [textMask]="{ mask: formatPhone, guide: false }"
                    required
                  />
                  <mat-error *ngIf="personalInformationFormGroup.controls['phoneCtrl'].hasError('required')">
                    Informe um telefone para contato.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field color="accent">
                  <mat-label>CNPJ</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="CNPJ"
                    formControlName="CNPJCtrl"
                    name="CNPJ"
                    [textMask]="{ mask: MASKS.cnpj.textMask }"
                  />
                  <mat-error *ngIf="personalInformationFormGroup.controls['CNPJCtrl'].hasError('cnpj')">
                    CNPJ inválido</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field color="primary">
                  <mat-label> Linkedin (link) </mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="Linkedin"
                    formControlName="linkedinCtrl"
                    name="linkedin"
                    required
                  />
                  <mat-error *ngIf="personalInformationFormGroup.controls['linkedinCtrl'].hasError('linkedin')">
                    Informe o link para o seu Linkedin
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field color="accent" class="w-100">
                  <input matInput type="password" placeholder="Senha Atual" formControlName="passwordCtrl" required />
                  <mat-error *ngIf="personalInformationFormGroup.controls['passwordCtrl'].hasError('required')">
                    Informe sua senha atual.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="my-2 text-right tip">* Campos obrigatórios</div>
              <div class="mt-3">
                <button
                  mat-raised-button
                  *ngIf="!isLoading"
                  color="primary"
                  class="btn-block btn-lg"
                  type="submit"
                  [disabled]="personalInformationFormGroup.invalid || shouldButtonDisable()"
                  (click)="editUser()"
                >
                  Atualizar dados
                </button>
                <mat-spinner [diameter]="40" color="accent" *ngIf="processing" class="mx-auto"></mat-spinner>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>

      <mat-card
        ><mat-card-content>
          <mat-expansion-panel style="margin-bottom: 24px">
            <mat-expansion-panel-header>
              <mat-panel-title> <i class="fas fa-key actions-icons"></i>Alterar Senha </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <form autocomplete="off" name="passwordFormGroup" [formGroup]="passwordFormGroup">
                <div class="form">
                  <div class="mb-1 d-flex align-items-center">
                    <mat-form-field color="primary">
                      <input
                        matInput
                        placeholder="Senha atual"
                        type="password"
                        name="oldPassword"
                        formControlName="oldPasswordCtrl"
                        required
                      />
                      <mat-error *ngIf="passwordFormGroup.get('oldPasswordCtrl')?.hasError('required')">
                        Digite sua senha atual
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="mb-1 d-flex align-items-center">
                    <mat-form-field color="primary">
                      <mat-label>Nova senha</mat-label>
                      <input
                        matInput
                        placeholder="Nova senha"
                        type="password"
                        name="newPassword"
                        required
                        formControlName="newPasswordCtrl"
                      />
                      <mat-error *ngIf="passwordFormGroup.get('newPasswordCtrl')?.hasError('required')">
                        Digite sua nova senha
                      </mat-error>
                      <mat-error *ngIf="passwordFormGroup.get('newPasswordCtrl')?.hasError('minlength')">
                        Escolha uma senha com mais de 6 caracteres
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="mb-1 d-flex align-items-center">
                    <mat-form-field color="primary">
                      <input
                        matInput
                        placeholder="Confirme a Senha"
                        type="password"
                        name="confirmNewPassword"
                        formControlName="confirmNewPasswordCtrl"
                        required
                      />
                      <mat-error *ngIf="passwordFormGroup.get('confirmNewPasswordCtrl')?.hasError('required')">
                        Confirme sua senha
                      </mat-error>
                      <mat-error *ngIf="passwordFormGroup.controls['confirmNewPasswordCtrl'].hasError('mismatch')">
                        As senhas são diferentes
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      class="action-button"
                      type="submit"
                      [class.disabled]="passwordFormGroup.invalid"
                      *ngIf="!isProcessingPassword"
                      [disabled]="passwordFormGroup.invalid"
                      (click)="changePassword()"
                    >
                      <mat-icon>check</mat-icon> Salvar senha
                    </button>
                    <div class="text-center">
                      <mat-spinner
                        *ngIf="isProcessingPassword"
                        [diameter]="40"
                        color="primary"
                        class="spinner-area"
                      ></mat-spinner>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel style="margin-bottom: 24px" [hidden]="true">
            <mat-expansion-panel-header
              ><i class="fas fa-envelope actions-icons"></i>Alterar Email</mat-expansion-panel-header
            >
            <div>
              <form name="emailFormGroup" [formGroup]="emailFormGroup">
                <div class="mb-1 d-flex align-items-center">
                  <mat-form-field color="primary">
                    <mat-label>Email Atual</mat-label>
                    <input
                      matInput
                      placeholder="Email Atual"
                      type="email"
                      name="oldEmail"
                      (change)="confirmOldEmail('oldEmailCtrl')"
                      required
                      formControlName="oldEmailCtrl"
                    />
                    <mat-error *ngIf="emailFormGroup.controls['oldEmailCtrl'].hasError('email')">
                      Email atual inválido.
                    </mat-error>
                    <mat-error *ngIf="emailFormGroup.controls['oldEmailCtrl'].hasError('required')">
                      Informe o email atual.
                    </mat-error>

                    <mat-error *ngIf="emailFormGroup.controls['oldEmailCtrl'].hasError('mismatch')">
                      Email digitado não corresponde ao email atual.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mb-1 d-flex align-items-center">
                  <mat-form-field color="primary">
                    <mat-label>Novo email</mat-label>
                    <input
                      matInput
                      placeholder="Novo email"
                      type="email"
                      name="newEmail"
                      required
                      formControlName="newEmailCtrl"
                    />
                    <mat-error *ngIf="emailFormGroup.controls['newEmailCtrl'].hasError('required')">
                      Informe o novo email.
                    </mat-error>
                    <mat-error *ngIf="emailFormGroup.controls['newEmailCtrl'].hasError('email')">
                      Novo email inválido.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="mb-1 d-flex align-items-center">
                  <mat-form-field color="primary">
                    <input
                      matInput
                      placeholder="Confirme o novo email"
                      type="email"
                      name="confirmNewEmail"
                      formControlName="confirmNewEmailCtrl"
                      required
                    />
                    <mat-error *ngIf="emailFormGroup.controls['confirmNewEmailCtrl'].hasError('email')">
                      Novo email inválido.
                    </mat-error>
                    <mat-error *ngIf="emailFormGroup.controls['confirmNewEmailCtrl'].hasError('required')">
                      Confirme o novo email.
                    </mat-error>
                    <mat-error *ngIf="emailFormGroup.controls['confirmNewEmailCtrl'].hasError('mismatch')">
                      Confirmação de email não confere.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form">
                  <div class="mb-1 d-flex align-items-center">
                    <mat-form-field color="primary">
                      <input
                        matInput
                        placeholder="Senha"
                        type="password"
                        name="password"
                        formControlName="passwordCtrl"
                        required
                      />
                      <mat-error *ngIf="emailFormGroup.get('passwordCtrl')?.hasError('required')">
                        Digite sua senha
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      class="action-button"
                      type="submit"
                      [class.disabled]="emailFormGroup.invalid"
                      *ngIf="!isProcessingEmail"
                      [disabled]="emailFormGroup.invalid"
                      (click)="changeEmail()"
                    >
                      <mat-icon>check</mat-icon> Salvar email
                    </button>
                    <div class="text-center">
                      <mat-spinner
                        *ngIf="isProcessingEmail"
                        [diameter]="40"
                        color="primary"
                        class="spinner-area"
                      ></mat-spinner>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header
              ><i class="fas fa-ban actions-icons"></i>Excluir Conta</mat-expansion-panel-header
            >
            <div>
              <div>
                <p>
                  Olá, <strong>{{ user?.name || user?.nome }}</strong
                  >. <br />
                  É uma pena que você queira excluir sua conta.
                </p>
                <p>
                  <strong>Observação: </strong> Para solicitar a exclusão de sua conta você deve selecionar um motivo e
                  digitar sua senha.
                </p>
              </div>

              <form [formGroup]="exclusedAccountFormGroup" (submit)="exclusedAccount()">
                <div class="flex-fill">
                  <app-form-field-select
                    fieldName="Por que você esta excluindo sua conta?"
                    controlName="motivationCtrl"
                    [formGroup]="exclusedAccountFormGroup"
                    [options]="reasonOptions[banker || agent ? 'agentsAndBanker' : 'customers']"
                    [required]="true"
                  ></app-form-field-select>
                </div>

                <div class="mb-1 d-flex align-items-center">
                  <mat-form-field color="primary">
                    <input
                      matInput
                      placeholder="Digite sua senha"
                      type="password"
                      name="oldPassword"
                      formControlName="oldPasswordCtrl"
                      required
                    />
                    <mat-error *ngIf="exclusedAccountFormGroup.get('oldPasswordCtrl')?.hasError('required')">
                      Digite sua senha
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="text-center">
                  <button
                    mat-stroked-button
                    color="warn"
                    class="action-button"
                    type="submit"
                    [disabled]="exclusedAccountFormGroup.invalid"
                  >
                    <span *ngIf="!isProcessingExclused">Solicitar a exclusão da sua conta</span>
                    <mat-spinner
                      *ngIf="isProcessingExclused"
                      [diameter]="40"
                      color="warn"
                      class="spinner-area"
                    ></mat-spinner>
                  </button>
                </div>
              </form>
            </div>
          </mat-expansion-panel> </mat-card-content
      ></mat-card>
    </mat-card-content>
  </mat-card>
</div>
