import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { GrupoInstituicaoFinanceira, InstituicaoFinanceira } from './grupo-instituicao-financeira';

@Injectable({
  providedIn: 'root',
})
export class GrupoInstituicaoFinanceiraService {
  grupoInstituicaoFinanceiraCollection: AngularFirestoreCollection<GrupoInstituicaoFinanceira>;
  grupoInstituicaoFinanceira: Observable<GrupoInstituicaoFinanceira[]>;
  grupoInstituicaoFinanceiraDoc: AngularFirestoreDocument<GrupoInstituicaoFinanceira>;

  instituicaoFinanceiraCollection: AngularFirestoreCollection<InstituicaoFinanceira>;
  instituicaoFinanceira: Observable<InstituicaoFinanceira[]>;

  constructor(private angularFirestore: AngularFirestore, private logger: LogService) {
    this.logger.controllerName = this.constructor.name;
    this.grupoInstituicaoFinanceiraCollection = this.angularFirestore.collection('instituicao-financeira-grupo', (x) =>
      x.orderBy('nome', 'asc')
    );
    this.instituicaoFinanceiraCollection = this.angularFirestore.collection('instituicoes-financeiras', (x) =>
      x.orderBy('nome', 'asc')
    );
  }

  getGruposInstituicoesFinanceiras() {
    this.grupoInstituicaoFinanceira = this.grupoInstituicaoFinanceiraCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as GrupoInstituicaoFinanceira;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.grupoInstituicaoFinanceira;
  }

  getGrupoInstituicaoFinanceira(grupoInstituicaoFinanceiraId: string) {
    return this.angularFirestore
      .doc<GrupoInstituicaoFinanceira>(`instituicao-financeira-grupo/${grupoInstituicaoFinanceiraId}`)
      .valueChanges();
  }

  addGrupoInstituicaoFinanceira(instituicaoFinanceira: GrupoInstituicaoFinanceira) {
    this.logger.info('Grupo de instituição financeira criado com sucesso.', instituicaoFinanceira.id);
    return this.grupoInstituicaoFinanceiraCollection.add(instituicaoFinanceira);
  }

  async updateGrupoInstituicaoFinanceira(grupoInstituicaoFinanceira: GrupoInstituicaoFinanceira) {
    this.grupoInstituicaoFinanceiraDoc = this.angularFirestore.doc(
      `instituicao-financeira-grupo/${grupoInstituicaoFinanceira.id}`
    );
    try {
      const s = await this.grupoInstituicaoFinanceiraDoc.set(grupoInstituicaoFinanceira, { merge: true });
      this.logger.info('Grupo de instituição financeira alterado com sucesso.', grupoInstituicaoFinanceira.id);
      return s;
    } catch (e) {
      this.logger.error('Problemas na alteração de grupo de instituição financeira.', e);
    }
  }

  getInstituicoesFinanceiras() {
    this.instituicaoFinanceira = this.instituicaoFinanceiraCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as InstituicaoFinanceira;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.instituicaoFinanceira;
  }
}
