import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-field-select',
  templateUrl: './form-field-select.component.html',
  styleUrls: ['./form-field-select.component.scss'],
})
export class FormFieldSelectComponent implements OnInit {
  @Input() fieldName: string;
  @Input() controlName: string;
  @Input() required = false;
  @Input() multiple = false;
  @Input() compareWith = false;
  @Input() options: BasicStructure[];
  @Input() formGroup: FormGroup;
  @Output() selectionChange = new EventEmitter<any>();

  constructor() {}

  get formControl(): AbstractControl {
    return this.formGroup.controls[this.controlName];
  }

  ngOnInit(): void {}

  onChangeSelect(event) {
    this.selectionChange.emit(event);
  }

  compareObjectsById(o1: any, o2: any): boolean {
    if (this.compareWith) {
      return o1?.id === o2?.id;
    }
    return false;
  }
}
