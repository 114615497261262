import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BasicStructure } from 'functions/src/models/common/BasicStructure';
import { map } from 'rxjs/operators';
import { InstituicaoFinanceira } from '../../admin/instituicao-financeira/instituicao-financeira';
import { UtilHandler } from './util.handler';

@Injectable({
  providedIn: 'root',
})
export class FinanciamentoHandler {
  constructor(private angularFirestore: AngularFirestore) {}

  getInstituicoesFinanceirasAtivas() {
    const instFinancRef = this.angularFirestore.collection(`instituicoes-financeiras`, (x) =>
      x.where('ativo.id', '==', 'sim')
    );
    return instFinancRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as InstituicaoFinanceira;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  calcularLimiteCredito(valorFaturamento: number, valorDivida: number) {
    const limiteCredito = 0.3 * valorFaturamento - valorDivida;
    return limiteCredito;
  }

  calcularCapitalMaximo(valorFaturamento: number) {
    const capitalMaximo = 0.3 * valorFaturamento;
    return capitalMaximo;
  }

  calcularEndividamento(valorDivida: number, valorFaturamento: number) {
    const endividamento = valorDivida / valorFaturamento;
    return endividamento;
  }

  tratarGarantias(garantiasUsuario, listaInstFinanceiras) {
    const financeiras: InstituicaoFinanceira[] = [];
    if (garantiasUsuario !== undefined && garantiasUsuario.length > 0) {
      this.tratarUsuarioComGarantia(garantiasUsuario, listaInstFinanceiras, financeiras);
    } else {
      this.tratarUsuarioSemGarantia(listaInstFinanceiras, financeiras);
    }

    return financeiras;
  }

  tratarUsuarioComGarantia(garantiasUsuario, listaInstFinanceiras, financeiras) {
    listaInstFinanceiras.forEach((f1) => {
      f1.garantiasFin = [];
      f1.garantias.forEach((f2) => {
        garantiasUsuario.forEach((userGarantias) => {
          if (userGarantias.id === f2.mnemonico && f2.mnemonico !== 'avalista') {
            f1.garantiasFin.push(f2.nome);
          }
        });
        if (f2.mnemonico === 'avalista') {
          f1.garantiasFin.push(f2.nome);
        }
      });
      if (f1.garantiasFin.length > 0) {
        financeiras.push(f1);
      }
    });
  }

  tratarUsuarioSemGarantia(listaInstFinanceiras, financeiras) {
    listaInstFinanceiras.forEach((f1) => {
      f1.garantiasFin = [];
      f1.garantias.forEach((f2) => {
        if (f2.mnemonico === 'avalista') {
          f1.garantiasFin.push(f2.nome);
        }
      });
      if (f1.garantiasFin.length > 0) {
        financeiras.push(f1);
      }
    });
  }

  obterValorOperacao(instituicaoFinanceira, valorFaturamento) {
    const capitalMaximo = this.calcularCapitalMaximo(valorFaturamento);
    let valorOp = '';
    if (instituicaoFinanceira.capitalMinimo > 0) {
      valorOp = instituicaoFinanceira.capitalMinimoStr + ' ';
    }
    if (instituicaoFinanceira.capitalMaximo > 0 && instituicaoFinanceira.capitalMaximo < capitalMaximo) {
      valorOp += 'até ' + instituicaoFinanceira.capitalMaximoStr;
    } else {
      valorOp += 'até ' + UtilHandler.getFormattedPrice(capitalMaximo);
    }
    if (valorOp === '') {
      valorOp = ' - ';
    }
    return valorOp;
  }

  validarInstFinancAdicionada(idBanco: string, bancosUsuario: BasicStructure[]) {
    let bancoAdicionado = false;
    bancosUsuario.forEach((banco) => {
      if (banco.id === idBanco) {
        bancoAdicionado = true;
      }
    });
    return bancoAdicionado;
  }

  //   getInstitFinancByUserData(avaliacaoChecklist: AvaliacaoChecklist) {
  //     let financeiras: InstituicaoFinanceira[];
  //     let listCreditoPreAprovado: CreditoPreAprovado[];
  //     let valorDivida = 0;
  //     if (avaliacaoChecklist.valorDivida) {
  //       valorDivida = avaliacaoChecklist.valorDivida;
  //     }
  //     let valorFaturamento = 0;
  //     if (avaliacaoChecklist.valorFaturamento) {
  //       valorFaturamento = avaliacaoChecklist.valorFaturamento;
  //     }
  //     const endividamento = this.calcularEndividamento(valorDivida, valorFaturamento);
  //     const limiteCredito = this.calcularLimiteCredito(valorFaturamento, valorDivida);

  //     return new Promise<CreditoPreAprovado[]>((resolver) => {
  //       this.getInstituicoesFinanceirasAtivas().subscribe((listaInstFinanceiras) => {
  //         financeiras = this.tratarGarantias(avaliacaoChecklist.garantias, listaInstFinanceiras);
  //         listCreditoPreAprovado = [];

  //         financeiras = financeiras.filter(
  //           (filter) => valorFaturamento >= filter.faturamentoMinimo && valorFaturamento <= filter.faturamentoMaximo
  //         );

  //         if (endividamento > 0.25) {
  //           financeiras = financeiras.filter((filter) => filter.endividamento25p.id === 'sim');
  //         }

  //         financeiras = financeiras.filter((filter) => !filter.naoIncluirAvaliacao);

  //         let count = 1;
  //         financeiras.forEach((item) => {
  //           const valorOp = this.obterValorOperacao(item, valorFaturamento);
  //           // Exclui os bancos que o usuário é cliente (tem conta).
  //           if (!this.validarInstFinancAdicionada(item.nomeNoSistema, avaliacaoChecklist.bancos)) {
  //             // Verificar se a instituição permite restrições
  //             if (
  //               item.pefin &&
  //               item.pefin.id === 'nao' &&
  //               avaliacaoChecklist.pefin &&
  //               avaliacaoChecklist.pefin.id === 'sim'
  //             ) {
  //               // A instituição tem a informação de pefin e não permite usuários com essa restrição.
  //               // O usuário tem a informação de pefin e tem restrição.
  //               return;
  //             } else if (
  //               item.refin &&
  //               item.refin.id === 'nao' &&
  //               avaliacaoChecklist.refin &&
  //               avaliacaoChecklist.refin.id === 'sim'
  //             ) {
  //               // A instituição tem a informação de refin e não permite usuários com essa restrição.
  //               // O usuário tem a informação de refin e tem restrição.
  //               return;
  //             } else if (
  //               item.chequeSemFundo &&
  //               item.chequeSemFundo.id === 'nao' &&
  //               avaliacaoChecklist.chequeSemFundo &&
  //               avaliacaoChecklist.chequeSemFundo.id === 'sim'
  //             ) {
  //               // A instituição tem a informação de cheques sem fundos e não permite usuários com essa restrição.
  //               // O usuário tem a informação de cheques sem fundos e tem restrição.
  //               return;
  //             } else if (
  //               item.fraude &&
  //               item.fraude.id === 'nao' &&
  //               avaliacaoChecklist.fraude &&
  //               avaliacaoChecklist.fraude.id === 'sim'
  //             ) {
  //               // A instituição tem a informação de fraude e não permite usuários com essa restrição.
  //               // O usuário tem a informação de fraude e tem restrição.
  //               return;
  //             } else {
  //               listCreditoPreAprovado.push(<CreditoPreAprovado>{
  //                 id: count,
  //                 nome: item.nome,
  //                 valor: valorOp,
  //                 modalidade: item.modalidade,
  //                 modalidadesStr: item.modalidade.map((o) => o.nome).join(', '),
  //                 prazo: item.prazo,
  //                 garantia: item.garantiasFin.join(', '),
  //                 limiteCredito: limiteCredito,
  //                 instituicaoFinanceiraId: item.id,
  //                 linhaAdicional: false,
  //                 linhaAtiva: false,
  //                 linhaFechada: false,
  //                 nomeNoSistema: item.nomeNoSistema,
  //                 motivoCancelamento: null,
  //                 situacao: null,
  //                 nameOppSalesforce: null,
  //                 idProposta: null,
  //                 urlProposta: null,
  //                 idSalesforce: null,
  //                 limiteOperacao: null,
  //                 limiteOperacaoStr: null,
  //                 taxa: null,
  //                 descricao: null,
  //                 faseOportunidade: {
  //                   id: 'Identificado',
  //                   name: 'Identificado',
  //                   defaultprobability: 10,
  //                   defaultprobabilitystr: '10%',
  //                   sortorder: 1,
  //                 },
  //                 contaBancariaSalesforce: item.contaBancariaSalesforce ? item.contaBancariaSalesforce : null,
  //               });
  //               count++;
  //             }
  //           }
  //         });
  //         resolver(listCreditoPreAprovado);
  //       });
  //     });
  //   }
}
