import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoneyplusEntity } from '../../../../functions/src/models/moneyplus/Moneyplus';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ScrBmpService {

  constructor(private firestore: AngularFirestore,  private http: HttpClient, private authService: AuthService) { }

  getDataFromScrBmp(uid: string): Observable<MoneyplusEntity> {
    return this.firestore.collection('scr-bmp-data-history', 
      ref => ref.where('uid', '==', uid).orderBy('createdAt', 'desc').limit(1)).get().pipe(
        map(querySnapshot => {
          const dataArray = querySnapshot.docs.map(doc => doc.data() as MoneyplusEntity);
          return dataArray.length > 0 ? dataArray[0] : null;
        })
      );
  }

  getIntegrationFromScrBmp(customerId: string): Promise<MoneyplusEntity> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/scrbmpapi/consult-scr`,
          { data: {customerId} },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((result) => {
          resolve(result as MoneyplusEntity);
        })
        .catch((err) => {
          console.error('Error getting general user extra data', err);
          reject(err);
        });
    });
  }
}
