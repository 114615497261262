import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { groupBy } from 'lodash';
import { User } from '../../../../../functions/src/models/User';
import { Customer } from '../../../../../functions/src/models/customers/Customer';
import {
  Document as DocumentMongoDB
} from '../../../../../functions/src/models/documents/UserDocument';
@Component({
  selector: 'app-expansion-doc-partner',
  templateUrl: './expansion-doc-partner.component.html',
  styleUrls: ['../user-detail.component.scss', '../expansion-doc-item/expansion-doc-item.component.scss', './expansion-doc-partner.component.scss']
})
export class ExpansionDocPartnerComponent implements OnInit, OnChanges {
  @Input() documents: DocumentMongoDB[];
  @Input() customer: Customer;
  @Input() loggedUser: Partial<User>;
  partnersDoc: { partner: string; docs: DocumentMongoDB[]; situation: string; socioQual: string; socioCPF: string }[];
  constructor() {
    //
  }

  ngOnInit(): void {
    this.getDocuments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents || changes.documents.currentValue.length !== changes.documents.previousValue.length) {
      this.getDocuments();
    }
  }

  getDocuments(): void {
    const docRecord = groupBy(this.documents, (doc) => doc.socioName);
    this.partnersDoc = Object.keys(docRecord).map(partner => ({
      partner,
      docs: docRecord[partner],
      situation: this.getSituation(docRecord[partner]) || 'Pendente',
      socioQual: docRecord[partner].find(d => d?.socioQual)?.socioQual || '',
      socioCPF: docRecord[partner].find(d => d?.socioCPF)?.socioCPF || '',

    }))

  }
  getSituation(docs: DocumentMongoDB[]): string {
    const situationDocs = Object.keys(groupBy(docs, doc => doc.situation))

    if (situationDocs.length === 1) return situationDocs[0]
    if (situationDocs.includes('Pendente')) return 'Pendente';
    if (situationDocs.includes('Envio Completo')) return 'Envio Completo';

    return 'Pendente';
  }

  getBorderStyle(docsLength: number, idx: number): "-first-child" | "-last-child" | "" {
    return idx === 0 ? '-first-child' : ((docsLength - 1) === idx ? '-last-child' : '')
  }

  hasPartnerAdmin(text: string): string {
    return text.toLocaleUpperCase().includes('ADMIN') ? 'Sim' : 'Não';
  }

  onDeleteDoc(mnemonic: string): void {
    const index = this.documents.findIndex(d => d.mnemonic === mnemonic)
    this.documents.splice(index, 1);
    this.getDocuments();
  }
}
