<div class="content">
  <div class="detail">
    <div>
      <h1>CRÉDITO PARA SUA EMPRESA</h1>
      <p>
        Recebemos sua solicitação de crédito pelo Canal MPME do BNDES. Para dar andamento, complete o cadastro em nosso
        painel.
      </p>
      <p>Temos mais de 300 instituições financeiras para ajudá-lo a conseguir o MELHOR empréstimo empresarial!</p>
    </div>
  </div>
  <div class="content-area">
    <div class="content-util-area">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
</div>
