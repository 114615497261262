import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Banker, BankerEvent, BankersResult } from 'functions/src/models/Banker';
import { Customer } from 'functions/src/models/Customer';
import { InstituicaoFinanceira } from 'functions/src/models/model-interface';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankerService } from 'src/app/bankers/services/banker.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankersAdminService {
  size;
  private readonly ibgeAPI = 'https://servicodados.ibge.gov.br/api/v1/localidades';

  constructor(
    private angularFirestore: AngularFirestore,
    private bankerService: BankerService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.size = angularFirestore
      .collection('bankers')
      .snapshotChanges()
      .pipe(
        map((snapshot) => {
          return snapshot.length;
        })
      );
  }

  changeBankerPassword(uid: string, password: string, shouldNotifyBanker: boolean, loggedAdmin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/banker/change-password`,
          {
            banker: { uid, password },
            admin: loggedAdmin,
            shouldNotify: shouldNotifyBanker,
            redirect: `${environment.baseURL}/bankers/login`,
          },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Banker password updated');
          resolve();
        })
        .catch((err) => {
          console.error('Error changing banker password', err);
          reject('error-changing-password');
        });
    });
  }

  async createBanker(bankerData: Banker, password: string, loggedAdmin: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const banker: Banker = {
        ...bankerData,
        isActive: true,
        manuallyCreated: true,
        notifications: {
          email: {
            chatMessages: true,
            opportunityClosed: true
          },
          statusChanged: true,
          opportunityCreate: true,
          opportunityUpdate: true,
          opportunityAccepted: true,
          opportunityRejected: true,
        },
        status: 'approved',
      };

      this.http
        .post(
          `${environment.functionsUrl}/banker/create-manually`,
          { banker: { ...banker, password }, admin: loggedAdmin, redirect: `${environment.baseURL}/bankers/login` },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          return resolve('success');
        })
        .catch((err) => {
          console.error('Problemas ao tentar criar um novo banker.', err);
          if (err.error) {
            if (err.error.isExistentAuth) {
              return resolve('email-in-use');
            } else if (err.error === 'error-sending-email') {
              return resolve('error-sending-email');
            } else {
              return reject(err);
            }
          } else {
            return reject(err);
          }
        });
    });
  }

  disableBanker(uid: string, loggedAdmin?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`bankers/${uid}`)
        .update({ isActive: false, status: 'suspended' })
        .then(() => {
          this.bankerService.registerBankerLog(uid, BankerEvent.disabled, loggedAdmin || undefined);
          resolve();
        })
        .catch((err) => {
          console.error('Error disabling banker', err);
          reject(err);
        });
    });
  }

  enableBanker(uid: string, loggedAdmin?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`bankers/${uid}`)
        .update({ isActive: true, status: 'approved' })
        .then(() => {
          this.bankerService.registerBankerLog(uid, BankerEvent.enabled, loggedAdmin || undefined);
          resolve();
        })
        .catch((err) => {
          console.error('Error enabling banker', err);
          reject(err);
        });
    });
  }

  generatePassword(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array(length)
      .fill('')
      .map(() => charset.charAt(Math.random() * charset.length))
      .join('');
  }

  getBankerData(uid): Observable<Banker> {
    return this.angularFirestore.collection('bankers').doc(uid.trim()).valueChanges();
  }

  getBankersOfOpportunity(institution: string, customer: string, address: Customer['address']): Promise<Banker[]> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('bankers', (ref) => ref.where('institution.name', '==', institution).where('isActive', '==', true))
        .get()
        .toPromise()
        .then(async (snapshot) => {
          if (snapshot.empty) {
            resolve([]);
          } else {
            const bankers = snapshot.docs.map(async (d) => {
              const banker = d.data() as Banker;

              if (banker.isRegionalized) {
                // se adress do costumer nao se enquadrar com nenhuma localidade das regionalizaçao
                let status = false;

                banker.regionalization.forEach((r) => {
                  if (!status) {
                    switch (r.type) {
                      case 'state':
                        if (r.state.abdr === address?.state) {
                          if (!r.city || r.city.name?.toUpperCase() === address?.city?.toUpperCase()) {
                            status = true;
                          }
                        }
                        break;

                      case 'region':
                        if (_.some(r.region.states, (state) => state.abdr === address?.state)) {
                          status = true;
                        }
                        break;

                      default:
                        break;
                    }

                    if (r.zipCode && r.zipCodeDigitQuantity) {
                      const regionZipCode = r.zipCode?.replace(/\D/g, '').slice(0, r.zipCodeDigitQuantity);
                      const addressZipCode = address?.zipCode?.replace(/\D/g, '').slice(0, r.zipCodeDigitQuantity);

                      if (regionZipCode === addressZipCode) {
                        status = r.type === 'zipcode' || status;
                      }
                    }
                  }
                });

                if (!status) {
                  return null;
                } else {
                  try {
                    const excludedSnapshot = await this.angularFirestore
                      .collection('bankers')
                      .doc(banker.uid)
                      .collection('region-exclusion')
                      .get()
                      .toPromise();
                    if (!excludedSnapshot.empty) {
                      const excluded = excludedSnapshot.docs.map((exc) => exc.data().user);
                      if (excluded.indexOf(customer) >= 0) {
                        return null;
                      } else {
                        return banker;
                      }
                    } else {
                      return banker;
                    }
                  } catch (err) {
                    return null;
                  }
                }
              } else {
                return banker;
              }
            });

            resolve(await Promise.all(_.filter(bankers, (b) => !!b)));
          }
        })
        .catch((err) => reject(err));
    });
  }

  getInstitutions(): Promise<InstituicaoFinanceira[]> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('instituicoes-financeiras')
        .get()
        .toPromise()
        .then((snapshot) => {
          if (!snapshot.empty) {
            let institutions = snapshot.docs.map((d) => d.data() as InstituicaoFinanceira);
            institutions = _.sortBy(institutions, ['nome']);
            resolve(institutions);
          } else {
            console.error('Empty institutions result');
            reject('empty-snapshot');
          }
        })
        .catch((err) => {
          console.error('Error getting institutions');
          reject(err);
        });
    });
  }

  searchBankers({ filterField, filterValue, orderField, orderDirection, pageSize, page }): Promise<BankersResult> {
    return new Promise((resolve, reject) => {
      const params = `filterField=${filterField}&filterValue=${filterValue}&orderField=${orderField}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`;

      this.http
        .get(`${environment.functionsUrl}/banker/search?${params}`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          resolve(res as BankersResult);
        })
        .catch((err) => {
          console.error('Error getting bankers.', err);
          reject(err);
        });
    });
  }

  updateBankerData(uid: string, data, loggedAdmin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const bankerRef: AngularFirestoreDocument<Banker> = this.angularFirestore.doc(`bankers/${uid}`);

        bankerRef
          .set(data, { merge: true })
          .then(() => {
            console.log('Banker data updated on database');

            this.bankerService.registerBankerLog(uid, BankerEvent.updated, loggedAdmin || undefined);
            resolve();
          })
          .catch((err) => {
            console.error('Error updating banker data', err);
            reject('error-updating-data');
          });
      }
    });
  }
  // regionalization
  getStates() {
    return this.http.get<any[]>(`${this.ibgeAPI}/estados?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getRegions() {
    return this.http.get<any[]>(`${this.ibgeAPI}/regioes?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }
  getUfsRegions(id: number) {
    return this.http.get<any[]>(`${this.ibgeAPI}/regioes/${id}/estados?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getCities(uf: number) {
    return this.http.get<any[]>(`${this.ibgeAPI}/estados/${uf}/municipios?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }
}
