import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { agentsSidenavAllowedURLs, breakpoints, environmentLabels } from 'src/app/constants';
import { environment as env } from 'src/environments/environment';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'app-agent-template',
  templateUrl: './template-agents.component.html',
  styleUrls: ['./template-agents.component.scss'],
})
export class TemplateAgentsComponent implements OnInit, OnDestroy {
  readonly urlPrivacyPolicy =
    'https://f.hubspotusercontent10.net/hubfs/8256969/Institucional/Pol%C3%ADtica%20de%20Privacidade%20-%20Capital%20Empreendedor.pdf';

  isAuthenticatedAndApproved = false;

  isProd = env.envName === 'PROD';
  envName = env.envName;
  envLabel = environmentLabels[env.envName] || environmentLabels['DEV:LOCAL'];
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = 'assets/images/logo.xs.png';
  logoFull =
    'https://firebasestorage.googleapis.com/v0/b/ce-dev-8dcce.appspot.com/o/assets%2Fimages%2Fsce_complete_logo.png?alt=media&token=47dbe7ca-6426-428d-a818-f87aecf59c98';

  mobileQuery: MediaQueryList;
  toggled = false;
  expanded = true;
  visible = false;

  isLoading = true;

  agentSubscription: Subscription;
  routerSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private agentService: AgentService,
    media: MediaMatcher
  ) {
    // Listener p/ resize de tela -> Ajuste da sidebar
    this.mobileQuery = media.matchMedia(`(max-width: ${breakpoints.lg}px)`);
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.toggled = !this.mobileQuery.matches;
  }

  ngOnInit(): void {
    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.isAuthenticatedAndApproved =
        !!agent && !agent.isAnonymous && agent.emailVerified && agent.isActive && agent.isApprovedByAdmin;
      this.visible = this.shouldSidenavBeVisible(this.router.url);
      this.isLoading = !agent && this.router.url.indexOf('signup') < 0;
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.visible = this.shouldSidenavBeVisible(event.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private _mobileQueryListener(): void  {
    return this.changeDetectorRef.detectChanges();
  }

  isPageWithSidenav(url: string = this.router.url): boolean {
    return agentsSidenavAllowedURLs.map((u) => url.indexOf(u) >= 0).reduce((r, u) => r || u, false);
  }

  shouldSidenavBeVisible(url: string = this.router.url): boolean {
    return this.isAuthenticatedAndApproved && this.isPageWithSidenav(url);
  }

  toggle(): void {
    // Sidebar toggle
    if (this.mobileQuery.matches) {
      this.toggled = !this.toggled;
      this.expanded = true;
    } else {
      this.toggled = true;
      this.expanded = !this.expanded;
    }
  }
}
