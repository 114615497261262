import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import _ from 'lodash';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/core/auth/auth.service';
import { AgentService } from '../../services/agent.service';

import { UtilHandler } from 'src/app/core/handler/util.handler';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';

export interface Lead {
  email: string;
  valid: boolean;
  color: string;
}

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class AgentInviteComponent implements OnInit, OnDestroy {
  agent: { id: string; name: string; email: string } = { id: '', name: '', email: '' }; // Only UID and name
  agentSubscription: Subscription;
  isLoading = {
    agent: true,
    config: true,
  };

  agentLink = '';

  // Invite tool
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  leads: Lead[] = [];
  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = true;
  limit = 10;
  disabled = true;
  hasError = false;
  isProcessing = false;

  // Message Preview
  @ViewChild('messagePreview', { static: false }) messagePreview: ElementRef;
  configSubscription: Subscription;
  subject = '';
  message = '';

  constructor(
    private authService: AuthService,
    private agentService: AgentService,
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoading = {
      agent: true,
      config: true,
    };
    this.agentService.redirectAccordingStatus(true, '/agents/invite', '/agents/invite');

    this.agentSubscription = this.agentService.agent.subscribe((agent) => {
      this.agent = {
        id: agent.uid,
        name: agent.name,
        email: agent.email,
      };
      this.agentLink = `${environment.baseURL}/cadastrar/${this.agent.id}`;
      this.isLoading.agent = false;
    });

    this.configSubscription = this.agentService.getAgentConfig().subscribe((config) => {
      this.subject = config.email.leadInvite.subject;
      this.message = config.email.leadInvite.message
        .replace('{{agentName}}', this.agent.name)
        .replace('{{agentLink}}', this.agentLink);
      this.messagePreview.nativeElement.innerHTML = this.message;
      this.messagePreview.nativeElement.children[0].style['margin'] = `1em auto`;
      this.limit = config.inviteLimit;
      this.isLoading.config = false;
    });
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }

  addLead(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add lead
    if ((value || '').trim()) {
      const valid = !!value.toLowerCase().match(UtilHandler.emailRegex);

      if (!_.find(this.leads, ['email', value.trim().toLowerCase()])) {
        this.leads.push({
          email: value.trim().toLowerCase(),
          valid,
          color: valid ? '' : 'warn',
        });
      }

      this.hasError = this.hasInvalidLeads();
      this.disabled = this.leads.length === 0 || this.leads.length > this.limit || this.hasError;
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeLead(lead: Lead): void {
    const index = this.leads.indexOf(lead);

    if (index >= 0) {
      this.leads.splice(index, 1);
      this.hasError = this.hasInvalidLeads();
      this.disabled = this.leads.length === 0 || this.leads.length > this.limit || this.hasError;
    }
  }

  hasInvalidLeads(): boolean {
    return _.find(this.leads, ['valid', false]) !== undefined;
  }

  sendInvites(): void {
    this.isProcessing = true;
    if (!this.hasInvalidLeads()) {
      this.http
        .post(
          `${environment.functionsUrl}/email/agent-lead-invite`,
          {
            agent: this.agent,
            subject: this.subject,
            message: this.message,
            recipients: this.leads.map((l) => l.email),
          },
          {
            responseType: 'text',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then((res) => {
          console.log('Convites enviados');
          this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '700px',
              data: {
                alertTitle: 'Convite enviado',
                alertDescription: `Os convites foram enviados com sucesso!`,
                isOnlyConfirm: true,
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.leads = [];
              this.isProcessing = false;
            });
        })
        .catch((err) => {
          console.error('Erro ao enviar convites por e-mail', err);
          this.dialog
            .open(AlertDialogComponent, {
              maxWidth: '700px',
              data: {
                alertTitle: 'Erro ao enviar convites',
                alertDescription: `Ocorreu um erro ao enviar os convites aos seus leads. Por favor, tente novamente mais tarde.`,
                isOnlyConfirm: true,
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.isProcessing = false;
            });
        });
    }
  }
}
